import { AssetMedia } from 'config/types/asset';
import { StorageService } from 'services/StorageService';
import { useAsyncAction } from 'hooks/useAsyncAction';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { DatabaseService } from 'services/DatabaseService';
import { merchantAssetsActions } from 'state/merchantAssets/merchantAssetsSlice';

type StateLogic = [(data: AssetMedia) => Promise<void>, boolean];

export const useAddMediaToAsset = (
    assetId: string,
    noCatch?: boolean
): StateLogic => {
    const dispatch = useAppDispatch();

    const addNewMedia = async (data: AssetMedia): Promise<void> => {
        const { file, ...rest } = data;
        // Save file to storage and get url pointing to it
        const uri = await StorageService.uploadMedia(file);
        // We save the storage uri and media type as an array as part of the asset data
        const mediaData = { ...rest, uri };
        const media = await DatabaseService.addMediaToAsset(assetId, mediaData);
        dispatch(merchantAssetsActions.addMediaToAsset({ assetId, media }));
    };

    const [addNewDocWithTryCatch, processing] = useAsyncAction<
        AssetMedia,
        void
    >(addNewMedia, {
        error: 'errorAddingDocument'
    });

    return [noCatch ? addNewMedia : addNewDocWithTryCatch, processing];
};
