import { styled } from '@mui/material/styles';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link, useLocation } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import 'react-perfect-scrollbar/dist/css/styles.css';

import { Row } from 'components/Row';
import { Routes } from 'components/Routes';
import { Notifications } from 'features/notifications/Notifications';
import { menuActions } from 'state/menu/menuSlice';
import { getMenuState } from 'state/menu/selectors';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { LanguageSelect } from 'components/LanguageSelect';
import { ToggleSidebarButton } from 'components/ToggleSidebarButton';
import { CollapseSidebarButton } from 'components/CollapseSidebarButton';
import { getUserName } from 'state/user/selectors';
import {
    getUserBalance,
    isUserAdmin,
    isUserLoggedIn
} from 'state/user/selectors';
import { Box } from '@mui/material';

import { getUserData } from 'state/user/selectors';
import { User } from 'config/types/user';
import { useAppSelector } from 'hooks/useAppSelector';

const Container = styled('main')(({ theme }) => ({
    flexGrow: 1,
    backgroundColor: theme.palette.success.main,
    color: theme.palette.primary.contrastText
}));

const Content = styled('div')`
    min-height: 100%;
`;

const Right = styled(Row)(({ theme }) => ({
    marginLeft: 'auto',
    [theme.breakpoints.down('md')]: {
        marginLeft: 15
    }
}));

const BoxLux = styled(Box)(({ theme }) => ({
    color: theme.palette.success.light,
    paddingTop: '.25rem',
    paddingLeft: '-.5rem',
    fontSize: '14px',
    zIndex: 200,
    '@media (max-width: 540px)': {
        position: 'absolute',
        top: '4.2rem',
        right: '1.4rem',
        fontSize: '10px',
        placeSelf: 'center',
        marginLeft: '0'
    },
    '@media (max-width: 290px)': {
        position: 'absolute',
        top: '4.2rem',
        right: '1rem',
        fontSize: '8px',
        placeSelf: 'center',
        marginLeft: '0'
    }
}));

const Title = styled('div')(({ theme }) => ({
    color: theme.palette.primary.contrastText
}));

export const Main = (): JSX.Element => {
    const { pathname } = useLocation();

    const userBalance: number = useAppSelector(getUserBalance);

    const ps = useRef<HTMLElement>();

    const dispatch = useAppDispatch();

    const menuState = useAppSelector(getMenuState);

    const userIsAdmin = useAppSelector(isUserAdmin);

    const fullName = useAppSelector(getUserName);
    const firstName = fullName?.split(' ')[0];

    const userIsLoggedIn = useAppSelector(isUserLoggedIn);

    const titles = [
        {
            urlPathName: '/portfolio',
            title: `Welcome back ${firstName}!`
        },
        {
            urlPathName: '/invest',
            title: 'Fractional Investing'
        },
        {
            urlPathName: '/invest-chart',
            title: 'Fractional Investing'
        },
        {
            urlPathName: '/investing',
            title: 'Fractional Investing'
        },
        {
            urlPathName: '/item-gallery',
            title: 'Item Gallery'
        },
        {
            urlPathName: '/marketplace',
            title: 'Marketplace'
        },
        {
            urlPathName: '/merchant-assets',
            title: 'Assets'
        },
        {
            urlPathName: '/staking',
            title: 'Staking'
        },
        {
            urlPathName: '/my-wallet',
            title: 'Wallet'
        },
        {
            urlPathName: '/contact-us',
            title: 'Support'
        },
        {
            urlPathName: '/settings',
            title: 'Settings'
        },
        {
            urlPathName: '/admin/asset-list',
            title: 'Admin'
        },
        {
            urlPathName: '/profile',
            title: 'My Profile'
        },
        {
            urlPathName: '/edit-profile',
            title: 'Edit Profile'
        }
    ];
    const user: User = useAppSelector(getUserData);
    const customer = user.userType === 'customer';

    const getTitle = (): any => {
        const titleObj = titles.find((t) => pathname.startsWith(t.urlPathName));
        return titleObj ? titleObj.title : '';
    };

    const handleCollapsedChange = (): void => {
        dispatch(menuActions.toggleCollapse());
    };

    const handleToggleSidebar = (): void => {
        dispatch(menuActions.toggleOpen());
    };

    const scrollTop = (): void => {
        const curr = ps.current;
        if (curr) {
            curr.scrollTop = 0;
        }
    };
    const CustomIcon = styled(AdminPanelSettingsIcon)(({ theme, color }) => ({
        color: theme.palette.success.light
    }));

    useEffect(() => {
        scrollTop();
    }, [pathname]);

    return (
        <Container>
            <PerfectScrollbar
                containerRef={(el) => {
                    ps.current = el;
                }}>
                <Content
                    sx={{
                        paddingTop: '25px',
                        margin: 'auto'
                    }}>
                    <Row
                        sx={{
                            mb: 1,
                            width: '90vw',
                            margin: 'auto',
                            overflow: 'auto',
                            paddingX: '12px',
                            '@media (min-width: 1500px)': {
                                width: '90vw',
                                marginBottom: '10px',
                                marginLeft: '17px',
                                marginRight: '17px'
                            },
                            '@media (max-width: 420px)': {
                                marginBottom: '10px',
                                marginLeft: '17px',
                                marginRight: '17px'
                            },
                            '@media (max-width: 290px)': {
                                marginBottom: '10px',
                                marginLeft: '12px',
                                marginRight: '12px'
                            }
                        }}
                        justifyContent="space-between">
                        <Row
                            sx={{
                                zIndex: 200,
                                bgcolor: 'main.black'
                            }}>
                            <ToggleSidebarButton toggle={handleToggleSidebar} />
                            <CollapseSidebarButton
                                collapsed={menuState.collapsed}
                                onToggle={handleCollapsedChange}
                            />
                        </Row>
                        <Title
                            sx={{
                                fontSize: '29px',
                                fontFamily: 'jost',
                                fontWeight: '600',
                                marginLeft: '30px',
                                marginTop: '-10px',
                                zIndex: 150,
                                '@media (max-width: 635px)': {
                                    fontSize: '25px',
                                    placeSelf: 'center'
                                },
                                '@media (max-width: 540px)': {
                                    position: 'absolute',
                                    top: '4rem',
                                    fontSize: '14px',
                                    placeSelf: 'center',
                                    marginLeft: '6px',
                                    paddingTop: '12px'
                                },
                                '@media (max-width: 420px)': {
                                    fontSize: '12px',
                                    placeSelf: 'center',
                                    marginLeft: '0px',
                                    paddingTop: '15px'
                                },
                                '@media (max-width: 290px)': {
                                    fontSize: '8px',
                                    placeSelf: 'center',
                                    marginLeft: '0px',
                                    paddingTop: '17px'
                                }
                            }}>
                            {getTitle()}
                        </Title>
                        <Right
                            spacing={2}
                            alignItems="center"
                            sx={{
                                '@media (max-width: 360px)': {
                                    marginLeft: '-2rem',
                                    overflow: 'hidden'
                                }
                            }}>
                            {userIsLoggedIn && (
                                <BoxLux>Balance: {userBalance} Lux</BoxLux>
                            )}
                            {userIsLoggedIn && <Notifications />}
                            <LanguageSelect />
                            {userIsAdmin && (
                                <Link to="/admin/asset-list">
                                    <CustomIcon />
                                </Link>
                            )}
                        </Right>
                    </Row>
                    <Routes />
                </Content>
            </PerfectScrollbar>
        </Container>
    );
};
