import logo from 'assets/logo.svg';
import { SITE_TITLE } from 'config/constants';
import { HTMLAttributes } from 'react';
import styled from 'styled-components';

type Props = {
    width?: string;
    style?: React.CSSProperties;
};

const LogoContainer = styled('img')(({ theme }) => ({}));
export const Logo = ({ width, style }: Props): JSX.Element => (
    <LogoContainer src={logo} alt={SITE_TITLE} width={width} style={style} />
);
