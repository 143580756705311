import { useEffect } from 'react';
import { Box, Stack, useTheme } from '@mui/material';

import { Row } from 'components/Row';
import { Spinner } from 'components/Spinner';
import { ListedItem } from 'config/types/asset';
import { Typography } from 'components/Typography';
import { DarkButton } from 'components/buttons/DarkButton';
import { LightDivider } from 'components/LightDivider';
import { ImageMagnifier } from 'features/imageMagnifier/ImageMagnifier';

import { BidInput } from './BidInput';
import { ItemPrice } from './ItemPrice';
import { ItemSpecs } from './ItemSpecs';
import { LinkToPictures } from './LinkToPictures';
import { ItemHighestBid } from './ItemHighestBid';
import { EnterBidButton } from './EnterBidButton';
import { useMarketItemSpecPanel } from './useMarketItemSpecPanel';

type Props = {
    data: ListedItem;
    selected: number;
    onSelectPic: (picIndex: number) => void;
};

export const MarketItemSpecPanel = ({
    data,
    selected = 0,
    onSelectPic
}: Props): JSX.Element => {
    const { coaURI, pictures, highestBid, fetchingBids } =
        useMarketItemSpecPanel(data);
    const theme = useTheme();
    console.log(pictures);
    console.log(selected);

    return (
        <Stack
            sx={{
                color: theme.palette.primary.contrastText,
                height: { sm: '100%', md: 'calc(100vh - 70px)' }
            }}
            justifyContent="space-between">
            <Stack sx={{ mt: 4 }} spacing={4}>
                <Typography uppercased variant="h4">
                    {data.name}
                </Typography>
                <Typography variant="subtitle2">{data.description}</Typography>
                <Row spacing={5} alignItems="baseline">
                    <ItemPrice data={data.price} />
                    <Box
                        sx={{
                            minWidth: 100,
                            textAlign: 'center'
                        }}>
                        {fetchingBids ? (
                            <Spinner />
                        ) : (
                            <ItemHighestBid data={highestBid} />
                        )}
                    </Box>
                </Row>
                <LightDivider />
                <ItemSpecs data={data} />
            </Stack>
            <Box sx={{ mt: 4 }}>
                <Row justifyContent={coaURI ? 'space-between' : 'flex-end'}>
                    {coaURI && (
                        <a href={coaURI} target="_blank" rel="noreferrer">
                            <DarkButton textId="viewCOA" />
                        </a>
                    )}
                    <EnterBidButton item={data} />
                </Row>
                <Box sx={{ display: { sm: 'none' }, mt: 2 }}>
                    <ImageMagnifier
                        src={pictures[selected].uri}
                        mouseOffsetPosY={450}
                        backgroundOffsetPosY={1400}
                    />
                </Box>
                <Box sx={{ mt: 4, display: { md: 'none' } }}>
                    <LinkToPictures pics={pictures} onSelect={onSelectPic} />
                </Box>
            </Box>
        </Stack>
    );
};
