import { Row } from 'components/Row';
import { RedButton } from 'components/buttons/RedButton';
import { DarkButton } from 'components/buttons/DarkButton';
import { Typography } from 'components/Typography';
import { LightButton } from 'components/buttons/LightButton';
import { BlackButton } from 'components/buttons/BlackButton';
import { AssetItem, AssetListingStatus } from 'config/types/asset';

import { useAssetListingStatusUpdateButton } from './useAssetListingStatusUpdateButton';

type Props = {
    data: AssetItem;
    handleClose: () => void;
};

export const AssetListingStatusUpdateButton = ({
    data,
    handleClose
}: Props): JSX.Element => {
    const { onUpdateStatus, onApprove, updating, approving } =
        useAssetListingStatusUpdateButton(data, handleClose);

    const actionButton: Record<AssetListingStatus, JSX.Element> = {
        sold: <Typography sx={{ color: 'success.main' }} textId="sold" />,
        notListed: <LightButton loading={updating} textId="listItem" />,
        unverified: (
            <Typography sx={{ color: 'primary.light' }} textId="sold" />
        ),
        listed: (
            <Row spacing={3}>
                <RedButton
                    fullWidth
                    textId="markSold"
                    onClick={() => onUpdateStatus('sold')}
                    loading={updating}
                />
                <BlackButton
                    fullWidth
                    textId="delist"
                    onClick={() => onUpdateStatus('notListed')}
                    loading={updating}
                />
            </Row>
        ),
        denied: (
            <DarkButton
                fullWidth
                textId="approve"
                onClick={() => onUpdateStatus('listed')}
                loading={updating}
            />
        ),
        pending: (
            <Row spacing={3}>
                <RedButton
                    fullWidth
                    textId="deny"
                    onClick={() => onUpdateStatus('denied')}
                    loading={updating}
                    disabled={approving}
                />
                <LightButton
                    fullWidth
                    textId="approve"
                    onClick={() => onApprove(data)}
                    loading={approving}
                />
            </Row>
        ),
        processing: (
            <Row spacing={3}>
                <RedButton fullWidth textId="deny" disabled />
                <LightButton fullWidth textId="approve" loading />
            </Row>
        )
    };

    return actionButton[data.status];
};
