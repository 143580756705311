import sortBy from 'lodash/sortBy';
import { Box, Stack } from '@mui/material';

import { Row } from 'components/Row';
import { Typography } from 'components/Typography';

import { useChatRoom } from './useChatRoom';
import { ChatRoomInput } from './ChatRoomInput';
import { ChatRoomMessage } from './ChatRoomMessage';
import { ChatRoomSubmitButton } from './ChatRoomSubmitButton';

type Props = {
    assetId: string;
    clientId: string;
    merchantId: string;
};

export const ChatRoomPanel = ({
    assetId,
    clientId,
    merchantId
}: Props): JSX.Element => {
    const {
        text,
        userId,
        messages,
        submitting,
        sendMessage,
        handleTextChange
    } = useChatRoom(assetId, clientId, merchantId);

    return (
        <Box sx={{ width: 320 }}>
            {messages && messages.length ? (
                <Stack sx={{ mt: 3, mb: 6 }} spacing={2}>
                    {sortBy(messages, 'createdAt').map((message) => (
                        <ChatRoomMessage
                            key={message.createdAt}
                            data={message}
                            ownMessage={message.userId === userId}
                        />
                    ))}
                </Stack>
            ) : (
                <Typography
                    sx={{ mt: 4, lineHeight: '1.5em' }}
                    capitalized
                    color="text.secondary"
                    textId="startConversation"
                    variant="h4"
                />
            )}
            <Row
                sx={{
                    p: 1,
                    right: 0,
                    width: 360,
                    bottom: 0,
                    position: 'fixed',
                    backgroundColor: 'common.black'
                }}
                justifyContent="center"
                spacing={3}>
                <ChatRoomInput text={text} onChange={handleTextChange} />
                <ChatRoomSubmitButton
                    submit={sendMessage}
                    submitting={submitting}
                />
            </Row>
        </Box>
    );
};
