import { getFunctions, httpsCallable } from 'firebase/functions';

import { logger } from 'libs/logger';
import { getApp } from 'firebase/app';
import { IPFSMedia } from 'config/types';

import { useAsyncAction } from './useAsyncAction';

type StateLogic = [(dataObj: any) => Promise<string>, boolean];

const log = logger('useRearrangeAssetMediaToIPFS');

const functions = getFunctions(getApp());

type CloudFunctionError = {
    error: string;
};

type Media = {
    uri: string;
    type: string;
    name: string;
};

type ReArrangeAssetMediaData = {
    tokenURI: string;
    medias: Media[];
};

const reArrangeAssetMedia = httpsCallable<
    ReArrangeAssetMediaData[],
    string | CloudFunctionError
>(functions, 'reArrangeAssetMedia');

export const useReArrangeAssetMediaToIPFS = (noCatch?: boolean): StateLogic => {
    const action = async (dataObj: any): Promise<string> => {
        log.debug(
            'Calling server function to rearrange media to IPFS: ',
            dataObj
        );
        const { data } = await reArrangeAssetMedia(dataObj);
        const { error } = data as CloudFunctionError;
        if (error) {
            throw new Error(JSON.stringify(error));
        }
        log.debug('Path for new JSON file uploaded to IPFS: ', data);
        return data as string;
    };

    const [actionWithTryCatch, processing] = useAsyncAction<any, string>(
        action,
        {
            info: 'Uploading media...',
            error: 'Error uploading media',
            success: 'Success uploading media'
        }
    );

    return [noCatch ? action : actionWithTryCatch, processing];
};
