import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';

import { LightButton } from 'components/buttons/LightButton';

const Link = styled(RouterLink)`
    text-decoration: none;
`;

export const ToEditProfileButton = (): JSX.Element => (
    <Link to="/edit-profile">
        <LightButton textId="updateProfile" />
    </Link>
);
