import PerfectScrollbar from 'react-perfect-scrollbar';
import { useEffect, useState } from 'react';

import { Row } from 'components/Row';
import { CardTitle } from 'components/CardTitle';
import { AssetItem } from 'config/types/asset';
import { ViewAllLink } from 'components/ViewAllLink';
import { StandardCard } from 'components/StandardCard';
import { UserItemCard } from 'features/dataCards/userItemCard/UserItemCard';
import { FetchingScreen } from 'components/FetchingScreen';
import { useFetchMerchantAssets } from 'hooks/useFetchMerchantAssets';

import { ButtonToAction } from './ButtonToAction';

type Props = {
    onSelectitem: (item: AssetItem) => void;
};

export const UserItemsSnapshotCard = ({ onSelectitem }: Props): JSX.Element => {
    const [list, setList] = useState<AssetItem[]>([]);

    const [fetchMerchantAssets, fetching] = useFetchMerchantAssets();

    useEffect(() => {
        (async () => {
            setList(await fetchMerchantAssets());
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <StandardCard
            sx={{
                maxWidth: { xs: 400, md: 800 },
                height: 470
            }}>
            <Row alignItems="baseline" justifyContent="space-between">
                <CardTitle textId="items" />
                {list && list.length > 0 && (
                    <ViewAllLink to="/portfolio-tables/items" />
                )}
            </Row>
            <FetchingScreen<AssetItem[]>
                sx={{ mt: fetching || !list?.length ? -8 : 2 }}
                data={list}
                empty={!list?.length}
                fetching={fetching}
                message="noItemInPortfolio">
                {(data: AssetItem[]) => (
                    <PerfectScrollbar>
                        <Row
                            sx={{
                                my: 3,
                                maxWidth: '250px',
                                '@media (max-width: 290px)': {
                                    maxWidth: '50px'
                                }
                            }}
                            spacing={4}>
                            {data.slice(0, 4).map((item) => (
                                <UserItemCard key={item.id} data={item}>
                                    <ButtonToAction
                                        id={item.id}
                                        status={item.status}
                                        onViewDetails={() => onSelectitem(item)}
                                    />
                                </UserItemCard>
                            ))}
                        </Row>
                    </PerfectScrollbar>
                )}
            </FetchingScreen>
        </StandardCard>
    );
};
