import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import { Row } from 'components/Row';
import { TradedItem } from 'config/types/asset';
import { LightDivider } from 'components/LightDivider';
import { EquityOwned } from 'features/itemGallery/EquityOwned';
import { PurchasePrice } from 'features/itemGallery//PurchasePrice';
import { ExpectedReturn } from 'features/itemGallery//ExpectedReturn';
import { getItemCoverPicture } from 'libs/utils';
import { ReactComponent as CertificateIcon } from 'assets/certificate.svg';

import { SalesPrice } from './SalesPrice';
import { ItemNameMobile } from './ItemNameMobile';
import { SoldItemActions } from './SoldItemActions';
import { UnsoldItemActions } from './UnsoldItemActions';

import { getUserData } from 'state/user/selectors';
import { User } from 'config/types/user';
import { useAppSelector } from 'hooks/useAppSelector';

type Props = Omit<React.ComponentProps<typeof Card>, 'children'> & {
    data: TradedItem;
};

export const ItemViewMobile = ({ data, ...other }: Props): JSX.Element => {
    const isSold = data.status === 'sold';

    const { uri } = getItemCoverPicture(data) || {};

    const { sx, ...rest } = other;

    const user: User = useAppSelector(getUserData);
    const customer = user.isAdmin === false;

    return (
        <Card sx={{ width: 400, borderRadius: 2, ...sx }} {...rest}>
            <CardMedia
                alt={data.name}
                image={uri}
                height="240"
                component="img"
            />
            <CardContent
                sx={{
                    background: customer
                        ? 'linear-gradient(179.14deg, #FFFFFF 0.73%, #F5F5F5 121.08%)'
                        : 'linear-gradient(180deg, #3c4858 0%, #151516 58.85%)',
                    border: customer ? '1px solid #b4b4b4' : 'none'
                }}>
                <Row>
                    <Box sx={{ position: 'absolute' }}>
                        <CertificateIcon height={30} width={30} />
                    </Box>
                    <ItemNameMobile sx={{ marginLeft: 5 }} data={data.name} />
                </Row>
                <Grid container sx={{ mb: 3 }}>
                    <Grid item xs={6}>
                        <EquityOwned data={data.equityOwned} />
                    </Grid>
                    <Grid item xs={6}>
                        <PurchasePrice data={data.price} />
                    </Grid>
                    <Grid item xs={6}>
                        <SalesPrice data={data.salesPrice} />
                    </Grid>
                    <Grid item xs={6}>
                        <ExpectedReturn data={data.expectedReturn} />
                    </Grid>
                </Grid>
                <LightDivider />
                <Box sx={{ mt: 3 }}>
                    {!isSold ? (
                        <UnsoldItemActions
                            onSell={() => null}
                            onStaking={() => null}
                            onShowInfo={() => null}
                        />
                    ) : (
                        <SoldItemActions invoiceURI="" salesReceiptURI="" />
                    )}
                </Box>
            </CardContent>
        </Card>
    );
};
