import { useState } from 'react';
import { Box, Grid, Stack } from '@mui/material';

import { Row } from 'components/Row';
import { Space } from 'components/Space';
import { AssetData } from 'config/types/asset';
import { LightDivider } from 'components/LightDivider';
import { chartRanges } from 'config';
import { ProductChart } from 'features/dataCards/productChartCard/ProductChart';
import { AuctionHistory } from 'features/productStats/AuctionHistory';
import { QuantityDataPoint } from 'components/dataPoints/QuantityDataPoint';
import { SelectRangeButton } from 'features/productStats/SelectRangeButton';
import { SalesPriceDataPoint } from 'components/dataPoints/SalesPriceDataPoint';
import { AskedPriceDataPoint } from 'components/dataPoints/AskedPrice';
import { HistoricalAppreciation } from 'features/productStats/HistoricalAppreciation';
import { PurchasePriceDataPoint } from 'components/dataPoints/PurchasePriceDataPoint';
import { AvailableSharesDataPoint } from 'components/dataPoints/AvailableSharesDataPoint';
import { ExpectedReturnDataPoint } from 'components/dataPoints/ExpectedReturnDataPoint';

import { victoryChartData } from 'dummy/chartData';
import { SellHoldingsModal } from 'features/tradeHoldingsModal/SellHoldingsModal';
import { InvestInHoldingsModal } from 'features/tradeHoldingsModal/InvestInHoldingsModal';

type Props = {
    data: AssetData;
};

const sliceWidth = Math.floor(victoryChartData.length / chartRanges.length);

export const ProductStats = ({ data }: Props): JSX.Element => {
    const [rangeSelected, setRangeSelected] = useState<number>(
        chartRanges.length - 1
    );

    const dates = victoryChartData.slice(0, (rangeSelected + 1) * sliceWidth);

    return (
        <Box sx={{ position: 'relative', top: -25, zIndex: 0 }}>
            <Stack direction="row-reverse" justifyContent="space-between">
                {data?.auctionHistory && (
                    <AuctionHistory data={data.auctionHistory} />
                )}
                {data?.historicalAppreciation && (
                    <HistoricalAppreciation
                        data={data.historicalAppreciation}
                        onDayMove={data.oneDayPriceMove}
                    />
                )}
                <div />
            </Stack>
            <Box sx={{ height: 300 }}>
                <ProductChart dates={dates} width={800} />
            </Box>
            <Row justifyContent="space-around">
                {chartRanges.map((rangeId: string, ind: number) => (
                    <SelectRangeButton
                        key={rangeId}
                        textId={rangeId}
                        onClick={() => setRangeSelected(ind)}
                        selected={rangeSelected === ind}
                    />
                ))}
            </Row>
            <Space height={20} />
            <LightDivider />
            <Space height={20} />
            <Grid container spacing={2}>
                {[
                    <AvailableSharesDataPoint data={data.availableShares} />,
                    <AskedPriceDataPoint data={data.askedPrice} />,
                    <QuantityDataPoint data={data.quantity} />,
                    <ExpectedReturnDataPoint data={data.expectedReturn} />,
                    <PurchasePriceDataPoint data={data.purchasePrice} />,
                    <SalesPriceDataPoint data={data.salesPrice} />,
                    <SellHoldingsModal holdingsOwned={50} />,
                    <InvestInHoldingsModal holdingsOwned={0} />
                ].map((component) => (
                    <Grid item md={3}>
                        {component}
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};
