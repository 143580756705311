import capitalize from 'lodash/capitalize';
import LaunchIcon from '@mui/icons-material/Launch';

import { MediaType } from 'config/types/asset';
import { DarkButton } from 'components/buttons/DarkButton';
import { AssetFilesModal } from 'features/assetFilesModal/AssetFilesModal';

type Props = {
    assetId: string;
    mediaType: MediaType;
};

export const ManageAssetFilesButton = ({
    assetId,
    mediaType
}: Props): JSX.Element => (
    <AssetFilesModal assetId={assetId} mediaType={mediaType}>
        {(openModal: () => void) => (
            <DarkButton
                textId={mediaType === 'picture' ? 'managePics' : 'manageDocs'}
                onClick={openModal}
                endIcon={<LaunchIcon />}
            />
        )}
    </AssetFilesModal>
);
