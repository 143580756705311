import { AssetItem } from 'config/types/asset';
import { DatabaseService } from 'services/DatabaseService';
import { merchantAssetsActions } from 'state/merchantAssets/merchantAssetsSlice';

import { useAsyncAction } from './useAsyncAction';
import { useAppDispatch } from './useAppDispatch';

type StateLogic = [() => Promise<AssetItem[]>, boolean];

export const useAdminFetchAssets = (noCatch?: boolean): StateLogic => {
    const dispatch = useAppDispatch();

    const action = async (): Promise<AssetItem[]> => {
        const assets = await DatabaseService.getAllAssets();
        dispatch(merchantAssetsActions.setAll(assets));
        return assets;
    };

    const [actionWithTryCatch, processing] = useAsyncAction<void, AssetItem[]>(
        action,
        { error: 'errorRetrievingAssets' }
    );

    return [noCatch ? action : actionWithTryCatch, processing];
};
