import { styled } from '@mui/material/styles';

import { Row } from 'components/Row';
import { Typography } from 'components/Typography';

type Props = {
    invoiceURI: string;
    salesReceiptURI: string;
};

const SalesReceiptButton = styled('a')(
    ({ theme }) => `
    color: ${theme.palette.common.black};
    padding: 8px;
    border-radius: 3px;
    background-color: ${theme.palette.primary.light};
    text-transform: uppercase;
`
);

const InvoiceButton = styled('a')(
    ({ theme }) => `
    color: ${theme.palette.common.white};
    padding: 8px;
    border-radius: 3px;
    background-color: ${theme.palette.primary.dark};
    text-transform: uppercase;
`
);

export const SoldItemActions = ({
    invoiceURI,
    salesReceiptURI
}: Props): JSX.Element => (
    <Row spacing={1} justifyContent="flex-start">
        <SalesReceiptButton href={salesReceiptURI} target="_blank">
            <Typography uppercased textId="salesReceipt" />
        </SalesReceiptButton>
        <InvoiceButton href={invoiceURI} target="_blank">
            <Typography uppercased textId="invoice" />
        </InvoiceButton>
    </Row>
);
