import { useState, useEffect } from 'react';

import { AssetItem } from 'config/types/asset';
import { useFetchAssetData } from '../../hooks/useFetchAssetData';

type StateLogic = [AssetItem | undefined, boolean];

export const useUserBidsPanel = (): StateLogic => {
    const [assetData, setAssetData] = useState<AssetItem>();

    const [fetchAssetData, fetching] = useFetchAssetData();

    useEffect(() => {
        (async () => {
            setAssetData(
                await fetchAssetData({ assetId: 'PUXWAJrPsu6GDwq6PO6O' })
            );
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return [assetData, fetching];
};
