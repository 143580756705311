import { Path } from 'react-hook-form';

import { InputField } from 'features/editProfile/InputField';
import { CustomTextField } from 'components/CustomTextField';

type Props<T> = {
    size?: number;
    name: Path<T>;
    disabled: boolean;
    multiline?: boolean;
    fullWidth?: boolean;
};

export const InputTextField = <T extends Record<string, unknown>>({
    size,
    name,
    disabled,
    multiline = false,
    fullWidth = false
}: Props<T>): JSX.Element => {
    return (
        <InputField size={size} fullWidth={fullWidth}>
            <CustomTextField<T>
                name={name}
                disabled={disabled}
                multiline={multiline}
            />
        </InputField>
    );
};
