import { Bid } from 'config/types';
import { Row } from 'components/Row';
import { Typography } from 'components/Typography';
import { defaultCurrencySymbol } from 'config';

type Props = {
    data?: Bid;
};

export const ItemHighestBid = ({ data }: Props): JSX.Element => {
    return data?.value ? (
        <Row>
            <Typography
                capitalized
                color="primary.light"
                textId="current bid"
            />
            <Typography color="primary.light">:</Typography>
            <Typography sx={{ ml: 1 }} color="primary.light">
                {defaultCurrencySymbol}
                {data.value}
            </Typography>
        </Row>
    ) : (
        <Typography color="primary.light" textId="noBids" />
    );
};
