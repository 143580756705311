import Stack from '@mui/material/Stack';

import { Row } from 'components/Row';
import { AllItemDropsSnapshot } from 'features/listSnapshots/AllItemDropsSnapshot';
import { ActiveItemDropsSnapshot } from 'features/listSnapshots/ActiveItemDropsSnapshot';
import { PostItemDropTradingSnapshot } from 'features/listSnapshots/PostItemDropTradingSnapshot';

export const InvestingPage = (): JSX.Element => (
    <Row
        sx={{
            display: 'grid',
            gridTemplateColumns: '30% 70%',
            gap: '10px',
            maxWidth: '1600px',
            padding: '25px',
            margin: 'auto',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            '@media (max-width: 1130px)': {
                gridTemplateColumns: 'repeat(1, minmax(0, 1fr))'
            },
            '@media (max-width: 420px)': {
                padding: '10px',
                paddingTop: '15px'
            },
            '@media (max-width: 290px)': {
                padding: '10px',
                paddingTop: '15px'
            },
            marginBottom: '32vh'
        }}>
        <AllItemDropsSnapshot />
        <Stack
            spacing={2}
            sx={{
                width: '100%',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                position: 'relative',
                height: '970px',
                '@media (max-width: 800px)': {
                    height: '2190px'
                }
            }}>
            <ActiveItemDropsSnapshot />
            <PostItemDropTradingSnapshot />
        </Stack>
    </Row>
);
