import { Link } from 'react-router-dom';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { MenuItem } from 'react-pro-sidebar';
import { SvgIconTypeMap } from '@mui/material/SvgIcon/SvgIcon';
import { OverridableComponent } from '@mui/material/OverridableComponent';

import { Typography } from 'components/Typography';

type Props = {
    Icon:
        | React.FunctionComponent<
              React.SVGProps<SVGSVGElement> & { title?: string | undefined }
          >
        | (OverridableComponent<
              SvgIconTypeMap<Record<string, unknown>, 'svg'>
          > & {
              muiName: string;
          });
    title: string;
    path?: string;
    onClick?: () => void;
    iconStyle?: React.CSSProperties;
};

const Item = styled(MenuItem)(
    ({ theme }) => `
    padding: 7px;
    font-size: 17px;
    &:hover {
        border-radius: 2px;
        background: ${theme.palette.grey[800]};
    }
`
);

export const MenuLink = ({
    Icon,
    path,
    title,
    onClick,
    iconStyle
}: Props): JSX.Element => {
    const Text = (): JSX.Element => (
        <Typography
            allCapitalized
            title={title}
            textId={title}
            variant="body2"
            fontSize={18}
        />
    );

    return (
        <Item icon={<Icon width={25} style={iconStyle} />} onClick={onClick}>
            {path ? (
                <Link to={path}>
                    <Text />
                </Link>
            ) : (
                <Text />
            )}
        </Item>
    );
};
