import { Bid } from 'config/types';
import { Typography } from 'components/Typography';
import { defaultCurrencySymbol } from 'config';

type Props = {
    data?: Bid;
};

export const ItemCardBid = ({ data }: Props): JSX.Element => (
    <Typography variant="subtitle2" sx={{ color: 'primary.light' }}>
        {data?.value
            ? `current bid: ${defaultCurrencySymbol}${data.value}`
            : 'No bid yet'}
    </Typography>
);
