import { DataRow } from 'features/itemGallery/DataRow';
import { DataPoint } from 'components/DataPoint';

type Props = {
    data?: number;
};

export const ExpectedReturn = ({ data }: Props): JSX.Element => (
    <DataRow textId="expectedReturn">
        <DataPoint bold data={data} suffix="%" />
    </DataRow>
);
