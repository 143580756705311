import DoneIcon from '@mui/icons-material/Done';

import { RedButton } from 'components/buttons/RedButton';
import { Typography } from 'components/Typography';
import { LightButton } from 'components/buttons/LightButton';
import { useUpdateAssetData } from 'hooks/useUpdateAssetData';
import { AssetAuthenticationStatus } from 'config/types/asset';

type Props = {
    id: string;
    status: AssetAuthenticationStatus;
    onAuthenticate: () => void;
};

export const AuthenticationStatusButton = ({
    id,
    status,
    onAuthenticate
}: Props): JSX.Element => {
    const [updateAssetData, processing] = useUpdateAssetData(id);

    const onCancel = async (): Promise<void> =>
        updateAssetData({ authenticationStatus: 'unauthenticated' });

    const statusToButton = {
        denied: (
            <Typography bold sx={{ color: 'error.main' }} textId="denied" />
        ),
        authenticated: (
            <DoneIcon sx={{ color: 'success.main' }} fontSize="small" />
        ),
        pending: (
            <RedButton
                textId="cancelProcess"
                onClick={onCancel}
                loading={processing}
            />
        ),
        unauthenticated: (
            <LightButton
                textId="authenticate"
                onClick={onAuthenticate}
                loading={processing}
            />
        )
    };

    return statusToButton[status];
};
