import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import { Row } from 'components/Row';
import { Image } from 'components/Image';
import GoldCoin from 'assets/tiers/gold.svg';
import SilverCoin from 'assets/tiers/silver.svg';
import { CardTitle } from 'components/CardTitle';
import { UserFunds } from 'config/types/user';
import PlatinumCoin from 'assets/tiers/platinum.svg';
import { Typography } from 'components/Typography';
import { LightButton } from 'components/buttons/LightButton';
import { LightDivider } from 'components/LightDivider';
import { StandardCard } from 'components/StandardCard';
import { useAppSelector } from 'hooks/useAppSelector';
import { getUserFundData } from 'state/user/selectors';
import { AddFundsButton } from 'features/addFunds/AddFundsButton';
import { MerchantAssetModal } from 'config/enums/merchantAssetModal';

import { DataType } from './DataType';
import { FundDataPoint } from './FundDataPoint';

const tierToSvg: Record<string, string> = {
    gold: GoldCoin,
    silver: SilverCoin,
    platinum: PlatinumCoin
};

export const UserFundsCard = (): JSX.Element => {
    const data: UserFunds = useAppSelector(getUserFundData);

    return (
        <StandardCard
            sx={{
                maxWidth: 400,
                height: 470,
                margin: '0 auto'
            }}>
            <Row alignItems="baseline" justifyContent="space-between">
                <Stack spacing={3}>
                    <CardTitle textId="myFunds" />
                    <Stack spacing={1}>
                        <DataType textId="totalBalance" />
                        <Row alignItems="baseline" spacing={1}>
                            <FundDataPoint data={data.balance} />
                            {!!data.balance && (
                                <Typography variant="body1">LUX</Typography>
                            )}
                        </Row>
                    </Stack>
                    <Stack spacing={1}>
                        <DataType textId="portfolioReturn" />
                        <FundDataPoint data={data.return} suffix="%" />
                    </Stack>
                    <Stack spacing={1}>
                        <DataType textId="netIRR" />
                        <FundDataPoint data={data.netIRR} suffix="%" />
                    </Stack>
                </Stack>
                <Stack alignItems="center" spacing={4}>
                    <Row alignItems="baseline" spacing={1}>
                        <DataType textId="returnLastMonth" />
                        <FundDataPoint
                            data={data.lastMonthReturn}
                            suffix="%"
                            sx={{ color: 'success.main' }}>
                            <ArrowDropUpIcon
                                fontSize="medium"
                                sx={{ position: 'absolute' }}
                            />
                        </FundDataPoint>
                    </Row>
                    <Image
                        uri={tierToSvg[data.tier]}
                        title={data.tier}
                        sx={{
                            width: { xs: '160px', md: '14vw' },
                            maxWidth: '170px',
                            '@media (max-width: 290px)': {
                                maxWidth: '100px'
                            }
                        }}
                    />
                    <Typography
                        sx={{
                            '@media (max-width: 290px)': {
                                fontSize: '1rem',
                                'line-height': '.9rem'
                            }
                        }}
                        uppercased
                        variant="h6"
                        textId={`${data.tier}Tier`}
                    />
                </Stack>
            </Row>
            <LightDivider margin={4} />
            <Row spacing={2} justifyContent="space-between">
                <AddFundsButton />
                <Link to={`/merchant-assets/${MerchantAssetModal.addNewAsset}`}>
                    <LightButton textId="makeListing" />
                </Link>
            </Row>
        </StandardCard>
    );
};
