import { styled } from '@mui/material/styles';
import { Box, Stack } from '@mui/material';

import { Row } from 'components/Row';
import { Logo } from 'components/Logo';
import { Typography } from 'components/Typography';
import RoadmapGraph from 'assets/roadmap.png';

const LogoContainer = styled(Box)(({ theme }) => ({
    top: '80px',
    right: '60px',
    position: 'absolute',
    [theme.breakpoints.down('md')]: {
        display: 'none'
    }
}));

export const RoadmapPage = (): JSX.Element => (
    <Box sx={{ textAlign: 'center' }}>
        <Stack sx={{ mb: 2, letterSpacing: '0.1em' }} spacing={1}>
            <Row justifyContent="center">
                <Typography variant="h3" uppercased textId="alwaysInnovating" />
                <Typography variant="h3">,</Typography>
            </Row>
            <Typography variant="h3" uppercased textId="alwaysInvesting" />
        </Stack>
        <img width="100%;" alt="roadmap-graph" src={RoadmapGraph} />
        <LogoContainer>
            <Logo width="80px" />
        </LogoContainer>
    </Box>
);
