import Divider from '@mui/material/Divider';

type Props = React.ComponentProps<typeof Divider> & {
    margin?: number;
};

export const LightDivider = ({ margin }: Props): JSX.Element => (
    <Divider
        sx={{
            my: margin,
            backgroundColor: 'primary.light'
        }}
    />
);
