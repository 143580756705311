import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';

import { Row } from 'components/Row';
import { Tooltip } from 'components/Tooltip';
import { TableCell } from 'components/table/TableCell';
import { AssetItem } from 'config/types/asset';
import { Typography } from 'components/Typography';
import { AssetRecordPicture } from 'features/AssetRecordPicture';
import { MerchantAssetModal } from 'config/enums/merchantAssetModal';

import { TableRow } from './TableRow';
import { ListingStatusButton } from './ListingStatusButton';
import { ManageAssetFilesButton } from '../assetFilesModal/ManageAssetFilesButton';
import { AuthenticationStatusButton } from './AuthenticationStatusButton';

type Props = {
    data: AssetItem;
    onAction: (cnt: MerchantAssetModal) => void;
};

export const MerchantAssetRecord = ({ data, onAction }: Props): JSX.Element => (
    <TableRow>
        {/* item picture and name */}
        <TableCell
            sx={{
                maxWidth: 500,
                whiteSpace: 'normal',
                wordWrap: 'break-word'
            }}>
            <Row spacing={2} alignItems="center">
                <AssetRecordPicture assetId={data.id} assetName={data.name} />
                <Tooltip title={data.name} maxWidth="400px">
                    <Typography noWrap bold variant="body2">
                        {data.name}
                    </Typography>
                </Tooltip>
            </Row>
        </TableCell>
        {/* category */}
        <TableCell>{data.category}</TableCell>
        {/* authentication status */}
        <TableCell>
            <AuthenticationStatusButton
                id={data.id}
                status={data.authenticationStatus}
                onAuthenticate={() =>
                    onAction(MerchantAssetModal.assetAuthentication)
                }
            />
        </TableCell>
        {/* status */}
        <TableCell>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <ListingStatusButton
                    id={data.id}
                    status={data.status}
                    onCreate={() =>
                        onAction(MerchantAssetModal.createAssetListing)
                    }
                />
            </div>
        </TableCell>
        {/* documents */}
        <TableCell>
            <ManageAssetFilesButton assetId={data.id} mediaType="document" />
        </TableCell>
        {/* pictures */}
        <TableCell>
            <ManageAssetFilesButton assetId={data.id} mediaType="picture" />
        </TableCell>
        {/* edit */}
        <TableCell>
            <IconButton
                onClick={() => onAction(MerchantAssetModal.editAssetData)}>
                <EditIcon sx={{ color: 'primary.light' }} />
            </IconButton>
        </TableCell>
    </TableRow>
);
