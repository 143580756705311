import { AssetItem } from 'config/types/asset';
import { RedButton } from 'components/buttons/RedButton';
import { Typography } from 'components/Typography';
import { GreenButton } from 'components/buttons/GreenButton';
import { YellowButton } from 'components/buttons/YellowButton';

type Props = {
    asset: AssetItem;
    onViewData: (asset: AssetItem) => void;
};

export const AuthenticationStatusButton = ({
    asset,
    onViewData
}: Props): JSX.Element => {
    const onClick = (): void => onViewData(asset);

    const statusToButton = {
        denied: <RedButton textId="denied" onClick={onClick} />,
        authenticated: <GreenButton textId="authenticated" onClick={onClick} />,
        pending: <YellowButton textId="pending" onClick={onClick} />,
        unauthenticated: (
            <Typography
                sx={{ color: 'primary.light' }}
                textId="unauthenticated"
            />
        )
    };

    return statusToButton[asset.authenticationStatus];
};
