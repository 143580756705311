import { grey } from '@mui/material/colors';
import Drawer from '@mui/material/Drawer';
import { styled, useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import PerfectScrollbar from 'react-perfect-scrollbar';

type Align = 'center' | 'left' | 'right';

type Props = React.ComponentProps<typeof Drawer> & {
    color?: string;
    width?: string;
    anchor?: 'bottom' | 'left' | 'right' | 'top' | undefined;
    textalign?: Align;
    nopadding?: boolean;
    noCloseButton?: boolean;
    children: React.ReactNode;
    onClose: () => void;
};

const Content = styled('div')<{
    color: string;
    width: string;
    textalign: Align;
    nopadding: boolean;
}>`
    width: ${(props) => props.width};
    padding: ${(props) => (props.nopadding ? '0px' : '20px')};
    min-height: 100%;
    text-align: ${(props) => props.textalign};
    background: ${(props) => props.color};
`;

export const StandardDrawer = ({
    color,
    width = 'inherit',
    anchor = 'right',
    textalign = 'center',
    nopadding = false,
    noCloseButton = false,
    onClose,
    children,
    ...props
}: Props): JSX.Element => {
    const theme = useTheme();
    return (
        <Drawer
            sx={{ zIndex: 1400 }}
            anchor={anchor}
            onClose={onClose}
            {...props}>
            <PerfectScrollbar>
                <Content
                    color={theme.palette.warning.contrastText}
                    width={width}
                    textalign={textalign}
                    nopadding={nopadding}>
                    {!noCloseButton && (
                        <IconButton
                            sx={{ position: 'fixed', top: 5, right: 5 }}
                            aria-label="close"
                            onClick={onClose}>
                            <CloseIcon
                                fontSize="small"
                                sx={{ color: grey[400] }}
                            />
                        </IconButton>
                    )}
                    {children}
                </Content>
            </PerfectScrollbar>
        </Drawer>
    );
};
