import { Contract, ContractInterface } from 'ethers';

import { Logger, logger } from 'libs/logger';
import { MissingContractAddressError } from 'libs/customErrors';
import { Web3Provider } from '@ethersproject/providers';
export class SmartContractService {
    public log: Logger;

    public debug: (...args: unknown[]) => void;

    public name: string;

    public abi: ContractInterface;

    public address: string;

    protected contract: Contract;

    constructor(
        address: string,
        abi: ContractInterface,
        contractName: string,
        provider: Web3Provider
    ) {
        if (!address) {
            throw new MissingContractAddressError(contractName);
        }
        this.log = logger(`${contractName} Contract`);
        this.debug = this.log.debug;
        this.name = contractName;
        this.abi = abi;
        this.address = address;
        this.debug('Contract address:', address);

        this.contract = new Contract(
            this.address,
            this.abi,
            provider.getSigner()
        );
    }
}
