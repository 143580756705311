import { useState, useEffect, useCallback } from 'react';

import { MediaType } from 'config/types/asset';
import { StandardModal } from 'components/StandardModal';
import { useManageAssetMedia } from 'hooks/useManageAssetMedia';
import { getMerchantAsset } from 'state/merchantAssets/selectors';
import { merchantAssetsActions } from 'state/merchantAssets/merchantAssetsSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { useReArrangeAssetMediaToIPFS } from 'hooks/useRearrangeAssetMediaToIPFS';
import { useAppSelector } from 'hooks/useAppSelector';
import { useNFTTokenService } from 'hooks/useNFTTokenService';

import { DatabaseService } from '../../../src/services/DatabaseService';
import { StorageService } from 'services/StorageService';
import { AssetItem } from 'config/types/asset';
import { Carousel } from 'components/Carousel';

import { styled } from '@mui/material/styles';
import GetAppIcon from '@mui/icons-material/GetApp';
import { useDropzone } from 'react-dropzone';
import { Row } from 'components/Row';
import { Typography } from 'components/Typography';

import { DarkButton } from 'components/buttons/DarkButton';
import { LightButton } from 'components/buttons/LightButton';

import { v4 as uuidv4 } from 'uuid';
import isEqual from 'lodash/isEqual';

type Props = {
    open: boolean;
    assetId: string;
    mediaType: MediaType;
    handleClose: () => void;
    onFileRemoved?: () => void;
};

const Dropzone = styled('div')<{
    isfocused: string;
    isdragreject: string;
    isdragaccept: string;
}>(
    ({ isdragreject, isdragaccept, theme }) => `
    height: 100%;
    cursor: pointer;
    padding: ${theme.spacing(4)};
    text-align: center;
    border-radius: 8px;
    border-width: 1px;
    border-style: dashed;
    border-color: ${(() => {
        if (isdragreject === 'true') return theme.palette.error.main;
        if (isdragaccept === 'true') return theme.palette.success.main;
        return theme.palette.primary.light;
    })()};
`
);

export const ViewFilesModal = ({
    open,
    assetId,
    mediaType,
    handleClose
}: Props): JSX.Element => {
    const { name } = useManageAssetMedia(assetId, mediaType);
    const dispatch = useAppDispatch();
    const [reArrangeAssetMediaToIPFS] = useReArrangeAssetMediaToIPFS();
    const { updateURI } = useNFTTokenService();
    const currentAsset = useAppSelector((state) =>
        getMerchantAsset(state, assetId)
    );

    const [allAssets, setAllAssets] = useState<AssetItem[]>([]);
    const [imageIndex, setImageIndex] = useState(0);
    const [URI, setURI] = useState();

    const onDelete = (imgIndex: number): void => {
        setAllAssets((prev) => {
            const wasFirst = imgIndex === 0;
            const updatedAssets = prev.map((asset) => {
                if (asset.medias) {
                    asset.medias = asset.medias.filter(
                        (_, index) => index !== imgIndex
                    );

                    if (wasFirst && asset.medias.length > 0) {
                        asset.medias[0].priority = 1;
                    }
                }
                return asset;
            });
            return updatedAssets;
        });

        if (imgIndex === 0 && imageIndex === 0) {
            setImageIndex(0);
        } else if (imageIndex >= allAssets.length - 1) {
            setImageIndex((prev) => prev - 1);
        }
    };

    const onDataChange = (from: number, to: number): void => {
        setAllAssets((prev) => {
            const flattenedMedias = prev.flatMap((item) => item.medias);
            const movedItem = flattenedMedias.splice(from, 1)[0];
            flattenedMedias.splice(to, 0, movedItem);
            let index = 0;
            const updatedData = prev.map((item) => {
                const mediasCount = item.medias.length;
                const updatedMedias = flattenedMedias.slice(
                    index,
                    index + mediasCount
                );
                index += mediasCount;
                return { ...item, medias: updatedMedias };
            });
            return updatedData;
        });
    };

    useEffect(() => {
        const fetchAssets = async (): Promise<void> => {
            const asset = await DatabaseService.getAssetData(assetId);
            setAllAssets([asset]);
        };

        fetchAssets();
    }, [assetId]);

    const getObjectUrl = (file: File): any => {
        return URL.createObjectURL(file);
    };

    const urls =
        allAssets.length > 0 && allAssets[0].medias
            ? allAssets[0].medias.map((file) => ({
                  name: file.name,
                  preview: file.uri
              }))
            : [];

    const onDrop = useCallback(
        async (acceptedFiles: File[]) => {
            const allowedTypes = ['image/jpg', 'image/jpeg', 'image/png'];
            const pickedFiles = acceptedFiles.slice(0, 51);
            const filteredFiles: any = pickedFiles.filter((file) =>
                allowedTypes.includes(file.type)
            );

            const processFiles = async (
                prevAssets: AssetItem[]
            ): Promise<any> => {
                const asset = prevAssets[0];

                // Find the maximum priority value from the existing images
                const maxPriority = Math.max(
                    ...asset.medias.map((media) => media.priority || 0),
                    0
                );

                // First object in the array has a priority of 1
                const priorityOffset = asset.medias.length === 0 ? 1 : 0;

                // Structure of new objects added
                const newMediasPromises = filteredFiles.map(
                    async (file: File, index: number) => {
                        const uri = await StorageService.uploadMedia(file);
                        return {
                            priority: maxPriority + index + 1 + priorityOffset,
                            Id: uuidv4(),
                            Title: file.name,
                            Type: 'picture',
                            Test: true,
                            name: file.name,
                            uri
                        };
                    }
                );
                const newMedias = await Promise.all(newMediasPromises);

                return [
                    {
                        ...asset,
                        medias: asset.medias.concat(newMedias)
                    },
                    ...prevAssets.slice(1)
                ];
            };

            const updatedAssets = await processFiles(allAssets);
            setAllAssets(updatedAssets);
        },
        [allAssets]
    );

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: '',
        maxFiles: 50
    });

    const handleCancel = async (): Promise<void> => {
        handleClose();
    };
    const handleSubmit = async (): Promise<void> => {
        await dispatch(merchantAssetsActions.reArrangeAssetMedia(allAssets[0]));
        const equal = isEqual(allAssets[0].medias, currentAsset.medias);

        if (currentAsset.nftId && !equal) {
            try {
                const tokenURI = await reArrangeAssetMediaToIPFS({
                    tokenURI: currentAsset.tokenURI,
                    medias: allAssets[0].medias
                });
                await updateURI({
                    nftId: currentAsset.nftId,
                    tokenURI: tokenURI
                });
                dispatch(
                    merchantAssetsActions.updateAssetTokenURI({
                        id: currentAsset.id,
                        tokenURI
                    })
                );
            } catch (e) {
                console.error(e);
            }
        }
        try {
            const asset = allAssets[0];
            await DatabaseService.updateAssetData(assetId, {
                medias: asset.medias
            });
            handleClose();
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <StandardModal
            minwidth="200px"
            title={name ?? 'viewFiles'}
            open={open}
            handleClose={handleClose}>
            <div style={{ marginTop: '40px' }}>
                <Carousel
                    text={(allAssets[0]?.medias?.length || 0) > 0}
                    imageSelection={(allAssets[0]?.medias?.length || 0) > 0}
                    buttons={(allAssets[0]?.medias?.length || 0) > 0}
                    delete={(allAssets[0]?.medias?.length || 0) > 0}
                    coverImage={(allAssets[0]?.medias?.length || 0) > 0}
                    data={urls}
                    imageIndex={imageIndex}
                    onDelete={onDelete}
                    setImageIndex={setImageIndex}
                    onDataChange={onDataChange}
                    getObjectUrl={getObjectUrl}
                />

                <Dropzone
                    {...getRootProps()}
                    className="drop-zone"
                    isfocused="false"
                    isdragreject="false"
                    isdragaccept="false"
                    style={{ marginTop: '30px' }}>
                    <input {...getInputProps()} multiple={true} />

                    <Row
                        spacing={2}
                        alignItems="center"
                        justifyContent="center">
                        <GetAppIcon
                            height={40}
                            width={40}
                            style={{ color: '#A5CEE4' }}
                        />
                        <Typography
                            capitalized
                            bold={false}
                            textId="Drop files here"
                            variant="body2"
                            style={{ color: '#A5CEE4' }}
                        />
                    </Row>
                </Dropzone>

                <Row style={{ marginTop: '20px' }}>
                    <DarkButton
                        textId="cancel"
                        style={{ marginRight: '10px' }}
                        onClick={handleCancel}
                    />
                    <LightButton
                        type="submit"
                        textId="submit"
                        onClick={handleSubmit}
                    />
                </Row>
            </div>
        </StandardModal>
    );
};
