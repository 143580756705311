import PerfectScrollbar from 'react-perfect-scrollbar';

import { TabPanelProps } from 'config/types';
import { TableContainer } from 'components/TableContainer';

import { TabPanel } from './TabPanel';
import { useTheme } from '@mui/material';

export const TabPanelAsTable = (props: TabPanelProps): JSX.Element => {
    const { children, ...other } = props;
    const theme = useTheme();
    return (
        <TabPanel {...other}>
            <TableContainer
                sx={{ width: '100%', background: theme.palette.success.dark }}>
                <PerfectScrollbar>{children}</PerfectScrollbar>
            </TableContainer>
        </TabPanel>
    );
};
