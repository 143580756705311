import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Stack, Theme } from '@mui/material';

import { Row } from 'components/Row';
import { DarkButton } from 'components/buttons/DarkButton';
import { Typography } from 'components/Typography';
import { LightButton } from 'components/buttons/LightButton';
import { AuthButton } from 'features/authButton/AuthButton';
import LandingPageImg from 'assets/landingPageGraphic.png';

const Title = styled(Typography)(({ theme }) => ({
    fontSize: '4.5vw',
    lineHeight: '7vw',
    letterSpacing: '0.1em',
    backgroundColor: theme.palette.common.black,
    [theme.breakpoints.down('sm')]: {
        zIndex: 100,
        padding: 15,
        fontSize: 33,
        lineHeight: '10vw',
        marginTop: 20
    }
}));

const SubTitle = styled(Typography)(({ theme }) => ({
    fontSize: 23,
    marginBottom: 10,
    backgroundColor: theme.palette.common.black,
    [theme.breakpoints.down('sm')]: {
        zIndex: 100,
        padding: 15,
        fontSize: 23
    }
}));

const Img = styled('img')(({ theme }) => ({
    top: 0,
    right: 0,
    width: '50%',
    height: '100vh',
    position: 'absolute',
    objectFit: 'cover',
    objectPosition: 'top',
    [theme.breakpoints.down('sm')]: {
        width: '100%',
        zIndex: 10
    }
}));

const ButtonTheme = ({ theme }: { theme: Theme }): Record<string, unknown> => ({
    padding: 10,
    fontSize: 20,
    [theme.breakpoints.down('sm')]: {
        zIndex: 100,
        padding: 5,
        fontSize: 20
    }
});

const StartTradingButton = styled(DarkButton)(ButtonTheme);

const LearnMoreButton = styled(LightButton)(ButtonTheme);

export const LandingPage = (): JSX.Element => (
    <Grid container>
        <Grid item sm={6} xs={12}>
            <Stack
                sx={{
                    px: { sm: 1, md: 3 },
                    height: 'calc(100vh - 95px)'
                }}
                justifyContent="space-between">
                <Title uppercased textId="appPurpose" />
                <SubTitle textId="appDescription" />
                <Row spacing={1} justifyContent="space-between">
                    <AuthButton>
                        {(loading: boolean, login: () => Promise<void>) => (
                            <StartTradingButton
                                textId="startTrading"
                                onClick={login}
                                loading={loading}
                            />
                        )}
                    </AuthButton>
                    <Link to="/who-we-are">
                        <LearnMoreButton textId="learnMore" />
                    </Link>
                </Row>
            </Stack>
        </Grid>
        <Grid item md={6} xs={12}>
            <Img src={LandingPageImg} />
        </Grid>
    </Grid>
);
