import { useAsyncAction } from 'hooks/useAsyncAction';
import { useAppSelector } from 'hooks/useAppSelector';
import { useUpdateAssetData } from 'hooks/useUpdateAssetData';
import { useCreateMarketplaceItem } from 'hooks/useCreateMarketplaceItem';
import { useUploadAssetMediaToIPFS } from 'hooks/useUploadAssetMediaToIPFS';
import { AssetItem, AssetListingStatus } from 'config/types/asset';
import { getItemCoverPicture } from 'libs/utils';
import { useNFTTokenService } from 'hooks/useNFTTokenService';
import {
    LUX_VESTING_INITIAL_BID_RATIO,
    NFT_MARKETPLACE_CONTRACT_ADDRESS
} from 'config/constants';
import { getUserAccount } from 'state/user/selectors';
import { useTokenService } from 'hooks/useTokenService';

type StateLogic = {
    updating: boolean;
    approving: boolean;
    onApprove: (data: AssetItem) => Promise<void>;
    onUpdateStatus: (status: AssetListingStatus) => void;
};

export const useAssetListingStatusUpdateButton = (
    data: AssetItem,
    onSuccess: () => void
): StateLogic => {
    const broker = useAppSelector(getUserAccount);

    const coverPhotoData = getItemCoverPicture(data);

    const throws = true;

    const [createMarketplaceItem] = useCreateMarketplaceItem();

    const [updateAssetData, updating] = useUpdateAssetData(data.id);

    const [uploadMediaFromDBToIPFS] = useUploadAssetMediaToIPFS(true);

    const { mintNFT, approveAddress } = useNFTTokenService();

    const { approveTokens } = useTokenService();

    const onUpdateStatus = async (
        status: AssetListingStatus
    ): Promise<void> => {
        await updateAssetData({ status });
        onSuccess();
    };

    const onApproveAsset = async (assetItem?: AssetItem): Promise<void> => {
        // Upload cover photo to ipfs
        console.log('Cover photo data:', coverPhotoData);
        console.log(data);
        if (!coverPhotoData) throw new Error('missingAssetCoverPicture');

        const { price } = assetItem || data;
        const { uri, name, type } = coverPhotoData;

        console.log('Info:', price, uri, name, type);

        await updateAssetData({ status: 'processing' });

        const medias = data.medias.map((media) => {
            return {
                uri: media.uri,
                name: media.name,
                type: media.type
            };
        });

        const tokenURI = await uploadMediaFromDBToIPFS(medias);
        console.log('tokenURI', tokenURI);
        const nftId = await mintNFT({ recipient: data.merchantId, tokenURI });
        console.log('nftId', nftId);
        await approveAddress({ to: NFT_MARKETPLACE_CONTRACT_ADDRESS, nftId });
        console.log(price, LUX_VESTING_INITIAL_BID_RATIO);
        const value = LUX_VESTING_INITIAL_BID_RATIO * price;
        console.log(value);
        await approveTokens({
            recipient: NFT_MARKETPLACE_CONTRACT_ADDRESS,
            value
        });
        await createMarketplaceItem({ recipient: broker, nftId, price, value });
        await updateAssetData({ status: 'listed', nftId, tokenURI });
        onSuccess();
    };

    const [onApprove, approving] = useAsyncAction(onApproveAsset, {
        error: 'listingAssetError'
    });

    return { onApprove, onUpdateStatus, updating, approving };
};
