import { MediaType } from 'config/types/asset';
import { useState } from 'react';

import { ViewFilesModal } from './ViewFilesModal';

type Props = {
    assetId: string;
    children: (handleOpen: () => void) => React.ReactNode;
    mediaType: MediaType;
};

export const AssetFilesModal = ({
    assetId,
    children,
    mediaType
}: Props): JSX.Element => {
    const [open, setOpen] = useState<boolean>(false);

    const handleOpen = (): void => {
        setOpen(true);
    };

    const handleClose = (): void => {
        setOpen(false);
    };

    return (
        <>
            {children(handleOpen)}
            <ViewFilesModal
                open={open}
                assetId={assetId}
                mediaType={mediaType}
                handleClose={handleClose}
            />
        </>
    );
};
