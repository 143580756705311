import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import { styled } from '@mui/material/styles';
import TableBody from '@mui/material/TableBody';
import PerfectScrollbar from 'react-perfect-scrollbar';
import MuiTableContainer from '@mui/material/TableContainer';

import { Space } from 'components/Space';
import { LightDivider } from 'components/LightDivider';
import { TableHeader } from 'components/table/TableHeader';
import { HistoricalActivityTable } from './HistoricalActivityTable';
import { HistoricalActivitySummary } from './HistoricalActivitySummary';

// type Props = {
// ;
// }

// const  = styled('div')`

// `;
const headers = [
    'transaction date',
    'product',
    'shares',
    'price',
    'total amount'
];

const TableContainer = styled(MuiTableContainer)(() => ({
    width: '100vw',
    height: '250px',
    '@media (max-width: 600px)': {
        width: '100vw'
    }
}));
const BoxHistorical = styled(Box)(({ theme }) => ({
    background: theme.palette.success.dark,
    width: '100vw'
}));

export const HistoricalActivityPanel = (): JSX.Element => {
    return (
        <BoxHistorical>
            <TableContainer>
                <PerfectScrollbar>
                    <Table stickyHeader size="small" aria-label="portfolio">
                        <TableHeader headers={headers} />
                        <TableBody>
                            <HistoricalActivityTable />
                        </TableBody>
                    </Table>
                </PerfectScrollbar>
            </TableContainer>
            <Space height={20} />
            <LightDivider />
            <MuiTableContainer>
                <Table stickyHeader size="small" aria-label="portfolio">
                    <TableHeader
                        sx={{ visibility: 'hidden' }}
                        headers={headers}
                    />
                    <TableBody>
                        <HistoricalActivitySummary />
                        <HistoricalActivityTable
                            sx={{ visibility: 'hidden' }}
                        />
                    </TableBody>
                </Table>
            </MuiTableContainer>
        </BoxHistorical>
    );
};
