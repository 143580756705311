/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { AssetItem } from 'config/types/asset';

const initialState: AssetItem[] = [];

export const assetsSlice = createSlice({
    name: 'assets',
    initialState,
    reducers: {
        reset: () => initialState,
        setList: (_, { payload }) => payload,
        // TODO: remove
        // addBid: (state, { payload: { id, data } }) => {
        //     const ind = state.findIndex((e) => e.id === id);
        //     if (ind > -1) {
        //         const { bids } = state[ind];
        //         state[ind] = { ...state[ind], bids: (bids || []).concat(data) };
        //     }
        // },
        updateData: (state, { payload: { id, data } }) => {
            const ind = state.findIndex((e) => e.id === id);
            if (ind > -1) {
                state[ind] = { ...state[ind], ...data };
            }
        }
    }
});

export const assetsActions = assetsSlice.actions;

export const assetsReducer = assetsSlice.reducer;
