import { StorageService } from 'services/StorageService';
import { useAsyncAction } from 'hooks/useAsyncAction';
import { useAppSelector } from 'hooks/useAppSelector';
import { DatabaseService } from 'services/DatabaseService';
import { merchantAssetsActions } from 'state/merchantAssets/merchantAssetsSlice';
import { getUserId, getUserName } from 'state/user/selectors';
import {
    NewAsset,
    MediaType,
    AddNewAssetFormInputMultiple,
    AssetMediaMetadata
} from 'config/types/asset';

import { getFileTitle } from 'libs/utils';
import { useAppDispatch } from './useAppDispatch';

// type StateLogic = [(values: AddNewAssetFormInput) => Promise<void>, boolean];
type StateLogic = [
    (values: AddNewAssetFormInputMultiple) => Promise<void>,
    boolean
];
export const useAddNewAsset = (noCatch?: boolean): StateLogic => {
    const dispatch = useAppDispatch();

    const merchantId = useAppSelector(getUserId);

    const merchantName = useAppSelector(getUserName);

    const type: MediaType = 'picture' || undefined;

    const stdData: Omit<NewAsset, 'name' | 'medias'> = {
        // Hard-coded for now until we add ability to mint on the ethereum network
        network: 'private',
        // Hard-coded: Only one category of products possible for now
        category: 'watch',
        merchantId,
        status: 'notListed',
        merchantName,
        authenticationStatus: 'unauthenticated'
    };

    // const addNewAsset = async (values: AddNewAssetFormInput): Promise<void> => {
    //     const { picture, name } = values;
    //     // Save file to storage and get url pointing to it
    //     const uri = await StorageService.uploadMedia(picture);
    //     // Save the new asset in the database and get its id (generated by firestore)
    //     const id = await DatabaseService.addNewAsset({ ...stdData, name });
    //     // Add the cover photo to the asset in the database
    //     const title = getFileTitle(picture);
    //     const metadata = {
    //         uri,
    //         type,
    //         name: picture.name,
    //         priority: 1,
    //         title,
    //         test: true
    //     };
    //     const media = await DatabaseService.addMediaToAsset(id, metadata);
    //     // Finally update the state with the new asset
    //     const newAssetData = { ...stdData, name, id, medias: [media] };
    //     dispatch(merchantAssetsActions.addAssets(newAssetData));
    // };

    const addNewAsset = async (
        values: AddNewAssetFormInputMultiple
    ): Promise<void> => {
        try {
            const { picture, name } = values;
            // Save files to storage and get urls pointing to them
            const uris = Array.isArray(picture)
                ? await Promise.all(
                      picture.map(
                          async (file) => await StorageService.uploadMedia(file)
                      )
                  )
                : [await StorageService.uploadMedia(picture)];
            // Save the new asset in the database and get its id (generated by firestore)
            const id = await DatabaseService.addNewAsset({ ...stdData, name });
            // Add the photos to the asset in the database
            const medias = await Promise.all(
                uris.map((uri, index) => {
                    const file = Array.isArray(picture)
                        ? picture[index]
                        : picture;
                    const title = getFileTitle(file);
                    const metadata: AssetMediaMetadata[] = [
                        {
                            uri: uri,
                            type: 'picture',
                            name: Array.isArray(picture)
                                ? picture[index].name
                                : picture.name,
                            priority: index + 1,
                            title: title,
                            test: true
                        }
                    ];
                    return DatabaseService.addMediaToAsset(id, metadata);
                })
            );
            // Finally update the state with the new asset
            const flatMedias = medias.map(([mediaObj]) => mediaObj);
            const newAssetData = { ...stdData, name, id, medias: flatMedias };
            dispatch(merchantAssetsActions.addAssets(newAssetData));
        } catch (e) {
            console.log('error adding new asset:', e);
        }
    };

    // const [addNewAssetWithTryCatch, processing] = useAsyncAction<
    //     AddNewAssetFormInput,
    //     void
    // >(addNewAsset, {
    //     error: 'errorAddingAsset',
    //     success: 'assetSuccessfullyAdded'
    // });
    const [addNewAssetWithTryCatch, processing] = useAsyncAction<
        AddNewAssetFormInputMultiple,
        void
    >(addNewAsset, {
        error: 'errorAddingAsset',
        success: 'assetSuccessfullyAdded'
    });

    return [noCatch ? addNewAsset : addNewAssetWithTryCatch, processing];
};
