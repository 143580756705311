import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import { getUserData } from 'state/user/selectors';
import { User } from 'config/types/user';
import { useAppSelector } from 'hooks/useAppSelector';

const Component = styled(Stack)(({ theme }) => {
    const user: User = useAppSelector(getUserData);
    const customer = user.isAdmin === false;
    return {
        padding: 60,
        textAlign: 'left',
        background: theme.palette.success.dark,
        boxShadow: '1px 0px 10px rgba(0, 0, 0, 0.42)',
        borderRadius: '5px',
        [theme.breakpoints.down('md')]: {
            padding: 10,
            width: '100%'
        }
    };
});

export const BoxGradient = (
    props: React.ComponentProps<typeof Stack>
): JSX.Element => <Component {...props} />;
