import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { purple } from '@mui/material/colors';
import CancelIcon from '@mui/icons-material/Cancel';

import { Row } from 'components/Row';
import { IconButton } from 'components/IconButton';
import { Typography } from 'components/Typography';
import { Notification } from 'config/types/user';

type Props = {
    data: Notification;
    onDelete: ({ id }: { id: string }) => void;
};

export const NotificationItem = ({ data, onDelete }: Props): JSX.Element => (
    <Row justifyContent="space-between">
        <Typography
            noWrap
            sx={{ p: 1, color: 'common.black', maxWidth: 300 }}
            title={data.value}>
            <Trans
                values={{ value: data.value }}
                i18nKey={data.type}
                components={{
                    to: (
                        <Link
                            style={{ color: purple[500] }}
                            to="/merchant-assets"
                        />
                    )
                }}
            />
        </Typography>
        <IconButton
            aria-label="delete"
            onClick={() => onDelete({ id: data.id })}>
            <CancelIcon fontSize="small" />
        </IconButton>
    </Row>
);
