import { createContext } from 'react';

import { defaultLang, defaultCategory } from 'config';

export type LangContextType = [string, (value: string) => void];

export const LangContext = createContext<LangContextType>([
    defaultLang,
    () => null
]);

export type CategoreyContextType = [string, (value: string) => void];

export const CategoreyContext = createContext<CategoreyContextType>([
    defaultCategory,
    () => null
]);
