import { yupLocale as yup } from 'config/yup';

export const editProfileSchema = yup
    .object({
        company: yup.string().required().trim().min(2).max(100),
        userType: yup.string().required().trim().min(2).max(10),
        lastName: yup.string().required().trim().min(2).max(100),
        firstName: yup.string().required().trim().min(2).max(100),
        description: yup.string().required().trim().min(10).max(500),
        paymentAccount: yup.string().email().required().trim().max(100)
    })
    .required();
