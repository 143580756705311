import { useState } from 'react';

import { getUserId } from 'state/user/selectors';
import { useAppSelector } from 'hooks/useAppSelector';

type StateLogic = {
    userId: string;
    onClose: () => void;
    merchantId?: string;
    openedChat: boolean;
    handleCloseChat: () => void;
    handleOpenChat: (id: string) => void;
};

export const useUserBidsDrawer = (handleClose: () => void): StateLogic => {
    const userId = useAppSelector(getUserId);

    const [merchantId, setMerchantId] = useState<string>('');

    const [openedChat, setOpenedChat] = useState<boolean>(false);

    const handleOpenChat = (id: string): void => {
        setMerchantId(id);
        setOpenedChat(true);
    };

    const handleCloseChat = (): void => {
        setOpenedChat(false);
    };

    const onClose = (): void => {
        setOpenedChat(false);
        handleClose();
    };

    return {
        userId,
        onClose,
        merchantId,
        openedChat,
        handleOpenChat,
        handleCloseChat
    };
};
