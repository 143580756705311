import { Stack } from '@mui/material';
import { FormProvider } from 'react-hook-form';

import { Row } from 'components/Row';
import { TextInput } from 'components/TextInput';
import { DarkButton } from 'components/buttons/DarkButton';
import { LightButton } from 'components/buttons/LightButton';
import { PictureInput } from 'components/PictureInput';
import { StandardModal } from 'components/StandardModal';
import { useAddNewAssetModal } from './useAddNewAssetModal';

type Props = {
    open: boolean;
    handleClose: () => void;
};

export const AddNewAssetModal = ({ open, handleClose }: Props): JSX.Element => {
    const {
        onClose,
        onSubmit,
        submitting,
        fileDropped,
        formMethods,
        onFileDropped,
        onFileRemoved
    } = useAddNewAssetModal(handleClose);

    // console.log('Add new asset modal');

    return (
        <StandardModal
            style={{ width: '100vw' }}
            title="addNewAsset"
            open={open}
            handleClose={onClose}
            maxw={'100%'}>
            <FormProvider {...formMethods}>
                <form
                    style={{ width: '100%' }}
                    noValidate
                    autoComplete="off"
                    onSubmit={formMethods.handleSubmit(onSubmit)}>
                    <Stack>
                        <TextInput
                            label="productName"
                            name="name"
                            sx={{
                                width: '500px',
                                marginBottom: '20px',
                                '@media (max-width: 600px)': {
                                    width: '100%'
                                }
                            }}
                            disabled={submitting}
                            required
                        />
                        <PictureInput
                            textId="uploadCoverPhoto"
                            disabled={submitting}
                            fieldName="picture"
                            onFileDropped={onFileDropped}
                            onFileRemoved={onFileRemoved}
                        />
                        <Row style={{ marginTop: '20px' }}>
                            <DarkButton
                                textId="cancel"
                                onClick={onClose}
                                disabled={submitting}
                                style={{ marginRight: '10px' }}
                            />
                            <LightButton
                                type="submit"
                                textId="submit"
                                loading={submitting}
                                disabled={!fileDropped}
                            />
                        </Row>
                    </Stack>
                </form>
            </FormProvider>
        </StandardModal>
    );
};
