import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';

type Props = {
    onToggle: () => void;
};

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 100,
    height: 32,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        transform: 'translateX(2px)',
        '&.Mui-checked': {
            transform: 'translateX(47px)',
            '& .MuiSwitch-thumb:before': {
                content: "'USD'"
            },
            '& + .MuiSwitch-track': {
                opacity: 1
            },
            '& + .MuiSwitch-track:before': {
                paddingLeft: 9,
                content: "'LVX'"
            }
        }
    },
    '& .MuiSwitch-thumb': {
        width: 52,
        height: 32,
        textAlign: 'center',
        paddingTop: 5,
        borderRadius: 30 / 2,
        color: theme.palette.common.black,
        backgroundColor: theme.palette.primary.light,
        '&:before': {
            content: "'LVX'"
        }
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        paddingTop: 2,
        backgroundColor: theme.palette.primary.dark,
        borderRadius: 30 / 2,
        border: `2px solid ${theme.palette.primary.light}`,
        '&:before': {
            paddingLeft: 56,
            content: "'USD'"
        }
    }
}));

export const SwitchCurrencyButton = ({ onToggle }: Props): JSX.Element => (
    <MaterialUISwitch
        sx={{ m: 1 }}
        defaultChecked
        onChange={() => onToggle()}
    />
);
