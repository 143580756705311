import Stack from '@mui/material/Stack';
import { categories } from 'config';
import { Row } from 'components/Row';
import { CustomTextField } from 'components/CustomTextField';
import { AssetListingFormInput } from 'config/types/asset';
import { CustomSelect } from 'components/CategorySelect';
// import { ListAsDropSelect } from './ListAsDropSelect';
// import { LengthOfDropAuctionInput } from './LengthOfDropAuctionInput';

export const CreateListingFields = (): JSX.Element => (
    <Row spacing={3}>
        <Stack spacing={3} sx={{ flex: 3 }}>
            <CustomTextField<AssetListingFormInput>
                rows={5}
                multiline
                required
                label="assetDescription"
                name="description"
            />
            <CustomSelect<AssetListingFormInput>
                label="category"
                options={categories}
                required
                name={'category'}></CustomSelect>
        </Stack>
        <Stack spacing={1} sx={{ flex: 1 }}>
            <CustomTextField<AssetListingFormInput>
                required
                type="number"
                label="price"
                name="price"
            />
            <CustomTextField<AssetListingFormInput>
                required
                type="string"
                label="dimensions"
                name="dimensions"
            />
            {/* <ListAsDropSelect /> */}
        </Stack>
        <Stack spacing={1} sx={{ flex: 1 }}>
            <CustomTextField<AssetListingFormInput>
                required
                label="color"
                name="color"
            />
            <CustomTextField<AssetListingFormInput>
                required
                label="material"
                name="material"
            />
            {/* <LengthOfDropAuctionInput /> */}
        </Stack>
    </Row>
);
