import { withEllipsis } from 'libs/utils';
import { Typography } from 'components/Typography';

type Props = {
    data: string;
};

export const ItemName = ({ data }: Props): JSX.Element => (
    <Typography
        sx={{ height: 80, overflow: 'hidden' }}
        title={data}
        variant="h4"
        uppercased>
        {withEllipsis(data, 40)}
    </Typography>
);
