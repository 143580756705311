import { Row } from 'components/Row';
import { Tooltip } from 'components/Tooltip';
import { TableCell } from 'components/table/TableCell';
import { TableRow } from 'components/table/TableRow';
import { AssetItem } from 'config/types/asset';
import { Typography } from 'components/Typography';
import { AssetRecordPicture } from 'features/AssetRecordPicture';
import { ManageAssetFilesButton } from 'features/assetFilesModal/ManageAssetFilesButton';

import { ShowBidsButton } from './ShowBidsButton';
import { AdminListingStatusButton } from './AdminListingStatusButton';
import { AuthenticationStatusButton } from './AdminAuthenticationStatusButton';

type Props = {
    data: AssetItem;
    onShowBids: () => void;
    onViewListingData: () => void;
    onViewAuthenticationData: () => void;
    fetchingBids: boolean;
};

export const AdminAssetTableRow = ({
    data,
    onShowBids,
    onViewListingData,
    onViewAuthenticationData,
    fetchingBids
}: Props): JSX.Element => {
    return (
        <TableRow key={data.id}>
            {/* product name */}
            <TableCell>
                <Row
                    spacing={2}
                    alignItems="center"
                    justifyContent="flex-start">
                    <AssetRecordPicture
                        assetId={data.id}
                        assetName={data.name}
                    />
                    <Tooltip title={data.name} maxWidth="210px">
                        <Typography bold noWrap variant="body2">
                            {data.name}
                        </Typography>
                    </Tooltip>
                </Row>
            </TableCell>

            {/* vendor name */}
            <TableCell>{data.merchantName}</TableCell>

            {/* date */}
            <TableCell>
                {new Date(data.createdAt).toLocaleDateString()}
            </TableCell>

            {/* authenticated status */}
            <TableCell>
                <AuthenticationStatusButton
                    asset={data}
                    onViewData={onViewAuthenticationData}
                />
            </TableCell>

            {/* status */}
            <TableCell>
                <AdminListingStatusButton
                    asset={data}
                    onViewData={onViewListingData}
                />
            </TableCell>

            {/* documents */}
            <TableCell>
                <ManageAssetFilesButton
                    assetId={data.id}
                    mediaType="document"
                />
            </TableCell>

            {/* pictures */}
            <TableCell>
                <ManageAssetFilesButton assetId={data.id} mediaType="picture" />
            </TableCell>

            {/* bids */}
            <TableCell>
                {data.status === 'listed' && (
                    <ShowBidsButton
                        data={data.bids}
                        onClick={onShowBids}
                        fetching={fetchingBids}
                    />
                )}
            </TableCell>
        </TableRow>
    );
};
