import { useState, useContext } from 'react';
import { BlockChainContext } from 'blockchain/BlockchainContext';
import { useTranslation } from 'react-i18next';

import { User } from 'config/types/user';
import { logger } from 'libs/logger';
import { useAlert } from 'hooks/useAlert';
import { userActions } from 'state/user/userSlice';
import { TorusService } from 'services/TorusService';
// import { tokenContract } from 'services/TokenContractService';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { DatabaseService } from 'services/DatabaseService';
import { LoginError, RecordNotFoundError } from 'libs/customErrors';

type StateLogic = [() => Promise<void>, boolean];

export const useTorusLogin = (): StateLogic => {
    const { torus, staking } = useContext(BlockChainContext);
    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    const { errorAlert } = useAlert();

    const [loading, setLoading] = useState<boolean>(false);

    const login = async (): Promise<void> => {
        try {
            setLoading(true);
            // Open Torus login popup
            // const torusService = await getTorusService();

            const userInfo: User = await TorusService.login(torus!);
            // Get the ethereum account address allocated by torus
            const { account } = userInfo;

            // Throws if torus did not return an account
            if (!account) {
                throw new LoginError(t('noAccountFound'));
            }
            // Get the user data from database for this account
            const storedUser: User = await DatabaseService.getUserById(account);
            // Aggregate all the user data
            const userData: User = storedUser || userInfo;
            // Add a new user If not stored in database yet
            if (!storedUser) {
                await DatabaseService.addUser(userData);
            }
            console.log('before fetching balance');
            const balance: string = await staking!.getBalance(account);
            console.log(balance);
            // Set global state user data
            dispatch(userActions.setInfo({ ...userData, balance }));
        } catch (e) {
            errorAlert(
                e instanceof RecordNotFoundError
                    ? 'recordNotFound'
                    : 'loginError'
            );
            logger().error(e);
        } finally {
            setLoading(false);
        }
    };

    return [login, loading];
};
