import { convertToBlockchainCurrencyUnit, dollarsToTokens } from 'libs/utils';
import { SmartContractService } from './SmartContractService';

export type CreateListingParams = {
    price: number;
    nftId: number;
    value: number;
    recipient: string;
};

export class NFTMarketplaceService extends SmartContractService {
    async createListing(params: CreateListingParams): Promise<void> {
        const {
            recipient: to,
            nftId,
            price: priceInFiat,
            value: valueInFiat
        } = params;
        const price = dollarsToTokens(priceInFiat);
        const value = dollarsToTokens(valueInFiat);
        const tx = await this.contract.createListing(nftId, value, price, to);
        console.log(tx);
        await tx.wait();
        return tx;
    }

    async getListingData(nftId: number): Promise<void> {
        try {
            const listingData = await this.contract.nftIdToListing(nftId);
            return listingData;
        } catch (e) {
            console.error(e);
        }
    }

    async getBidData(bidRef: number): Promise<void> {
        try {
            const biddingData = await this.contract.bidIdToBid(bidRef);
            return biddingData;
        } catch (e) {
            console.error(e);
        }
    }

    async endAuction(nftId: number): Promise<void> {
        try {
            const tx = await this.contract.endAuction(nftId);
            console.log(tx);
            await tx.wait();
            return tx;
        } catch (e) {
            console.error(e);
        }
    }
}
