import { styled } from '@mui/material/styles';
import MuiButton from '@mui/material/Button';
import { Button as ButtonIntl } from 'components/buttons/Button';

type Props = React.ComponentProps<typeof MuiButton> & {
    textId: string;
    loading?: boolean;
};

const Button = styled(ButtonIntl)(
    ({ theme }) => `
    color: ${theme.palette.common.white};
    background: ${theme.palette.error.main};
    &: hover {
        background: ${theme.palette.error.main};
    }
`
);

export const RedButton = ({ ...props }: Props): JSX.Element => (
    <Button {...props} />
);
