import {
    query,
    where,
    addDoc,
    collection,
    Firestore,
    getFirestore,
    DocumentData,
    WithFieldValue,
    SnapshotOptions,
    FirestoreDataConverter,
    QueryDocumentSnapshot
} from 'firebase/firestore';
import { useState } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';

import { Message } from 'config/types';
import { getUserId } from 'state/user/selectors';
import { firebaseApp } from 'libs/firebaseApp';
import { useAppSelector } from 'hooks/useAppSelector';
import { useAsyncAction } from 'hooks/useAsyncAction';
import { setRecordWithDate } from 'libs/firestoreUtils';
import { useUserNotifications } from 'features/notificationsButton/useUserNotifications';

type StateLogic = {
    text: string;
    userId: string;
    messages: Message[] | undefined;
    submitting: boolean;
    sendMessage: () => void;
    handleTextChange: (value: string) => void;
};

const messageConverter: FirestoreDataConverter<Message> = {
    toFirestore(message: WithFieldValue<Message>): DocumentData {
        return {
            text: message.text,
            userId: message.userId,
            clientId: message.clientId,
            createdAt: message.createdAt,
            merchantId: message.merchantId
        };
    },
    fromFirestore(
        snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions
    ): Message {
        const data = snapshot.data(options);
        return {
            text: data.text,
            userId: data.userId,
            clientId: data.clientId,
            createdAt: data.createdAt,
            merchantId: data.merchantId
        };
    }
};

export const useChatRoom = (
    assetId: string,
    clientId: string,
    merchantId: string
): StateLogic => {
    const userId = useAppSelector(getUserId);

    const [_, sendNotification] = useUserNotifications({ silent: true });

    const db: Firestore = getFirestore(firebaseApp);

    const messageRef = collection(
        db,
        'messages',
        assetId,
        'assetMessages'
    ).withConverter(messageConverter);

    // Retrieve messages associated with this asset and this potential buyer
    const q = query(
        messageRef,
        where('clientId', '==', clientId),
        where('merchantId', '==', merchantId)
    );

    const [messages] = useCollectionData(q);

    const [text, setText] = useState<string>('');

    const handleTextChange = (value: string): void => {
        setText(value);
    };

    const action = async (): Promise<void> => {
        const record = { text, userId, clientId, merchantId };
        await addDoc(messageRef, setRecordWithDate(record));
        sendNotification({ text: `New message about this` });
        setText('');
    };

    const [sendMessage, submitting] = useAsyncAction<void, void>(action, {
        error: 'errorSendingMessage'
    });

    return {
        text,
        userId,
        messages,
        submitting,
        sendMessage,
        handleTextChange
    };
};
