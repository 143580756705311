import { SubmitHandler } from 'react-hook-form';

import { getUserId } from 'state/user/selectors';
import { StorageService } from 'services/StorageService';
import { useAppSelector } from 'hooks/useAppSelector';
import { useAsyncAction } from 'hooks/useAsyncAction';
import { useUpdateUserData } from 'hooks/useUpdateUserData';
import { User, EditProfileFormInputWithAvatar } from 'config/types/user';

type StateLogic = [SubmitHandler<EditProfileFormInputWithAvatar>, boolean];

export const useEditProfile = (noCatch?: boolean): StateLogic => {
    const userId = useAppSelector(getUserId);

    const [updateUserData] = useUpdateUserData();

    const edit: SubmitHandler<EditProfileFormInputWithAvatar> = async ({
        avatarFile,
        ...data
    }): Promise<void> => {
        // Merge the new user input data into the previously stored one
        const newData: Partial<User> = data;
        // Save avatar to storage and get url pointing to it
        if (avatarFile) {
            newData.avatar = await StorageService.uploadAvatar(
                avatarFile,
                userId
            );
        }
        updateUserData(newData);
    };

    const [process, processing] = useAsyncAction(edit, {
        error: 'profileEditError',
        success: 'successfulProfileEdit'
    });

    return [noCatch ? edit : process, processing];
};
