import Box from '@mui/material/Box';
import { Row } from 'components/Row';
import { Image } from 'components/Image';
import { TradedItem } from 'config/types/asset';
import { LightDivider } from 'components/LightDivider';
import { EquityOwned } from 'features/itemGallery/EquityOwned';
import { PurchasePrice } from 'features/itemGallery/PurchasePrice';
import { ExpectedReturn } from 'features/itemGallery/ExpectedReturn';
import { getItemCoverPicture } from 'libs/utils';

import { SalesPrice } from './SalesPrice';
import { ItemName } from './ItemName';
import { ItemViewTopBar } from './ItemViewTopBar';
import { SoldItemActions } from './SoldItemActions';
import { UnsoldItemActions } from './UnsoldItemActions';
import { styled, useTheme } from '@mui/material/styles';

import { getUserData } from 'state/user/selectors';
import { User } from 'config/types/user';
import { useAppSelector } from 'hooks/useAppSelector';

type Props = Omit<React.ComponentProps<typeof Row>, 'children'> & {
    data: TradedItem;
};

const GradientBox = styled(Box)`
    padding: 0px 50px 0px 50px;
    width: 100%;
    border-radius: 0 5px 5px 0;
    background: ${({ theme }) => theme.palette.success.dark};
    border: 1px solid #b4b4b4;
    @media (min-width: 768px) {
        width: 55%;
    }

    @media (min-width: 1200px) {
        width: 50%;
    }
`;

export const ItemView = ({ data, ...other }: Props): JSX.Element => {
    const isSold = data.status === 'sold';
    const theme = useTheme();
    const user: User = useAppSelector(getUserData);
    const customer = user.isAdmin === false;

    const { uri } = getItemCoverPicture(data) || {};

    return (
        <Row {...other}>
            <Image
                sx={{
                    width: { sm: '45%', lg: '50%' },
                    height: 470,
                    display: 'block',
                    objectFit: 'cover',
                    objectPosition: 'center',
                    borderRadius: '5px 0 0 5px'
                }}
                uri={uri}
                title={data.name}
            />
            <GradientBox customer={customer}>
                <ItemViewTopBar isSold={isSold} />
                <Box sx={{ my: 3 }}>
                    <ItemName data={data.name} />
                    <EquityOwned data={data.equityOwned} />
                    <PurchasePrice data={data.price} />
                    <SalesPrice data={data.salesPrice} />
                    <ExpectedReturn data={data.expectedReturn} />
                </Box>
                <LightDivider />
                <Box sx={{ mt: 3 }}>
                    {!isSold ? (
                        <UnsoldItemActions
                            onSell={() => null}
                            onStaking={() => null}
                            onShowInfo={() => null}
                        />
                    ) : (
                        <SoldItemActions invoiceURI="" salesReceiptURI="" />
                    )}
                </Box>
            </GradientBox>
        </Row>
    );
};
