import { useState } from 'react';

import { Row } from 'components/Row';
import { Typography } from 'components/Typography';
import { StandardModal } from 'components/StandardModal';
import { TradeHoldingsForm } from './TradeHoldingsForm';

type Props = {
    title: string;
    children: (handleOpen: () => void) => React.ReactNode;
    instructions: React.ReactNode;
    holdingsOwned: number;
};

export const TradeHoldingsModal = ({
    title,
    children,
    instructions,
    holdingsOwned
}: Props): JSX.Element => {
    const [open, setOpen] = useState<boolean>(false);

    const handleOpen = (): void => {
        setOpen(true);
    };

    const handleClose = (): void => {
        setOpen(false);
    };

    return (
        <>
            {children(handleOpen)}
            <StandardModal title={title} open={open} handleClose={handleClose}>
                <Row sx={{ mt: 1, mb: 5 }} spacing={1}>
                    <Typography
                        uppercased
                        variant="body1"
                        textId="holdings owned"
                    />
                    <Typography bold variant="body1">
                        {holdingsOwned}
                    </Typography>
                </Row>
                <TradeHoldingsForm
                    onCancel={handleClose}
                    maxHoldings={holdingsOwned}>
                    {instructions}
                </TradeHoldingsForm>
            </StandardModal>
        </>
    );
};
