/* eslint-disable prettier/prettier, max-len  */
import { Menu } from 'react-pro-sidebar';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import LiveHelpOutlinedIcon from '@mui/icons-material/LiveHelpOutlined';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';

import { MenuLink } from 'features/sidebar/MenuLink';
import { ReactComponent as HomeIcon } from 'assets/homeIcon.svg';
import { ReactComponent as WatchIcon } from 'assets/watch.svg';
import { ReactComponent as ContactUsIcon } from 'assets/contactUsIcon.svg';
import { ReactComponent as WhoWeAreIcon } from 'assets/whoWeAreIcon.svg';
import { ReactComponent as LoyaltyTiersIcon } from 'assets/loyaltyTiersIcon.svg';

type Props = {
    onClick?: () => void;
}

export const PublicSidebar = ({onClick}: Props): JSX.Element => (
    <Menu iconShape="circle" onClick={onClick}>
        <MenuLink Icon={HomeIcon} path="/auth" title="home" />
        <MenuLink Icon={WhoWeAreIcon} path="/who-we-are" title="whoWeAre" />
        <MenuLink Icon={WatchIcon} path="/our-team" title="ourTeam" />
        <MenuLink Icon={MapOutlinedIcon} path="/roadmap" title="roadmap" />
        <MenuLink Icon={LoyaltyTiersIcon} path="/loyalty-tiers" title="nftLoyaltyTiers" />
        <MenuLink Icon={ContactUsIcon} path="contact-us" title="contactUs" />
        <MenuLink Icon={LiveHelpOutlinedIcon} path="faq" title="faq" />
        <MenuLink Icon={VerifiedUserOutlinedIcon} path="privacy-policy" title="privacyPolicy" />
    </Menu>
);
