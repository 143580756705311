import Grid from '@mui/material/Grid';

type Props = {
    size?: number;
    children: React.ReactNode;
    fullWidth?: boolean;
};

export const InputField = ({
    size = 6,
    fullWidth = false,
    children
}: Props): JSX.Element => {
    return (
        <Grid item xs={12} md={fullWidth ? 12 : size}>
            {children}
        </Grid>
    );
};
