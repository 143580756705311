export const BLOCKCHAIN_CURRENCY_TO_DOLLAR = 1350;

export const DOLLAR_TO_PROPRIETARY_CURRENCY = 100;

export const DEV_MODE = process.env.NODE_ENV === 'development';

export const PROD_MODE = process.env.NODE_ENV === 'production';

export const TEST_MODE = process.env.NODE_ENV === 'test';

export const SHOW_RENDERED = true;

export const LOCAL_BLOCKCHAIN = false;

export const HOST_NETWORK = LOCAL_BLOCKCHAIN ? 'localhost' : 'goerli';

export const SITE_TITLE = 'Lux Vesting';

export const LUX_TOKEN_TO_USD = 100;

export const IPFS_DOC_UPLOAD_FEE = 100; // in LVX

export const NFT_TOKEN_CONTRACT_ADDRESS = LOCAL_BLOCKCHAIN
    ? process.env.REACT_APP_NFT_TOKEN_CONTRACT_ADDRESS_ON_LOCALHOST!
    : process.env.REACT_APP_NFT_TOKEN_CONTRACT_ADDRESS_ON_GOERLI!;

export const NFT_MARKETPLACE_CONTRACT_ADDRESS = LOCAL_BLOCKCHAIN
    ? process.env.REACT_APP_MARKETPLACE_CONTRACT_ADDRESS_ON_LOCALHOST!
    : process.env.REACT_APP_MARKETPLACE_CONTRACT_ADDRESS_ON_GOERLI!;

export const TOKEN_CONTRACT_ADDRESS = LOCAL_BLOCKCHAIN
    ? process.env.REACT_APP_TOKEN_CONTRACT_ADDRESS_ON_LOCALHOST!
    : process.env.REACT_APP_TOKEN_CONTRACT_ADDRESS_ON_GOERLI!;

export const TOKEN_VENDOR_CONTRACT_ADDRESS = LOCAL_BLOCKCHAIN
    ? process.env.REACT_APP_TOKEN_VENDOR_CONTRACT_ADDRESS_ON_LOCALHOST!
    : process.env.REACT_APP_TOKEN_VENDOR_CONTRACT_ADDRESS_ON_GOERLI!;

export const CONTRACT_OWNER_PRIVATE_KEY =
    process.env.REACT_APP_OWNER_ACCOUNT_PRIVATE_KEY;

export const IS_TEST_NETWORK = HOST_NETWORK === 'goerli';

export const LUX_VESTING_INITIAL_BID_RATIO = 0.25;
