// TODO: Merge with other table components
import { styled } from '@mui/material/styles';
import MuiTableRow from '@mui/material/TableRow';

export const TableRow = styled(MuiTableRow)(({ theme }) => ({
    td: {
        border: 0,
        textAlign: 'center'
    },
    '&:hover': {
        td: {
            border: `2px solid ${theme.palette.primary.light}`,
            borderRight: 0,
            borderLeft: 0,
            '&:first-of-type': {
                borderRadius: '10px 0 0 10px',
                border: `2px solid ${theme.palette.primary.light}`,
                borderRight: 0
            },
            '&:last-child': {
                borderRadius: '0 10px 10px 0',
                border: `2px solid ${theme.palette.primary.light}`,
                borderLeft: 0
            }
        }
    }
}));
