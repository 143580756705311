import { styled } from '@mui/material/styles';
import MuiSelect from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { Controller, Path, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type Props<T> = {
    name: Path<T>;
    values: readonly string[];
    disabled?: boolean;
};

const Label = styled('div')(
    ({ theme }) => `
    color: #fff;
    font-size: 11px;
    margin-bottom: ${theme.spacing(1)};
    text-align: left;
`
);

const Select = styled(MuiSelect)`
    background-color: #fff;
    width: 115px;
`;

const validationRules = {
    required: { value: true, message: 'Required' }
};

export const SelectValue = <T extends Record<string, unknown>>({
    name,
    values,
    disabled
}: Props<T>): JSX.Element => {
    const { t } = useTranslation();

    const {
        control,
        formState: { errors }
    } = useFormContext();

    const error = errors?.userType?.message;

    return (
        <Controller
            name="userType"
            rules={validationRules}
            control={control}
            render={({ field }) => (
                <FormControl error={!!error}>
                    <Label>{t(name)}</Label>
                    <Select
                        id={`${name}-select`}
                        size="small"
                        label={t(name)}
                        labelId={`${name}-select-label`}
                        defaultValue=""
                        disabled={disabled}
                        {...field}>
                        {values.map((value: string) => (
                            <MenuItem key={value} value={value}>
                                {t(value)}
                            </MenuItem>
                        ))}
                    </Select>
                    {!!error && <FormHelperText>{error}</FormHelperText>}
                </FormControl>
            )}
        />
    );
};
