import { useEffect, useReducer } from 'react';
import Torus from '@toruslabs/torus-embed';
import { providers } from 'ethers';

import { Web3State, AllActions } from 'config/types/blockchain';

import { logger } from 'libs/logger';
import { torusConfig } from 'config/torus';
import { contractClasses, contractInfo, hostNetwork } from 'config';

import { useAlert } from 'hooks/useAlert';

const log = logger('Blockchain Service');

const initialState: Web3State = {
    loading: true,
    ready: false
};

const reducer = (
    state: Web3State,
    { type, payload }: AllActions
): Web3State => {
    switch (type) {
        case 'INITIALIZE': {
            return {
                ...state,
                loading: false,
                ready: true,
                torus: payload.torus,
                provider: payload.provider
            };
        }

        case 'CONTRACTS': {
            return {
                ...state,
                nft: payload.nft,
                staking: payload.staking,
                marketplace: payload.marketplace,
                bidding: payload.bidding,
                frac: payload.frac
            };
        }

        case 'ERROR': {
            return {
                ...state,
                loading: false
            };
        }

        default:
            return state;
    }
};

const Handler = (): Web3State => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { errorAlert } = useAlert();

    const handleError = (e: unknown): void => {
        dispatch({ type: 'ERROR', payload: undefined });
        errorAlert('appInitialisationError');
        logger().error(e);
    };

    const createContracts = (): void => {
        console.log('creating contracts');
        try {
            const nft = new contractClasses.nft(
                ...contractInfo.nft,
                state.provider!
            );

            Object.freeze(nft);

            const staking = new contractClasses.staking(
                ...contractInfo.staking,
                state.provider!
            );

            Object.freeze(staking);

            const marketplace = new contractClasses.marketplace(
                ...contractInfo.marketplace,
                state.provider!
            );

            Object.freeze(marketplace);

            const bidding = new contractClasses.bidding(
                ...contractInfo.bidding,
                state.provider!
            );
            Object.freeze(bidding);

            Object.freeze(bidding);

            const frac = new contractClasses.frac(
                ...contractInfo.frac,
                state.provider!
            );

            Object.freeze(frac);

            dispatch({
                type: 'CONTRACTS',
                payload: { nft, staking, marketplace, bidding, frac }
            });
        } catch (e) {
            handleError(e);
        }
    };

    const init = async (): Promise<void> => {
        try {
            log.debug('Initialisation');

            const torus = new Torus();
            await torus.init(torusConfig);

            log.debug('Get provider for network: ', hostNetwork);
            const provider = new providers.Web3Provider(torus.provider);

            dispatch({ type: 'INITIALIZE', payload: { torus, provider } });
        } catch (e) {
            handleError(e);
        }
    };

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        if (state.ready) createContracts();
    }, [state.ready]);

    return state;
};

export default Handler;
