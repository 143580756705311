import Box from '@mui/material/Box';

type Props = {
    sx?: Record<string, unknown>;
    children: React.ReactNode;
};

export const AlignRight = ({ sx, children }: Props): JSX.Element => (
    <Box
        sx={{ display: 'flex', width: '100%', ...sx }}
        justifyContent="flex-end">
        {children}
    </Box>
);
