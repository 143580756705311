import { useState } from 'react';

import { BidInput } from 'features/bidInput/BidInput';
import { AssetItem } from 'config/types/asset';
import { LightButton } from 'components/buttons/LightButton';

type Props = {
    item: AssetItem;
};

export const EnterBidButton = ({ item }: Props): JSX.Element => {
    const [showInput, setShowInput] = useState<boolean>(false);

    const onToggleBid = (): void => setShowInput(!showInput);

    return showInput ? (
        <BidInput item={item} handleClose={onToggleBid} />
    ) : (
        <LightButton textId="bid" onClick={onToggleBid} />
    );
};
