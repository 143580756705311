import { useContext } from 'react';
import { BlockChainContext } from 'blockchain/BlockchainContext';
import { getUserId, getUserName } from 'state/user/selectors';
import { RootState } from 'app/store';
import { AssetItem } from 'config/types/asset';
import { assetsActions } from 'state/assets/assetsSlice';
import { useAppSelector } from 'hooks/useAppSelector';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { Bid, HookOptions } from 'config/types';
import { useUserNotifications } from 'features/notificationsButton/useUserNotifications';
import { getAssetNftId, getAssetName } from 'state/assets/selectors';

import { useAsyncAction } from './useAsyncAction';
import { useTokenService } from './useTokenService';
import { NFT_MARKETPLACE_CONTRACT_ADDRESS } from 'config/constants';

type ActionParams = { value: number };

type StateLogic = [(param: ActionParams) => Promise<void>, boolean];

export const useBidOnItem = (
    { id, merchantId }: AssetItem,
    op: HookOptions = {}
): StateLogic => {
    const dispatch = useAppDispatch();
    const { bidding, staking } = useContext(BlockChainContext);

    const { sendNotification } = useUserNotifications(merchantId);

    const nftId = useAppSelector((state: RootState) =>
        getAssetNftId(state, id)
    );

    const itemName = useAppSelector((state: RootState) =>
        getAssetName(state, id)
    );

    const bidder = useAppSelector(getUserId);

    const { approveTokens } = useTokenService();

    const action = async ({ value }: ActionParams): Promise<void> => {
        const highestBid: Bid = { nftId, value, bidder };

        await approveTokens({
            recipient: NFT_MARKETPLACE_CONTRACT_ADDRESS,
            value
        });

        await bidding!.createBid(highestBid);
        dispatch(assetsActions.updateData({ id, data: { highestBid } }));
        sendNotification({ type: 'newBidPlaced', value: itemName });
    };

    const [bidOnItem, processing] = useAsyncAction<ActionParams, void>(action, {
        info: 'recordindgBidOnBlockchain',
        error: 'errorBiddingOnItem',
        success: 'successBiddingOnItem',
        ...op
    });

    return [bidOnItem, processing];
};
