import PerfectScrollbar from 'react-perfect-scrollbar';

import { Row } from 'components/Row';
import { Typography } from 'components/Typography';
import { ViewAllLink } from 'components/ViewAllLink';
import { StandardCard } from 'components/StandardCard';
import { useAppSelector } from 'hooks/useAppSelector';
import { UserLimitOrder } from 'config/types/user';
import { getUserLimitOrdersData } from 'state/user/selectors';
import { UserLimitOrdersCard } from 'features/dataCards/userLimitOrdersCard/UserLimitOrdersCard';

export const UserLimitOrdersSnapshot = (): JSX.Element => {
    const data: UserLimitOrder[] = useAppSelector(getUserLimitOrdersData);

    return (
        <StandardCard
            sx={{
                maxWidth: { xs: 400, md: 800 },
                height: 550,
                m: 'auto'
            }}>
            <Row alignItems="baseline" justifyContent="space-between">
                <Typography
                    bold
                    allCapitalized
                    variant="h5"
                    textId="limit orders"
                />
                <ViewAllLink to="/portfolio-tables/limit-orders" />
            </Row>
            <PerfectScrollbar>
                <Row sx={{ my: 2 }} spacing={2}>
                    {data.map((item) => (
                        <UserLimitOrdersCard key={item.item} data={item} />
                    ))}
                </Row>
            </PerfectScrollbar>
        </StandardCard>
    );
};
