import { Row } from 'components/Row';
import { Spinner } from 'components/Spinner';
import { RedButton } from 'components/buttons/RedButton';
import { AssetListingStatus } from 'config/types/asset';
import { LightButton } from 'components/buttons/LightButton';
import { BlackButton } from 'components/buttons/BlackButton';

import { Typography } from 'components/Typography';
import { EditButton } from './EditButton';

type Props = {
    id: string;
    status: AssetListingStatus;
    onViewDetails: () => void;
};

export const ButtonToAction = ({
    id,
    status,
    onViewDetails
}: Props): JSX.Element =>
    ({
        pending: (
            <Row justifyContent="space-between">
                <EditButton id={id} />
                <BlackButton textId="details" onClick={onViewDetails} />
            </Row>
        ),
        denied: (
            <Row justifyContent="space-between">
                <EditButton id={id} />
                <BlackButton textId="details" onClick={onViewDetails} />
            </Row>
        ),
        listed: (
            <Row justifyContent="space-between">
                <BlackButton textId="details" onClick={onViewDetails} />
                <EditButton id={id} />
                <RedButton textId="delete" />
            </Row>
        ),
        notListed: (
            <Row justifyContent="space-between">
                <EditButton id={id} />
                <BlackButton textId="details" onClick={onViewDetails} />
                <RedButton textId="delete" />
            </Row>
        ),
        unverified: (
            <Row justifyContent="space-between">
                <BlackButton textId="details" onClick={onViewDetails} />
                <EditButton id={id} />
                <RedButton textId="delete" />
            </Row>
        ),
        purchased: <LightButton textId="payoutInformation" />,
        processing: <Spinner />,
        sold: (
            <Row justifyContent="center">
                <Typography textId="sold" />
            </Row>
        )
    }[status]);
