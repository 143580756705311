import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import { Row } from 'components/Row';
import { Logo } from 'components/Logo';
import { Typography } from 'components/Typography';
import { Grid } from '@mui/material';

const totalCoins = 1245;
const coinValue = 12452;

const Container = styled(Row)(({ theme }) => ({
    padding: '10px',
    borderRadius: '5px',
    marginBottom: '20px',
    background: theme.palette.error.contrastText
}));

const Datatype = styled(Typography)(({ theme }) => ({
    fontWeight: theme.typography.fontWeightBold,
    '@media (max-width: 820px)': {
        fontSize: '20px'
    },
    '@media (max-width: 600px)': {
        fontSize: '12px'
    },
    '@media (max-width: 290px)': {
        fontSize: '10px'
    }
}));

const Data = styled(Typography)(({ theme }) => ({
    fontWeight: theme.typography.fontWeightLight,
    '@media (max-width: 820px)': {
        fontSize: '16px'
    },
    '@media (max-width: 600px)': {
        fontSize: '12px'
    },
    '@media (max-width: 290px)': {
        fontSize: '10px'
    }
}));

const LogoContainer = styled(Logo)(({ theme }) => ({
    width: '120px',
    '@media (max-width: 600px)': {
        width: '60px'
    }
}));

export const OverviewBanner = (): JSX.Element => (
    <Container spacing={0} alignItems="center" justifyContent="space-around">
        <LogoContainer style={{ width: '14vw' }} />

        <Grid alignItems="center" spacing={2}>
            <Datatype variant="h4">Platinum</Datatype>
            <Data variant="h6" textId="coupon tier" allCapitalized />
        </Grid>
        <Datatype variant="h4">=</Datatype>
        <Grid alignItems="center" spacing={2}>
            <Row alignItems="baseline">
                <Datatype variant="h4">15</Datatype>
                <Datatype variant="h5">% off</Datatype>
            </Row>
            <Data variant="h6" textId="items in marketplace" allCapitalized />
        </Grid>
        <Grid alignItems="center" spacing={2}>
            <Datatype variant="h4">${totalCoins.toLocaleString()}</Datatype>
            <Data variant="h6" textId="total LVX coins" allCapitalized />
        </Grid>
        <Grid alignItems="center" spacing={2}>
            <Datatype variant="h4">${coinValue.toLocaleString()}</Datatype>
            <Data variant="h6" textId="LVC value (USD)" allCapitalized />
        </Grid>
        <Grid alignItems="center" spacing={2}>
            <Datatype variant="h4">$0.00</Datatype>
            <Data variant="h6" textId="net IRR" allCapitalized />
        </Grid>
    </Container>
);
