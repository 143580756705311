import Avatar from '@mui/material/Avatar';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useTranslation } from 'react-i18next';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Menu, MenuItem, SubMenu } from 'react-pro-sidebar';

import { Spinner } from 'components/Spinner';

import { useTorusLogout } from './useTorusLogout';
import { User } from 'config/types/user';
import styled from 'styled-components';

type Props = {
    name?: User;
    avatar?: string;
};

export const SidebarUserOverview = ({
    avatar = '',
    name
}: Props): JSX.Element => {
    const { t } = useTranslation();

    const MenuContainer = styled(Menu)(() => ({
        background: name?.isAdmin
            ? 'linear-gradient(180deg, #3C4858 0%, #151516 58.85%)'
            : 'linear-gradient(179.14deg, #FFFFFF 0.73%, #F5F5F5 121.08%)'
    }));

    const SubMenuContainer = styled(SubMenu)(() => ({
        color: name?.isAdmin ? 'white' : 'black'
    }));

    const [logout, loggingOut, loggedOut] = useTorusLogout();

    return (
        <MenuContainer iconShape="circle">
            <SubMenuContainer
                title={name?.name}
                icon={<Avatar alt={name?.name} src={avatar} />}>
                <MenuItem icon={<AccountCircleIcon />}>
                    <Link
                        to="/profile"
                        style={{
                            color: name?.isAdmin ? 'white' : 'black'
                        }}>
                        {t('myProfile')}
                    </Link>
                </MenuItem>
                <MenuItem icon={<EditIcon />}>
                    <Link
                        style={{ color: name?.isAdmin ? 'white' : 'black' }}
                        to="/edit-profile">
                        {t('editProfile')}
                    </Link>
                </MenuItem>
                <MenuItem onClick={logout} icon={<ExitToAppIcon />}>
                    {loggingOut && !loggedOut ? <Spinner /> : t('logout')}
                </MenuItem>
            </SubMenuContainer>
        </MenuContainer>
    );
};
