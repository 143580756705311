import { Typography } from 'components/Typography';

type Props = {
    textId: string;
};

export const CardTitle = ({ textId }: Props): JSX.Element => (
    <Typography
        sx={{
            '@media (max-width: 290px)': {
                fontSize: '1rem',
                'line-height': '.9rem'
            }
        }}
        bold
        allCapitalized
        variant="h5"
        textId={textId}
    />
);
