/* eslint-disable max-len */
import { PortfolioOrdersData } from 'config/types';

export const portfolioOrdersData: PortfolioOrdersData[] = [
    {
        id: '44d9c5ae-4a9a-484c-aee5-bb650fe2dc9f',
        product: 'Langloisia Greene',
        vendor: 'bstenm',
        customer: 'cgossop0',
        shares: 18,
        costPerShare: 16.67,
        totalCost: 61.67,
        estNav: 4.6,
        impliedGrossChange: 0.11,
        netIRR: 6.96,
        date: '5/11/2022',
        status: 'pending',
        paymentType: 'debit card',
        actions: 'approve'
    },
    {
        id: '59e0bf4e-c08a-40af-a7f3-d495121884fd',
        product: 'Perityle ciliata (L.H. Dewey) Rydb.',
        vendor: 'bstenm',
        customer: 'mhamor1',
        shares: 34,
        costPerShare: 25.21,
        totalCost: 77.46,
        estNav: 0.62,
        impliedGrossChange: 0.32,
        netIRR: 6.17,
        date: '7/30/2021',
        status: 'sold',
        paymentType: 'credit card',
        actions: 'mark sold'
    },
    {
        id: '6622de26-ddcc-4709-b489-2c590d552612',
        product: 'Metrosideros waialealae (Rock) Rock var. waialealae',
        vendor: 'bstenm',
        customer: 'dgiacobo2',
        shares: 61,
        costPerShare: 32.58,
        totalCost: 59.31,
        estNav: 0.49,
        impliedGrossChange: 0.43,
        netIRR: 8.51,
        date: '10/23/2021',
        status: 'listed',
        paymentType: 'debit card',
        actions: 'mark sold'
    },
    {
        id: 'f7b731f1-4d29-4804-8570-65810b7f0176',
        product: 'Cryptantha roosiorum Munz',
        vendor: 'bstenm',
        customer: 'cfeifer3',
        shares: 33,
        costPerShare: 43.69,
        totalCost: 84.0,
        estNav: 6.86,
        impliedGrossChange: 0.78,
        netIRR: 2.52,
        date: '7/4/2022',
        status: 'pending',
        paymentType: 'bank transfer',
        actions: 'approve'
    },
    {
        id: '05a7c2de-a2ff-4a6d-a579-7b06ac9e7e52',
        product: 'Phacelia leonis J.T. Howell',
        vendor: 'bstenm',
        customer: 'awestmorland4',
        shares: 48,
        costPerShare: 10.05,
        totalCost: 59.49,
        estNav: 0.79,
        impliedGrossChange: 0.06,
        netIRR: 4.63,
        date: '6/2/2022',
        status: 'listed',
        paymentType: 'credit card',
        actions: 'item info'
    },
    {
        id: '72df46e2-9eb4-4175-aac6-ecd4504185c0',
        product: 'Trichoptilium A. Gray',
        vendor: 'bstenm',
        customer: 'oscriven5',
        shares: 90,
        costPerShare: 24.14,
        totalCost: 85.07,
        estNav: 3.39,
        impliedGrossChange: 0.49,
        netIRR: 1.9,
        date: '11/12/2021',
        status: 'pending',
        paymentType: 'debit card',
        actions: 'approve'
    },
    {
        id: '4bb95a34-7a33-4b5d-bfd0-71f6b1313f5a',
        product: 'Labordia triflora Hillebr.',
        vendor: 'bstenm',
        customer: 'fbugg6',
        shares: 52,
        costPerShare: 24.81,
        totalCost: 65.35,
        estNav: 4.72,
        impliedGrossChange: 0.22,
        netIRR: 2.56,
        date: '3/29/2022',
        status: 'sold',
        paymentType: 'bank transfer',
        actions: 'mark sold'
    },
    {
        id: '8ea785c6-5375-4b9e-8037-d8ee6fb09ad0',
        product: 'Poa cusickii Vasey ssp. cusickii',
        vendor: 'bstenm',
        customer: 'sshooter7',
        shares: 11,
        costPerShare: 16.99,
        totalCost: 52.2,
        estNav: 7.86,
        impliedGrossChange: 0.17,
        netIRR: 7.99,
        date: '2/15/2022',
        status: 'pending',
        paymentType: 'credit card',
        actions: 'mark sold'
    },
    {
        id: '93911a45-6798-4cc3-beaf-2d2550d7b818',
        product:
            'Ranunculus inamoenus Greene var. subaffinis (A. Gray) L.D. Benson',
        vendor: 'bstenm',
        customer: 'bcullnean8',
        shares: 86,
        costPerShare: 30.26,
        totalCost: 87.72,
        estNav: 5.02,
        impliedGrossChange: 0.8,
        netIRR: 7.89,
        date: '7/9/2022',
        status: 'sold',
        paymentType: 'credit card',
        actions: 'item info'
    },
    {
        id: 'bb6db2f5-bdb9-4397-bf6b-fa4b282310c7',
        product: 'Alchemilla glomerulans Buser',
        vendor: 'bstenm',
        customer: 'dwinyard9',
        shares: 75,
        costPerShare: 33.46,
        totalCost: 53.16,
        estNav: 5.63,
        impliedGrossChange: 0.45,
        netIRR: 7.34,
        date: '4/29/2022',
        status: 'sold',
        paymentType: 'bank transfer',
        actions: 'approve'
    },
    {
        id: 'd958430b-b4a8-439c-8b11-ab776d04d8b3',
        product: 'Sulcaria isidiifera Brodo',
        vendor: 'bstenm',
        customer: 'ttuttietta',
        shares: 22,
        costPerShare: 30.28,
        totalCost: 68.03,
        estNav: 8.98,
        impliedGrossChange: 0.66,
        netIRR: 1.29,
        date: '2/8/2022',
        status: 'pending',
        paymentType: 'debit card',
        actions: 'approve'
    },
    {
        id: '4261097b-007c-4318-927e-063c4a1c79b8',
        product: 'Ocotea wrightii (Meisn.) Mez',
        vendor: 'bstenm',
        customer: 'gsillickb',
        shares: 14,
        costPerShare: 47.11,
        totalCost: 68.6,
        estNav: 7.24,
        impliedGrossChange: 0.98,
        netIRR: 7.52,
        date: '1/29/2022',
        status: 'sold',
        paymentType: 'debit card',
        actions: 'mark sold'
    },
    {
        id: '48605ac9-509e-4668-a9e1-3d673db8d661',
        product: 'Cyrtandra ×villicalyx H. St. John & Storey (pro sp.)',
        vendor: 'bstenm',
        customer: 'mandrusc',
        shares: 84,
        costPerShare: 26.44,
        totalCost: 70.21,
        estNav: 9.35,
        impliedGrossChange: 0.34,
        netIRR: 5.03,
        date: '12/5/2021',
        status: 'pending',
        paymentType: 'debit card',
        actions: 'approve'
    },
    {
        id: 'caf5efac-787f-42e3-b3f5-120ab0d22cc8',
        product: 'Spergularia salina J. Presl & C. Presl',
        vendor: 'bstenm',
        customer: 'dbaldickd',
        shares: 19,
        costPerShare: 14.22,
        totalCost: 53.02,
        estNav: 7.34,
        impliedGrossChange: 0.66,
        netIRR: 0.28,
        date: '1/14/2022',
        status: 'listed',
        paymentType: 'bank transfer',
        actions: 'approve'
    },
    {
        id: '9f4b99b0-7a03-4b1b-bb7e-fddc7086e583',
        product: 'Geranium robertianum L. ssp. purpureum (Vill.) Nyman',
        vendor: 'bstenm',
        customer: 'sfaradye',
        shares: 83,
        costPerShare: 36.15,
        totalCost: 55.72,
        estNav: 2.44,
        impliedGrossChange: 0.31,
        netIRR: 3.92,
        date: '6/10/2022',
        status: 'listed',
        paymentType: 'credit card',
        actions: 'mark sold'
    },
    {
        id: 'd8ef309b-cf5a-4964-ba5a-151eaf1f9efa',
        product: 'Cyrtandra hawaiensis C.B. Clarke',
        vendor: 'bstenm',
        customer: 'skobusf',
        shares: 89,
        costPerShare: 32.71,
        totalCost: 82.87,
        estNav: 7.21,
        impliedGrossChange: 0.5,
        netIRR: 8.74,
        date: '11/27/2021',
        status: 'pending',
        paymentType: 'debit card',
        actions: 'approve'
    },
    {
        id: 'e7f07411-3349-4d71-bd81-1d1edf6468ad',
        product: 'Rhexia nashii Small',
        vendor: 'bstenm',
        customer: 'rpycockg',
        shares: 19,
        costPerShare: 45.03,
        totalCost: 96.84,
        estNav: 8.5,
        impliedGrossChange: 0.3,
        netIRR: 7.67,
        date: '1/23/2022',
        status: 'sold',
        paymentType: 'debit card',
        actions: 'mark sold'
    },
    {
        id: 'a0a879b3-f679-4285-a932-dc3753b50a37',
        product: 'Sidalcea hickmanii Greene ssp. anomala C.L. Hitchc.',
        vendor: 'bstenm',
        customer: 'mcuncarrh',
        shares: 34,
        costPerShare: 19.26,
        totalCost: 85.65,
        estNav: 0.98,
        impliedGrossChange: 0.94,
        netIRR: 2.59,
        date: '9/12/2021',
        status: 'sold',
        paymentType: 'credit card',
        actions: 'item info'
    },
    {
        id: 'f747b821-e065-4d33-aaf2-612955bae6c0',
        product: 'Caloplaca squamosa (de Lesd.) Zahlbr.',
        vendor: 'bstenm',
        customer: 'tscholcrofti',
        shares: 80,
        costPerShare: 48.6,
        totalCost: 53.05,
        estNav: 3.63,
        impliedGrossChange: 0.98,
        netIRR: 0.78,
        date: '1/19/2022',
        status: 'listed',
        paymentType: 'credit card',
        actions: 'item info'
    },
    {
        id: '2f34e84e-7a5d-46fd-82fb-547dc262f0f8',
        product: 'Malus ×robusta (Carrière) Rehder',
        vendor: 'bstenm',
        customer: 'tjaquemarj',
        shares: 48,
        costPerShare: 23.37,
        totalCost: 65.0,
        estNav: 1.99,
        impliedGrossChange: 0.51,
        netIRR: 7.0,
        date: '10/14/2021',
        status: 'sold',
        paymentType: 'bank transfer',
        actions: 'approve'
    },
    {
        id: 'c49f8a67-7fce-4858-908a-60bcfab7736e',
        product: 'Penstemon cinicola D.D. Keck',
        vendor: 'bstenm',
        customer: 'rderuggierok',
        shares: 64,
        costPerShare: 42.2,
        totalCost: 70.34,
        estNav: 1.12,
        impliedGrossChange: 0.64,
        netIRR: 0.91,
        date: '5/15/2022',
        status: 'pending',
        paymentType: 'bank transfer',
        actions: 'item info'
    },
    {
        id: '9062bd35-7d53-4efe-9d24-e5cde8b0ca02',
        product: 'Xylorhiza Nutt.',
        vendor: 'bstenm',
        customer: 'afribergl',
        shares: 92,
        costPerShare: 24.2,
        totalCost: 67.95,
        estNav: 8.01,
        impliedGrossChange: 0.59,
        netIRR: 0.57,
        date: '11/17/2021',
        status: 'pending',
        paymentType: 'bank transfer',
        actions: 'approve'
    },
    {
        id: '7ee8da8a-bf1d-4800-846c-8416185db802',
        product: 'Glyceria pulchella (Nash) K. Schum.',
        vendor: 'bstenm',
        customer: 'lyesm',
        shares: 78,
        costPerShare: 25.11,
        totalCost: 65.4,
        estNav: 1.53,
        impliedGrossChange: 0.89,
        netIRR: 7.94,
        date: '8/12/2021',
        status: 'listed',
        paymentType: 'debit card',
        actions: 'approve'
    },
    {
        id: '507c9456-4d9f-4790-9c63-7e1e41a00bce',
        product: 'Cyanea tritomantha A. Gray',
        vendor: 'bstenm',
        customer: 'pstubbingtonn',
        shares: 60,
        costPerShare: 32.19,
        totalCost: 71.76,
        estNav: 4.2,
        impliedGrossChange: 0.56,
        netIRR: 9.42,
        date: '12/21/2021',
        status: 'listed',
        paymentType: 'debit card',
        actions: 'item info'
    },
    {
        id: '66c5c5fd-9674-45b9-b70e-e4e265a6f5cb',
        product: 'Combretum imberbe Wawra',
        vendor: 'bstenm',
        customer: 'bscotchmoreo',
        shares: 51,
        costPerShare: 48.32,
        totalCost: 85.52,
        estNav: 1.07,
        impliedGrossChange: 0.95,
        netIRR: 5.86,
        date: '6/17/2022',
        status: 'listed',
        paymentType: 'debit card',
        actions: 'mark sold'
    },
    {
        id: 'e31ce9c8-ae80-4c11-8a1d-d52b66457f9b',
        product: 'Aegilops tauschii Coss.',
        vendor: 'bstenm',
        customer: 'eskitralp',
        shares: 48,
        costPerShare: 26.07,
        totalCost: 88.67,
        estNav: 7.64,
        impliedGrossChange: 0.39,
        netIRR: 9.62,
        date: '7/24/2021',
        status: 'pending',
        paymentType: 'debit card',
        actions: 'approve'
    },
    {
        id: '921c9886-7c10-4f83-99d1-031073fefd4d',
        product: 'Eriogonum greggii Torr. & A. Gray',
        vendor: 'bstenm',
        customer: 'szuenelliq',
        shares: 96,
        costPerShare: 31.84,
        totalCost: 96.17,
        estNav: 8.23,
        impliedGrossChange: 0.62,
        netIRR: 6.61,
        date: '12/26/2021',
        status: 'sold',
        paymentType: 'credit card',
        actions: 'mark sold'
    },
    {
        id: 'de9d2cfa-49f1-4c8c-851e-6db3c5b18288',
        product: 'Vulpia C.C. Gmel.',
        vendor: 'bstenm',
        customer: 'cjerramsr',
        shares: 87,
        costPerShare: 31.7,
        totalCost: 96.51,
        estNav: 8.61,
        impliedGrossChange: 0.0,
        netIRR: 4.21,
        date: '4/11/2022',
        status: 'sold',
        paymentType: 'bank transfer',
        actions: 'mark sold'
    },
    {
        id: '4733f1e0-9612-43c4-85d0-759a30ec92ef',
        product: 'Heuchera novomexicana Wheelock',
        vendor: 'bstenm',
        customer: 'arojels',
        shares: 91,
        costPerShare: 28.57,
        totalCost: 64.22,
        estNav: 4.49,
        impliedGrossChange: 0.02,
        netIRR: 5.98,
        date: '3/12/2022',
        status: 'listed',
        paymentType: 'bank transfer',
        actions: 'approve'
    },
    {
        id: '25b214b9-3732-4181-adbd-6c23f83bee5c',
        product: 'Ananas Mill.',
        vendor: 'bstenm',
        customer: 'nmacdonaldt',
        shares: 59,
        costPerShare: 13.84,
        totalCost: 65.65,
        estNav: 7.27,
        impliedGrossChange: 0.85,
        netIRR: 4.64,
        date: '7/2/2022',
        status: 'sold',
        paymentType: 'bank transfer',
        actions: 'mark sold'
    },
    {
        id: '770b8b25-eac2-4bb9-9cc8-e5f95d7b5d60',
        product: 'Juncus effusus L. var. gracilis Hook.',
        vendor: 'bstenm',
        customer: 'tisaacsonu',
        shares: 31,
        costPerShare: 45.99,
        totalCost: 87.78,
        estNav: 5.45,
        impliedGrossChange: 0.52,
        netIRR: 7.48,
        date: '12/30/2021',
        status: 'listed',
        paymentType: 'bank transfer',
        actions: 'item info'
    },
    {
        id: '30f0ac40-419f-4f80-ba43-f38c537288a8',
        product: 'Emilia coccinea (Sims) G. Don',
        vendor: 'bstenm',
        customer: 'clipscombev',
        shares: 78,
        costPerShare: 12.33,
        totalCost: 53.19,
        estNav: 0.76,
        impliedGrossChange: 0.68,
        netIRR: 0.08,
        date: '6/13/2022',
        status: 'pending',
        paymentType: 'credit card',
        actions: 'item info'
    },
    {
        id: 'a31c17e7-0e01-429c-aeca-8f81d05f9942',
        product: 'Cladonia pocillum (Ach.) Grognot',
        vendor: 'bstenm',
        customer: 'meickw',
        shares: 100,
        costPerShare: 24.21,
        totalCost: 60.48,
        estNav: 1.31,
        impliedGrossChange: 0.12,
        netIRR: 1.2,
        date: '10/8/2021',
        status: 'pending',
        paymentType: 'debit card',
        actions: 'mark sold'
    },
    {
        id: 'd6b91c18-dd67-4466-b1f0-a8dc863977ed',
        product: 'Cheilanthes lindheimeri Hook.',
        vendor: 'bstenm',
        customer: 'hthurstonx',
        shares: 31,
        costPerShare: 12.39,
        totalCost: 74.8,
        estNav: 3.01,
        impliedGrossChange: 0.54,
        netIRR: 2.46,
        date: '5/17/2022',
        status: 'sold',
        paymentType: 'credit card',
        actions: 'mark sold'
    },
    {
        id: 'f649d1b4-4dab-468a-bc1a-e9ff884e6026',
        product: 'Caragana frutex (L.) K. Koch',
        vendor: 'bstenm',
        customer: 'lknowlingy',
        shares: 66,
        costPerShare: 19.97,
        totalCost: 61.27,
        estNav: 3.91,
        impliedGrossChange: 0.36,
        netIRR: 9.04,
        date: '12/20/2021',
        status: 'sold',
        paymentType: 'debit card',
        actions: 'item info'
    },
    {
        id: 'd4573f06-a960-4ab0-bccc-9933b8a6e185',
        product: 'Berberis L.',
        vendor: 'bstenm',
        customer: 'cmaccambridgez',
        shares: 80,
        costPerShare: 36.15,
        totalCost: 69.59,
        estNav: 2.25,
        impliedGrossChange: 0.21,
        netIRR: 8.16,
        date: '3/1/2022',
        status: 'sold',
        paymentType: 'bank transfer',
        actions: 'mark sold'
    },
    {
        id: '60842873-d777-4838-a690-f69ca37730ad',
        product: 'Oxytropis lagopus Nutt. var. lagopus',
        vendor: 'bstenm',
        customer: 'pchadwyck10',
        shares: 15,
        costPerShare: 39.07,
        totalCost: 74.07,
        estNav: 9.55,
        impliedGrossChange: 0.93,
        netIRR: 4.42,
        date: '6/19/2022',
        status: 'sold',
        paymentType: 'debit card',
        actions: 'mark sold'
    },
    {
        id: '408d83f3-a80c-4a5a-afe1-8f6730cfdda6',
        product: 'Euphorbia schizoloba Engelm.',
        vendor: 'bstenm',
        customer: 'ddillinger11',
        shares: 69,
        costPerShare: 47.73,
        totalCost: 56.7,
        estNav: 5.37,
        impliedGrossChange: 0.09,
        netIRR: 7.66,
        date: '1/30/2022',
        status: 'pending',
        paymentType: 'credit card',
        actions: 'approve'
    },
    {
        id: '107b7a6d-d6e4-41a3-b19c-4a03af199b6b',
        product: 'Vicia ervilia (L.) Willd. [excluded]',
        vendor: 'bstenm',
        customer: 'dcroasdale12',
        shares: 41,
        costPerShare: 14.82,
        totalCost: 75.5,
        estNav: 8.32,
        impliedGrossChange: 0.8,
        netIRR: 1.6,
        date: '6/15/2022',
        status: 'sold',
        paymentType: 'credit card',
        actions: 'approve'
    },
    {
        id: 'f8f34705-b9e5-48d3-a457-69e83b719179',
        product: 'Malvastrum A. Gray',
        vendor: 'bstenm',
        customer: 'acater13',
        shares: 40,
        costPerShare: 44.44,
        totalCost: 99.55,
        estNav: 9.48,
        impliedGrossChange: 0.78,
        netIRR: 0.68,
        date: '12/14/2021',
        status: 'pending',
        paymentType: 'bank transfer',
        actions: 'item info'
    },
    {
        id: '4d02b971-dac1-4f4d-a59b-6f54640ee0d6',
        product: 'Lesquerella obdeltata Rollins',
        vendor: 'bstenm',
        customer: 'kcarvilla14',
        shares: 22,
        costPerShare: 43.01,
        totalCost: 68.09,
        estNav: 2.78,
        impliedGrossChange: 0.02,
        netIRR: 1.84,
        date: '5/11/2022',
        status: 'sold',
        paymentType: 'credit card',
        actions: 'mark sold'
    },
    {
        id: 'cbf4e346-9f0b-44ff-967e-130d7ee7eb37',
        product: 'Bromus texensis (Shear) Hitchc.',
        vendor: 'bstenm',
        customer: 'flinham15',
        shares: 57,
        costPerShare: 44.56,
        totalCost: 52.59,
        estNav: 0.71,
        impliedGrossChange: 0.36,
        netIRR: 5.5,
        date: '5/22/2022',
        status: 'sold',
        paymentType: 'bank transfer',
        actions: 'item info'
    },
    {
        id: '788cecd2-427b-4772-a6d6-bca89a9ed638',
        product: 'Lycopodium ×issleri (Rouy) Domin',
        vendor: 'bstenm',
        customer: 'fblackey16',
        shares: 31,
        costPerShare: 10.48,
        totalCost: 82.18,
        estNav: 8.68,
        impliedGrossChange: 0.05,
        netIRR: 5.1,
        date: '9/9/2021',
        status: 'listed',
        paymentType: 'bank transfer',
        actions: 'item info'
    },
    {
        id: 'c52cae21-e895-46e8-a7a2-6c1ab5fdcafd',
        product: 'Trautvetteria caroliniensis (Walter) Vail',
        vendor: 'bstenm',
        customer: 'hpottage17',
        shares: 42,
        costPerShare: 19.73,
        totalCost: 55.96,
        estNav: 9.98,
        impliedGrossChange: 0.79,
        netIRR: 2.7,
        date: '11/23/2021',
        status: 'sold',
        paymentType: 'debit card',
        actions: 'mark sold'
    },
    {
        id: 'c9ac43ac-555a-47a2-869f-31facf4b8c49',
        product: 'Pityrogramma Link',
        vendor: 'bstenm',
        customer: 'gbartalucci18',
        shares: 100,
        costPerShare: 48.5,
        totalCost: 87.34,
        estNav: 2.03,
        impliedGrossChange: 0.6,
        netIRR: 9.56,
        date: '1/28/2022',
        status: 'sold',
        paymentType: 'debit card',
        actions: 'mark sold'
    },
    {
        id: 'f26178a0-a56e-476d-9546-b70480f61001',
        product: 'Pohlia vexans (Limpr.) H. Lindb.',
        vendor: 'bstenm',
        customer: 'ksherland19',
        shares: 81,
        costPerShare: 45.23,
        totalCost: 93.18,
        estNav: 0.66,
        impliedGrossChange: 0.92,
        netIRR: 5.9,
        date: '2/19/2022',
        status: 'sold',
        paymentType: 'debit card',
        actions: 'approve'
    },
    {
        id: 'bcecbb76-6c85-4b67-8683-6e0cc8823c5e',
        product: 'Guizotia abyssinica (L. f.) Cass.',
        vendor: 'bstenm',
        customer: 'kwithers1a',
        shares: 55,
        costPerShare: 49.92,
        totalCost: 81.0,
        estNav: 5.44,
        impliedGrossChange: 0.69,
        netIRR: 4.47,
        date: '1/26/2022',
        status: 'sold',
        paymentType: 'debit card',
        actions: 'item info'
    },
    {
        id: '38a02e53-8730-4cc1-9e26-f9438369f2db',
        product: 'Porpidia contrapoenda (Arnold) Knoph & Hertel',
        vendor: 'bstenm',
        customer: 'aforshaw1b',
        shares: 78,
        costPerShare: 13.86,
        totalCost: 94.3,
        estNav: 3.88,
        impliedGrossChange: 0.34,
        netIRR: 0.58,
        date: '4/15/2022',
        status: 'listed',
        paymentType: 'debit card',
        actions: 'mark sold'
    },
    {
        id: 'd550b411-1149-43cc-89c5-5b75d11c8fbe',
        product: 'Theobroma bicolor Humb. & Bonpl.',
        vendor: 'bstenm',
        customer: 'mmcinility1c',
        shares: 71,
        costPerShare: 41.91,
        totalCost: 73.93,
        estNav: 8.38,
        impliedGrossChange: 0.38,
        netIRR: 7.06,
        date: '10/24/2021',
        status: 'listed',
        paymentType: 'debit card',
        actions: 'mark sold'
    },
    {
        id: '210d47a8-6895-451a-bb3c-658b1e571ae3',
        product: 'Melanomma oxysporum (Zahlbr.) D. Hawksw.',
        vendor: 'bstenm',
        customer: 'hburchard1d',
        shares: 32,
        costPerShare: 25.6,
        totalCost: 53.66,
        estNav: 1.7,
        impliedGrossChange: 0.61,
        netIRR: 1.37,
        date: '7/27/2021',
        status: 'sold',
        paymentType: 'bank transfer',
        actions: 'approve'
    },
    {
        id: '2a242b0e-dfe1-434b-a578-c1bef062cde1',
        product: 'Erigeron angustatus Greene',
        vendor: 'bstenm',
        customer: 'greckhouse1e',
        shares: 18,
        costPerShare: 35.96,
        totalCost: 80.07,
        estNav: 8.68,
        impliedGrossChange: 0.69,
        netIRR: 8.97,
        date: '1/7/2022',
        status: 'pending',
        paymentType: 'credit card',
        actions: 'item info'
    },
    {
        id: '72e37b7a-24fa-4ef5-a254-1622857fb771',
        product:
            'Symphyotrichum praealtum (Poir.) G.L. Nesom var. angustior (Wiegand) G.L. Nesom',
        vendor: 'bstenm',
        customer: 'ctregunna1f',
        shares: 68,
        costPerShare: 33.73,
        totalCost: 57.59,
        estNav: 6.07,
        impliedGrossChange: 0.69,
        netIRR: 9.1,
        date: '8/3/2021',
        status: 'sold',
        paymentType: 'bank transfer',
        actions: 'mark sold'
    },
    {
        id: 'ada7292d-8cb5-4f03-b013-ee9ad9e7dfab',
        product: 'Trifolium stoloniferum Muhl. ex Eaton',
        vendor: 'bstenm',
        customer: 'cnugent1g',
        shares: 40,
        costPerShare: 49.51,
        totalCost: 79.67,
        estNav: 5.57,
        impliedGrossChange: 0.6,
        netIRR: 0.23,
        date: '10/21/2021',
        status: 'pending',
        paymentType: 'bank transfer',
        actions: 'approve'
    },
    {
        id: 'e75c1d6a-5e6a-47f0-8539-5834530394e4',
        product: 'Gomidesia Berg',
        vendor: 'bstenm',
        customer: 'hwren1h',
        shares: 35,
        costPerShare: 18.57,
        totalCost: 58.64,
        estNav: 0.26,
        impliedGrossChange: 0.66,
        netIRR: 8.23,
        date: '1/12/2022',
        status: 'sold',
        paymentType: 'credit card',
        actions: 'item info'
    },
    {
        id: '7265f13d-c236-4fba-a7ad-1f9c56c55bfb',
        product: 'Thelypteris maemonensis (W.H. Wagner & Grether) Stone',
        vendor: 'bstenm',
        customer: 'mlamputt1i',
        shares: 89,
        costPerShare: 37.32,
        totalCost: 89.81,
        estNav: 1.3,
        impliedGrossChange: 0.84,
        netIRR: 2.74,
        date: '4/16/2022',
        status: 'pending',
        paymentType: 'debit card',
        actions: 'item info'
    },
    {
        id: 'e21483a5-8281-4890-ab8c-bfc56533b7f1',
        product: 'Mycocalicium Vain.',
        vendor: 'bstenm',
        customer: 'ascoble1j',
        shares: 37,
        costPerShare: 35.39,
        totalCost: 69.37,
        estNav: 5.74,
        impliedGrossChange: 0.49,
        netIRR: 1.93,
        date: '4/14/2022',
        status: 'listed',
        paymentType: 'debit card',
        actions: 'mark sold'
    },
    {
        id: '3504b066-e4e4-418d-a8b6-d608b5095b11',
        product: 'Teramnus labialis (L. f.) Spreng.',
        vendor: 'bstenm',
        customer: 'rmaxwell1k',
        shares: 33,
        costPerShare: 48.33,
        totalCost: 70.63,
        estNav: 2.09,
        impliedGrossChange: 0.52,
        netIRR: 3.85,
        date: '10/20/2021',
        status: 'pending',
        paymentType: 'bank transfer',
        actions: 'mark sold'
    },
    {
        id: '70f5e605-f5c9-4e10-9dee-72cd63089fef',
        product: 'Muhlenbergia sobolifera (Muhl. ex Willd.) Trin.',
        vendor: 'bstenm',
        customer: 'ppechan1l',
        shares: 85,
        costPerShare: 20.94,
        totalCost: 69.25,
        estNav: 4.5,
        impliedGrossChange: 0.03,
        netIRR: 0.3,
        date: '12/23/2021',
        status: 'pending',
        paymentType: 'bank transfer',
        actions: 'approve'
    },
    {
        id: '294d7dcc-b2a4-4785-83d4-23cb78aae91e',
        product: 'Artemisia cana Pursh',
        vendor: 'bstenm',
        customer: 'aelham1m',
        shares: 91,
        costPerShare: 10.67,
        totalCost: 67.27,
        estNav: 9.77,
        impliedGrossChange: 0.36,
        netIRR: 6.17,
        date: '1/20/2022',
        status: 'sold',
        paymentType: 'debit card',
        actions: 'mark sold'
    },
    {
        id: '438a9128-fa56-4da0-8815-6ce947d68f1b',
        product: 'Physaria rollinsii G. Mulligan',
        vendor: 'bstenm',
        customer: 'lost1n',
        shares: 96,
        costPerShare: 19.58,
        totalCost: 71.93,
        estNav: 7.02,
        impliedGrossChange: 0.43,
        netIRR: 3.84,
        date: '8/26/2021',
        status: 'sold',
        paymentType: 'credit card',
        actions: 'approve'
    },
    {
        id: '95839a73-ab0d-47f9-884f-6f3925d07e5c',
        product:
            'Nemastylis tenuis (Herb.) Benth. ssp. pringlei (S. Watson) Goldblatt',
        vendor: 'bstenm',
        customer: 'zpegg1o',
        shares: 57,
        costPerShare: 37.68,
        totalCost: 75.98,
        estNav: 7.02,
        impliedGrossChange: 0.43,
        netIRR: 4.46,
        date: '5/26/2022',
        status: 'pending',
        paymentType: 'bank transfer',
        actions: 'item info'
    },
    {
        id: '08bd1f00-8e6f-4736-aad2-df53a99b3837',
        product: 'Clarkia mildrediae (A. Heller) F.H. Lewis & M.E. Lewis',
        vendor: 'bstenm',
        customer: 'ddiglin1p',
        shares: 53,
        costPerShare: 18.81,
        totalCost: 74.55,
        estNav: 1.72,
        impliedGrossChange: 0.19,
        netIRR: 8.84,
        date: '4/25/2022',
        status: 'sold',
        paymentType: 'credit card',
        actions: 'item info'
    },
    {
        id: '9a8a3a02-d58b-4d03-9acb-6f388fba2244',
        product: 'Schistophragma Benth. ex Endl.',
        vendor: 'bstenm',
        customer: 'nshovelin1q',
        shares: 14,
        costPerShare: 27.06,
        totalCost: 65.24,
        estNav: 5.83,
        impliedGrossChange: 0.59,
        netIRR: 2.74,
        date: '1/6/2022',
        status: 'pending',
        paymentType: 'debit card',
        actions: 'mark sold'
    },
    {
        id: '1923b4aa-4d0b-4a68-94dd-10aa1a954dd4',
        product: 'Cathestecum J. Presl',
        vendor: 'bstenm',
        customer: 'ckupec1r',
        shares: 32,
        costPerShare: 32.07,
        totalCost: 85.39,
        estNav: 7.21,
        impliedGrossChange: 0.69,
        netIRR: 8.13,
        date: '7/5/2022',
        status: 'sold',
        paymentType: 'debit card',
        actions: 'mark sold'
    },
    {
        id: '5188125c-c407-425b-bdf6-a38051fc9c4e',
        product: 'Mertensia umbratilis Greenm.',
        vendor: 'bstenm',
        customer: 'mraynton1s',
        shares: 25,
        costPerShare: 21.83,
        totalCost: 97.77,
        estNav: 6.21,
        impliedGrossChange: 0.57,
        netIRR: 6.5,
        date: '5/15/2022',
        status: 'pending',
        paymentType: 'debit card',
        actions: 'item info'
    },
    {
        id: '6d19de75-bb6d-4c9c-893d-7a59d19854a9',
        product: 'Geranium versicolor L.',
        vendor: 'bstenm',
        customer: 'cpavett1t',
        shares: 16,
        costPerShare: 13.74,
        totalCost: 78.66,
        estNav: 8.35,
        impliedGrossChange: 0.73,
        netIRR: 1.87,
        date: '4/26/2022',
        status: 'listed',
        paymentType: 'bank transfer',
        actions: 'item info'
    },
    {
        id: '75351b22-aa1a-45b9-8658-b4bff0a59211',
        product: 'Bromus ciliatus L. var. ciliatus',
        vendor: 'bstenm',
        customer: 'lmcphelim1u',
        shares: 84,
        costPerShare: 18.15,
        totalCost: 71.86,
        estNav: 3.82,
        impliedGrossChange: 0.45,
        netIRR: 2.95,
        date: '10/23/2021',
        status: 'pending',
        paymentType: 'bank transfer',
        actions: 'approve'
    },
    {
        id: '9964b982-6736-428d-8c24-d2c0c6617302',
        product: 'Clematis campaniflora Brot.',
        vendor: 'bstenm',
        customer: 'lbuckwell1v',
        shares: 80,
        costPerShare: 49.12,
        totalCost: 52.97,
        estNav: 1.39,
        impliedGrossChange: 0.25,
        netIRR: 5.65,
        date: '3/25/2022',
        status: 'pending',
        paymentType: 'debit card',
        actions: 'approve'
    },
    {
        id: '7407de57-d783-4196-99b9-55bc7bd802c0',
        product: 'Elymus elymoides (Raf.) Swezey',
        vendor: 'bstenm',
        customer: 'pkahn1w',
        shares: 12,
        costPerShare: 20.14,
        totalCost: 73.22,
        estNav: 3.06,
        impliedGrossChange: 0.4,
        netIRR: 7.96,
        date: '11/30/2021',
        status: 'pending',
        paymentType: 'debit card',
        actions: 'item info'
    },
    {
        id: '0ab8d46e-9f86-4976-a244-b064c3007827',
        product: 'Argemone mexicana L.',
        vendor: 'bstenm',
        customer: 'sbaunton1x',
        shares: 78,
        costPerShare: 40.22,
        totalCost: 80.21,
        estNav: 8.36,
        impliedGrossChange: 0.22,
        netIRR: 8.8,
        date: '3/1/2022',
        status: 'pending',
        paymentType: 'debit card',
        actions: 'mark sold'
    },
    {
        id: '3fd5d9e6-fc52-494a-b088-99a1ff798a19',
        product: 'Alchemilla subcrenata Buser',
        vendor: 'bstenm',
        customer: 'bsignoret1y',
        shares: 90,
        costPerShare: 18.77,
        totalCost: 74.83,
        estNav: 1.8,
        impliedGrossChange: 0.02,
        netIRR: 2.96,
        date: '9/25/2021',
        status: 'sold',
        paymentType: 'debit card',
        actions: 'approve'
    },
    {
        id: '453a39d6-c76b-49e3-a876-d838a2f34909',
        product: 'Rhynchospora pallida M.A. Curtis',
        vendor: 'bstenm',
        customer: 'rnequest1z',
        shares: 25,
        costPerShare: 32.31,
        totalCost: 58.02,
        estNav: 7.54,
        impliedGrossChange: 0.22,
        netIRR: 5.92,
        date: '4/14/2022',
        status: 'pending',
        paymentType: 'bank transfer',
        actions: 'item info'
    },
    {
        id: 'c8bb310c-2834-4fd6-aaba-0a6ad27f7074',
        product: 'Eriogonum umbellatum Torr. var. devestivum Reveal',
        vendor: 'bstenm',
        customer: 'oswannack20',
        shares: 38,
        costPerShare: 38.08,
        totalCost: 60.13,
        estNav: 0.06,
        impliedGrossChange: 0.55,
        netIRR: 7.23,
        date: '8/2/2021',
        status: 'listed',
        paymentType: 'credit card',
        actions: 'mark sold'
    },
    {
        id: 'ca4d1cb4-aa4a-48ca-bfa9-e51a5248470a',
        product: 'Tripleurospermum perforatum (Mérat) M. Lainz',
        vendor: 'bstenm',
        customer: 'iaspey21',
        shares: 17,
        costPerShare: 34.29,
        totalCost: 84.21,
        estNav: 6.7,
        impliedGrossChange: 0.61,
        netIRR: 1.42,
        date: '10/17/2021',
        status: 'pending',
        paymentType: 'debit card',
        actions: 'item info'
    },
    {
        id: 'c7cb5935-5f1d-47b7-9edf-da0ba3977d28',
        product: 'Phacelia ivesiana Torr. var. pediculoides J.T. Howell',
        vendor: 'bstenm',
        customer: 'jhabbin22',
        shares: 19,
        costPerShare: 12.95,
        totalCost: 77.57,
        estNav: 8.22,
        impliedGrossChange: 0.2,
        netIRR: 8.93,
        date: '7/31/2021',
        status: 'listed',
        paymentType: 'debit card',
        actions: 'approve'
    },
    {
        id: '315d1579-b1ef-4f96-bff0-5e62646bfb99',
        product:
            'Cypripedium parviflorum Salisb. var. pubescens (Willd.) Knight',
        vendor: 'bstenm',
        customer: 'nwegenen23',
        shares: 48,
        costPerShare: 37.0,
        totalCost: 93.73,
        estNav: 3.46,
        impliedGrossChange: 0.76,
        netIRR: 1.62,
        date: '2/19/2022',
        status: 'sold',
        paymentType: 'bank transfer',
        actions: 'approve'
    },
    {
        id: 'a99e9d30-bfcc-4bf4-9ca8-d4af1aede318',
        product: 'Cleyera albopunctata (Griseb.) Krug & Urb.',
        vendor: 'bstenm',
        customer: 'fcleaver24',
        shares: 88,
        costPerShare: 37.05,
        totalCost: 52.41,
        estNav: 1.41,
        impliedGrossChange: 0.03,
        netIRR: 5.47,
        date: '6/12/2022',
        status: 'pending',
        paymentType: 'debit card',
        actions: 'mark sold'
    },
    {
        id: '72051794-93c3-45ef-a0fa-4497eca8ea03',
        product: 'Braunia secunda (Hook.) Schimp.',
        vendor: 'bstenm',
        customer: 'hhuws25',
        shares: 86,
        costPerShare: 27.71,
        totalCost: 78.6,
        estNav: 0.04,
        impliedGrossChange: 0.54,
        netIRR: 6.37,
        date: '12/8/2021',
        status: 'sold',
        paymentType: 'bank transfer',
        actions: 'mark sold'
    },
    {
        id: '817a7b47-7dc4-4568-b2c9-41490afedef0',
        product: 'Rayjacksonia phyllocephala (DC.) R.L. Hartm. & M.A. Lane',
        vendor: 'bstenm',
        customer: 'jvittet26',
        shares: 17,
        costPerShare: 49.94,
        totalCost: 96.18,
        estNav: 0.1,
        impliedGrossChange: 0.83,
        netIRR: 6.57,
        date: '10/22/2021',
        status: 'pending',
        paymentType: 'bank transfer',
        actions: 'item info'
    },
    {
        id: '955bcea6-4db4-45d7-8d42-4ea14082e529',
        product: 'Woodsia oregana D.C. Eaton ssp. oregana',
        vendor: 'bstenm',
        customer: 'aswainston27',
        shares: 27,
        costPerShare: 13.06,
        totalCost: 94.41,
        estNav: 6.0,
        impliedGrossChange: 0.44,
        netIRR: 4.15,
        date: '4/4/2022',
        status: 'sold',
        paymentType: 'bank transfer',
        actions: 'item info'
    },
    {
        id: 'bdfaf297-401f-4022-bb55-8eb90078aa9c',
        product: 'Nerisyrenia linearifolia (S. Watson) Greene',
        vendor: 'bstenm',
        customer: 'vbaldin28',
        shares: 60,
        costPerShare: 24.94,
        totalCost: 75.73,
        estNav: 1.13,
        impliedGrossChange: 0.93,
        netIRR: 9.22,
        date: '3/17/2022',
        status: 'pending',
        paymentType: 'bank transfer',
        actions: 'approve'
    },
    {
        id: '3a987c1d-e642-4366-86d4-8105fecd1189',
        product: 'Launaea Cass.',
        vendor: 'bstenm',
        customer: 'sdimitrov29',
        shares: 24,
        costPerShare: 28.98,
        totalCost: 62.9,
        estNav: 0.36,
        impliedGrossChange: 0.06,
        netIRR: 8.95,
        date: '11/28/2021',
        status: 'pending',
        paymentType: 'bank transfer',
        actions: 'approve'
    },
    {
        id: '1681202c-9662-4cef-a4ee-03f6b93fa50d',
        product: 'Urtica gracilenta Greene',
        vendor: 'bstenm',
        customer: 'agabbat2a',
        shares: 71,
        costPerShare: 21.68,
        totalCost: 89.36,
        estNav: 7.38,
        impliedGrossChange: 0.85,
        netIRR: 5.12,
        date: '5/22/2022',
        status: 'sold',
        paymentType: 'debit card',
        actions: 'approve'
    },
    {
        id: '7ee21b15-931a-4f8e-b935-42bb6da19c8c',
        product: 'Astragalus pattersonii A. Gray ex Brandegee',
        vendor: 'bstenm',
        customer: 'rhumphrys2b',
        shares: 99,
        costPerShare: 11.48,
        totalCost: 51.73,
        estNav: 2.43,
        impliedGrossChange: 0.92,
        netIRR: 7.53,
        date: '7/6/2022',
        status: 'listed',
        paymentType: 'debit card',
        actions: 'item info'
    },
    {
        id: '1c522084-1487-4c38-8cad-c094c6938a2e',
        product: 'Trillium maculatum Raf.',
        vendor: 'bstenm',
        customer: 'gdunkerk2c',
        shares: 74,
        costPerShare: 49.4,
        totalCost: 94.57,
        estNav: 4.68,
        impliedGrossChange: 0.63,
        netIRR: 3.2,
        date: '8/11/2021',
        status: 'sold',
        paymentType: 'debit card',
        actions: 'item info'
    },
    {
        id: 'c4e95ed1-ce01-4c87-af94-0dd023176c24',
        product: 'Packera glabella (Poir.) C. Jeffrey',
        vendor: 'bstenm',
        customer: 'ntong2d',
        shares: 84,
        costPerShare: 28.27,
        totalCost: 86.31,
        estNav: 6.92,
        impliedGrossChange: 0.46,
        netIRR: 9.92,
        date: '5/22/2022',
        status: 'listed',
        paymentType: 'debit card',
        actions: 'mark sold'
    },
    {
        id: '757fc8d2-2a29-4091-8e7d-dbbc744542ca',
        product: 'Cynodon magennisii Hurcombe (pro hybr.)',
        vendor: 'bstenm',
        customer: 'tgenese2e',
        shares: 68,
        costPerShare: 11.2,
        totalCost: 81.56,
        estNav: 2.91,
        impliedGrossChange: 0.66,
        netIRR: 0.13,
        date: '6/14/2022',
        status: 'sold',
        paymentType: 'bank transfer',
        actions: 'item info'
    },
    {
        id: 'a5fdba3a-01b2-4034-bd1d-f2c893f8dfeb',
        product: 'Phlox subulata L. ssp. brittonii (Small) Wherry',
        vendor: 'bstenm',
        customer: 'atessington2f',
        shares: 78,
        costPerShare: 42.45,
        totalCost: 50.12,
        estNav: 7.56,
        impliedGrossChange: 0.86,
        netIRR: 5.91,
        date: '12/24/2021',
        status: 'sold',
        paymentType: 'bank transfer',
        actions: 'item info'
    },
    {
        id: '7dccea0f-73a2-41ac-9e87-520717e45892',
        product: 'Trifolium gracilentum Torr. & A. Gray var. gracilentum',
        vendor: 'bstenm',
        customer: 'ncharlick2g',
        shares: 97,
        costPerShare: 44.62,
        totalCost: 84.59,
        estNav: 3.65,
        impliedGrossChange: 0.5,
        netIRR: 0.54,
        date: '12/5/2021',
        status: 'pending',
        paymentType: 'bank transfer',
        actions: 'approve'
    },
    {
        id: 'e4ee7030-caad-4c02-9558-52d3dfbd13d2',
        product: 'Antennaria soliceps S.F. Blake',
        vendor: 'bstenm',
        customer: 'rmcturk2h',
        shares: 35,
        costPerShare: 12.2,
        totalCost: 98.9,
        estNav: 8.15,
        impliedGrossChange: 0.55,
        netIRR: 6.2,
        date: '6/9/2022',
        status: 'pending',
        paymentType: 'bank transfer',
        actions: 'item info'
    },
    {
        id: '5913f6db-3728-480d-80d0-46879d39e425',
        product: 'Entelea R. Br.',
        vendor: 'bstenm',
        customer: 'lmillson2i',
        shares: 100,
        costPerShare: 33.23,
        totalCost: 78.58,
        estNav: 1.81,
        impliedGrossChange: 0.05,
        netIRR: 6.28,
        date: '10/19/2021',
        status: 'sold',
        paymentType: 'debit card',
        actions: 'approve'
    },
    {
        id: 'd2d57975-a02e-41b9-9142-7db26377d752',
        product: 'Phaeorrhiza nimbosa (Fr.) H. Mayrh. & Poelt',
        vendor: 'bstenm',
        customer: 'fjaeggi2j',
        shares: 42,
        costPerShare: 11.3,
        totalCost: 75.15,
        estNav: 3.15,
        impliedGrossChange: 0.12,
        netIRR: 3.62,
        date: '8/4/2021',
        status: 'pending',
        paymentType: 'bank transfer',
        actions: 'mark sold'
    },
    {
        id: 'c6375089-6927-4f0d-8dfb-b315f7509cdd',
        product: 'Hieracium triste Willd. ex Spreng.',
        vendor: 'bstenm',
        customer: 'ypenreth2k',
        shares: 24,
        costPerShare: 32.69,
        totalCost: 75.42,
        estNav: 3.78,
        impliedGrossChange: 0.56,
        netIRR: 7.59,
        date: '8/16/2021',
        status: 'pending',
        paymentType: 'debit card',
        actions: 'approve'
    },
    {
        id: '0ec346ae-081c-4146-9b81-ebe84033d2f4',
        product: 'Spartina pectinata Bosc ex Link',
        vendor: 'bstenm',
        customer: 'csurgood2l',
        shares: 33,
        costPerShare: 16.38,
        totalCost: 56.42,
        estNav: 2.29,
        impliedGrossChange: 0.45,
        netIRR: 8.36,
        date: '1/11/2022',
        status: 'listed',
        paymentType: 'bank transfer',
        actions: 'approve'
    },
    {
        id: '5373079c-f648-4618-ac02-936753b8d0c1',
        product: 'Ditrichum pusillum (Hedw.) Hampe',
        vendor: 'bstenm',
        customer: 'omcgraith2m',
        shares: 37,
        costPerShare: 42.68,
        totalCost: 77.97,
        estNav: 5.61,
        impliedGrossChange: 0.91,
        netIRR: 7.74,
        date: '6/10/2022',
        status: 'sold',
        paymentType: 'bank transfer',
        actions: 'approve'
    },
    {
        id: '809b35ef-ee34-4f63-a6a6-666548b551a3',
        product: 'Stellaria ruscifolia Pall. ex Schltdl.',
        vendor: 'bstenm',
        customer: 'hheintz2n',
        shares: 84,
        costPerShare: 28.19,
        totalCost: 82.09,
        estNav: 4.42,
        impliedGrossChange: 0.08,
        netIRR: 4.36,
        date: '6/19/2022',
        status: 'sold',
        paymentType: 'bank transfer',
        actions: 'mark sold'
    },
    {
        id: 'c7b8847f-adee-40ea-b0f5-078f8dd3b5bb',
        product: 'Wahlenbergia Schrad. ex Roth',
        vendor: 'bstenm',
        customer: 'dtunbridge2o',
        shares: 41,
        costPerShare: 21.33,
        totalCost: 61.67,
        estNav: 7.58,
        impliedGrossChange: 0.85,
        netIRR: 6.55,
        date: '5/16/2022',
        status: 'pending',
        paymentType: 'credit card',
        actions: 'item info'
    },
    {
        id: 'dda1b5b9-c0be-40ea-93b9-a3bc5b1cfea9',
        product: 'Chaenorhinum minus (L.) Lange',
        vendor: 'bstenm',
        customer: 'kbolles2p',
        shares: 28,
        costPerShare: 10.66,
        totalCost: 91.5,
        estNav: 5.26,
        impliedGrossChange: 0.99,
        netIRR: 8.2,
        date: '10/3/2021',
        status: 'sold',
        paymentType: 'debit card',
        actions: 'mark sold'
    },
    {
        id: '8de1a431-fac7-4d94-a1c2-72c4740d9ffe',
        product: 'Tephromela aglaea (Sommerf.) Hertel & Rambold',
        vendor: 'bstenm',
        customer: 'dscutter2q',
        shares: 74,
        costPerShare: 16.6,
        totalCost: 76.25,
        estNav: 9.18,
        impliedGrossChange: 0.4,
        netIRR: 7.81,
        date: '12/28/2021',
        status: 'pending',
        paymentType: 'credit card',
        actions: 'item info'
    },
    {
        id: 'b5fef2db-6bc2-471d-8ad4-9be9f89c053b',
        product: 'Psoroma hypnorum (Vahl) A. Gray',
        vendor: 'bstenm',
        customer: 'tsweetmore2r',
        shares: 28,
        costPerShare: 30.93,
        totalCost: 70.32,
        estNav: 6.43,
        impliedGrossChange: 0.68,
        netIRR: 7.55,
        date: '9/12/2021',
        status: 'sold',
        paymentType: 'debit card',
        actions: 'approve'
    }
];
