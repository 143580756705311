/* eslint-disable */

import { PortfolioHistoricalActivityData } from "config/types/types";

export const portfolioHistoricalActivityData: PortfolioHistoricalActivityData[] =[{"id":"e1a42f93-a067-4acc-931c-f832e2197033","product":"Dodecatheon pulchellum (Raf.) Merr. ssp. monanthum (Greene ex R. Knuth) H.J. Thomp. ex Munz","quantity":93,"transactionDate":"8/16/2021","price":4642},
{"id":"08a9734b-65c8-4afb-addf-9eba9b72e1b9","product":"Asterothyrium rotuliforme (Müll. Arg.) Serus.","quantity":71,"transactionDate":"5/21/2022","price":4604},
{"id":"35f68ae8-31f8-4037-9d94-bed8f06baab2","product":"Ichnanthus pallens (Sw.) Munro ex Benth. var. pallens","quantity":8,"transactionDate":"2/8/2022","price":3315},
{"id":"e6e81988-d678-4654-931a-cc504e38cab2","product":"Juniperus osteosperma (Torr.) Little","quantity":1,"transactionDate":"11/18/2021","price":3143},
{"id":"ca548a01-92db-4563-86bf-15c15d5a03ed","product":"Heterotheca viscida (A. Gray) V.L. Harms","quantity":97,"transactionDate":"8/25/2021","price":2958},
{"id":"3f519fb0-d7ba-4dbb-9bc0-672dab09fa10","product":"Platanus ×hispanica Mill. ex Münchh.","quantity":22,"transactionDate":"9/26/2021","price":4056},
{"id":"62a28175-7826-42ff-a276-0f5d71189f50","product":"Salvia leucantha Cav.","quantity":91,"transactionDate":"2/22/2022","price":4646},
{"id":"b67dd0ad-8ca0-4716-bdce-b36ccd9b96d0","product":"Ximenia L.","quantity":66,"transactionDate":"2/21/2022","price":796},
{"id":"b870a261-2ba2-4bd9-ba00-13a659981ad5","product":"Astragalus subcinereus A. Gray","quantity":50,"transactionDate":"1/15/2022","price":3353},
{"id":"ca8cc364-373d-40a3-94c6-992a16b1fd99","product":"Banksia ashbyi Baker f.","quantity":17,"transactionDate":"4/6/2022","price":438},
{"id":"710ebfee-b6f9-4667-8848-eb5f9f354f31","product":"Scoliciosporum umbrinum (Ach.) Arnold var. compacta (Körb.) Vezda","quantity":16,"transactionDate":"11/20/2021","price":3369},
{"id":"6f299dae-133e-4841-bedd-d01c0f984f0c","product":"Astragalus lentiginosus Douglas ex Hook. var. scorpionis M.E. Jones","quantity":65,"transactionDate":"9/28/2021","price":612},
{"id":"21db20c8-c994-4e4c-aafd-dee319a6b4ab","product":"Minuartia nuttallii (Pax) Briq.","quantity":81,"transactionDate":"12/5/2021","price":2189},
{"id":"8421ef19-bd70-4057-8d6c-f950ed6d82a6","product":"Peltula tortuosa (Nees) Wetmore","quantity":67,"transactionDate":"12/17/2021","price":3375},
{"id":"78b01022-0e76-4d5a-9606-34c5ec745b20","product":"Desmodium incanum DC.","quantity":100,"transactionDate":"5/17/2022","price":2205},
{"id":"a7b56482-0982-4985-bf86-1d363d33b548","product":"Dodecatheon hendersonii A. Gray ssp. hendersonii","quantity":96,"transactionDate":"10/2/2021","price":717},
{"id":"54b7ff89-a489-4015-83c9-4dd98b8fc0a0","product":"Notothylas Sull.","quantity":35,"transactionDate":"8/25/2021","price":4549},
{"id":"7e03532f-e3b3-47c6-91f5-787290458216","product":"Halimolobos perplexa (L.F. Hend.) Rollins var. lemhiensis C.L. Hitchc.","quantity":19,"transactionDate":"1/28/2022","price":1755},
{"id":"ad9bc7fc-de7a-49b4-b1e7-210eb78e66d4","product":"Brigantiaea Trevis.","quantity":41,"transactionDate":"7/27/2021","price":3859},
{"id":"9989c19d-d3d6-4152-80ad-205a4f9c7e28","product":"Justicia pacifica (Oerst.) Hemsl.","quantity":12,"transactionDate":"1/11/2022","price":3849},
{"id":"f2daeb2a-4d95-446e-8148-08eb5f4c2223","product":"Carex crinita Lam. var. porteri (Olney) Fernald","quantity":75,"transactionDate":"3/1/2022","price":3212},
{"id":"591bbca5-d169-469e-8fe1-44e911d7101c","product":"Psorothamnus fremontii (Torr. ex A. Gray) Barneby","quantity":71,"transactionDate":"12/8/2021","price":1612},
{"id":"04e3e648-2b38-41cf-a197-4c078a4dad64","product":"Fraxinus pennsylvanica Marshall","quantity":92,"transactionDate":"11/12/2021","price":1734},
{"id":"a7e33817-703a-4e4d-9cf8-2d4dcd8d6dcf","product":"Ludwigia peploides (Kunth) P.H. Raven","quantity":25,"transactionDate":"11/27/2021","price":1250},
{"id":"bce047a3-eb14-4880-961d-381d76f90f55","product":"Legousia Durand","quantity":59,"transactionDate":"2/16/2022","price":495},
{"id":"2ccebddb-8647-4d7c-a10a-bc200fbd345e","product":"Plantago virginica L.","quantity":59,"transactionDate":"5/5/2022","price":2392},
{"id":"60389933-2b5f-4d45-9849-0f79291f5b9a","product":"Pinus ponderosa Lawson & C. Lawson var. ponderosa C. Lawson","quantity":73,"transactionDate":"2/8/2022","price":1622},
{"id":"30bb5605-8cf1-45c8-8474-f096022b888b","product":"Koenigia islandica L.","quantity":79,"transactionDate":"11/12/2021","price":3702},
{"id":"2ae11a5a-b019-45fb-bda3-f1472d963efb","product":"Quercus palustris Münchh.","quantity":94,"transactionDate":"10/13/2021","price":4526},
{"id":"6d38f1af-2c46-4c43-8aaa-e545af16e291","product":"Astragalus clarianus Jeps.","quantity":36,"transactionDate":"1/19/2022","price":4733},
{"id":"20fa5690-554b-435f-86b8-2e0f88cd0e8d","product":"Thalictrum occidentale A. Gray var. occidentale","quantity":76,"transactionDate":"10/14/2021","price":247},
{"id":"d059d0f3-0084-449a-843a-d53420344ea4","product":"Fritillaria liliacea Lindl.","quantity":59,"transactionDate":"9/26/2021","price":975},
{"id":"9edeca60-d6af-430f-b0c8-0733b60b7938","product":"Rosa stellata Wooton ssp. stellata","quantity":66,"transactionDate":"8/5/2021","price":814},
{"id":"bdf01458-9d26-46bf-8a71-c751f368d810","product":"Hymenophyllum brevifrons Kunze","quantity":72,"transactionDate":"1/16/2022","price":1249},
{"id":"ad417d22-0b6a-4ef7-9a12-dc54ac22892c","product":"Piper dilatatum Rich.","quantity":60,"transactionDate":"2/1/2022","price":1600},
{"id":"f44ebf22-c57b-4204-8c1e-6c727ca0bfb3","product":"Melastoma sanguineum Sims","quantity":95,"transactionDate":"8/17/2021","price":3661},
{"id":"da6da3a1-9354-4710-a422-8bf121873430","product":"Lasiacis sorghoidea (Desv. ex Ham.) Hitchc. & Chase","quantity":12,"transactionDate":"5/27/2022","price":2417},
{"id":"7e5cc6e5-2e08-4b4b-905c-a464af6a634b","product":"Carex aquatilis Wahlenb.","quantity":28,"transactionDate":"1/27/2022","price":855},
{"id":"74eaaa85-d6b3-4822-9ad0-04d1303d8f05","product":"Calamagrostis montanensis Scribn. ex Vasey","quantity":87,"transactionDate":"6/21/2022","price":1771},
{"id":"5347455f-3ac1-4cda-9050-42250a115075","product":"Phleum L.","quantity":9,"transactionDate":"9/8/2021","price":981},
{"id":"e1a07d9d-33f5-415d-8ff8-992ef6bfaf40","product":"Glandularia hispida Ruiz & Pav.","quantity":98,"transactionDate":"10/12/2021","price":4546},
{"id":"0d019d5d-a07f-4bfc-97d1-f5c1fdb997e3","product":"Leucopogon parviflorus (Andrews) Lindl.","quantity":17,"transactionDate":"6/9/2022","price":4481},
{"id":"f322f47a-462e-4119-a8ae-2fd869d5b9fa","product":"Eriogonum heracleoides Nutt. var. angustifolium (Nutt.) Torr. & A. Gray","quantity":17,"transactionDate":"12/20/2021","price":2641},
{"id":"0d6897d1-e092-47ea-9c50-1263c6933843","product":"Clematis brachiata Thunb.","quantity":8,"transactionDate":"6/15/2022","price":4742},
{"id":"4beb1cc0-7808-4822-9e50-d67d30ffd6c9","product":"Limnanthes douglasii R. Br. ssp. sulphurea (C.T. Mason) C.T. Mason","quantity":51,"transactionDate":"2/21/2022","price":4599},
{"id":"f28c4cc0-ab1e-4b61-93fc-8bb28e95c60c","product":"Cyanea copelandii Rock","quantity":71,"transactionDate":"11/27/2021","price":3787},
{"id":"c277f647-75c4-4fa1-b760-e6efebabc50b","product":"Vanilla dilloniana Correll","quantity":37,"transactionDate":"2/22/2022","price":521},
{"id":"b764f748-2b0a-42a2-924f-4407d128ced1","product":"Stylosanthes fruticosa (Retz.) Alston","quantity":9,"transactionDate":"5/5/2022","price":4561},
{"id":"b02a00ad-43f0-4c17-9969-2b81ae65ef1e","product":"Condalia globosa I.M. Johnst. var. pubescens I.M. Johnst.","quantity":10,"transactionDate":"3/18/2022","price":3965},
{"id":"e8f221cb-b371-422f-9800-d7cf51786b3a","product":"Quercus cedrosensis C.H. Mull.","quantity":98,"transactionDate":"7/23/2021","price":4887},
{"id":"0e699e9f-20da-442f-933e-7b831916e540","product":"Dichelostemma ×venustum (Greene) Hoover (pro sp.)","quantity":86,"transactionDate":"12/25/2021","price":1112},
{"id":"ff61620a-9933-4e1a-bf21-d24d6cf776dd","product":"Lawsonia inermis L.","quantity":61,"transactionDate":"12/28/2021","price":2458},
{"id":"fd27352b-92b9-4960-85ef-146d6aeed515","product":"Philonotis uncinata (Schwägr.) Brid.","quantity":3,"transactionDate":"6/23/2022","price":2384},
{"id":"73df841c-4866-4cb8-b346-70b66f8ff396","product":"Actaea rubra (Aiton) Willd.","quantity":51,"transactionDate":"11/19/2021","price":4571},
{"id":"2cd23fac-9227-4f10-b1f7-429d25c92d46","product":"Dicranum scoparium Hedw.","quantity":79,"transactionDate":"1/21/2022","price":1965},
{"id":"5f0aaedc-eefd-4584-8ab2-fde6daf45400","product":"Lycium barbarum L.","quantity":97,"transactionDate":"10/12/2021","price":1395},
{"id":"0e3927d3-9d62-4a7a-a255-38e3011c2309","product":"Nolina bigelovii (Torr.) S. Watson","quantity":97,"transactionDate":"11/1/2021","price":3246},
{"id":"d5ae90aa-60ef-4c5e-a14e-c9b86b7e400f","product":"Osmanthus americanus (L.) Benth. & Hook. f. ex A. Gray var. americanus","quantity":79,"transactionDate":"5/8/2022","price":4294},
{"id":"5233cb93-9207-494c-ba47-31c61ffb1dc2","product":"Calophyllum L.","quantity":93,"transactionDate":"3/16/2022","price":1693},
{"id":"f46ee6a0-220c-4d86-930b-477ee92a642c","product":"Geranium retrorsum L'Hér. ex DC.","quantity":75,"transactionDate":"5/20/2022","price":1891},
{"id":"794eff1b-b6e0-47f9-b348-72e302f94aef","product":"Verbena ×goodmanii Moldenke","quantity":72,"transactionDate":"8/13/2021","price":1538},
{"id":"13af6fd0-24c0-46f2-9b8b-52c8500957ad","product":"Datura pruinosa Greenman","quantity":51,"transactionDate":"7/9/2022","price":2390},
{"id":"01a4b5c0-03f2-40cd-98ae-87087c9957c4","product":"Carex atrata L.","quantity":52,"transactionDate":"4/12/2022","price":1498},
{"id":"1ac48a33-de76-4ede-af53-b2ab68377c71","product":"Halesia Ellis ex L.","quantity":94,"transactionDate":"5/3/2022","price":2250},
{"id":"9e352882-0cdb-4147-847b-ab2de48c8b38","product":"Astragalus lyallii A. Gray","quantity":23,"transactionDate":"10/11/2021","price":1359},
{"id":"54e526fa-a1c5-45b9-ae31-071920d6ad51","product":"Argemone albiflora Hornem. ssp. albiflora","quantity":36,"transactionDate":"8/12/2021","price":4019},
{"id":"ade99645-4503-4734-9ba5-345e075d84dc","product":"Psychotria hexandra H. Mann ssp. hexandra","quantity":3,"transactionDate":"3/4/2022","price":4643},
{"id":"69b2d39e-128a-4433-b018-1b5ec72942f1","product":"Mirabilis texensis (J.M. Coult.) B.L. Turner","quantity":84,"transactionDate":"8/30/2021","price":535},
{"id":"0384a0f4-1ade-4731-ae77-b6eb4b664f33","product":"Polygonum striatulum B.L. Rob.","quantity":39,"transactionDate":"12/8/2021","price":1313},
{"id":"6bf589de-4152-4625-9810-76b683d13564","product":"Leymus Hochst.","quantity":25,"transactionDate":"6/29/2022","price":3981},
{"id":"fdb33079-815f-454f-a2b2-0329c865013e","product":"Fuirena simplex Vahl var. simplex","quantity":96,"transactionDate":"6/20/2022","price":356},
{"id":"78c5b06e-ad2e-4930-854d-0bfaba962bd6","product":"Racomitrium affine (Schleich. ex F. Weber & D. Mohr) Lindb.","quantity":60,"transactionDate":"4/22/2022","price":3325},
{"id":"db77dc1e-67d4-481a-9854-fa86f802d21f","product":"Vaccinium uliginosum L.","quantity":30,"transactionDate":"5/29/2022","price":1704},
{"id":"2e4b19e4-9c86-45d3-91d4-04142ffe84d9","product":"Atriplex rosea L.","quantity":8,"transactionDate":"12/21/2021","price":809},
{"id":"617680a6-8890-4181-983e-d2d98cdff3a0","product":"Ceanothus ×otayensis McMinn","quantity":46,"transactionDate":"4/23/2022","price":588},
{"id":"5d33544b-2ad9-4f96-8660-5c4a25b85bca","product":"Draba helleriana Greene var. blumeri C.L. Hitchc.","quantity":25,"transactionDate":"7/29/2021","price":2207},
{"id":"6d61629e-01ad-4db5-a83c-4f09dbb29590","product":"Galium L.","quantity":77,"transactionDate":"4/24/2022","price":775},
{"id":"9dfd75e5-7587-416a-9d1c-8f2017817eb1","product":"Lotus nevadensis (S. Watson) Greene","quantity":28,"transactionDate":"11/10/2021","price":983},
{"id":"58847596-0e89-400e-ba17-4c5e71b9093c","product":"Trichodon cylindricus (Hedw.) Schimp.","quantity":53,"transactionDate":"10/9/2021","price":3603},
{"id":"34977f5b-a821-4771-8284-402cbd39ba88","product":"Iris germanica L.","quantity":96,"transactionDate":"12/14/2021","price":1303},
{"id":"7d0efd46-923d-4228-8b18-8cf8ce873136","product":"Cochlearia sessilifolia Rollins","quantity":90,"transactionDate":"8/18/2021","price":4281},
{"id":"5c604ebf-6fb9-4f5c-bc04-d834b8901b67","product":"Sphagnum girgensohnii Russow","quantity":81,"transactionDate":"4/21/2022","price":2936},
{"id":"67d34566-6d32-442d-ae15-03cbc8d8e1dc","product":"Chenopodium fremontii S. Watson var. fremontii","quantity":51,"transactionDate":"5/29/2022","price":3388},
{"id":"485c8d1c-c046-4e47-b97d-4f020911d7af","product":"Eriogonum thompsoniae S. Watson var. thompsoniae","quantity":88,"transactionDate":"11/14/2021","price":961},
{"id":"9ed6b3f5-358c-4976-8bcf-b1e6419011f3","product":"Lobelia grayana E. Wimm.","quantity":17,"transactionDate":"4/9/2022","price":1726},
{"id":"6eb3d3f9-fac4-41ec-bd07-7802601225fc","product":"Festuca rubra L. ssp. arctica (Hack.) Govor.","quantity":66,"transactionDate":"3/19/2022","price":2815},
{"id":"9cf0a935-2dd7-4efa-a8f8-ba3317d7152e","product":"Cynanchum cubense (A. Rich.) Woodson","quantity":42,"transactionDate":"1/22/2022","price":3321},
{"id":"7246e4fa-6ce8-4212-b03f-74f78647d670","product":"Adolphia Meisn.","quantity":40,"transactionDate":"4/16/2022","price":4804},
{"id":"efaa12d9-9e35-4936-8ca5-9f858bb7d35a","product":"Melica stricta Bol.","quantity":72,"transactionDate":"5/23/2022","price":1838},
{"id":"fedfae3b-cbb3-4e6d-a499-2fff600c18ae","product":"Arabis codyi G. Mulligan","quantity":4,"transactionDate":"2/5/2022","price":1886},
{"id":"895b5d90-01fb-43dc-ad53-633afa8d0589","product":"Caloplaca microphyllina (Tuck.) Hasse","quantity":100,"transactionDate":"4/14/2022","price":2337},
{"id":"eab85dff-3376-4b28-aa96-e33b6d6f897c","product":"Poa douglasii Nees","quantity":55,"transactionDate":"11/29/2021","price":866},
{"id":"dc1a170c-040b-4c75-aca5-55c0eeee3cf0","product":"Hepatica nobilis Schreb.","quantity":19,"transactionDate":"3/7/2022","price":4856},
{"id":"3f6f34ce-7e33-4860-9499-273f97657c8b","product":"Prunus myrtifolia (L.) Urb.","quantity":68,"transactionDate":"1/23/2022","price":4348},
{"id":"86d884e9-cd06-4a82-9efa-5c1a489818cb","product":"Quercus ×sterilis Trel. ex Palmer","quantity":44,"transactionDate":"5/7/2022","price":730},
{"id":"650df05e-f27b-4fe9-964a-a8d8bf1a03a7","product":"Bulbothrix coronata (Fée) Hale","quantity":20,"transactionDate":"8/14/2021","price":603},
{"id":"9c0795db-912a-49aa-a930-300e489740eb","product":"Salix richardsonii Hook.","quantity":11,"transactionDate":"1/6/2022","price":3215},
{"id":"96e999a6-11da-4290-a822-0be50545c370","product":"Layia septentrionalis D.D. Keck","quantity":53,"transactionDate":"7/26/2021","price":4934},
{"id":"6bfb5e53-eb99-4654-acd8-5a5a38c01755","product":"Penstemon ambiguus Torr. var. ambiguus","quantity":73,"transactionDate":"8/26/2021","price":3018},
{"id":"4a86c324-b03b-4a28-a0e7-5df60e949352","product":"Zea L.","quantity":28,"transactionDate":"11/29/2021","price":3080}]