import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { useTorusLogout } from 'features/sidebar/useTorusLogout';
import Button from '@mui/material/Button';
import { ReactComponent as Pencil } from 'assets/pencil.svg';
import { ReactComponent as AccountSettings } from 'assets/accountSettings.svg';
import { ReactComponent as Privacy } from 'assets/privacyPolicyIcon.svg';
import { ReactComponent as TermsOfUse } from 'assets/termsOfUse.svg';
import { ReactComponent as ReportProblem } from 'assets/reportProblem.svg';
import { ReactComponent as HelpCenter } from 'assets/helpCenter.svg';
import { ReactComponent as LoadCustomers } from 'assets/loadCustomers.svg';
import { ReactComponent as MergeCustomer } from 'assets/mergeCustomers.svg';

import { getUserData } from 'state/user/selectors';
import { User } from 'config/types/user';
import { useAppSelector } from 'hooks/useAppSelector';

const GradientCard = styled('div')(({ theme }) => {
    return {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        textAlign: 'center',
        width: '100%',
        background: theme.palette.success.dark,
        boxShadow: '1px 0px 10px rgba(0, 0, 0, 0.42)',
        borderRadius: '5px',
        padding: '60px',
        [theme.breakpoints.down('md')]: {
            maxHeight: '100%',
            padding: '20px'
        }
    };
});

const Item = styled(Grid)(({ theme }) => ({
    maxWidth: '100%',
    padding: '20px 0px',
    [theme.breakpoints.down('md')]: {
        maxWidth: '100%'
    }
}));

const SettingsSectionTitle = styled('h1')(({ theme }) => {
    return {
        fontFamily: 'Jost',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '29px',
        lineHeight: '42px',
        textTransform: 'uppercase',
        textAlign: 'left',
        color: theme.palette.primary.contrastText
    };
});

const LinkItem = styled(Link)(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left'
}));

const LinkItemText = styled('span')(({ theme }) => {
    return {
        fontFamily: 'Jost',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '28px',
        lineHeight: '40px',
        color: theme.palette.primary.contrastText,
        marginLeft: '20px'
    };
});

const LogoutButton = styled(Button)(({ theme }) => {
    return {
        background: '#264257',
        borderRadius: '5px',
        width: '100%',
        height: '67px',
        color: 'white',
        fontFamily: 'Jost',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '23px',
        lineHeight: '33px'
    };
});

export const Settings = (): JSX.Element => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
    const [logout, loggingOut, loggedOut] = useTorusLogout();
    const { userType }: User = useAppSelector(getUserData);

    return (
        <Grid
            container
            sx={{
                padding: '25px',
                '@media (max-width: 290px)': {
                    padding: '0px'
                }
            }}>
            <Item item xs={12}>
                <GradientCard>
                    <Grid container>
                        <Item item xs={12} md={6}>
                            <SettingsSectionTitle>Account</SettingsSectionTitle>
                            <Grid container>
                                <Item item xs={12}>
                                    <LinkItem to="/settings">
                                        <Pencil />
                                        <LinkItemText>
                                            Edit Profile
                                        </LinkItemText>
                                    </LinkItem>
                                </Item>
                                <Item item xs={12}>
                                    <LinkItem to="/settings">
                                        <AccountSettings />
                                        <LinkItemText>
                                            Account Settings
                                        </LinkItemText>
                                    </LinkItem>
                                </Item>
                                <Item item xs={12}>
                                    <LinkItem to="/settings">
                                        <Privacy />
                                        <LinkItemText>Privacy</LinkItemText>
                                    </LinkItem>
                                </Item>
                                <Item item xs={12}>
                                    <LinkItem to="/settings">
                                        <TermsOfUse />
                                        <LinkItemText>
                                            Terms Of Use
                                        </LinkItemText>
                                    </LinkItem>
                                </Item>
                            </Grid>
                        </Item>
                        <Item item xs={12} md={6}>
                            <SettingsSectionTitle>Support</SettingsSectionTitle>
                            <Grid container>
                                <Item item xs={12}>
                                    <LinkItem to="/settings">
                                        <ReportProblem />
                                        <LinkItemText>
                                            Report A Problem
                                        </LinkItemText>
                                    </LinkItem>
                                </Item>
                                <Item item xs={12}>
                                    <LinkItem to="/settings">
                                        <HelpCenter />
                                        <LinkItemText>Help Center</LinkItemText>
                                    </LinkItem>
                                </Item>
                            </Grid>
                        </Item>

                        {userType === 'admin' ? (
                            <Item item xs={12} md={6}>
                                <SettingsSectionTitle>
                                    Databases
                                </SettingsSectionTitle>
                                <Grid container>
                                    <Item item xs={12}>
                                        <LinkItem to="/settings">
                                            <LoadCustomers />
                                            <LinkItemText>
                                                Load New Customer Database
                                            </LinkItemText>
                                        </LinkItem>
                                    </Item>
                                    <Item item xs={12}>
                                        <LinkItem to="/settings">
                                            <MergeCustomer />
                                            <LinkItemText>
                                                Merge/Update Customer Database
                                            </LinkItemText>
                                        </LinkItem>
                                    </Item>
                                </Grid>
                            </Item>
                        ) : null}
                    </Grid>
                    <LogoutButton onClick={logout}>Log Out</LogoutButton>
                </GradientCard>
            </Item>
        </Grid>
    );
};
