import { PortfolioHistoricalActivityData } from 'config/types';
import { portfolioHistoricalActivityData } from 'dummy/portfolioHistoricalActivityData';
import { TableCell } from 'components/table/TableCell';
import { TableRow } from 'components/table/TableRow';
import { TableCellWithToolip } from 'components/table/TableCellWithToolip';

// TO REMOVE
const list = portfolioHistoricalActivityData.slice(0, 10);

type Props = {
    sx?: Record<string, unknown>;
};

export const HistoricalActivityTable = ({ sx }: Props): JSX.Element => (
    <>
        {list.map((row: PortfolioHistoricalActivityData) => (
            <TableRow sx={sx} key={row.product}>
                <TableCell>{row.transactionDate}</TableCell>
                <TableCellWithToolip maxWidth="600px" title={row.product}>
                    <b>{row.product}</b>
                </TableCellWithToolip>
                <TableCell>{row.quantity}</TableCell>
                <TableCell>{row.price.toLocaleString()}</TableCell>
                <TableCell>
                    {(row.price * row.quantity).toLocaleString()}
                </TableCell>
            </TableRow>
        ))}
    </>
);
