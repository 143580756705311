import { useState } from 'react';

import { logger } from 'libs/logger';
import { getErrorMessage } from 'libs/getErrorMessage';

import { useAlert } from './useAlert';

type Options = {
    info?: string;
    error?: string;
    silent?: boolean;
    throws?: boolean;
    success?: string;
    onError?: () => void;
};

type StateLogic<T, V> = [(args: T) => Promise<V>, boolean];

export const useAsyncAction = <T extends Record<string, unknown> | void, V>(
    action: (args: T) => Promise<V>,
    { silent, throws, info, success, error, onError }: Options = {}
): StateLogic<T, V> => {
    const [processing, setProcessing] = useState<boolean>(false);

    const { infoAlert, errorAlert, successAlert } = useAlert();

    const execute = async (args: T): Promise<V | undefined> => {
        try {
            setProcessing(true);
            if (info && !silent) {
                infoAlert(info);
            }
            const result = await action(args);
            if (success && !silent) {
                successAlert(success);
            }
            return result;
        } catch (e) {
            if (error && !silent && !throws) {
                errorAlert(error);
            }
            if (throws) {
                throw new Error(getErrorMessage(e));
            }
            if (onError) {
                onError();
            }
            logger().error(e);
            return undefined;
        } finally {
            setProcessing(false);
        }
    };

    return [execute as (args: T) => Promise<V>, processing];
};
