import { styled } from '@mui/material/styles';
import GetAppIcon from '@mui/icons-material/GetApp';
import { DropEvent, useDropzone } from 'react-dropzone';
import { ErrorMessage } from '@hookform/error-message';
import { useFormContext } from 'react-hook-form';
import {
    SetStateAction,
    useCallback,
    useEffect,
    useRef,
    useState
} from 'react';
import { FormControl, Stack, Box } from '@mui/material';
import { Row } from 'components/Row';
import { FileType } from 'config/types';
import { useAlert } from 'hooks/useAlert';
import { Typography } from 'components/Typography';
import { FormHelperText } from 'components/FormHelperText';
import { FileTooLargeError } from 'libs/customErrors';
import { ReactComponent as PdfIcon } from 'assets/pdf.svg';
import { allowedFileMimes, fileMaxSize, fileMaxAmount } from 'config';

import { Carousel } from 'components/Carousel';
import { FileHolder } from 'config/types/carousel';

type Props = {
    textId?: string;
    disabled?: boolean;
    fileType: FileType;
    fieldName: string;
    onFileDropped?: () => void;
    onFileRemoved?: () => void;
};

const Dropzone = styled('div')<{
    isfocused: string;
    isdragreject: string;
    isdragaccept: string;
}>(
    ({ isdragreject, isdragaccept, theme }) => `
    height: 100%;
    cursor: pointer;
    padding: ${theme.spacing(4)};
    text-align: center;
    border-radius: 8px;
    border-width: 1px;
    border-style: dashed;
    border-color: ${(() => {
        if (isdragreject === 'true') return theme.palette.error.main;
        if (isdragaccept === 'true') return theme.palette.success.main;
        return theme.palette.primary.light;
    })()};
`
);

export const DropFileAreaWithPreview = ({
    textId,
    disabled,
    fileType,
    fieldName,
    onFileDropped,
    onFileRemoved
}: Props): JSX.Element => {
    const { errorAlert } = useAlert();

    const {
        register,
        setValue,
        formState: { errors }
    } = useFormContext();

    const error = errors?.[fieldName]?.message;
    const [newFiles, setnewFiles] = useState<FileHolder[]>([]);
    const [imageIndex, setImageIndex] = useState(0);

    // carousel functionality
    const onDelete = (imgIndex: number): void => {
        setnewFiles((prev) => {
            const wasFirst = imgIndex === 0;
            const updatedFiles = prev.filter((_, index) => index !== imgIndex);

            if (wasFirst && updatedFiles.length > 0) {
                updatedFiles[0].priority = 1;
            }

            return updatedFiles;
        });

        if (imageIndex >= newFiles.length - 1) {
            setImageIndex((prev) => prev - 1);
        }

        if (onFileRemoved) {
            onFileRemoved();
        }
    };

    const onDataChange = (from: number, to: number): void => {
        // grab the new data from the carousel and change the files here
        setnewFiles((prev) => {
            const movedItem = prev.splice(from, 1)[0];
            if (to === 0) {
                movedItem.priority = 1;
            } else if (from === 0 && prev.length > 0) {
                prev[0].priority = 1;
            }
            prev.splice(to, 0, movedItem);
            return [...prev];
        });
    };

    const getObjectUrl = (file: File): any => {
        // get the url from the file
        return URL.createObjectURL(file);
    };

    // other functionality

    const onDrop = useCallback(
        async (acceptedFiles: File[]) => {
            try {
                const imageNotAccepted: string[] = [];
                // console.log(acceptedFiles, 'accept');
                const pickedFiles = acceptedFiles.slice(0, 51);
                const allowedTypes = ['image/jpg', 'image/jpeg', 'image/png'];
                const filteredFiles = pickedFiles.filter((file) => {
                    if (
                        file.size > fileMaxSize[fileType] ||
                        !allowedTypes.includes(file.type)
                    ) {
                        imageNotAccepted.push(file.name);
                        // console.log(file.name, 'here');
                        return false;
                    } else if (file.size > fileMaxSize[fileType])
                        throw new FileTooLargeError();
                    return true;
                });

                if (imageNotAccepted.length > 0) {
                    errorAlert(
                        `Some Files were not an accepted
                         file type!
                         Files not Included are: ${imageNotAccepted.join(', ')}`
                    );
                }
                const createFileHolder = (file: File): FileHolder => ({
                    preview: URL.createObjectURL(file),
                    file
                });

                setnewFiles((prevFiles: FileHolder[]) => {
                    if (prevFiles.length === 0 && filteredFiles.length < 51) {
                        return filteredFiles.map(createFileHolder);
                    } else if (prevFiles.length + filteredFiles.length < 55) {
                        const newFilteredFiles =
                            filteredFiles.map(createFileHolder);

                        return prevFiles.concat(newFilteredFiles);
                    } else {
                        errorAlert(
                            'File capacity is 10 files! Please try again.'
                        );
                        return prevFiles;
                    }
                });

                if (onFileDropped) {
                    onFileDropped();
                }
            } catch (e) {
                errorAlert(
                    e instanceof FileTooLargeError
                        ? 'fileTooLargeError'
                        : 'unexpectedError'
                );
            }
        },
        [errorAlert, fieldName, onFileDropped, fileType, newFiles]
    );

    useEffect(() => {
        setValue(
            fieldName,
            newFiles.map((file) => file.file),
            {
                shouldValidate: true
            }
        );
    }, [newFiles, setValue, fieldName]);

    const {
        isFocused,
        isDragReject,
        isDragAccept,
        getRootProps,
        getInputProps
    } = useDropzone({
        onDrop,
        accept: allowedFileMimes[fileType],
        disabled,
        maxFiles: 50
    });

    const handleDragStart = async (
        event: React.DragEvent<HTMLDivElement>
    ): Promise<void> => {
        const files = (event.target as HTMLInputElement).files;
        // console.log(event, 'DragStart');
        if (files) {
            const file = files[0];
            const reader = new FileReader();
            reader.onload = (loadEvent) => {
                const dataUrl = (loadEvent.target as FileReader).result;

                if (dataUrl) {
                    const dataTransfer = new DataTransfer();
                    dataTransfer.setData(
                        'application/x-moz-file',
                        dataUrl.toString()
                    );
                    event.dataTransfer = dataTransfer;
                    event.dataTransfer.effectAllowed = 'move';
                }
            };
            reader.readAsDataURL(file);
        }
    };

    const handleDrop = async (
        event: React.DragEvent<HTMLDivElement>
    ): Promise<void> => {
        event.preventDefault();
        event.stopPropagation();
        const files = event.dataTransfer?.files;
        if (files) {
            const fileArray = [...files];
            onDrop(fileArray);
        }
    };

    const handleDragOver = async (
        event: React.DragEvent<HTMLDivElement>
    ): Promise<void> => {
        // console.log(event, 'eventDragOver');
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    };

    useEffect(() => {
        const div = document.querySelector('.drop-zone');
        div?.addEventListener('dragstart', () => handleDragStart);
        return () => {
            // console.log('dragStart');
            div?.removeEventListener('dragstart', () => handleDragStart);
        };
    }, []);

    let color = 'primary.light';
    let msgId = textId || 'dropFilesOrClickHere';

    if (isDragReject) {
        color = 'error.main';
        msgId = 'File type not allowed';
    } else if (isDragAccept) {
        color = 'success.main';
    }
    const ErrorMsg = (): JSX.Element => (
        <ErrorMessage
            errors={errors}
            name={fieldName}
            render={({ message }) => <FormHelperText textId={message} />}
        />
    );

    const urls = newFiles.map((file) => ({
        name: file.file.name,
        preview: file.preview
    }));

    return newFiles.length ? (
        <Stack sx={{ py: 4 }}>
            <Row
                spacing={2}
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center'
                }}>
                {fileType === 'document' ? (
                    <PdfIcon width={30} height={30} />
                ) : (
                    <Carousel
                        text={true}
                        imageSelection={true}
                        buttons={true}
                        delete={true}
                        coverImage={true}
                        data={urls}
                        imageIndex={imageIndex}
                        onDelete={onDelete}
                        setImageIndex={setImageIndex}
                        onDataChange={onDataChange}
                        getObjectUrl={getObjectUrl}
                    />
                )}
            </Row>
            <>
                <div
                    style={{
                        width: '100%',
                        margin: 'auto',
                        display: 'flex',
                        flexWrap: 'wrap'
                    }}>
                    <FormControl
                        sx={{
                            width: '100%',
                            height: '100%',
                            marginTop: '20px'
                        }}>
                        <Dropzone
                            {...getRootProps()}
                            className="drop-zone"
                            isfocused={isFocused.toString()}
                            isdragreject={isDragReject.toString()}
                            isdragaccept={isDragAccept.toString()}>
                            <input
                                {...getInputProps()}
                                multiple={true}
                                // onChange={handleFileSelect}
                            />

                            <Row
                                spacing={2}
                                alignItems="center"
                                justifyContent="center">
                                <GetAppIcon
                                    sx={{ color }}
                                    height={40}
                                    width={40}
                                />
                                <Typography
                                    capitalized
                                    sx={{ color }}
                                    bold={isDragReject}
                                    textId={msgId}
                                    variant="body2"
                                />
                            </Row>
                        </Dropzone>
                    </FormControl>
                </div>
            </>
            {error && (
                <Box sx={{ mt: 2, mx: 'auto' }}>
                    <ErrorMsg />
                </Box>
            )}
        </Stack>
    ) : (
        <FormControl sx={{ width: '100%', height: '100%' }}>
            <Dropzone
                {...getRootProps()}
                className="drop-zone"
                onDragStart={handleDragStart}
                onDrop={handleDrop}
                onDragEnd={handleDragOver}
                isfocused={isFocused.toString()}
                isdragreject={isDragReject.toString()}
                isdragaccept={isDragAccept.toString()}>
                <input
                    {...register(fieldName)}
                    {...getInputProps()}
                    multiple={true}
                    value={newFiles.map(({ preview }) => preview)}
                    // onChange={handleFileSelect}
                />
                <Row spacing={2} alignItems="center" justifyContent="center">
                    <GetAppIcon sx={{ color }} height={40} width={40} />
                    <Typography
                        capitalized
                        sx={{ color }}
                        bold={isDragReject}
                        textId={msgId}
                        variant="body2"
                    />
                </Row>
            </Dropzone>
            {error && <ErrorMsg />}
        </FormControl>
    );
};
