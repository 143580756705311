/* eslint-disable */
import { PortfolioItemsData } from "config/types";

export const portfolioItemsData: PortfolioItemsData[] = [{"id":"1e0f0d32-f0ef-4ed2-ac35-0bdd34fbeddc","product":"Loudetia simplex (Nees) C.E. Hubb.","soldTo":"achittock0","dateCreated":"6/28/2022","price":30211,"status":"sold","netIRR":6.13,"paymentType":"bank transfer"},
{"id":"8de1ce3a-c097-4e4c-a7b9-2f6e4e94ea79","product":"Agrostis vinealis Schreb.","soldTo":"apettengell1","dateCreated":"12/3/2021","price":6402,"status":"sold","netIRR":6.7,"paymentType":"debit card"},
{"id":"f10c420a-7cee-4ee1-8eb2-66ca84b0700d","product":"Astragalus cottonii M.E. Jones","soldTo":"wmintram2","dateCreated":"9/28/2021","price":33852,"status":"pending","netIRR":2.08,"paymentType":"debit card"},
{"id":"cdadc4ec-4bc0-44f5-8de1-3bf06bee6385","product":"Amorpha californica Nutt. var. napensis Jeps.","soldTo":"agresley3","dateCreated":"12/7/2021","price":36961,"status":"sold","netIRR":1.38,"paymentType":"debit card"},
{"id":"dfaf542b-8f5a-45f6-8c2f-e62adb973804","product":"Lepidium serra H. Mann","soldTo":"tmilburn4","dateCreated":"5/23/2022","price":20586,"status":"pending","netIRR":7.14,"paymentType":"credit card"},
{"id":"c274aa97-cc60-4a55-9223-4c3d19bec023","product":"Eriophyllum lanatum (Pursh) Forbes var. achillaeoides (DC.) Jeps.","soldTo":"abernhard5","dateCreated":"7/22/2021","price":24863,"status":"denied","netIRR":6.91,"paymentType":"credit card"},
{"id":"d75726d6-5dfe-4141-87a9-e8c0a8cc5049","product":"Erigeron breweri A. Gray var. bisanctus G.L. Nesom","soldTo":"zmounce6","dateCreated":"10/11/2021","price":8563,"status":"pending","netIRR":1.23,"paymentType":"credit card"},
{"id":"91b45756-521c-4010-8b3b-7a85340859e5","product":"Dudleya saxosa (M.E. Jones) Britton & Rose ssp. saxosa","soldTo":"gvizard7","dateCreated":"5/16/2022","price":15348,"status":"listed","netIRR":3.76,"paymentType":"debit card"},
{"id":"01499940-29a7-4a51-ac22-77173c4f4ebd","product":"Elmera racemosa (S. Watson) Rydb. var. puberulenta C.L. Hitchc.","soldTo":"emckechnie8","dateCreated":"4/4/2022","price":44713,"status":"sold","netIRR":4.59,"paymentType":"debit card"},
{"id":"66a89df2-27cc-4b15-b484-325d2b0e13e5","product":"Orthotrichum tenellum Bruch ex Brid.","soldTo":"fchanning9","dateCreated":"11/6/2021","price":29274,"status":"pending","netIRR":9.77,"paymentType":"LVC coins"},
{"id":"596185dd-953f-4ec1-a941-c6f0fb42410b","product":"Pohlia elongata Hedw.","soldTo":"gcheesmana","dateCreated":"7/4/2022","price":40153,"status":"sold","netIRR":0.48,"paymentType":"bank transfer"},
{"id":"f40b6321-9544-4ff0-a6ad-580d83fed575","product":"Atamisquea Miers ex Hook. & Arn.","soldTo":"lridolfob","dateCreated":"8/16/2021","price":21562,"status":"listed","netIRR":4.36,"paymentType":"LVC coins"},
{"id":"17c808df-a251-496b-97e0-c272fa01942a","product":"Macleaya cordata (Willd.) R. Br.","soldTo":"egideonc","dateCreated":"3/28/2022","price":40481,"status":"sold","netIRR":2.66,"paymentType":"debit card"},
{"id":"945445ba-8743-4332-ae62-809fe95d9dc8","product":"Glyptopleura marginata D.C. Eaton","soldTo":"dkinnerd","dateCreated":"5/4/2022","price":18799,"status":"listed","netIRR":7.13,"paymentType":"debit card"},
{"id":"5813577e-cd83-4727-b644-3b3e910aac55","product":"Ammannia auriculata Willd.","soldTo":"wferrase","dateCreated":"3/23/2022","price":31485,"status":"sold","netIRR":3.12,"paymentType":"debit card"},
{"id":"f0a28246-8eaa-43dc-b2af-0789de6bc411","product":"Schoenus L.","soldTo":"wbiskupskif","dateCreated":"5/11/2022","price":26076,"status":"denied","netIRR":6.18,"paymentType":"LVC coins"},
{"id":"30dbf8f7-f7da-426c-aa40-afb3d750c513","product":"Heuchera americana L. var. hispida (Pursh) E.F. Wells","soldTo":"breisensteing","dateCreated":"5/26/2022","price":17906,"status":"pending","netIRR":7.29,"paymentType":"debit card"},
{"id":"28819cab-c569-423a-aa39-74a90b94008e","product":"Gonocarpus Thunb.","soldTo":"dhaywardh","dateCreated":"1/31/2022","price":41710,"status":"sold","netIRR":8.38,"paymentType":"bank transfer"},
{"id":"a546711c-5012-4c67-a97f-8b649d0ae51b","product":"Hordeum brachyantherum Nevski ssp. californicum (Covas & Stebbins) Bothmer, N. Jacobsen & Seberg","soldTo":"hhollowsi","dateCreated":"5/30/2022","price":40005,"status":"pending","netIRR":8.4,"paymentType":"debit card"},
{"id":"dcca9f00-47d5-4aee-8447-2318af460f44","product":"Caloplaca brattiae W.A. Weber","soldTo":"rscarsbrookej","dateCreated":"1/9/2022","price":34840,"status":"pending","netIRR":6.69,"paymentType":"LVC coins"},
{"id":"82442f05-2cdc-4eea-a2b5-122b4f740b25","product":"Ipomoea macrorhiza Michx.","soldTo":"cthornbarrowk","dateCreated":"2/7/2022","price":23841,"status":"listed","netIRR":2.38,"paymentType":"LVC coins"},
{"id":"ec07d589-7fb1-439e-8626-d314d0443c5b","product":"Stachys recta L.","soldTo":"vgastickel","dateCreated":"4/6/2022","price":49798,"status":"pending","netIRR":4.87,"paymentType":"LVC coins"},
{"id":"dbccb090-993b-4f6f-8315-dec9aa72a17e","product":"Combretum hereroense Schinz","soldTo":"aebbuttm","dateCreated":"8/18/2021","price":10807,"status":"listed","netIRR":9.27,"paymentType":"bank transfer"},
{"id":"6cdf777e-805c-4815-a5b1-cb6d6729f69b","product":"Sorghum bicolor (L.) Moench ssp. drummondii (Nees ex Steud.) de Wet & Harlan","soldTo":"sgrunbaumn","dateCreated":"7/14/2021","price":38077,"status":"denied","netIRR":4.9,"paymentType":"debit card"},
{"id":"767ad000-0996-4f54-b4c3-0fac8262c485","product":"Edrudia W.P. Jordan","soldTo":"scrightono","dateCreated":"7/2/2022","price":27003,"status":"sold","netIRR":6.44,"paymentType":"debit card"},
{"id":"4b4a16e0-c894-4808-a117-c141131868a4","product":"Arctostaphylos cruzensis J.B. Roof","soldTo":"bdorrinsp","dateCreated":"11/22/2021","price":16649,"status":"sold","netIRR":9.8,"paymentType":"credit card"},
{"id":"3ba4f8ee-909a-44ab-8748-5cef7ab6e6e7","product":"Arthrostylidium farctum (Aubl.) Soderstr. & Lourteig.","soldTo":"cnassiq","dateCreated":"12/8/2021","price":47782,"status":"pending","netIRR":2.36,"paymentType":"credit card"},
{"id":"ea1ccedf-38ae-49ad-84d0-0f6982c0a203","product":"Astragalus saurinus Barneby","soldTo":"elavigner","dateCreated":"8/20/2021","price":34459,"status":"pending","netIRR":5.35,"paymentType":"credit card"},
{"id":"fccbfdd7-4222-4262-b1a9-85ef57f0ca02","product":"Phlox griseola Wherry ssp. griseola","soldTo":"bbouskills","dateCreated":"2/24/2022","price":49266,"status":"pending","netIRR":1.56,"paymentType":"debit card"},
{"id":"73291319-4582-4ed8-8c18-42e80137bae8","product":"Dasistoma Raf.","soldTo":"hcastellanost","dateCreated":"8/5/2021","price":48274,"status":"listed","netIRR":8.21,"paymentType":"LVC coins"},
{"id":"f33d5be5-d3b0-4e5d-82fa-89bd553483f4","product":"Spiranthes ovalis Lindl.","soldTo":"druffu","dateCreated":"11/6/2021","price":7802,"status":"listed","netIRR":1.62,"paymentType":"LVC coins"},
{"id":"41b77253-c8bc-40d4-8828-90033b33258d","product":"Philadelphus pumilus Rydb. var. ovatus Hu","soldTo":"cwoodhamsv","dateCreated":"1/11/2022","price":34228,"status":"listed","netIRR":4.1,"paymentType":"credit card"},
{"id":"238bc646-dd23-4554-b27c-eaf9d92e62f2","product":"Condalia hookeri M.C. Johnst. var. edwardsiana (Cory) M.C. Johnst.","soldTo":"gbrozw","dateCreated":"10/7/2021","price":39627,"status":"denied","netIRR":0.08,"paymentType":"credit card"},
{"id":"6632217a-108a-425e-9391-ed674700a161","product":"Schwalbea americana L.","soldTo":"sjostx","dateCreated":"11/9/2021","price":26619,"status":"listed","netIRR":4.47,"paymentType":"bank transfer"},
{"id":"f2b0bf0a-5f7b-47b9-ae12-9aa9a40784f9","product":"Dryas drummondii Richardson ex Hook.","soldTo":"gbasiloney","dateCreated":"1/30/2022","price":36328,"status":"listed","netIRR":3.83,"paymentType":"credit card"},
{"id":"19e6d377-8db6-4167-9f0f-eeb51411e364","product":"Thymophylla tenuiloba (DC.) Small var. tenuiloba","soldTo":"emccaugheyz","dateCreated":"1/23/2022","price":14119,"status":"sold","netIRR":0.63,"paymentType":"credit card"},
{"id":"d8eecc5f-f2d9-4884-b38e-a9d692e388d8","product":"Spartina alterniflora Loisel.","soldTo":"fmckague10","dateCreated":"5/25/2022","price":45489,"status":"listed","netIRR":0.89,"paymentType":"bank transfer"},
{"id":"7e15043a-6668-41da-ad97-4c728c097f80","product":"Thelomma carolinianum (Tuck.) Tibell","soldTo":"smacteague11","dateCreated":"7/5/2022","price":29951,"status":"listed","netIRR":7.42,"paymentType":"LVC coins"},
{"id":"de77e1d1-0712-4431-8596-18c313ee94c5","product":"Echinochloa picta (J. Koenig) P.W. Michael","soldTo":"sbrassington12","dateCreated":"11/7/2021","price":39023,"status":"denied","netIRR":6.79,"paymentType":"credit card"},
{"id":"60dfca4f-2dda-45eb-9c84-a0c6bef1b2f2","product":"Eutrema R. Br.","soldTo":"tosgood13","dateCreated":"2/17/2022","price":14645,"status":"denied","netIRR":5.08,"paymentType":"bank transfer"},
{"id":"1aeea9ce-b2ea-4891-8b03-3a51b63b196c","product":"Vriesea sintenisii (Baker) L.B. Sm. & Pittendr.","soldTo":"hbenck14","dateCreated":"6/11/2022","price":11947,"status":"sold","netIRR":4.12,"paymentType":"bank transfer"},
{"id":"babc3402-2094-4f82-821f-87a0dc3816be","product":"Cerastium beeringianum Cham. & Schltdl. ssp. beeringianum var. grandiflorum (Fenzl) Hultén","soldTo":"amarriot15","dateCreated":"8/4/2021","price":37214,"status":"sold","netIRR":7.82,"paymentType":"bank transfer"},
{"id":"6ef0c52d-d765-4dd4-90cb-00f983a33e1f","product":"Aletris L.","soldTo":"awessing16","dateCreated":"3/4/2022","price":26477,"status":"denied","netIRR":3.99,"paymentType":"debit card"},
{"id":"94b8cf38-22a9-48cb-8001-f1c374496f16","product":"Muellerella pygmaea (Körb.) D. Hawksw.","soldTo":"jbownas17","dateCreated":"3/8/2022","price":40629,"status":"sold","netIRR":4.82,"paymentType":"LVC coins"},
{"id":"8e3e0459-3197-46a9-85ba-524edc3a17fd","product":"Polygonum scandens L. var. cristatum (Engelm. & A. Gray) Gleason","soldTo":"crubinfajn18","dateCreated":"1/14/2022","price":39873,"status":"pending","netIRR":6.72,"paymentType":"LVC coins"},
{"id":"a5a3bc36-71c5-4b5b-af12-d29482a170ae","product":"Cypripedium parviflorum Salisb.","soldTo":"rlegate19","dateCreated":"10/10/2021","price":23439,"status":"denied","netIRR":3.91,"paymentType":"debit card"},
{"id":"342f071e-d127-4616-b143-3681e199d51c","product":"Stylocline psilocarphoides M. Peck","soldTo":"ibertlin1a","dateCreated":"1/21/2022","price":39578,"status":"denied","netIRR":5.5,"paymentType":"bank transfer"},
{"id":"2627928e-4b5f-42c9-802f-2278959ff7ef","product":"Achillea millefolium L. var. alpicola (Rydb.) Garrett","soldTo":"fshrimptone1b","dateCreated":"6/3/2022","price":41601,"status":"listed","netIRR":8.03,"paymentType":"bank transfer"},
{"id":"e52b7e52-004d-43bf-9697-9c9df7056c2d","product":"Arctostaphylos tomentosa (Pursh) Lindl. ssp. crustacea (Eastw.) P.V. Wells","soldTo":"jroebuck1c","dateCreated":"2/25/2022","price":38660,"status":"denied","netIRR":2.58,"paymentType":"bank transfer"},
{"id":"0aec582c-7902-4394-897d-b236de02ccbe","product":"Spergularia atrosperma R.P. Rossb.","soldTo":"gstowgill1d","dateCreated":"3/2/2022","price":43649,"status":"sold","netIRR":8.29,"paymentType":"bank transfer"},
{"id":"c40d8e49-efb0-43f4-a4ff-8e8658ffad26","product":"Iris xiphium L.","soldTo":"schittleburgh1e","dateCreated":"7/30/2021","price":13687,"status":"denied","netIRR":2.78,"paymentType":"bank transfer"},
{"id":"c9a6b1a9-0310-4f2f-b7ad-4a7ccedda635","product":"Bryocaulon pseudosatoanum (Asah.) Karnefelt","soldTo":"lzanneli1f","dateCreated":"2/8/2022","price":10138,"status":"sold","netIRR":6.25,"paymentType":"debit card"},
{"id":"d1771adc-ad83-4120-8bd3-c514c787f13a","product":"Miriquidica leucophaea (Flörke ex Rabenh.) Hertel & Rambold","soldTo":"sliepins1g","dateCreated":"6/17/2022","price":44801,"status":"pending","netIRR":4.79,"paymentType":"credit card"},
{"id":"a188122f-57db-4294-a229-b43ae775cece","product":"Phleum paniculatum Huds.","soldTo":"mcartan1h","dateCreated":"4/16/2022","price":14094,"status":"sold","netIRR":5.71,"paymentType":"bank transfer"},
{"id":"b35659f2-6651-4899-8fee-2dec6155bc8f","product":"Bacidia De Not.","soldTo":"rsiviour1i","dateCreated":"11/24/2021","price":20992,"status":"sold","netIRR":5.78,"paymentType":"LVC coins"},
{"id":"bc0caa09-0bdc-4f0a-b8ea-0aa36617e577","product":"Elatine triandra Schkuhr","soldTo":"ckhomin1j","dateCreated":"7/7/2022","price":12504,"status":"pending","netIRR":4.72,"paymentType":"debit card"},
{"id":"977e5ba2-9e12-44de-a03a-269e96727048","product":"Polymeridium quinqueseptatum (Nyl.) R.C. Harris","soldTo":"hmacklin1k","dateCreated":"10/16/2021","price":23132,"status":"sold","netIRR":6.96,"paymentType":"bank transfer"},
{"id":"f62f3470-ebd6-4c75-a410-b3eff958fb38","product":"Chamaesyce trachysperma (Engelm.) Millsp.","soldTo":"bdyball1l","dateCreated":"3/12/2022","price":7424,"status":"listed","netIRR":4.17,"paymentType":"credit card"},
{"id":"97634ac1-0723-490b-bed2-14259b629e82","product":"Salix pellita Andersson","soldTo":"cseers1m","dateCreated":"7/16/2021","price":20452,"status":"denied","netIRR":5.53,"paymentType":"LVC coins"},
{"id":"19c5c723-3268-4eaa-b372-76104ca8c968","product":"Bigelowia nudata (Michx.) DC. ssp. australis L.C. Anderson","soldTo":"aadelman1n","dateCreated":"6/26/2022","price":32323,"status":"listed","netIRR":8.47,"paymentType":"bank transfer"},
{"id":"c59d0e77-000d-4933-a7ce-29d5c0fbc7cd","product":"Selaginella lepidophylla (Hook. & Grev.) Spring","soldTo":"pjeff1o","dateCreated":"12/15/2021","price":27044,"status":"listed","netIRR":7.06,"paymentType":"debit card"},
{"id":"3917d9a0-eb1c-4355-a5e3-14854b8bc8ed","product":"Carex tribuloides Wahlenb. var. sangamonensis Clokey","soldTo":"pwaine1p","dateCreated":"6/29/2022","price":17809,"status":"denied","netIRR":6.95,"paymentType":"LVC coins"},
{"id":"c54d9ec5-1b86-4b49-9daa-37eec876781a","product":"Cordylanthus rigidus (Benth.) Jeps. ssp. rigidus","soldTo":"cfinneran1q","dateCreated":"3/2/2022","price":12156,"status":"pending","netIRR":2.96,"paymentType":"bank transfer"},
{"id":"2174f31e-941f-45b0-8871-7b7fd6f52072","product":"Lindernia crustacea (L.) F. Muell.","soldTo":"tbaythrop1r","dateCreated":"7/1/2022","price":16608,"status":"sold","netIRR":3.96,"paymentType":"credit card"},
{"id":"1bf50d4a-9cae-4172-90b0-7a12a1a1e310","product":"Berberis julianae C.K. Schneid.","soldTo":"rthreadgold1s","dateCreated":"8/27/2021","price":19572,"status":"sold","netIRR":9.31,"paymentType":"credit card"},
{"id":"e2992cae-2d1b-4247-9288-f586d334cf7b","product":"Pluchea Cass.","soldTo":"dbrouncker1t","dateCreated":"3/27/2022","price":5559,"status":"pending","netIRR":3.16,"paymentType":"credit card"},
{"id":"4099ae8c-8b94-431d-9c6b-3a8fd68f7fa3","product":"Leptogium isidiosellum (Riddle) Sierk","soldTo":"cspencelayh1u","dateCreated":"6/19/2022","price":9985,"status":"listed","netIRR":6.79,"paymentType":"credit card"},
{"id":"c55604bd-e6bd-4ffc-a52f-795812eda7a7","product":"Saxifraga howellii Greene","soldTo":"ctancock1v","dateCreated":"4/3/2022","price":29737,"status":"listed","netIRR":7.58,"paymentType":"debit card"},
{"id":"74a70d5b-029b-4c52-831e-618071564217","product":"Epimedium ×youngianum Fisch. & C.A. Mey. (pro sp.)","soldTo":"aferroli1w","dateCreated":"12/6/2021","price":28792,"status":"pending","netIRR":3.48,"paymentType":"debit card"},
{"id":"63545846-c594-48e1-9ecd-7b8325e52c3c","product":"Fissidens obtusifolius Wilson var. obtusifolius","soldTo":"cmckinlay1x","dateCreated":"3/23/2022","price":38097,"status":"denied","netIRR":1.94,"paymentType":"LVC coins"},
{"id":"d40684bf-6883-4619-aee1-648b42eb67ec","product":"Trisetum glomeratum (Kunth) Trin. ex Steud.","soldTo":"gsthill1y","dateCreated":"1/11/2022","price":30674,"status":"denied","netIRR":7.69,"paymentType":"bank transfer"},
{"id":"fe65170a-0a84-402b-84d6-4baaa9b0b499","product":"Rhytidium rugosum (Hedw.) Kindb.","soldTo":"thufton1z","dateCreated":"5/4/2022","price":30798,"status":"denied","netIRR":9.48,"paymentType":"bank transfer"},
{"id":"d7d4fb01-670e-4ec8-8038-3d0a99d989b4","product":"Stenanthium gramineum (Ker Gawl.) Morong","soldTo":"cdarbon20","dateCreated":"3/6/2022","price":29267,"status":"sold","netIRR":5.96,"paymentType":"bank transfer"},
{"id":"aca33e52-837e-4838-8f37-1bf43507743c","product":"Dalea wrightii A. Gray var. warnockii (Tharp & F.A. Barkley) B.L. Turner","soldTo":"bwills21","dateCreated":"11/13/2021","price":14463,"status":"sold","netIRR":8.03,"paymentType":"debit card"},
{"id":"b44c433f-4bdd-4883-8d6d-738a416cd10f","product":"Sphagnum steerei R.E. Andrus","soldTo":"kalpin22","dateCreated":"7/27/2021","price":21665,"status":"denied","netIRR":3.13,"paymentType":"debit card"},
{"id":"875e3aa5-6e18-4d26-b082-98d1dd7f1e2e","product":"Carex aggregata Mack.","soldTo":"kgellert23","dateCreated":"5/11/2022","price":7626,"status":"listed","netIRR":2.28,"paymentType":"debit card"},
{"id":"fd248917-4e51-48b8-b275-c0f7296ec132","product":"Isocoma arguta Greene","soldTo":"ckybbye24","dateCreated":"7/30/2021","price":46004,"status":"listed","netIRR":4.71,"paymentType":"bank transfer"},
{"id":"675d1183-f33d-4799-9788-800efe522b31","product":"Bulbostylis ciliatifolia (Elliott) Fernald var. ciliatifolia","soldTo":"abanger25","dateCreated":"2/21/2022","price":20418,"status":"pending","netIRR":6.83,"paymentType":"bank transfer"},
{"id":"6aea340f-cb16-4a33-a32c-29240509673b","product":"Proboscidea louisianica (Mill.) Thell. ssp. fragrans (Lindl.) Bretting","soldTo":"ajammes26","dateCreated":"10/15/2021","price":28879,"status":"denied","netIRR":7.09,"paymentType":"credit card"},
{"id":"404f6689-e442-47f8-baf2-770d11a63e83","product":"Cladonia turgida Hoffm.","soldTo":"tgilstoun27","dateCreated":"7/28/2021","price":26784,"status":"pending","netIRR":0.21,"paymentType":"LVC coins"},
{"id":"a9eeac48-d493-475e-a275-ce4595dc98e8","product":"Centaurea trichocephala M. Bieb. ex Willd.","soldTo":"oslowey28","dateCreated":"10/9/2021","price":48007,"status":"denied","netIRR":8.54,"paymentType":"credit card"},
{"id":"c0153407-6ffa-4fa2-ba88-b892ead97ad2","product":"Cyperus owanii Boeckeler","soldTo":"gcraddy29","dateCreated":"9/22/2021","price":15775,"status":"sold","netIRR":6.63,"paymentType":"debit card"},
{"id":"ea3d4a8a-d9fb-466b-842e-b353ea1f0812","product":"Diplotaxis DC.","soldTo":"dfeathers2a","dateCreated":"8/20/2021","price":25171,"status":"listed","netIRR":6.13,"paymentType":"bank transfer"},
{"id":"058ae0fc-ae11-4e52-9185-640bbf34a974","product":"Sansevieria Thunb.","soldTo":"scortnay2b","dateCreated":"12/1/2021","price":23763,"status":"listed","netIRR":7.16,"paymentType":"credit card"},
{"id":"f38b17e0-3127-4e6d-8a1a-42b6c882f755","product":"Fraxinus anomala Torr. ex S. Watson var. anomala","soldTo":"akernell2c","dateCreated":"4/30/2022","price":49822,"status":"pending","netIRR":0.45,"paymentType":"bank transfer"},
{"id":"e205e25f-866a-4f6d-bca5-593597b6cc30","product":"Remya Hillebr. ex Benth.","soldTo":"rbeisley2d","dateCreated":"8/30/2021","price":26110,"status":"listed","netIRR":8.38,"paymentType":"credit card"},
{"id":"eac17279-e20a-4541-a3a2-fbd45706830a","product":"Rainiera stricta (Greene) Greene","soldTo":"hscarre2e","dateCreated":"3/11/2022","price":6182,"status":"pending","netIRR":1.86,"paymentType":"credit card"},
{"id":"b3cafb3f-1cc5-466e-a8a7-5adc079b46a3","product":"Setaria tenacissima Schrad.","soldTo":"ldunlop2f","dateCreated":"4/4/2022","price":41659,"status":"sold","netIRR":8.35,"paymentType":"credit card"},
{"id":"562a9309-359a-493c-a835-914eb7db00a8","product":"Lotus oblongifolius (Benth.) Greene","soldTo":"wfeighry2g","dateCreated":"10/7/2021","price":22164,"status":"listed","netIRR":9.55,"paymentType":"debit card"},
{"id":"ceabe698-9d5b-4fe7-8777-786ca4102efc","product":"Phacelia inundata J.T. Howell","soldTo":"fpedrocco2h","dateCreated":"8/22/2021","price":36538,"status":"denied","netIRR":7.27,"paymentType":"LVC coins"},
{"id":"abe5767a-e2ce-44dd-ae0d-2199807f3fdf","product":"Astragalus lentiginosus Douglas ex Hook. var. latus (M.E. Jones) M.E. Jones","soldTo":"kpatek2i","dateCreated":"12/25/2021","price":19388,"status":"pending","netIRR":6.35,"paymentType":"debit card"},
{"id":"a23a9b3e-5d38-4abb-b609-91de9443d95b","product":"Lupinus lyallii A. Gray ssp. washoensis (A. Heller) Cox","soldTo":"ghazard2j","dateCreated":"6/7/2022","price":8693,"status":"denied","netIRR":4.94,"paymentType":"bank transfer"},
{"id":"b3ee827b-f130-4be1-986a-da50164fd7a8","product":"Cycas revoluta Thunb.","soldTo":"crepper2k","dateCreated":"3/18/2022","price":27829,"status":"denied","netIRR":0.35,"paymentType":"bank transfer"},
{"id":"9d8a8d6b-896d-4d8a-9464-4cca10410737","product":"Coryphantha robustispina (Schott ex Engelm.) Britton & Rose","soldTo":"agrumbridge2l","dateCreated":"1/21/2022","price":10496,"status":"pending","netIRR":1.37,"paymentType":"debit card"},
{"id":"bb106011-f65e-4ffb-b114-2dfd85147ee1","product":"Hieracium triste Willd. ex Spreng. var. triste","soldTo":"jcolebourn2m","dateCreated":"9/15/2021","price":36933,"status":"sold","netIRR":8.99,"paymentType":"debit card"},
{"id":"cd6b7f99-74c7-477b-b8c7-cf07d8e1d191","product":"Salix geyeriana Andersson","soldTo":"kflorey2n","dateCreated":"8/12/2021","price":18799,"status":"denied","netIRR":3.73,"paymentType":"credit card"},
{"id":"f4b19b52-2855-49d7-9b97-ba5455d48a92","product":"Platanthera aquilonis Sheviak","soldTo":"ncumbers2o","dateCreated":"3/6/2022","price":37704,"status":"pending","netIRR":4.55,"paymentType":"credit card"},
{"id":"1099bd50-56ce-485d-aed4-47c8d2d37c46","product":"Maesopsis Engl.","soldTo":"rballay2p","dateCreated":"10/30/2021","price":37734,"status":"denied","netIRR":4.37,"paymentType":"LVC coins"},
{"id":"9e677ad4-ca7c-4dff-b2a5-bb552889bd66","product":"Brachionidium Lindl.","soldTo":"dsouthers2q","dateCreated":"3/22/2022","price":23406,"status":"listed","netIRR":7.14,"paymentType":"debit card"},
{"id":"d8734cde-73c0-4dde-ac72-8ce4d0f4c240","product":"Pseudelephantopus Rohr","soldTo":"acroysdale2r","dateCreated":"9/14/2021","price":43322,"status":"listed","netIRR":7.7,"paymentType":"LVC coins"}]