import capitalize from 'lodash/capitalize';
import MuiIconButton from '@mui/material/IconButton';
import { useTranslation } from 'react-i18next';

type Props = React.ComponentProps<typeof MuiIconButton> & {
    title?: string;
};

export const IconButton = ({ title, ...props }: Props): JSX.Element => {
    const { t } = useTranslation();
    return <MuiIconButton {...props} title={title && capitalize(t(title))} />;
};
