import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import { Row } from 'components/Row';
import { Typography } from 'components/Typography';
import MuiLinearProgress from '@mui/material/LinearProgress/LinearProgress';

type Props = {
    shareOwned: number;
    nbOfHolders: number;
};

const LinearProgress = styled(MuiLinearProgress)(({ theme }) => ({
    backgroundColor: theme.palette.primary.light,
    '& .MuiLinearProgress-bar': {
        borderRadius: 10,
        backgroundColor: theme.palette.primary.dark
    }
}));

export const OwnedShareBar = ({
    nbOfHolders,
    shareOwned
}: Props): JSX.Element => {
    const { t } = useTranslation();

    return (
        <Stack>
            <Row justifyContent="space-between">
                <Typography variant="caption">
                    {shareOwned}% {t('owned').toUpperCase()}
                </Typography>
                <Typography variant="caption">
                    {nbOfHolders} {t('holdings owners').toUpperCase()}
                </Typography>
            </Row>
            <Box sx={{ width: '100%' }}>
                <LinearProgress variant="determinate" value={shareOwned} />
            </Box>
        </Stack>
    );
};
