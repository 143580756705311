import Stack from '@mui/material/Stack';

import { Typography } from 'components/Typography';

type Props = {
    price: number;
};

export const ItemCardPrice = ({ price }: Props): JSX.Element => (
    <Stack alignItems="flex-end">
        <Typography uppercased variant="body2" textId="salesPrice" />
        <Typography bold variant="subtitle1">
            ${price?.toLocaleString()}
        </Typography>
    </Stack>
);
