import { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { BlockChainContext } from 'blockchain/BlockchainContext';

import { isUserLoggedIn } from 'state/user/selectors';
import { useAppSelector } from 'hooks/useAppSelector';

import { useTorusLogin } from './useTorusLogin';

type Props = {
    children: (loading: boolean, login: () => Promise<void>) => JSX.Element;
};

export const AuthButton = ({ children }: Props): JSX.Element => {
    const { loading, ready } = useContext(BlockChainContext);
    const loggedIn = useAppSelector(isUserLoggedIn);

    const [login, loggingIn] = useTorusLogin();

    if (loggedIn) {
        return <Redirect from="/" to="/portfolio" />;
    }

    if (!loading && !ready && !loggingIn) {
        return <Redirect from="/" to="/something-went-wrong" />;
    }

    return children(loggingIn || loading, login);
};
