import { Typography } from 'components/Typography';

type Props = {
    textId: string;
};

export const DataType = ({ textId }: Props): JSX.Element => (
    <Typography
        sx={{
            '@media (max-width: 290px)': {
                fontSize: '.75rem',
                'line-height': '.9rem'
            }
        }}
        textId={textId}
        variant="body2"
        allCapitalized
        bold
    />
);
