import TableHead from '@mui/material/TableHead';
import { TableCellProps, styled } from '@mui/material';

import { TableCell } from './TableCell';
import { TableRow } from './TableRow';

type Props = React.ComponentProps<typeof TableHead> & {
    headers: string[];
    alignText?: TableCellProps['align'];
};

const Head = styled(TableHead)(({ theme }) => ({
    '& .MuiTableCell-root': {
        color: theme.palette.primary.contrastText,
        backgroundImage: theme.palette.success.contrastText
    }
}));

export const TableHeader = ({
    headers,
    alignText = 'left',
    ...other
}: Props): JSX.Element => {
    return (
        <Head {...other}>
            <TableRow>
                {headers.map((header) => (
                    <TableCell key={header} align={alignText} textId={header} />
                ))}
            </TableRow>
        </Head>
    );
};
