import sortBy from 'lodash/sortBy';
import { useState } from 'react';

import { RootState } from 'app/store';
import { StorageService } from 'services/StorageService';
import { DatabaseService } from 'services/DatabaseService';
import { getMerchantAsset } from 'state/merchantAssets/selectors';
import { merchantAssetsActions } from 'state/merchantAssets/merchantAssetsSlice';
import { AssetMediaMetadataItem, MediaType } from 'config/types/asset';

import { useAppSelector } from './useAppSelector';
import { useAppDispatch } from './useAppDispatch';

type RemoveParams = {
    id: string;
};

type StateLogic = {
    files: AssetMediaMetadataItem[];
    name: string;
    sortable: boolean;
    submitting: boolean;
    onSaveList: () => void;
    onReorderList: (items: AssetMediaMetadataItem[]) => void;
    toggleSortable: () => void;
    onRemoveItem: ({ id }: RemoveParams) => void;
};

export const useManageAssetMedia = (
    assetId: string,
    mediaType: MediaType
): StateLogic => {
    const dispatch = useAppDispatch();

    const { name, medias } = useAppSelector((state: RootState) =>
        getMerchantAsset(state, assetId)
    );

    const files = sortBy(
        medias.filter((e) => e.type === mediaType),
        'priority'
    );

    const [list, setList] = useState<AssetMediaMetadataItem[]>(files);

    const [submitting, setSubmitting] = useState<boolean>(false);

    const [sortable, setSortable] = useState<boolean>(false);

    const toggleSortable = (): void => {
        setSortable(!sortable);
    };

    const onReorderList = (items: AssetMediaMetadataItem[]): void => {
        setList(items);
    };

    const onSaveList = async (): Promise<void> => {
        setSubmitting(true);
        const newMediaList: AssetMediaMetadataItem[] = medias.map((m) => {
            const idx = list.findIndex((e) => e.id === m.id);
            return idx > -1 ? { ...m, priority: idx + 1 } : m;
        });
        await DatabaseService.updateAssetMedias(assetId, newMediaList);
        dispatch(
            merchantAssetsActions.updateAssetData({
                id: assetId,
                data: { medias: newMediaList }
            })
        );
        setSortable(false);
        setSubmitting(false);
    };

    const onRemoveItem = async ({ id }: RemoveParams): Promise<void> => {
        setSubmitting(true);
        const newList = medias.filter((e) => e.id !== id);
        await DatabaseService.updateAssetMedias(assetId, newList);
        dispatch(
            merchantAssetsActions.updateAssetData({
                id: assetId,
                data: { medias: newList }
            })
        );
        // We remove the file from the storage at the end so an
        // exception would not prevent the deletion from the database
        const media = medias.find((e) => e.id === id);
        if (media) {
            await StorageService.removeMedia(media.name);
        }
        setSubmitting(false);
    };

    return {
        files,
        name,
        sortable,
        submitting,
        toggleSortable,
        onSaveList,
        onReorderList,
        onRemoveItem
    };
};
