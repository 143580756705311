import Box from '@mui/material/Box';

import { Grid } from 'components/Grid';
import { RootState } from 'app/store';
import { AssetItem } from 'config/types/asset';
import { AssetName } from 'components/AssetName';
import { StandardModal } from 'components/StandardModal';
import { useAppSelector } from 'hooks/useAppSelector';
import { getMerchantAssetPictures } from 'state/merchantAssets/selectors';

import { AssetPictures } from './AssetPictures';
import { AssetListingData } from './AssetListingData';
import { AssetListingStatusUpdateButton } from './AssetListingStatusUpdateButton';

type Props = {
    open: boolean;
    data: AssetItem;
    handleClose: () => void;
};

export const AssetListingStatusControlModal = ({
    data,
    open,
    handleClose
}: Props): JSX.Element => {
    const pics = useAppSelector((state: RootState) =>
        getMerchantAssetPictures(state, data.id)
    );

    return (
        <StandardModal
            title="listingDetails"
            open={open}
            handleClose={handleClose}>
            <div
                style={{
                    width: '230px',
                    margin: 'auto'
                }}>
                <AssetName data={data.name} />
            </div>
            <Grid
                sx={{ ml: 0 }}
                style={{
                    width: '230px',
                    display: 'flex',
                    flexWrap: 'wrap',
                    margin: 'auto'
                }}>
                <Grid item xs={4}>
                    <AssetPictures data={pics} />
                </Grid>
                <Grid item xs={8} style={{ height: '120px' }}>
                    <AssetListingData data={data} />
                </Grid>
            </Grid>
            <Box
                sx={{ pt: 3 }}
                style={{
                    width: '230px',
                    margin: 'auto'
                }}>
                <AssetListingStatusUpdateButton
                    data={data}
                    handleClose={handleClose}
                />
            </Box>
        </StandardModal>
    );
};
