import { getUserId } from 'state/user/selectors';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { userBidsDrawerActions } from 'state/userBidsDrawer/userBidsDrawerSlice';
import { getUserBidsDrawerState } from 'state/userBidsDrawer/selectors';

import { useAppSelector } from './useAppSelector';

type StateLogic = [() => void, () => void, boolean];

export const useUserBidsDrawer = (): StateLogic => {
    const dispatch = useAppDispatch();

    const userId = useAppSelector(getUserId);

    const opened = useAppSelector(getUserBidsDrawerState);

    const open = (): void => {
        dispatch(userBidsDrawerActions.open(userId));
    };

    const close = (): void => {
        dispatch(userBidsDrawerActions.close());
    };

    return [open, close, opened];
};
