import {
    createTheme,
    ThemeOptions,
    ThemeProvider,
    responsiveFontSizes
} from '@mui/material/styles';
import * as locales from '@mui/material/locale';

import { Layout } from 'components/Layout';
import { useAlert } from 'hooks/useAlert';
import { RootState } from 'app/store';
import { AlertProps } from 'state/alert/alertSlice';
import { defaultLang } from 'config';
import { LangContext } from 'libs/contexts';
import { AlertSnackbar } from 'components/AlertSnackbar';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'hooks/useAppSelector';
import { AddFundsPanel } from 'features/addFunds/AddFundsPanel';
import { useUserBidsDrawer } from 'hooks/useUserBidsDrawer';
import { useAddFundsDrawer } from 'hooks/useAddFundsDrawer';
import { StandardDrawer } from './StandardDrawer';
import { getUserData } from 'state/user/selectors';
import { User } from 'config/types/user';
import { Typography } from '@mui/material';
import { UserBidsDrawer } from 'features/userBidsDrawer/UserBidsDrawer';

type Props = {
    theme: ThemeOptions;
};

export const App = ({ theme }: Props): JSX.Element => {
    const { i18n } = useTranslation();

    const lang = i18n.language ?? defaultLang;
    const user: User = useAppSelector(getUserData);
    const { closeAlert } = useAlert();

    const alert: AlertProps = useAppSelector((state: RootState) => state.alert);

    const [__, closeUserBidsDrawer, openedUserBidsDrawer] = useUserBidsDrawer();

    const [_, closeDrawer, openedDrawer] = useAddFundsDrawer();

    // TODO: get locale from i18next
    const themeWithLocale = createTheme(theme, locales.enUS);

    const responsiveTheme = responsiveFontSizes(themeWithLocale);

    // const popperInner = document.querySelector(
    //     '.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item > .popper-inner'
    // ) as HTMLElement;

    if (user.isAdmin === false) {
        if (responsiveTheme.palette && responsiveTheme.palette.primary) {
            responsiveTheme.palette.success.main = '#F5F5F5';
            responsiveTheme.palette.primary.contrastText = '#212121';
            responsiveTheme.palette.success.dark =
                'linear-gradient(179.14deg, #FFFFFF 0.73%, #F5F5F5 121.08%)';
            responsiveTheme.palette.success.light = '#274B73';
            responsiveTheme.palette.success.contrastText =
                'linear-gradient(179.14deg, #FFFFFF 0.73%, #F5F5F5 121.08%)';
            responsiveTheme.palette.grey[800] =
                responsiveTheme.palette.primary.dark;
            responsiveTheme.palette.error.dark =
                'linear-gradient(180deg, #ebebeb 0%, #ffffff 100%)';
            responsiveTheme.palette.error.light =
                'linear-gradient(179.14deg, #FFFFFF 0.73%, #F5F5F5 121.08%)';
            responsiveTheme.palette.warning.dark =
                'linear-gradient(179.14deg, #FFFFFF 0.73%, #F5F5F5 121.08%)';
            responsiveTheme.palette.error.contrastText =
                'linear-gradient(180deg, #ebebeb 0%, #ffffff 100%)';
            responsiveTheme.palette.warning.light =
                'linear-gradient(179.14deg, #FFFFFF 0.73%, #F5F5F5 121.08%)';
            responsiveTheme.palette.warning.contrastText =
                'linear-gradient(180deg, #ebebeb 0%, #ffffff 100%)';
        }
    }

    return (
        <LangContext.Provider value={[lang, i18n.changeLanguage]}>
            <ThemeProvider theme={responsiveTheme}>
                <Layout />
                <AlertSnackbar
                    type={alert.type}
                    onClose={closeAlert}
                    message={alert.message}
                />
                <StandardDrawer open={openedDrawer} onClose={closeDrawer}>
                    <AddFundsPanel handleClose={closeDrawer} />
                </StandardDrawer>
                <UserBidsDrawer
                    open={openedUserBidsDrawer}
                    handleClose={closeUserBidsDrawer}
                />
            </ThemeProvider>
        </LangContext.Provider>
    );
};
