import { SmartContractService } from './SmartContractService';

export class NFTTokenService extends SmartContractService {
    async mint(recipient: string, tokenURI: string): Promise<number> {
        console.log('Minting process started');
        console.log(this.contract, recipient, tokenURI);
        try {
            const tx = await this.contract.functions.mintNFT(
                recipient,
                tokenURI,
                false
            );
            console.log(tx);
            await tx.wait();
            const filter = this.contract.filters.Transfer(null, recipient);
            console.log(filter);
            const events = await this.contract.queryFilter(filter);
            console.log(events);
            const event = events[events.length - 1];
            console.log(event);
            const nftId = event?.args?.tokenId.toNumber();

            if (!nftId) throw new Error('Could not find NFT ID');

            console.log(nftId);

            return nftId;
        } catch (e) {
            console.error(e);
            throw new Error('Could not mint nft');
        }
    }

    async approve(to: string, nftId: number): Promise<void> {
        const tx = await this.contract.approve(to, nftId);
        await tx.wait();
    }

    async setTokenURI(nftId: number, tokenURI: string): Promise<void> {
        try {
            const tx = await this.contract.setTokenURI(nftId, tokenURI);
            await tx.wait();
        } catch (e) {
            throw new Error('Could not set setTokenURI');
        }
    }
}
