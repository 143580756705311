import Snackbar from '@mui/material/Snackbar';
import capitalize from 'lodash/capitalize';
import Alert, { AlertColor } from '@mui/material/Alert';

type Props = {
    type?: AlertColor;
    message?: string;
    onClose: () => void;
};

export const AlertSnackbar = ({
    type,
    onClose,
    message
}: Props): JSX.Element => (
    <Snackbar
        sx={{ zIndex: 3000, maxWidth: 400 }}
        open={!!message}
        autoHideDuration={10000}
        onClose={onClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={onClose} severity={type || 'error'} elevation={0}>
            {capitalize(message)}
        </Alert>
    </Snackbar>
);
