import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import { Row } from 'components/Row';
import { Typography } from 'components/Typography';
import MuiLinearProgress from '@mui/material/LinearProgress/LinearProgress';

type Props = {
    percentage: number;
};

const LinearProgress = styled(MuiLinearProgress)(({ theme }) => ({
    backgroundColor: theme.palette.primary.light,
    '& .MuiLinearProgress-bar': {
        borderRadius: 10,
        backgroundColor: theme.palette.primary.dark
    }
}));

export const BoughtInDropsBar = ({ percentage }: Props): JSX.Element => {
    const { t } = useTranslation();

    return (
        <Stack>
            <Typography variant="caption">
                % {t('bought in drops').toUpperCase()}
            </Typography>
            <Box sx={{ mb: 1, width: '100%' }}>
                <LinearProgress variant="determinate" value={percentage} />
            </Box>
            <Row justifyContent="flex-end">
                <Typography variant="caption">
                    % {t('resold').toUpperCase()}
                </Typography>
            </Row>
        </Stack>
    );
};
