import Card from '@mui/material/Card';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { styled } from '@mui/material/styles';

import { ContactForm } from 'features/contactForm/ContactForm';
import { StandardPage } from 'components/StandardPage';

import { getUserData } from 'state/user/selectors';
import { User } from 'config/types/user';
import { useAppSelector } from 'hooks/useAppSelector';

export const ContactUsPage = (): JSX.Element => {
    const user: User = useAppSelector(getUserData);
    const customer = user.isAdmin === false;

    const FormContainer = styled(Card)(({ theme }) => ({
        padding: 20,
        background: theme.palette.success.dark,
        boxShadow: '1px 0px 10px rgba(0, 0, 0, 0.42)',
        '& .MuiCard-root': {
            padding: '0'
        },
        borderRadius: 5,
        [theme.breakpoints.down('md')]: {
            width: '100%'
        }
    }));

    const FAQ = styled(Link)(({ theme }) => ({
        color: theme.palette.primary.light,
        fontSize: '22px',
        textDecoration: 'underline'
    }));

    return (
        <StandardPage
            sx={{ marginBottom: '5rem' }}
            titleId="hereToHelp"
            subtitle={
                <Trans
                    i18nKey="faqPrompt"
                    components={{ to: <FAQ to="/faq" /> }}
                />
            }>
            <FormContainer>
                <ContactForm />
            </FormContainer>
        </StandardPage>
    );
};
