import { useState } from 'react';

import { FloatingAddButton } from 'components/FloatingAddButton';
import { AddNewAssetModal } from 'features/addNewAssetModal/AddNewAssetModal';
import { MerchantAssetsTable } from 'features/merchantAssetsTable/MerchantAssetsTable';

export const MerchantAssetsPage = (): JSX.Element => {
    const [openModal, setOpenModal] = useState<boolean>(false);

    const handleCloseModal = (): void => {
        setOpenModal(false);
    };

    const handleOpenModal = (): void => {
        setOpenModal(true);
    };

    return (
        <>
            <MerchantAssetsTable />
            <FloatingAddButton onClick={() => handleOpenModal()} />
            <AddNewAssetModal open={openModal} handleClose={handleCloseModal} />
        </>
    );
};
