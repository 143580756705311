/* eslint-disable max-len */
import NFTTokenContract from 'artifacts/contracts/LuxVestingNFT.sol/LuxVestingNFT.json';
import TokenContract from 'artifacts/contracts/LuxVestingToken.sol/LuxVestingToken.json';
import NFTMarketplaceContract from 'artifacts/contracts/NFTMarketplace.sol/NFTMarketplace.json';
import TokenVendor from 'artifacts/contracts/LuxVestingTokenVendor.sol/LuxVestingTokenVendor.json';
import {
    LOCAL_BLOCKCHAIN,
    NFT_MARKETPLACE_CONTRACT_ADDRESS,
    NFT_TOKEN_CONTRACT_ADDRESS,
    TOKEN_CONTRACT_ADDRESS,
    TOKEN_VENDOR_CONTRACT_ADDRESS
} from 'config/constants';
import { ContractInterface } from 'ethers';
import { BiddingService } from 'services/BiddingService';
import { NFTMarketplaceService } from 'services/NFTMarketplaceService';
import { NFTTokenService } from 'services/NFTTokenService';
import { SmartContractService } from 'services/SmartContractService';
import { TokenContractService } from 'services/TokenContractService';
import { ContractName } from './types/blockchain';
import { TokenVendorContractService } from 'services/TokenVendorContractService';

export const allowedFileMimes: Record<string, string[]> = {
    picture: ['image/jpg', 'image/jpeg', 'image/png'],
    document: ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf']
};

export const allowedFileTypes: Record<string, string[]> = {
    avatar: ['jpeg', 'jpg', 'png'],
    picture: ['jpg', 'jpeg', 'png'],
    document: ['jpg', 'jpeg', 'png', 'pdf']
};

export const fileMaxSize = {
    // in kb
    avatar: 500 * 1000,
    picture: 500 * 1000,
    document: 500 * 1000
};
export const fileMaxAmount = {
    file: 10
};

export const avatarsStoragePath = 'avatars';

export const mediaStoragePath = 'medias';

export const userTypes = ['customer', 'vendor', 'admin'] as const;

export const userTiers = ['silver', 'gold', 'platinum'] as const;

export const languages = ['en', 'zh', 'es'];

export const defaultLang = languages[0];

export const categories = [
    'Precious Metals',
    'Gems',
    'Jewelry',
    'Watches',
    'Vehicles',
    'Insurance',
    'Collectibles',
    'Designer Apparel'
];

export const defaultCategory = categories[0];

export const defaultCurrency = 'USD';

export const defaultCurrencySymbol = '$';

export const cryptoCurrency = 'ETH';

export const proprietaryCurrency = 'LVX';

export const minBidAllowed = 10;

export const ipfsApi = 'https://ipfs.infura.io:5001/api/v0';

export const ipfsEndpoint = 'https://infura-ipfs.io/ipfs/';

export const localhostProvider = `http://localhost:8545`;

export const goerliProvider = `https://eth-goerli.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMY_API_KEY}`;

export const mumbaiProvider = `https://polygon-mumbai.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMY_API_KEY}`;

export const chainId = LOCAL_BLOCKCHAIN ? 1337 : 5;

export const hostNetwork = LOCAL_BLOCKCHAIN ? localhostProvider : 'goerli';

export const networkName = LOCAL_BLOCKCHAIN
    ? 'Local Network'
    : 'Goerli Network';

export const rpcProvider = LOCAL_BLOCKCHAIN
    ? localhostProvider
    : goerliProvider;

export const nftMarketplaceContractAddress = LOCAL_BLOCKCHAIN
    ? process.env.REACT_APP_MARKETPLACE_CONTRACT_ADDRESS_ON_LOCALHOST
    : process.env.REACT_APP_MARKETPLACE_CONTRACT_ADDRESS_ON_GOERLI;

export const minBidValueAllowed = 10;

export const chartRanges: string[] = [
    '1Day',
    '1Week',
    '1Month',
    '3Months',
    '1Year'
];

export const contractInfo: Record<
    'nft' | 'staking' | 'marketplace' | 'bidding' | 'frac',
    [string, ContractInterface, string]
> = {
    nft: [NFT_TOKEN_CONTRACT_ADDRESS, NFTTokenContract.abi, 'Lux NFT'],
    staking: [TOKEN_CONTRACT_ADDRESS, TokenContract.abi, 'Token'],
    marketplace: [
        NFT_MARKETPLACE_CONTRACT_ADDRESS,
        NFTMarketplaceContract.abi,
        'Marketplace'
    ],
    bidding: [
        NFT_MARKETPLACE_CONTRACT_ADDRESS,
        NFTMarketplaceContract.abi,
        'Bidding'
    ],
    frac: [
        TOKEN_VENDOR_CONTRACT_ADDRESS,
        TokenContract.abi,
        'Fractionalization'
    ]
};

export const contractClasses = {
    nft: NFTTokenService,
    staking: TokenContractService,
    marketplace: NFTMarketplaceService,
    bidding: BiddingService,
    frac: TokenVendorContractService
};
