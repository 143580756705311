import { Box } from '@mui/material';

import { Row } from 'components/Row';
import { Typography } from 'components/Typography';
import { Message } from 'config/types';

type Props = {
    data: Message;
    ownMessage: boolean;
};

export const ChatRoomMessage = ({ data, ownMessage }: Props): JSX.Element => (
    <Row justifyContent={ownMessage ? 'left' : 'right'}>
        <Box
            sx={{
                p: 1,
                maxWidth: '80%',
                borderRadius: 2,
                backgroundColor: ownMessage ? 'primary.dark' : 'primary.light'
            }}>
            <Typography
                sx={{
                    color: ownMessage ? 'common.white' : 'common.black'
                }}>
                {data.text}
            </Typography>
        </Box>
    </Row>
);
