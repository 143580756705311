import { DarkButton } from 'components/buttons/DarkButton';
import { TradeHoldingsModal } from 'features/tradeHoldingsModal/TradeHoldingsModal';

import { SellHoldingsInstructions } from './SellHoldingsInstructions';

type Props = {
    holdingsOwned: number;
};

export const SellHoldingsModal = ({ holdingsOwned }: Props): JSX.Element => (
    <TradeHoldingsModal
        title="sell holdings"
        holdingsOwned={holdingsOwned}
        instructions={<SellHoldingsInstructions />}>
        {(openModal: () => void) => (
            <DarkButton textId="sell shares" onClick={openModal} />
        )}
    </TradeHoldingsModal>
);
