import { Stack } from '@mui/material';
import { Typography } from 'components/Typography';

type Props = {
    data: number;
    onDayMove?: number;
};

export const HistoricalAppreciation = ({
    data,
    onDayMove
}: Props): JSX.Element => {
    return (
        <Stack spacing={1} alignItems="center">
            <Typography
                uppercased
                sx={{ fontWeight: 300 }}
                textId="historicalAppreciation"
                variant="body1"
            />
            <Typography variant="h4">{data.toLocaleString()}%</Typography>
            {onDayMove && (
                <Stack justifyContent="center" direction="row" spacing={2}>
                    <Typography sx={{ color: 'primary.light' }} variant="body1">
                        -${onDayMove?.toLocaleString()}%
                    </Typography>
                    <Typography variant="body1" uppercased>
                        TODAY
                    </Typography>
                </Stack>
            )}
        </Stack>
    );
};
