import { useParams } from 'react-router-dom';
import { useAppSelector } from 'hooks/useAppSelector';
import { useState, useEffect } from 'react';

import { AssetItem } from 'config/types/asset';
import { getMerchantAssets } from 'state/merchantAssets/selectors';
import { MerchantAssetModal } from 'config/enums/merchantAssetModal';
import { useFetchMerchantAssets } from 'hooks/useFetchMerchantAssets';

type StateLogic = {
    list: AssetItem[];
    fetching: boolean;
    openedModal: MerchantAssetModal;
    selectedAssetId?: string;
    handleCloseModal: () => void;
    handleOpenModal: (id: string, content: MerchantAssetModal) => void;
};

export const useMerchantAssetsTable = (): StateLogic => {
    const { action, itemId } = useParams<{
        action?: string;
        itemId?: string;
    }>();

    const [openedModal, setOpenedModal] = useState<MerchantAssetModal>(
        (action as MerchantAssetModal) ?? MerchantAssetModal.none
    );

    const [selectedAssetId, setSelectedAssetId] = useState<string | undefined>(
        itemId
    );

    const handleCloseModal = (): void => {
        setOpenedModal(MerchantAssetModal.none);
    };

    const handleOpenModal = (id: string, content: MerchantAssetModal): void => {
        setSelectedAssetId(id);
        // Close any currently opened modal
        handleCloseModal();
        setOpenedModal(content);
    };

    const list: AssetItem[] = useAppSelector(getMerchantAssets);

    const [fetchMerchantAssets, fetching] = useFetchMerchantAssets();

    useEffect(() => {
        fetchMerchantAssets();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        list,
        fetching,
        openedModal,
        selectedAssetId,
        handleOpenModal,
        handleCloseModal
    };
};
