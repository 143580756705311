import { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, UseFormReturn, SubmitHandler } from 'react-hook-form';

import {
    MediaType,
    AssetAuthenticationStatus,
    AssetAuthenticationFormInput
} from 'config/types/asset';
import { logger } from 'libs/logger';
import { getUserData } from 'state/user/selectors';
import { useAppSelector } from 'hooks/useAppSelector';
import { useAsyncAction } from 'hooks/useAsyncAction';
import { useUpdateUserData } from 'hooks/useUpdateUserData';
import { useUpdateAssetData } from 'hooks/useUpdateAssetData';

import { useAddMediaToAsset } from 'hooks/useAddMediaToAsset';
import { assetAuthenticationFormSchema } from './assetAuthenticationFormSchema';

type StateLogic = {
    onClose: () => void;
    onSubmit: (data: AssetAuthenticationFormInput) => void;
    submitting: boolean;
    // eslint-disable-next-line @typescript-eslint/ban-types
    formMethods: UseFormReturn<AssetAuthenticationFormInput, object>;
    noDocument: boolean;
    onNoDocumentChange: () => void;
};

export const useAssetAuthenticationForm = (
    id: string,
    handleClose: () => void
): StateLogic => {
    const [noDocument, setNoDocument] = useState<boolean>(false);

    const intialData = useAppSelector(getUserData);

    const [addNewMedia] = useAddMediaToAsset(id, true);

    const [updateUserData] = useUpdateUserData(true);

    const [updateAssetData] = useUpdateAssetData(id, true);

    const onNoDocumentChange = (): void => {
        setNoDocument(!noDocument);
    };

    const formMethods = useForm<AssetAuthenticationFormInput>({
        resolver: yupResolver(assetAuthenticationFormSchema),
        defaultValues: {
            city: intialData.city || '',
            state: intialData.state || '',
            address: intialData.address || '',
            zipCode: intialData.zipCode || ''
        }
    });

    const onClose = (): void => {
        formMethods.reset();
        handleClose();
    };

    const action: SubmitHandler<AssetAuthenticationFormInput> = async (
        data
    ): Promise<void> => {
        logger('Submitting asset authentication data').info(data);
        const authenticationStatus: AssetAuthenticationStatus = 'pending';
        const { certificateOfAuthenticityFile: file, ...shippingData } = data;
        // Save the certificate and retrieve the uri pointing to it
        const title = 'certificateOfAuthenticity';
        const type: MediaType = 'document';
        if (file) await addNewMedia({ name: file.name, type, title, file });
        // Save data relevant to user (shipping address)
        await updateUserData(shippingData);
        // Save data relevant to asset
        await updateAssetData({ authenticationStatus });
        handleClose();
    };

    const [onSubmit, submitting] = useAsyncAction<
        AssetAuthenticationFormInput,
        void
    >(action, { error: 'errorUpdatingData' });

    return {
        onClose,
        onSubmit,
        submitting,
        formMethods,
        noDocument,
        onNoDocumentChange
    };
};
