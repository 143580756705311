import { TextField } from 'components/TextField';

type Props = {
    text: string;
    onChange: (text: string) => void;
};

export const ChatRoomInput = ({ text, onChange }: Props): JSX.Element => (
    <TextField
        autoFocus
        value={text}
        onChange={(e) => onChange(e.target.value)}
        placeholder="typeYourMessage"
        inputProps={{
            sx: { color: 'common.white' }
        }}
    />
);
