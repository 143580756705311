import * as yup from 'yup';

import { isValidDocumentFileType } from 'libs/utils';

export const assetAuthenticationFormSchema = yup
    .object({
        city: yup.string().min(2).max(50).required().trim(),
        state: yup.string().min(2).max(50).required().trim(),
        address: yup.string().min(2).max(150).required().trim(),
        zipCode: yup.string().min(2).max(10).required().trim(),
        certificateOfAuthenticityFile: yup
            .mixed()
            .test(
                'file_type_validation',
                'Invalid file type',
                isValidDocumentFileType
            )
    })
    .required();
