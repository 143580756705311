import { getUserId } from 'state/user/selectors';
import { AssetItem } from 'config/types/asset';
import { DatabaseService } from 'services/DatabaseService';
import { merchantAssetsActions } from 'state/merchantAssets/merchantAssetsSlice';

import { useAppSelector } from './useAppSelector';
import { useAsyncAction } from './useAsyncAction';
import { useAppDispatch } from './useAppDispatch';

type StateLogic = [() => Promise<AssetItem[]>, boolean];

export const useFetchMerchantAssets = (noCatch?: boolean): StateLogic => {
    const userId = useAppSelector(getUserId);

    const dispatch = useAppDispatch();

    const action = async (): Promise<AssetItem[]> => {
        const assets = await DatabaseService.getMerchantAssets(userId);
        dispatch(merchantAssetsActions.setAll(assets));
        return assets;
    };

    const [actionWithTryCatch, processing] = useAsyncAction<void, AssetItem[]>(
        action,
        { error: 'errorRetrievingAssets' }
    );

    return [noCatch ? action : actionWithTryCatch, processing];
};
