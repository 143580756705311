import { Bid, BidRecord } from 'config/types';
import { dollarsToTokens, dollarsFromTokens } from 'libs/utils';
import { SmartContractService } from './SmartContractService';

export class BiddingService extends SmartContractService {
    async getItemBids(nftId: number): Promise<Bid[]> {
        const [bids] = await this.contract.getBid(nftId);
        console.log(bids);
        const existingBids = new Set();

        const bidToString = (bid: BidRecord): string =>
            `${bid.bidder}-${bid.value}`;

        return bids.reduce((acc: Bid[], bid: BidRecord): Bid[] => {
            if (!bid.value) return acc;
            if (existingBids.has(bidToString(bid))) return acc;

            existingBids.add(bidToString(bid));

            const value = dollarsFromTokens(bid.value);
            acc.push({ bidder: bid.bidder, nftId, value });
            return acc;
        }, []);
    }

    async createBid(bid: Bid): Promise<void> {
        const { value: valueInFiat, bidder, nftId } = bid;
        const value = dollarsToTokens(valueInFiat);
        // !!The user eth account is their user id!!
        console.log('Value:', value);
        const tx = await this.contract.createBid(nftId, bidder, value);
        await tx.wait();
    }

    async endAuction(nftId: number): Promise<void> {
        const tx = await this.contract.endAuction(nftId);
        await tx.wait();
    }
}
