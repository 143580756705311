import Box from '@mui/material/Box';
import { FormProvider } from 'react-hook-form';

import { Row } from 'components/Row';
import { AssetName } from 'components/AssetName';
import { DarkButton } from 'components/buttons/DarkButton';
import { LightButton } from 'components/buttons/LightButton';
import { StandardModal } from 'components/StandardModal';

import { CreateListingFields } from './CreateListingFields';
import { useCreateAssetListingModal } from './useCreateAssetListingModal';
import { Asset, AssetItem } from 'config/types/asset';

type Props = {
    open: boolean;
    assetId: string;
    handleClose: () => void;
    data: AssetItem[];
};

const findAssetById = (
    data: AssetItem[],
    assetId: string
): AssetItem | undefined => {
    return data.find((element) => element.id === assetId);
};

export const CreateAssetListingModal = ({
    open,
    assetId,
    handleClose,
    data
}: Props): JSX.Element => {
    const { onClose, assetName, onSubmit, submitting, formMethods } =
        useCreateAssetListingModal(
            assetId,
            findAssetById(data, assetId) as AssetItem,
            handleClose
        );

    return (
        <StandardModal
            sx={{ p: 5 }}
            open={open}
            minwidth="800px"
            handleClose={handleClose}>
            <FormProvider {...formMethods}>
                <form
                    noValidate
                    autoComplete="off"
                    onSubmit={formMethods.handleSubmit(onSubmit)}>
                    <AssetName data={assetName} />
                    <Box sx={{ my: 3, mb: 0 }}>
                        <CreateListingFields />
                    </Box>
                    <Row sx={{ mt: 3 }} justifyContent="space-between">
                        <DarkButton
                            textId="cancel"
                            onClick={onClose}
                            disabled={submitting}
                        />
                        <LightButton
                            type="submit"
                            textId="submit"
                            loading={submitting}
                        />
                    </Row>
                </form>
            </FormProvider>
        </StandardModal>
    );
};
