import { useContext } from 'react';
import { BlockChainContext } from 'blockchain/BlockchainContext';
import { getUserId, getUserName } from 'state/user/selectors';
import { useAppSelector } from 'hooks/useAppSelector';
import { DatabaseService } from 'services/DatabaseService';

import { useAsyncAction } from './useAsyncAction';

type Param = {
    assetId: string;
    nftId: number;
};

type StateLogic = [(param: Param) => Promise<void>, boolean];

export const useBuyMarketItem = (noCatch?: boolean): StateLogic => {
    const { marketplace } = useContext(BlockChainContext);
    const merchantId = useAppSelector(getUserId);

    const merchantName = useAppSelector(getUserName);

    const action = async (param: Param): Promise<void> => {
        const { assetId, nftId } = param;
        console.log(nftId);
        await marketplace!.endAuction(nftId);
        await DatabaseService.updateAssetData(assetId, {
            status: 'sold',
            merchantName,
            merchantId
        });
    };

    const [actionWithTryCatch, processing] = useAsyncAction<Param, void>(
        action,
        {
            info: 'blockchainTransactionStarted',
            error: 'errorBuyingItem',
            success: 'successBuyingItem'
        }
    );

    return [noCatch ? action : actionWithTryCatch, processing];
};
