import { DropFileAreaWithPreview } from 'features/dropFileArea/DropFileAreaWithPreview';
import { fileMaxSize, allowedFileMimes } from 'config';

type Props = Partial<React.ComponentProps<typeof DropFileAreaWithPreview>> & {
    fieldName: string;
};

export const PictureInput = ({ fieldName, ...other }: Props): JSX.Element => (
    <DropFileAreaWithPreview
        {...other}
        fieldName={fieldName}
        fileMaxSize={fileMaxSize.picture}
        allowedFileTypes={allowedFileMimes.picture}
    />
);
