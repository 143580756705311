import { styled } from '@mui/material';
import MuiButton from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

import { Spinner } from 'components/Spinner';

type Props = React.ComponentProps<typeof MuiButton> & {
    textId: string;
    loading?: boolean;
    disabled?: boolean;
    fullWidth?: boolean;
};

const CustomButton = styled(MuiButton)<{ fullWidth?: boolean }>`
    width: ${(props) => (props.fullWidth ? '100%' : 'inherit')};
    font-weight: 400;
`;
export const Button = ({
    textId,
    loading,
    disabled,
    fullWidth,
    ...props
}: Props): JSX.Element => {
    const { t } = useTranslation();

    return (
        <CustomButton
            {...props}
            disabled={loading || disabled}
            fullWidth={fullWidth}>
            {loading ? <Spinner /> : t(textId)}
        </CustomButton>
    );
};
