import { Typography } from 'components/Typography';

export const NoPictureMessage = (): JSX.Element => (
    <Typography
        textId="missingCoverPhoto"
        variant="h4"
        sx={{
            p: 2,
            color: 'primary.light',
            height: '100%',
            opacity: 0.5,
            textAlign: 'center',
            lineHeight: '1.5em',
            fontWeight: 'bold'
        }}
    />
);
