import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import sortBy from 'lodash/sortBy';
import { styled } from '@mui/material/styles';
import { useState } from 'react';

import { Row } from 'components/Row';
import { Image } from 'components/Image';
import { AssetItem } from 'config/types/asset';
import { getItemPics } from 'libs/utils';
import { ImageMagnifier } from 'features/imageMagnifier/ImageMagnifier';

type Props = {
    data: AssetItem;
    selected: number;
    onSelectPic: (index: number) => void;
};

const Container = styled(Row)(({ theme }) => ({
    height: '100vh',
    marginLeft: 80,
    backgroundColor: theme.palette.success.main,
    [theme.breakpoints.down('md')]: {
        marginLeft: 0
    },
    [theme.breakpoints.down('sm')]: {
        border: '2px solid green'
    }
}));

const ViewOptionImg = styled(Image)({
    width: '100%',
    height: '15vh',
    cursor: 'pointer',
    objectFit: 'cover',
    borderRadius: 5,
    objectPosition: 'center'
});

export const MarketItemImagesPanel = ({
    data,
    selected,
    onSelectPic
}: Props): JSX.Element => {
    const pictures = sortBy(getItemPics(data), 'priority');

    return (
        <Container>
            <Box sx={{ height: '100vh', width: '100%' }}>
                <ImageMagnifier src={pictures[selected].uri} />
            </Box>
            <Stack
                sx={{
                    display: { xs: 'none', md: 'block' },
                    py: 0,
                    px: 2,
                    width: '20%'
                }}>
                {pictures.map((pic, index) => (
                    <Box sx={{ height: '15vh', mt: '4vh' }}>
                        <ViewOptionImg
                            uri={pic.uri}
                            key={pic.id}
                            title={pic.title}
                            onClick={() => onSelectPic(index)}
                            component="img"
                        />
                    </Box>
                ))}
            </Stack>
        </Container>
    );
};
