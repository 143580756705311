// import { useEffect } from 'react';
import { useEffect } from 'react';

import { ItemList } from 'components/ItemList';
import { ListedItem } from 'config/types/asset';
import { LightButton } from 'components/buttons/LightButton';
import { getAllAssets } from 'state/assets/selectors';
import { useAppSelector } from 'hooks/useAppSelector';
import { useFetchAllMarketplaceItems } from 'hooks/useFetchAllMarketplaceItems';

type Props = {
    onSelectitem: (item: ListedItem) => void;
};

export const Marketplace = ({ onSelectitem }: Props): JSX.Element => {
    const list = useAppSelector(getAllAssets);

    const [getList, fetching] = useFetchAllMarketplaceItems();

    useEffect(() => {
        (async () => {
            await getList();
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ItemList<ListedItem>
            list={list}
            loading={fetching}
            onSelectitem={onSelectitem}>
            {() => <LightButton textId="info" />}
        </ItemList>
    );
};
