import { DataRow } from 'features/itemGallery/DataRow';
import { DataPoint } from 'components/DataPoint';
import { Typography } from 'components/Typography';

type Props = {
    data?: number;
};

export const EquityOwned = ({ data }: Props): JSX.Element => (
    <DataRow textId="equityOwned">
        <DataPoint bold data={data}>
            <Typography textId="shares" />
            <Typography>/</Typography>
            {data && (
                <Typography bold>
                    {(data ?? 0 / 1000).toLocaleString()} %
                </Typography>
            )}
        </DataPoint>
    </DataRow>
);
