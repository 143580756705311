import { AssetItem } from 'config/types/asset';
import { DatabaseService } from 'services/DatabaseService';

import { useAsyncAction } from './useAsyncAction';

type Args = { assetId: string };

type StateLogic = [(args: Args) => Promise<AssetItem>, boolean];

export const useFetchAssetData = (noCatch?: boolean): StateLogic => {
    const action = async ({ assetId }: Args): Promise<AssetItem> =>
        DatabaseService.getAssetData(assetId);

    const [actionWithTryCatch, processing] = useAsyncAction<Args, AssetItem>(
        action,
        { error: 'errorRetrievingAsset' }
    );

    return [noCatch ? action : actionWithTryCatch, processing];
};
