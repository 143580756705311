import { History } from 'history';
import { connectRouter } from 'connected-react-router';
import { combineReducers, Reducer } from '@reduxjs/toolkit';

import { userReducer } from 'state/user/userSlice';
import { alertReducer } from 'state/alert/alertSlice';
import { menuReducer } from 'state/menu/menuSlice';
import { assetsReducer } from 'state/assets/assetsSlice';
import { userBidsDrawerReducer } from 'state/userBidsDrawer/userBidsDrawerSlice';
import { merchantAssetsReducer } from 'state/merchantAssets/merchantAssetsSlice';
import { addFundsDrawerReducer } from 'state/addFundsDrawer/addFundsDrawerSlice';

export const rootReducer = (history: History): Reducer =>
    combineReducers({
        router: connectRouter(history),
        user: userReducer,
        alert: alertReducer,
        menu: menuReducer,
        assets: assetsReducer,
        userBidsDrawer: userBidsDrawerReducer,
        merchantAssets: merchantAssetsReducer,
        addFundsDrawer: addFundsDrawerReducer
    });
