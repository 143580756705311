import { Stack } from '@mui/material';
import { Typography } from 'components/Typography';

type Props = {
    data: number;
};

export const AuctionHistory = ({ data }: Props): JSX.Element => {
    return (
        <Stack spacing={1} alignItems="center">
            <Typography
                uppercased
                sx={{ fontWeight: 300 }}
                textId="auctionHistory"
                variant="body1"
            />
            <Typography variant="body1">{data} Days</Typography>
        </Stack>
    );
};
