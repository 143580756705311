import { useState, useContext, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { StakingChart } from 'features/stakingChart/StakingChart';
import { MerchantInvestModal } from 'features/investModal/MerchantInvestModal';
import Button from '@mui/material/Button';
import { CardMedia } from '@mui/material';
import LeftArrow from '../assets/LeftArrow.svg';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import { BlockChainContext } from 'blockchain/BlockchainContext';
import { dollarsFromTokens } from 'libs/utils';

const GradientCard = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    width: '100%',
    background: theme.palette.success.dark,
    boxShadow: '1px 0px 10px rgba(0, 0, 0, 0.42)',
    borderRadius: '5px',
    padding: '30px',
    [theme.breakpoints.down('md')]: {
        maxHeight: '100%',
        padding: '20px'
    }
}));

const Item = styled(Grid)(({ theme }) => ({
    maxWidth: '100%',
    padding: '20px 0px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
        maxWidth: '100%'
    }
}));

const FlexItem = styled(Grid)(() => ({
    maxWidth: '100%',
    padding: '20px 0px',
    display: 'flex',
    flexDirection: 'column'
}));

const HistoricalAppreciation = styled('span')(() => ({
    fontFamily: 'Jost',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '21px',
    lineHeight: '42px',
    textAlign: 'left',
    color: '#FFFFFF',
    display: 'flex',
    marginBottom: '20px'
}));

const HistoricalPercentage = styled('span')(({ theme }) => ({
    fontFamily: 'Jost',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '42px',
    color: theme.palette.primary.contrastText,
    grid: 'initial',
    gridColumn: 'auto',
    marginBottom: '20px',
    textAlign: 'center'
}));

const HistoricalPercentageDetails = styled('span')(({ theme }) => ({
    fontFamily: 'Jost',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    color: '#A5CEE4',
    textAlign: 'center'
}));

const TimeFilterDiv = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    color: theme.palette.primary.contrastText,
    padding: '2px 8px',
    borderRadius: '5px',
    fontSize: '.75rem',
    lineHeight: '.8rem',
    fontWeight: '400',
    fontFamily: 'Jost',
    textAlign: 'center',
    textTransform: 'uppercase'
}));

const ColoredDivider = styled('div')(({ theme }) => ({
    width: '115%',
    borderTop: `1px solid ${theme.palette.primary.light}`
}));

const StakingDetailsTitle = styled('span')(({ theme }) => ({
    fontFamily: 'Jost',
    fontStyle: 'normal',
    fontWeight: 400,
    letterSpacing: '0.02em',
    textTransform: 'uppercase',
    color: theme.palette.primary.contrastText,
    textAlign: 'start'
}));

const StakingDetailsValue = styled('span')(({ theme }) => ({
    fontFamily: 'Jost',
    fontStyle: 'normal',
    fontWeight: 600,
    letterSpacing: '0.02em',
    color: theme.palette.primary.contrastText,
    textAlign: 'start'
}));

const SellSharesButtonDiv = styled(Button)`
    color: #ffffff;
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 0.6rem;
    line-height: 1rem;
    height: 0.5rem;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    background-color: #3c4858;
    border-radius: 3px;
    padding: 15px;
    margin: 10px;
    &:hover {
        cursor: pointer;
        background-color: #516278;
    }
`;

const InvestNowButtonDiv = styled(Button)`
    color: black;
    font-family: 'Jost';
    font-style: normal;
    font-size: 0.6rem;
    line-height: 1rem;
    height: 0.5rem;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    background-color: #a5cee4;
    margin: 10px;
    border-radius: 3px;
    padding: 15px;
    &:hover {
        cursor: pointer;
        background-color: #b5e0f8;
    }
`;

const EditHoldingsBtn = styled(Button)`
    color: white;
    font-family: 'Jost';
    font-style: normal;
    font-size: 0.6rem;
    line-height: 1rem;
    height: 0.5rem;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    background-color: #040405;
    margin: 10px;
    border-radius: 3px;
    padding: 15px;
    &:hover {
        cursor: pointer;
        background-color: #28282b;
    }
`;
const filters = ['1 Day', '1 Week', '1 Month', '3 Months', '1 Year', 'All'];

export const Staking = (): JSX.Element => {
    const [timeAgoFilter, setTimeAgoFilter] = useState<string>('1 Month');
    const [openModal, setOpenModal] = useState<boolean>(false);
    const { marketplace } = useContext(BlockChainContext);
    const [salePrice, setSalePrice] = useState<number>();
    const [purchasePrice, setPurchasePrice] = useState<number>();

    const handleOpenModal = (): void => setOpenModal(true);
    const handleCloseModal = (): void => setOpenModal(false);

    const history = useHistory();
    const urlLocation = useLocation();
    const handleListingData = async (path: string): Promise<void> => {
        const matched: any = path.match(/\/invest-chart\/(\d+)/);
        if (matched !== null && matched !== undefined) {
            const grabNum = parseInt(matched[1], 10);
            const listing: any = await marketplace?.getListingData(grabNum);
            const salePriceToDollars = dollarsFromTokens(listing.max);
            setSalePrice(salePriceToDollars);

            const bidRefNumber = listing.bidRef;
            const biddingObject: any = await marketplace?.getBidData(
                bidRefNumber
            );
            const pruchasePriceToDollars = dollarsFromTokens(
                biddingObject.value
            );
            setPurchasePrice(pruchasePriceToDollars);
        }
    };

    useEffect((): void => {
        if (urlLocation.pathname !== null) {
            handleListingData(urlLocation.pathname);
        }
    }, []);

    return (
        <Grid
            container
            style={{
                padding: 0,
                zIndex: 150
            }}>
            <Item item xs={12} style={{ padding: 0 }}>
                <GradientCard>
                    <div style={{ width: '100%', marginBottom: '20vh' }}>
                        {/* go back */}
                        <Grid
                            sx={{
                                fontSize: '12px',
                                display: 'grid',
                                paddingTop: '3rem',
                                'grid-template-columns':
                                    'repeat(12, minmax(0, 1fr))',
                                gap: '1.6rem',
                                height: '1rem',
                                marginTop: '-4rem',
                                zIndex: '150',
                                placeContent: 'start',
                                paddingBottom: '1vh'
                            }}>
                            <Item
                                item
                                height=".45rem"
                                width=".45rem"
                                marginBottom="20px"
                                gridColumn="span 1 / span 1"
                                marginLeft="2px"
                                sx={{
                                    '@media (max-width: 360px)': {
                                        gridColumn: 'span 2 / span 2'
                                    }
                                }}>
                                <CardMedia
                                    onClick={() => history.goBack()}
                                    component="img"
                                    src={LeftArrow}
                                    sx={{
                                        cursor: 'pointer',
                                        zIndex: '0',
                                        marginBottom: '.3rem'
                                    }}
                                />{' '}
                            </Item>
                            <HistoricalPercentageDetails
                                onClick={() => history.goBack()}
                                sx={{
                                    color: 'primary',
                                    cursor: 'pointer',
                                    paddingTop: '.5rem',
                                    gridColumn: 'span 1 / span 1',
                                    lineHeight: '1.2rem',
                                    height: '1rem',
                                    marginLeft: '-3.5rem',
                                    position: 'relative',
                                    zIndex: 5,
                                    '@media (min-width: 600px)': {
                                        marginLeft: '0'
                                    },
                                    '@media (max-width: 580px)': {
                                        marginLeft: '.1rem'
                                    },
                                    '@media (max-width: 420px)': {
                                        marginLeft: '-1rem'
                                    },
                                    '@media (max-width: 361px)': {
                                        marginLeft: '-2rem'
                                    },
                                    '@media (min-width: 1200px)': {
                                        marginLeft: '-5rem'
                                    },
                                    '@media (min-width: 1400px)': {
                                        marginLeft: '-7rem'
                                    }
                                }}>
                                Back
                            </HistoricalPercentageDetails>
                            <HistoricalAppreciation
                                sx={{
                                    gridColumn: 'span 5 / span 5',
                                    '@media (max-width: 600px)': {
                                        gridColumn: 'span 3 / span 3'
                                    },
                                    '@media (max-width: 360px)': {
                                        gridColumn: 'unset'
                                    }
                                }}></HistoricalAppreciation>
                            <HistoricalPercentage
                                sx={{
                                    fontFamily: 'Jost',
                                    fontStyle: 'normal',
                                    fontWeight: 400,
                                    fontSize: '14px',
                                    textAlign: 'center',
                                    paddingTop: '.5rem',
                                    gridColumn: 'span 5 / span 5',
                                    lineHeight: '1.2rem',
                                    height: '1rem',
                                    '@media (max-width: 600px)': {
                                        gridColumn: 'span 7 / span 7'
                                    },
                                    '@media (max-width: 300px)': {
                                        gridColumn: 'span 7 / span 7',
                                        marginLeft: '-4rem'
                                    }
                                }}>
                                Initial Drop Status:
                                <HistoricalPercentageDetails
                                    style={{
                                        color: 'red',
                                        paddingTop: '.5rem',
                                        lineHeight: '1.2rem',
                                        height: '1rem'
                                    }}>
                                    {' '}
                                    CLOSED
                                </HistoricalPercentageDetails>
                            </HistoricalPercentage>
                        </Grid>
                        <Grid
                            sx={{
                                width: '100%',
                                height: 'fit-content',
                                display: 'grid',
                                gridTemplateColumns:
                                    'repeat(3, minmax(0, 3fr))',
                                gridTemplateRows:
                                    'grid-template-rows: repeat(3, minmax(0, 1fr))',
                                paddingBottom: '20px',
                                justifyContent: 'flex-start',
                                marginBottom: '-10vh',
                                marginTop: '.5vh'
                            }}>
                            {/* Holdings */}
                            <Item
                                item
                                sx={{
                                    display: 'grid',
                                    alignItems: 'center'
                                }}>
                                <HistoricalPercentage
                                    sx={{
                                        textAlign: 'start',
                                        fontSize: '.88rem',
                                        'line-height': '1.5rem',
                                        '@media (max-width: 600px)': {
                                            fontSize: '.5rem',
                                            'line-height': '.9rem'
                                        }
                                    }}>
                                    <span>MY HOLDINGS: </span>
                                    <span style={{ fontWeight: 'bold' }}>
                                        500{' '}
                                    </span>
                                    <br></br>
                                    MY LISTED HOLDINGS:{' '}
                                    <span style={{ fontWeight: 'bold' }}>
                                        100{' '}
                                    </span>
                                </HistoricalPercentage>
                            </Item>
                            {/* historical appreciation */}
                            <Item
                                style={{
                                    display: 'grid',
                                    placeContent: 'center'
                                }}>
                                <HistoricalPercentage
                                    sx={{
                                        '@media (min-width: 940px)': {
                                            fontSize: '.88rem',
                                            marginTop: '3rem'
                                        },
                                        '@media (max-width: 600px)': {
                                            marginTop: '1.1rem',
                                            fontSize: '.5rem',
                                            'line-height': '.9rem'
                                        },
                                        '@media (min-width: 601px)': {
                                            marginTop: '1.1rem',
                                            fontSize: '.7rem',
                                            'line-height': '1.5rem'
                                        }
                                    }}>
                                    <span
                                        style={{
                                            textAlign: 'start',

                                            textTransform: 'uppercase'
                                        }}>
                                        Historical Appreciation: {''} <br></br>{' '}
                                    </span>
                                    <HistoricalPercentage
                                        sx={{
                                            fontSize: '2rem',
                                            'line-height': '1.5rem',
                                            '@media (max-width: 600px)': {
                                                fontSize: '1.4rem',
                                                'line-height': '1.5rem'
                                            }
                                        }}>
                                        13.83%
                                    </HistoricalPercentage>

                                    <HistoricalPercentageDetails
                                        sx={{
                                            '@media (max-width: 600px)': {
                                                fontSize: '.5rem',
                                                'line-height': '.9rem'
                                            }
                                        }}>
                                        <br></br> -$1,000 (3.5%){' '}
                                        <HistoricalPercentage
                                            sx={{
                                                fontFamily: 'Jost',
                                                fontStyle: 'normal',
                                                fontWeight: 400,
                                                fontSize: '16px',
                                                textAlign: 'center',
                                                '@media (max-width: 600px)': {
                                                    fontSize: '.5rem',
                                                    'line-height': '.9rem'
                                                }
                                            }}>
                                            TODAY
                                        </HistoricalPercentage>
                                    </HistoricalPercentageDetails>
                                </HistoricalPercentage>
                            </Item>
                            {/* Auction hisitory */}
                            <Item
                                item
                                style={{
                                    width: '100%',
                                    textTransform: 'uppercase',
                                    alignItems: 'center'
                                }}>
                                <HistoricalPercentage
                                    sx={{
                                        width: '100%',
                                        textAlign: 'center',
                                        fontSize: '.88rem',
                                        'line-height': '1.2rem',
                                        '@media (max-width: 600px)': {
                                            fontSize: '.5rem',
                                            'line-height': '.9rem'
                                        }
                                    }}>
                                    <p>Auction History</p>
                                    <p
                                        style={{
                                            fontWeight: 'bold',
                                            textTransform: 'capitalize'
                                        }}>
                                        30 Days
                                    </p>
                                </HistoricalPercentage>
                            </Item>
                        </Grid>
                        {/* chart */}
                        <Item
                            style={{
                                height: '300px',
                                zIndex: '10',
                                overflow: 'auto'
                            }}
                            item
                            xs={12}>
                            <StakingChart
                                domain={{ y: [1, 1.25] }}
                                timeAgoFilter={timeAgoFilter}
                                chartPadding={{ left: 0, right: 100 }}
                                fontSize="30px"
                                offsetX={90}
                            />
                        </Item>
                        <Item
                            item
                            style={{
                                marginTop: '-4vh',
                                textAlign: 'start',
                                width: '95vw'
                            }}>
                            {filters.map((f) => (
                                <TimeFilterDiv
                                    key={f}
                                    sx={
                                        timeAgoFilter !== f
                                            ? {
                                                  '@media (max-width: 600px)': {
                                                      fontSize: '.5rem',
                                                      'line-height': '.9rem'
                                                  }
                                              }
                                            : {
                                                  background: '#3C4858',
                                                  borderRadius: '5px',
                                                  '@media (max-width: 600px)': {
                                                      fontSize: '.5rem',
                                                      'line-height': '.9rem'
                                                  }
                                              }
                                    }
                                    onClick={() => setTimeAgoFilter(f)}>
                                    {f}
                                </TimeFilterDiv>
                            ))}
                        </Item>
                        <Item item xs={12}>
                            <ColoredDivider />
                        </Item>
                    </div>
                    <Grid
                        container
                        sx={{
                            fontSize: '12px',
                            display: 'grid',
                            marginTop: '-20vh',
                            'grid-template-columns':
                                'repeat(12, minmax(0, 1fr))',
                            gap: '1.6rem',
                            '@media (max-width: 600px)': {
                                fontSize: '.5rem',
                                'line-height': '.9rem'
                            }
                        }}>
                        <FlexItem
                            item
                            sx={{ 'grid-column': 'span 2 / span 2' }}>
                            <StakingDetailsTitle>
                                Available Holdings
                            </StakingDetailsTitle>
                            <StakingDetailsValue>500</StakingDetailsValue>
                        </FlexItem>
                        <FlexItem
                            item
                            sx={{ 'grid-column': 'span 2 / span 2' }}>
                            <StakingDetailsTitle>Ask Price</StakingDetailsTitle>
                            <StakingDetailsValue>$24.75</StakingDetailsValue>
                        </FlexItem>
                        <FlexItem
                            item
                            sx={{ 'grid-column': 'span 2 / span 2' }}>
                            <StakingDetailsTitle>
                                Purchase Price
                            </StakingDetailsTitle>
                            <StakingDetailsValue>
                                ${purchasePrice}
                            </StakingDetailsValue>
                        </FlexItem>
                        <FlexItem
                            item
                            sx={{ 'grid-column': 'span 2 / span 2' }}>
                            <StakingDetailsTitle>
                                Sale Price
                            </StakingDetailsTitle>
                            <StakingDetailsValue>
                                ${salePrice}
                            </StakingDetailsValue>
                        </FlexItem>
                        <FlexItem
                            item
                            sx={{
                                'grid-column': 'span 4 / span 4',
                                '@media (max-width: 300px)': {
                                    'grid-column': 'span 3 / span 3'
                                }
                            }}>
                            <StakingDetailsTitle>
                                Avg. Historical Return
                            </StakingDetailsTitle>
                            <StakingDetailsValue>110%</StakingDetailsValue>
                        </FlexItem>
                    </Grid>
                    <Grid
                        sx={{
                            display: 'grid',
                            'grid-template-columns':
                                'repeat(11, minmax(0, 1fr))',
                            width: '100%',
                            margin: '30px',
                            paddingBottom: '50vh',
                            '@media (max-width: 540px)': {
                                paddingBottom: '0px'
                            }
                        }}
                        item>
                        <Item
                            item
                            sx={{
                                'grid-column': 'span 1 / span 1;'
                            }}></Item>
                        <EditHoldingsBtn
                            sx={{
                                'grid-column': 'span 4 / span 4;',
                                '@media (max-width: 790px)': {
                                    fontSize: '.5rem',
                                    'line-height': '.9rem'
                                },
                                '@media (max-width: 390px)': {
                                    fontSize: '.43rem',
                                    'line-height': '.8rem'
                                },
                                '@media (max-width: 300px)': {
                                    fontSize: '.3rem',
                                    'line-height': '.8rem'
                                }
                            }}>
                            Edit Listed Holdings
                        </EditHoldingsBtn>
                        <SellSharesButtonDiv
                            sx={{
                                'grid-column': 'span 3 / span 3;',
                                '@media (max-width: 790px)': {
                                    fontSize: '.5rem',
                                    'line-height': '.9rem'
                                },
                                '@media (max-width: 390px)': {
                                    fontSize: '.43rem',
                                    'line-height': '.8rem'
                                }
                            }}>
                            Sell Shares
                        </SellSharesButtonDiv>
                        <InvestNowButtonDiv
                            onClick={handleOpenModal}
                            sx={{
                                'grid-column': 'span 3 / span 3;',
                                '@media (max-width: 790px)': {
                                    fontSize: '.5rem',
                                    'line-height': '.9rem'
                                },
                                '@media (max-width: 390px)': {
                                    fontSize: '.43rem',
                                    'line-height': '.8rem'
                                }
                            }}>
                            Invest Now
                        </InvestNowButtonDiv>
                        <MerchantInvestModal
                            open={openModal}
                            handleClose={handleCloseModal}
                        />
                    </Grid>
                </GradientCard>
            </Item>
        </Grid>
    );
};
