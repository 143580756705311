import { Menu } from 'react-pro-sidebar';

import BiotechIcon from '@mui/icons-material/Biotech';
import CategoryIcon from '@mui/icons-material/CategoryOutlined';
import AutoGraphIcon from '@mui/icons-material/AutoGraphOutlined';
import GavelIcon from '@mui/icons-material/GavelOutlined';
import ExtensionIcon from '@mui/icons-material/ExtensionOutlined';
import DiamondIcon from '@mui/icons-material/DiamondOutlined';

import { MenuLink } from 'features/sidebar/MenuLink';
import { useUserBidsDrawer } from 'hooks/useUserBidsDrawer';
import { ReactComponent as WatchIcon } from 'assets/watch.svg';
import { ReactComponent as WalletIcon } from 'assets/wallet.svg';
import { ReactComponent as StakingIcon } from 'assets/staking.svg';
// import { ReactComponent as AuctionIcon } from 'assets/auction.svg';
import { ReactComponent as SettingsIcon } from 'assets/settings.svg';
import { ReactComponent as SupportIcon } from 'assets/support.svg';
import { ReactComponent as PortfolioIcon } from 'assets/portfolio.svg';
// import { ReactComponent as DocumentsIcon } from 'assets/documents.svg';
// import { ReactComponent as MarketplaceIcon } from 'assets/marketplace.svg';
// import { ReactComponent as Dollar } from 'assets/dollar.svg';
import { useAppSelector } from 'hooks/useAppSelector';
import { isUserBuilder } from 'state/user/selectors';
import styled from 'styled-components';
import { User } from 'config/types/user';

type Props = {
    account: User;
    handleCollapse: () => void;
};

export const ProtectedSidebar = ({
    account,
    handleCollapse
}: Props): JSX.Element => {
    const [openUserBidsDrawer] = useUserBidsDrawer();

    const isBuilder = useAppSelector(isUserBuilder);
    const MenuContainer = styled(Menu)(() => ({
        background: account?.isAdmin
            ? 'linear-gradient(180deg, #3C4858 0%, #151516 58.85%)'
            : 'linear-gradient(179.14deg, #FFFFFF 0.73%, #F5F5F5 121.08%)'
    }));
    return (
        <MenuContainer iconShape="circle">
            <MenuLink
                Icon={StakingIcon}
                title="myBids"
                onClick={openUserBidsDrawer}
            />
            <MenuLink
                Icon={PortfolioIcon}
                path="/portfolio"
                title="portfolio"
                onClick={handleCollapse}
            />
            <MenuLink
                Icon={ExtensionIcon}
                path="/investing"
                title="investing"
            />
            <MenuLink
                Icon={CategoryIcon}
                path="/item-gallery"
                title="item gallery"
            />
            <MenuLink
                Icon={GavelIcon}
                path="/marketplace"
                title="marketplace"
            />
            <MenuLink
                Icon={DiamondIcon}
                path="/merchant-assets"
                title="assets"
            />
            <MenuLink
                Icon={AutoGraphIcon}
                path="/staking"
                title="staking"
                iconStyle={{ transform: 'scale(1.6)', marginLeft: '-4px' }}
            />
            <MenuLink
                Icon={WalletIcon}
                path={`/my-wallet/${account.account}`}
                title="wallet"
            />
            <MenuLink Icon={SupportIcon} path="/contact-us" title="contactUs" />
            <MenuLink Icon={SettingsIcon} path="/settings" title="settings" />
            {isBuilder && (
                <MenuLink Icon={BiotechIcon} path="/test" title="test" />
            )}
        </MenuContainer>
    );
};
