import { Typography } from 'components/Typography';

export const SoldBanner = (): JSX.Element => (
    <Typography
        bold
        uppercased
        sx={{
            py: 1,
            px: 3,
            left: '-40px',
            position: 'relative',
            backgroundColor: 'error.main'
        }}
        textId="sold"
        variant="body1"
    />
);
