import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';

import { Row } from 'components/Row';
import { DarkButton } from 'components/buttons/DarkButton';
import { BlackButton } from 'components/buttons/BlackButton';
import { LightButton } from 'components/buttons/LightButton';
import { TableCell } from 'components/table/TableCell';
import { TableRow } from 'components/table/TableRow';
import { TableHeader } from 'components/table/TableHeader';
import { TableCellWithToolip } from 'components/table/TableCellWithToolip';
import { ProductStatusLabel } from 'components/ProductStatusLabel';
import { portfolioItemsData } from 'dummy/portfolioItemsData';
import { PortfolioItemsData, ProductStatus } from 'config/types';
import { AssetItemsData } from 'config/types';

import { DatabaseService } from 'services/DatabaseService';
import { useEffect, useState, useContext } from 'react';
import { AssetItem } from 'config/types/asset';
import { BlockChainContext } from 'blockchain/BlockchainContext';
import { getAllAssets } from 'state/assets/selectors';
import { dollarsFromTokens } from 'libs/utils';

// TO REMOVE
const list = portfolioItemsData.slice(20, 40);

const headers = [
    'product',
    'sold to',
    'date created',
    'price',
    'status',
    'net IRR',
    'payment type',
    'actions'
];

const mockNames = [
    'Sarah Johnson',
    'Jacob Rodriguez',
    'Maya Patel',
    'Ethan Davis',
    'Ava Thompson'
];

const mockPaymentTypes = [
    'Credit Card',
    'Debit Card',
    'Bank Transfer',
    'LVC Coins'
];

const actionsToButton: Record<ProductStatus, JSX.Element> = {
    sold: <LightButton fullWidth textId="payout info" />,
    purchased: <LightButton textId="purchased" />,
    newListing: <LightButton textId="new listing" />,
    listed: (
        <Row spacing={1}>
            <DarkButton fullWidth textId="edit" />
            <BlackButton fullWidth textId="delete" />
        </Row>
    ),
    notListed: (
        <Row spacing={1}>
            <DarkButton fullWidth textId="edit" />
            <BlackButton fullWidth textId="delete" />
        </Row>
    ),
    denied: <LightButton fullWidth textId="item info" />,
    processing: <LightButton fullWidth textId="processing" />,
    pending: (
        <Row spacing={1}>
            <DarkButton fullWidth textId="edit" />
            <BlackButton fullWidth textId="delete" />
        </Row>
    )
};
export const ItemsTable = (): JSX.Element => {
    const [holdAllAssets, setHoldAllAssets] = useState<AssetItem[]>([]);
    const { marketplace } = useContext(BlockChainContext);
    const [latestBids, setLatestBids] = useState<Map<number, number>>(
        new Map()
    );

    const getLatestBid = async (nftId: number): Promise<number> => {
        const listing: any = await marketplace?.getListingData(nftId);
        const bidRef = listing?.bidRef;
        const biddingObject: any = await marketplace?.getBidData(bidRef);
        if (biddingObject) {
            const latestBid = dollarsFromTokens(biddingObject.value);
            return latestBid;
        } else {
            return 0;
        }
    };

    const formatDate = (dateString: string): string => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${month}/${day}/${year}`;
    };

    const fetchLatestBids = async (assets: AssetItem[]): Promise<void> => {
        const bids = new Map<number, number>();
        for (const asset of assets) {
            const latestBid = await getLatestBid(asset.nftId);
            bids.set(asset.nftId, latestBid);
        }
        setLatestBids(bids);
    };

    const getRandom = (array: String[]): String => {
        const randomIndex = Math.floor(Math.random() * array.length);
        return array[randomIndex];
    };

    useEffect(() => {
        const fetchAssets = async (): Promise<void> => {
            const allAssets = await DatabaseService.getAllAssets();
            setHoldAllAssets(allAssets);
            fetchLatestBids(allAssets);
        };

        fetchAssets();
    }, []);

    return (
        <Table stickyHeader size="small" aria-label="portfolio">
            <TableHeader headers={headers} />
            <TableBody>
                {holdAllAssets.map((row: any) => (
                    <TableRow key={row.id}>
                        <TableCellWithToolip maxWidth="400px" title={row.name}>
                            <b>{row.name}</b>
                        </TableCellWithToolip>
                        <TableCell>
                            {row.status === 'sold'
                                ? getRandom(mockNames)
                                : '----'}
                        </TableCell>
                        <TableCell>{formatDate(row.createdAt)}</TableCell>
                        <TableCell>
                            {row.status === 'listed'
                                ? `$ ${
                                      latestBids
                                          .get(row.nftId)
                                          ?.toLocaleString() || '----'
                                  }`
                                : row.price
                                ? `$ ${row.price?.toLocaleString()}`
                                : '----'}
                        </TableCell>

                        <TableCell>
                            <ProductStatusLabel fullWidth status={row.status} />
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell>{getRandom(mockPaymentTypes)}</TableCell>
                        {/* @ts-ignore */}
                        <TableCell>{actionsToButton[row.status]}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};
