import { Box, CircularProgress, circularProgressClasses } from '@mui/material';

type Props = {
    size?: number;
};

export const Spinner = ({ size }: Props): JSX.Element => {
    return (
        <Box
            sx={{
                display: 'flex',
                position: 'relative',
                alignItems: 'center',
                justifyContent: 'center',
                width: '80px'
            }}>
            <CircularProgress
                variant="determinate"
                sx={{ color: 'common.white' }}
                size={size ?? 17}
                thickness={6}
                value={100}
            />
            <CircularProgress
                variant="indeterminate"
                disableShrink
                sx={{
                    color: 'secondary.main',
                    animationDuration: '550ms',
                    position: 'absolute',
                    left: '50%',
                    marginLeft: '-8.5px',
                    [`& .${circularProgressClasses.circle}`]: {
                        strokeLinecap: 'round'
                    }
                }}
                size={size ?? 17}
                thickness={6}
            />
        </Box>
    );
};
