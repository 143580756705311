import { Typography } from 'components/Typography';

type Props = React.ComponentProps<typeof Typography> & {
    data?: string | number;
    prefix?: string;
    suffix?: string;
    local?: boolean;
    children?: React.ReactNode;
};

export const DataPoint = ({
    data,
    prefix,
    suffix,
    local = true,
    children,
    ...other
}: Props): JSX.Element => {
    if (!data) {
        return (
            <Typography
                sx={{
                    fontStyle: 'italic',
                    color: 'text.secondary',
                    '@media (max-width: 290px)': {
                        fontSize: '.75rem',
                        'line-height': '.9rem'
                    }
                }}
                textId="noData"
                variant="body2"
                capitalized
            />
        );
    }

    if (children) {
        return <Typography {...other}>{children}</Typography>;
    }

    let entry =
        local && typeof data === 'number' ? data.toLocaleString() : data;

    if (prefix) entry = `${prefix}${entry}`;

    if (suffix) entry = `${entry}${suffix}`;

    if (children) entry = `${entry}${children}`;

    return <Typography {...other}>{entry}</Typography>;
};
