import Stack from '@mui/material/Stack';

type Props = React.ComponentProps<typeof Stack> & {
    children: React.ReactNode;
};

export const Row = ({ children, ...other }: Props): JSX.Element => (
    <Stack direction="row" {...other}>
        {children}
    </Stack>
);
