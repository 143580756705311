import { useContext } from 'react';
import { BlockChainContext } from 'blockchain/BlockchainContext';
import { useAsyncAction } from './useAsyncAction';

type MintParams = {
    recipient: string;
    tokenURI: string;
};

type ApproveParams = {
    to: string;
    nftId: number;
};

type SetTokenParams = {
    nftId: number;
    tokenURI: string;
};

type StateLogic = {
    minting: boolean;
    approving: boolean;
    updatingURI: boolean;
    mintNFT: (params: MintParams) => Promise<number>;
    approveAddress: (params: ApproveParams) => Promise<void>;
    updateURI: (params: SetTokenParams) => Promise<void>;
};

export const useNFTTokenService = (): StateLogic => {
    const { nft } = useContext(BlockChainContext);

    const mint = async ({
        recipient,
        tokenURI
    }: MintParams): Promise<number> => {
        return nft!.mint(recipient, tokenURI);
    };

    const [mintNFT, minting] = useAsyncAction<MintParams, number>(mint, {
        info: 'mintingProcessLaunched',
        error: 'errorMintingAssetNFT',
        success: 'successMintingAssetNFT'
    });

    const approveAction = async ({ to, nftId }: ApproveParams): Promise<void> =>
        nft!.approve(to, nftId);

    const [approveAddress, approving] = useAsyncAction<ApproveParams, void>(
        approveAction,
        {
            error: 'errorApprovingContract'
        }
    );

    const setTokenURI = async ({
        nftId,
        tokenURI
    }: SetTokenParams): Promise<void> => {
        return nft!.setTokenURI(nftId, tokenURI);
    };

    const [updateURI, updatingURI] = useAsyncAction<SetTokenParams, void>(
        setTokenURI,
        {
            info: 'Updating NFT URI...',
            error: 'Error updating NFT URI',
            success: 'Success updating NFT URI'
        }
    );

    return {
        minting,
        approving,
        mintNFT,
        approveAddress,
        updateURI,
        updatingURI
    };
};
