import { ProductDataPoint } from 'components/dataPoints/ProductDataPoint';

type Props = {
    data?: number;
};

export const QuantityDataPoint = ({ data }: Props): JSX.Element => (
    <ProductDataPoint textId="quantity" showMissing={!data}>
        {data?.toLocaleString()}
    </ProductDataPoint>
);
