import { Link } from 'react-router-dom';

import { LightButton } from 'components/buttons/LightButton';
import { MerchantAssetModal } from 'config/enums/merchantAssetModal';

type Props = {
    id: string;
};

export const EditButton = ({ id }: Props): JSX.Element => (
    <Link to={`/merchant-assets/${MerchantAssetModal.editAssetData}/${id}`}>
        <LightButton textId="edit" />
    </Link>
);
