import { styled } from '@mui/material/styles';

import { Space } from 'components/Space';
import { Typography } from 'components/Typography';

type Props = {
    textId: string;
    children: React.ReactNode;
};

const Container = styled('div')`
    display: flex;
    align-items: baseline;
    margin-top: 20px;
`;

export const DataRow = ({ textId, children }: Props): JSX.Element => (
    <Container>
        <Typography allCapitalized textId={textId} />
        <Typography>:</Typography>
        <Space width="5px" />
        {children}
    </Container>
);
