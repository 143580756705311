import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import { styled } from '@mui/material/styles';
import TableBody from '@mui/material/TableBody';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { AssetItem } from 'config/types/asset';
import { Typography } from 'components/Typography';
import { TableHeader } from 'components/table/TableHeader';
import { TableContainer } from 'components/TableContainer';
import { FetchingScreen } from 'components/FetchingScreen';
import { EditAssetDataModal } from 'features/editAssetDataModal/EditAssetDataModal';
import { MerchantAssetModal } from 'config/enums/merchantAssetModal';
import { CreateAssetListingModal } from 'features/createAssetListingModal/CreateAssetListingModal';
import { AssetAuthenticationFormModal } from 'features/assetAuthenticationFormModal/AssetAuthenticationFormModal';

import { MerchantAssetRecord } from './MerchantAssetRecord';
import { useMerchantAssetsTable } from './useMerchantAssetsTable';

import { getUserData } from 'state/user/selectors';
import { User } from 'config/types/user';
import { useAppSelector } from 'hooks/useAppSelector';

const headers: string[] = [
    'item',
    'category',
    'authenticationStatus',
    'status',
    'documents',
    'pictures',
    'edit'
];

export const MerchantAssetsTable = (): JSX.Element => {
    const {
        list,
        fetching,
        openedModal,
        selectedAssetId,
        handleOpenModal,
        handleCloseModal
    } = useMerchantAssetsTable();

    const user: User = useAppSelector(getUserData);
    const customer = user.isAdmin === false;

    const getTitleBackground = (isCustomer: boolean): string => {
        return isCustomer
            ? 'linear-gradient(179.14deg, #FFFFFF 0.73%, #F5F5F5 121.08%)'
            : 'linear-gradient(180deg, #151516 33.58%, #343434 123.98%)';
    };

    const Title = styled(Box)`
        width: 90vw;
        margin: '0';
        padding: 2px;
        text-align: center;
        border-radius: 10px 10px 0 0;
        background: ${() => getTitleBackground(customer)};
    `;
    const Body = styled(TableBody)(({ theme }) => ({
        '& .MuiTableCell-root': {
            color: theme.palette.primary.contrastText,
            backgroundImage: theme.palette.success.contrastText
        }
    }));

    return (
        <FetchingScreen<AssetItem[]>
            fullscreen
            data={list}
            empty={!list || !list.length}
            fetching={fetching}
            message="noAssetsFound">
            {(data: AssetItem[]) => (
                <>
                    <Box
                        sx={{
                            paddingTop: '15px',
                            margin: 'auto',
                            width: '90vw',
                            paddingRight: '-12px',
                            '@media (max-width: 1000px)': {
                                margin: '4px',
                                width: '80vw'
                            },
                            '@media (max-width: 899px)': {
                                width: '95vw',
                                marginLeft: '20px',
                                paddingX: '16px'
                            },
                            '@media (max-width: 542px)': {
                                paddingTop: '3rem',
                                margin: '0 auto',
                                width: '98vw'
                            },
                            '@media (max-width: 420px)': {
                                paddingTop: '15px',
                                margin: '0 auto'
                            }
                        }}>
                        <Title id="your-assets-header">
                            <Typography
                                textId="yourAssets"
                                variant="h6"
                                allCapitalized
                            />
                        </Title>
                        <TableContainer userType={user.isAdmin}>
                            <PerfectScrollbar>
                                <Table
                                    stickyHeader
                                    size="small"
                                    aria-label="portfolio">
                                    <TableHeader
                                        alignText="center"
                                        headers={headers}
                                    />
                                    <Body>
                                        {data.map((asset: AssetItem) => (
                                            <MerchantAssetRecord
                                                key={asset.id}
                                                data={asset}
                                                onAction={(cnt) =>
                                                    handleOpenModal(
                                                        asset.id,
                                                        cnt
                                                    )
                                                }
                                            />
                                        ))}
                                    </Body>
                                </Table>
                            </PerfectScrollbar>
                        </TableContainer>
                    </Box>
                    {selectedAssetId && (
                        <>
                            <AssetAuthenticationFormModal
                                open={
                                    openedModal ===
                                    MerchantAssetModal.assetAuthentication
                                }
                                assetId={selectedAssetId}
                                handleClose={handleCloseModal}
                            />
                            <CreateAssetListingModal
                                open={
                                    openedModal ===
                                    MerchantAssetModal.createAssetListing
                                }
                                assetId={selectedAssetId}
                                handleClose={handleCloseModal}
                                data={data}
                            />
                            <EditAssetDataModal
                                open={
                                    openedModal ===
                                    MerchantAssetModal.editAssetData
                                }
                                assetId={selectedAssetId}
                                handleClose={handleCloseModal}
                            />
                        </>
                    )}
                </>
            )}
        </FetchingScreen>
    );
};
