import { Bid } from 'config/types';
import { Spinner } from 'components/Spinner';
import { Typography } from 'components/Typography';
import { LightButton } from 'components/buttons/LightButton';

type Props = {
    data: Bid[];
    fetching: boolean;
    onClick: () => void;
};

export const ShowBidsButton = ({
    data,
    fetching,
    onClick
}: Props): JSX.Element => {
    if (fetching) {
        return <Spinner />;
    }

    if (data && data.length) {
        return <LightButton textId="bids" onClick={onClick} />;
    }

    return <Typography color="primary.light" textId="noBids" />;
};
