import { styled } from '@mui/material/styles';
import MuiTableContainer from '@mui/material/TableContainer';

export const TableContainer = styled(MuiTableContainer)<{
    height?: string;
    userType?: boolean;
}>`
    width: 90vw;
    height: ${(props) => props.height ?? 'calc(100vh - 140px)'};
    background: ${(props) =>
        props.userType === false
            ? 'linear-gradient(179.14deg, #ffffff 0.73%, #F5F5F5 121.08%)'
            : 'linear-gradient(180deg, #3c4858 0%, #151516 58.85%)'};
`;
