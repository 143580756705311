import { Box } from '@mui/material';
import CardContent from '@mui/material/CardContent';
// import CardMedia from '@mui/material/CardMedia';
import { styled } from '@mui/material/styles';
import MuiCard from '@mui/material/Card';
import Typography from '@mui/material/Typography';
// import BrokenImageOutlinedIcon from '@mui/icons-material/BrokenImageOutlined';

import { Row } from 'components/Row';
import { Space } from 'components/Space';
import { AssetData } from 'config/types/asset';
import { defaultCurrencySymbol } from 'config';
import { ReactComponent as WatchImage } from 'assets/watch-example.svg';
import { ProductChart } from 'features/dataCards/productChartCard/ProductChart';

import { victoryChartData } from 'dummy/chartData';
import { LightDivider } from 'components/LightDivider';

type Props<T> = {
    data: T;
    onClick?: () => void;
};

const Card = styled(MuiCard)`
    max-width: 300px;
    min-width: 250px;
    border-radius: 5px;
    background: linear-gradient(180deg, #212121 0%, rgba(33, 33, 33, 0) 100%);
`;

// const B`rokenImagePlaceholder = styled('div')({
//     width: '100%',
//     height: '140px',
//     display: 'flex',
//     alignItems: 'center',
//     background: '#eee',
//     borderRadius: '5px',
//     justifyContent: 'center'
// });

export const ProductChartCard = <T extends AssetData>({
    data,
    onClick
}: Props<T>): JSX.Element => (
    <Card onClick={onClick} sx={{ cursor: 'pointer' }}>
        {/* {brokenImage ? (
                <BrokenImagePlaceholder>
                    <BrokenImageOutlinedIcon fontSize="large" />
                </BrokenImagePlaceholder>
            ) : */}
        <CardContent>
            <Row>
                <WatchImage width={70} height={90} />
                <Space width="20px" />
                <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="body1" sx={{ height: 65 }}>
                        {data.name.toUpperCase()}
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{ fontWeight: 600, textAlign: 'right' }}>
                        {defaultCurrencySymbol}
                        {data?.salesPrice?.toLocaleString()}
                    </Typography>
                </Box>
            </Row>
            <Space height="10px" />
            <LightDivider />
            <Space height="10px" />
            <div style={{ height: 150 }}>
                <ProductChart dates={victoryChartData.slice(0, 30)} />
            </div>
        </CardContent>
    </Card>
);
