import { useState } from 'react';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { useAppSelector } from 'hooks/useAppSelector';
import { User } from 'config/types/user';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { getUserData } from 'state/user/selectors';
import { SupportForm } from 'components/SupportForm';

const GradientCard = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    width: '100%',
    background: 'linear-gradient(180deg, #3C4858 0%, #151516 58.85%)',
    boxShadow: '1px 0px 10px rgba(0, 0, 0, 0.42)',
    borderRadius: '5px',
    padding: '60px',
    [theme.breakpoints.down('md')]: {
        maxHeight: '100%',
        padding: '20px'
    }
}));

const Item = styled(Grid)(({ theme }) => ({
    maxWidth: '100%',
    padding: '20px 0px',
    [theme.breakpoints.down('md')]: {
        maxWidth: '100%'
    }
}));

const SettingsSectionTitle = styled('h1')(() => ({
    fontFamily: 'Jost',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '29px',
    lineHeight: '42px',
    textTransform: 'uppercase',
    textAlign: 'left',
    color: '#FFFFFF'
}));

const SettingsSectionSubTitle = styled('span')(() => ({
    fontFamily: 'Jost',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '21px',
    lineHeight: '42px',
    textAlign: 'left',
    color: '#FFFFFF',
    display: 'flex',
    marginBottom: '20px'
}));

export const SupportPage = (): JSX.Element => {
    const u: User = useAppSelector(getUserData);
    const { email } = u;
    const firstName = u.name?.split(' ')[0];
    const lastName = u.name?.split(' ')[1];
    const [associatedId, setAssociatedId] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const [messageSent, setMessageSent] = useState<boolean>(false);
    return (
        <Grid container>
            <Item item xs={12}>
                <GradientCard>
                    <Grid container>
                        <Item item xs={12}>
                            <SettingsSectionTitle>
                                Contact us
                            </SettingsSectionTitle>
                            <SettingsSectionSubTitle>
                                Fill out the form and our Team will get back to
                                you within 24 hours.
                            </SettingsSectionSubTitle>
                            <SupportForm
                                firstName={firstName || ''}
                                lastName={lastName || ''}
                                email={email || ''}
                                message={message}
                                setMessage={setMessage}
                                associatedId={associatedId}
                                setAssociatedId={setAssociatedId}
                                setMessageSent={setMessageSent}
                            />
                        </Item>
                    </Grid>
                </GradientCard>
            </Item>

            <Snackbar
                sx={{ zIndex: 3000, maxWidth: 400 }}
                open={messageSent}
                autoHideDuration={10000}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert
                    onClose={() => setMessageSent(false)}
                    severity="success"
                    elevation={0}>
                    Successfully received your support message.
                </Alert>
            </Snackbar>
        </Grid>
    );
};
