import ImageListItem from '@mui/material/ImageListItem';

import { AssetMediaMetadata } from 'config/types/asset';

import { NoPictureMessage } from './NoPictureMessage';

type Props = {
    data: AssetMediaMetadata[];
};

export const AssetPictures = ({ data }: Props): JSX.Element =>
    data && data.length ? (
        <ImageListItem>
            <img
                alt={data[0].name}
                src={data[0].uri}
                srcSet={data[0].uri}
                loading="lazy"
            />
        </ImageListItem>
    ) : (
        <NoPictureMessage />
    );
