import React, { useCallback, useState, useEffect } from 'react';
import arrow from '../assets/arrow.svg';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { useTheme } from '@mui/material';

// interfaces
interface CarouselProps {
    text: boolean;
    imageSelection: boolean;
    coverImage: boolean;
    data: {
        name: string;
        preview: string;
    }[];
    buttons: boolean;
    delete: boolean;
    onDelete: (index: number) => void;
    imageIndex: number;
    setImageIndex: (index: number) => void;
    onDataChange: (from: number, to: number) => void;
    getObjectUrl: (item: any) => string;
}

interface ThumbnailProps {
    name: string;
    preview: string;
    index: number;
    imageIndex: number;
    onClick: (index: number) => void;
    getObjectUrl: (item: any) => string;
}

// cover image
const Thumbnail: React.FC<ThumbnailProps> = ({
    name,
    preview,
    index,
    onClick
}) => {
    return (
        <div
            style={{
                backgroundImage: `url(${preview})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                width: '50px',
                height: '50px',
                borderRadius: '10px',
                margin: '3px',
                cursor: 'pointer',
                border: index === 0 ? '2px solid #A5CEE4' : ''
            }}
            onClick={() => onClick(index)}
        />
    );
};

// carousel
export const Carousel = ({
    text: showText,
    imageSelection: allowImageSelection,
    coverImage,
    data,
    buttons: allowButtons,
    delete: canDelete,
    onDelete,
    imageIndex,
    setImageIndex,
    onDataChange,
    getObjectUrl
}: CarouselProps): JSX.Element => {
    // Inside Carousel component
    const [scrollbarVisible, setScrollbarVisible] = useState(false);
    const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(
        null
    );

    const theme = useTheme();

    const updateScrollbarVisibility = (
        element: HTMLDivElement | null
    ): void => {
        if (element) {
            const hasScrollbar = element.scrollWidth > element.clientWidth;
            setScrollbarVisible(hasScrollbar);
        }
    };

    useEffect(() => {
        updateScrollbarVisibility(containerRef);
    }, [data, containerRef]);

    const handleLeftClick = (): void => {
        if (imageIndex <= 0) {
            setImageIndex(data.length - 1);
        } else {
            setImageIndex(imageIndex - 1);
        }
    };
    const handleRightClick = (): void => {
        if (imageIndex >= data.length - 1) {
            setImageIndex(0);
        } else {
            setImageIndex(imageIndex + 1);
        }
    };

    const handleImageClick = (
        event: React.MouseEvent<HTMLImageElement>
    ): void => {
        const rect = event.currentTarget.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const halfWidth = rect.width / 2;
        if (x < halfWidth) {
            handleLeftClick();
        } else {
            handleRightClick();
        }
    };

    const onDragEnd = useCallback(
        (result) => {
            if (!result.destination) return;

            onDataChange(result.source.index, result.destination.index);
        },
        [onDataChange]
    );

    return (
        <>
            {/* main image and buttons */}
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                {/* main image */}
                {coverImage && (
                    <div
                        id="main-asset-image"
                        style={{
                            backgroundImage: data?.[imageIndex]
                                ? `url(${data[imageIndex].preview})`
                                : '',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            width: '200px',
                            height: '200px',
                            borderRadius: '10px',
                            margin: '3px',
                            position: 'relative',
                            cursor: 'pointer',
                            border: imageIndex === 0 ? '2px solid #A5CEE4' : ''
                        }}
                        onClick={(event: any) => handleImageClick(event)}>
                        {/* buttons */}
                        {allowButtons && (
                            <img
                                src={arrow}
                                alt="go left"
                                style={{
                                    position: 'absolute',
                                    left: '-20px',
                                    top: '48%',
                                    cursor: 'pointer',
                                    userSelect: 'none'
                                }}
                                onClick={() => handleLeftClick()}
                            />
                        )}
                        {allowButtons && (
                            <img
                                src={arrow}
                                alt="go right"
                                style={{
                                    position: 'absolute',
                                    right: '-20px',
                                    top: '48%',
                                    rotate: '180deg',
                                    cursor: 'pointer',
                                    userSelect: 'none'
                                }}
                                onClick={() => handleRightClick()}
                            />
                        )}
                    </div>
                )}
            </div>
            {/* delete button and text */}
            <div
                style={{
                    width: '100%',
                    height: '30px',
                    marginTop: '0px',
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    marginBottom: '-20px'
                }}>
                <div
                    style={{
                        width: '240px',
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'start',
                        alignContent: 'center',
                        marginLeft: '10px'
                    }}>
                    {/* delete button */}
                    {canDelete && (
                        <HighlightOffIcon
                            style={{
                                cursor: 'pointer'
                            }}
                            sx={{
                                width: 40,
                                color: 'primary.light'
                            }}
                            onClick={() => onDelete(imageIndex)}
                        />
                    )}
                    {/* text */}
                    {showText && (
                        <div
                            style={{
                                color: theme.palette.primary.contrastText
                            }}>
                            {data[imageIndex]?.name
                                ? data[imageIndex]?.name
                                : 'error'}
                        </div>
                    )}
                </div>
            </div>
            {/* all the images */}
            {allowImageSelection && (
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable
                        droppableId="carousel-thumbnails"
                        direction="horizontal">
                        {(provided) => (
                            <div
                                ref={(ref) => {
                                    provided.innerRef(ref);
                                    setContainerRef(ref);
                                }}
                                className={`carousel-images-container ${
                                    scrollbarVisible
                                        ? 'thumbnailContainerLeft'
                                        : 'thumbnailContainerCenter'
                                }`}
                                style={{ marginTop: '30px' }}
                                {...provided.droppableProps}>
                                {data.map((item: any, index: number) => (
                                    <Draggable
                                        key={item.preview}
                                        draggableId={`draggable-${item.preview}`}
                                        index={index}>
                                        {(draggableProvided) => (
                                            <div
                                                ref={draggableProvided.innerRef}
                                                {...draggableProvided.draggableProps}
                                                {...draggableProvided.dragHandleProps}>
                                                <Thumbnail
                                                    name={item.name}
                                                    preview={item.preview}
                                                    index={index}
                                                    imageIndex={imageIndex}
                                                    onClick={setImageIndex}
                                                    getObjectUrl={getObjectUrl}
                                                />
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            )}
        </>
    );
};
