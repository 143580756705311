import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { useEffect } from 'react';

import { AssetItem } from 'config/types/asset';
import { TableHeader } from 'components/table/TableHeader';
import { TableContainer } from 'components/TableContainer';
import { FetchingScreen } from 'components/FetchingScreen';
import { useAppSelector } from 'hooks/useAppSelector';
import { getMerchantAssets } from 'state/merchantAssets/selectors';
import { useAdminFetchAssets } from 'hooks/useAdminFetchAssets';

import { AdminAssetRecord } from './AdminAssetRecord';
import { Row } from 'components/Row';
import styled from 'styled-components';

const headers = [
    'product',
    'vendor',
    'date created',
    'authenticationStatus',
    'status',
    'documents',
    'pictures',
    'bids'
];
const StyledTableContainer = styled(TableContainer)(() => {
    return {
        '&.MuiTableContainer-root': {
            margin: 'auto',
            borderRadius: '10px',
            ' @media (max-width: 1000px)': {
                width: '88vw',
                margin: 'auto'
            },
            ' @media (max-width: 420px)': {
                width: '92vw',
                marginTop: '1.5rem'
            }
        }
    };
});

const GradientCard = styled('div')(({ theme }) => {
    return {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        textAlign: 'center',
        width: '100%',
        background: theme.palette.success.dark,
        boxShadow: '1px 0px 10px rgba(0, 0, 0, 0.42)',
        borderRadius: '5px',
        padding: '60px',
        [theme.breakpoints.down('md')]: {
            maxHeight: '100%',
            padding: '20px'
        }
    };
});

export const AdminAssetTable = (): JSX.Element => {
    const list = useAppSelector(getMerchantAssets);

    const [fetchAdminAssets, fetching] = useAdminFetchAssets();

    useEffect(() => {
        fetchAdminAssets();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <FetchingScreen<AssetItem[]>
            fullscreen
            data={list}
            empty={!list || !list.length}
            style={{ marginLeft: '0' }}
            fetching={fetching}>
            {(data: AssetItem[]) => (
                <StyledTableContainer>
                    <Table size="small" aria-label="portfolio">
                        <TableHeader headers={headers} />
                        <TableBody>
                            {data.map((asset: AssetItem) => (
                                <AdminAssetRecord data={asset} />
                            ))}
                        </TableBody>
                    </Table>
                </StyledTableContainer>
            )}
        </FetchingScreen>
    );
};
