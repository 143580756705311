import Stack from '@mui/material/Stack';

import { Row } from 'components/Row';
import { Typography } from 'components/Typography';
import { useTranslation } from 'react-i18next';

export const SellHoldingsInstructions = (): JSX.Element => {
    const { t } = useTranslation();

    return (
        <Stack spacing={4}>
            <Typography
                variant="body2"
                textId="
                    Be aware that completing more than 3
                    Sell and Invest Shares transactions in a 24 hour period
                    will result in a fee higher than 2%."
            />
            <Typography variant="body2" textId="Do you wish to proceed?" />
            <Row spacing={1}>
                <Typography sx={{ color: 'red' }} bold variant="body2">
                    {t('attention').toUpperCase()}:
                </Typography>
                <Typography
                    bold
                    variant="body2"
                    textId="
                     We do not advise selling for this price.
                     Your holdings are worth more than the
                     Price per Holding amount."
                />
            </Row>
        </Stack>
    );
};
