import { DataPoint } from 'components/DataPoint';

export const FundDataPoint = (
    props: React.ComponentProps<typeof DataPoint>
): JSX.Element => (
    <DataPoint
        sx={{
            '@media (max-width: 290px)': {
                fontSize: '7px'
            }
        }}
        bold
        variant="h5"
        {...props}
    />
);
