import { Row } from 'components/Row';
import { RedButton } from 'components/buttons/RedButton';
import { AssetName } from 'components/AssetName';
import { LightButton } from 'components/buttons/LightButton';
import { MediaItem } from 'components/MediaItem';
import { StandardModal } from 'components/StandardModal';
import { AssetItem, AssetAuthenticationStatus } from 'config/types/asset';

import { useAssetAuthenticationStatusControlModal } from './useAssetAuthenticationStatusControlModal';

type Props = {
    open: boolean;
    data: AssetItem;
    handleClose: () => void;
};

export const AssetAuthenticationStatusControlModal = ({
    data,
    open,
    handleClose
}: Props): JSX.Element => {
    const { onApprove, onDeny, approving, denying } =
        useAssetAuthenticationStatusControlModal(data.id, handleClose);

    const actionButton: Record<AssetAuthenticationStatus, JSX.Element> = {
        authenticated: (
            <RedButton
                fullWidth
                textId="deny"
                onClick={onDeny}
                loading={denying}
                disabled={approving}
            />
        ),
        unauthenticated: <div />,
        denied: (
            <LightButton
                fullWidth
                textId="approve"
                onClick={onApprove}
                loading={approving}
            />
        ),
        pending: (
            <Row spacing={3}>
                <RedButton
                    fullWidth
                    textId="deny"
                    onClick={onDeny}
                    loading={denying}
                    disabled={approving}
                />
                <LightButton
                    fullWidth
                    textId="approve"
                    loading={approving}
                    disabled={denying}
                    onClick={onApprove}
                />
            </Row>
        )
    };

    const { name, authenticationStatus, medias } = data;

    return (
        <StandardModal
            title="authenticationDetails"
            open={open}
            handleClose={handleClose}>
            <AssetName data={name} />
            <Row sx={{ px: 4, mb: 4 }} spacing={4}>
                {medias.map((media) => (
                    <MediaItem key={media.uri} data={media} />
                ))}
            </Row>
            {actionButton[authenticationStatus]}
        </StandardModal>
    );
};
