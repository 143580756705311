import { DropFileAreaWithPreview } from 'features/dropFileArea/DropFileAreaWithPreview';

type Props = {
    disabled: boolean;
    onFileDropped?: () => void;
    onFileRemoved?: () => void;
};

export const CertificateFileInput = ({
    disabled,
    onFileDropped,
    onFileRemoved
}: Props): JSX.Element => (
    <DropFileAreaWithPreview
        textId="uploadCertificateOfOwnershipFile"
        fileType="document"
        disabled={disabled}
        fieldName="certificateOfAuthenticityFile"
        onFileDropped={onFileDropped}
        onFileRemoved={onFileRemoved}
    />
);
