// TODO: Check how wdyr package works
import './wdyr';

import { yellow } from '@mui/material/colors';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { BlockchainProvider } from 'blockchain/BlockchainContext';
import { Provider } from 'react-redux';
import CssBaseline from '@mui/material/CssBaseline';
import { getAnalytics } from 'firebase/analytics';
import { ThemeOptions } from '@mui/material/styles/createTheme';
import { BrowserTracing } from '@sentry/tracing';
import { ConnectedRouter } from 'connected-react-router';
import { BrowserRouter } from 'react-router-dom';
import { StrictMode, Suspense } from 'react';

import { App } from 'components/App';
import { Fallback } from 'components/Fallback';
import { firebaseApp } from 'libs/firebaseApp';
import { store, history } from 'app/store';
import * as serviceWorkerRegistration from 'serviceWorkerRegistration';
import reportWebVitals from 'reportWebVitals';

import 'config/i18n';
import './index.css';

// Sentry.init({
//     dsn: process.env.REACT_APP_SENTRY_URL,
//     integrations: [new BrowserTracing()],

//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 1.0
// });

// Firebase analytics
getAnalytics(firebaseApp);

const theme: ThemeOptions = {
    typography: {
        allVariants: {
            color: 'white'
        },
        fontFamily: `"Jost",  sans-serif`,
        fontSize: 14,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500
    },
    palette: {
        text: {
            secondary: '#aaa'
        },
        info: {
            main: yellow[300],
            contrastText: '#fff'
        },
        success: {
            main: '#000',
            dark: 'linear-gradient(180deg, #3C4858 0%, #151516 58.85%)',
            light: '#A5CEE4',
            contrastText: 'linear-gradient(180deg, #2E3641 0%, #343E4B 58.85%)'
        },
        error: {
            main: '#000',
            dark: `linear-gradient(180deg, #212121 0%, rgba(33, 33, 33, 0) 100%)`,
            light: '#000',
            contrastText: '#212121'
        },
        warning: {
            main: '#000',
            dark: 'linear-gradient(180deg, rgba(60, 72, 88, 0.93) 0%, rgba(21, 21, 22, 0.93) 58.85%)',
            light: 'linear-gradient(89.95deg, #264257 0.03%, #151516 99.96%)',
            contrastText: 'linear-gradient(180deg, #3C4858 0%, #151516 58.85%)'
        },
        primary: {
            dark: '#274B73',
            // dark: '#264257',
            light: '#A5CEE4',
            main: '#3C4858',
            contrastText: '#fff'
        },
        secondary: {
            dark: '#264257',
            light: '#876cc7',
            main: '#3C4858',
            contrastText: '#000'
        }
    }
};

ReactDOM.render(
    // <StrictMode>
    <>
        <CssBaseline />
        <Provider store={store}>
            <BrowserRouter history={history}>
                <Suspense fallback={<Fallback />}>
                    <BlockchainProvider>
                        <App theme={theme} />
                    </BlockchainProvider>
                </Suspense>
            </BrowserRouter>
        </Provider>
    </>,
    // </StrictMode>,
    document.getElementById('root')
);

// So we can dispatch action such as marking user as logged in
if (window.Cypress) {
    window.store = store;
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
