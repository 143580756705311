import { ProductDataPoint } from 'components/dataPoints/ProductDataPoint';
import { defaultCurrencySymbol } from 'config';

type Props = {
    data?: number;
    textId: string;
};

export const PriceDataPoint = ({ data, textId }: Props): JSX.Element => (
    <ProductDataPoint textId={textId} showMissing={!data}>
        {defaultCurrencySymbol}
        {data?.toLocaleString()}
    </ProductDataPoint>
);
