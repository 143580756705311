import Stack from '@mui/material/Stack';

import { Row } from 'components/Row';
import { ViewAllLink } from 'components/ViewAllLink';
import { StandardCard } from 'components/StandardCard';
import { FetchingScreen } from 'components/FetchingScreen';
import { ProductChartCard } from 'features/dataCards/productChartCard/ProductChartCard';
import { MarketItemForSale } from 'config/types/asset';
import { useFetchAllMarketItems } from 'hooks/useFetchAllMarketItems';
import { SnapshotTitle } from './SnapshotTitle';

export const AllItemDropsSnapshot = (): JSX.Element => {
    const [list, fetching] = useFetchAllMarketItems();

    return (
        <StandardCard
            sx={{
                width: '100%',
                '@media (max-width: 1130px)': {
                    height: '500px'
                }
            }}>
            <Row
                sx={{ mb: 2 }}
                alignItems="baseline"
                justifyContent="space-between">
                <SnapshotTitle textId="all item drops" />
                <ViewAllLink to="/invest" />
            </Row>
            <FetchingScreen
                fullscreen
                fetching={fetching}
                empty={!list || !list.length}
                sx={{
                    display: 'grid',
                    placeItems: 'center',
                    padding: '0px',
                    '@media (max-width: 1130px)': {
                        height: '450px'
                    }
                }}>
                <Stack>
                    {list.slice(0, 3).map((item: MarketItemForSale) => (
                        <ProductChartCard<MarketItemForSale>
                            key={item.id}
                            data={item}
                        />
                    ))}
                </Stack>
            </FetchingScreen>
        </StandardCard>
    );
};
