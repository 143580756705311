import IconButton from '@mui/material/IconButton';
import ChevronRight from '@mui/icons-material/ChevronRight';

import { Spinner } from 'components/Spinner';

type Props = {
    submit: () => void;
    submitting: boolean;
};

export const ChatRoomSubmitButton = ({
    submit,
    submitting
}: Props): JSX.Element => (
    <IconButton sx={{ backgroundColor: 'primary.dark' }} onClick={submit}>
        {submitting ? (
            <Spinner />
        ) : (
            <ChevronRight sx={{ color: 'common.white' }} />
        )}
    </IconButton>
);
