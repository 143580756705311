import MenuIcon from '@mui/icons-material/Menu';
import { useState } from 'react';
import DescriptionIcon from '@mui/icons-material/Description';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Box, Stack, styled } from '@mui/material';

import { Image } from 'components/Image';
import { Typography } from 'components/Typography';
import { AssetMediaMetadata } from 'config/types/asset';

import { Row } from './Row';
import { IconButton } from './IconButton';

type Props = {
    data: AssetMediaMetadata;
    noName?: boolean;
    draggable?: boolean;
    onRemove?: () => void;
};

const Item = styled(Typography)(
    ({ theme }) => `
        width: 110px;
        cursor: pointer;
        border: 1px solid ${theme.palette.common.black};
        padding: 3px;
        text-align: center;
        background-color:${theme.palette.common.black};
`
);

const Img = styled(Image)({
    width: 100,
    height: 100,
    objectFit: 'cover',
    objectPosition: 'center',
    borderRadius: 3,
    lineHeight: 0
});

export const MediaItem = ({
    data,
    noName,
    draggable,
    onRemove
}: Props): JSX.Element => {
    const [onHover, setOnHover] = useState<boolean>(false);

    return (
        <Stack
            alignItems="center"
            onMouseEnter={() => setOnHover(true)}
            onMouseLeave={() => setOnHover(false)}>
            <a href={data.uri} target="_blank" rel="noreferrer">
                {data.type === 'picture' ? (
                    <Box sx={{ mb: 1, width: 100, height: 100 }}>
                        <Img
                            uri={data.uri}
                            title={data.title}
                            draggable={false}
                        />
                    </Box>
                ) : (
                    <DescriptionIcon
                        sx={{ color: 'primary.light' }}
                        fontSize="large"
                    />
                )}
            </a>
            <Row
                sx={{ p: 0, width: 110 }}
                spacing={1}
                justifyContent="space-between">
                {noName ? (
                    <div />
                ) : (
                    <Item
                        noWrap
                        capitalized
                        variant="caption"
                        title={data.title}
                        textId={data.title}
                    />
                )}
                {onHover && onRemove && (
                    <IconButton
                        sx={{ p: 0, m: 0 }}
                        onClick={onRemove}
                        draggable={false}>
                        <HighlightOffIcon
                            fontSize="small"
                            sx={{ color: 'primary.light' }}
                        />
                    </IconButton>
                )}
            </Row>
            {draggable && (
                <IconButton sx={{ py: 0 }}>
                    <MenuIcon
                        fontSize="small"
                        sx={{
                            color: 'primary.light',
                            opacity: onHover ? 1 : 0.4
                        }}
                    />
                </IconButton>
            )}
        </Stack>
    );
};
