import Grid from '@mui/material/Grid';
import { FormProvider } from 'react-hook-form';

import { Row } from 'components/Row';
import { Checkbox } from 'components/Checkbox';
import { DarkButton } from 'components/buttons/DarkButton';
import { LightButton } from 'components/buttons/LightButton';
import { StandardModal } from 'components/StandardModal';
import { CustomTextField } from 'components/CustomTextField';
import { AssetAuthenticationFormInput } from 'config/types/asset';

import { CertificateFileInput } from './CertificateFileInput';
import { useAssetAuthenticationForm } from './useAssetAuthenticationForm';

type Props = {
    open: boolean;
    assetId: string;
    handleClose: () => void;
};

export const AssetAuthenticationFormModal = ({
    open,
    assetId,
    handleClose
}: Props): JSX.Element => {
    const {
        onClose,
        onSubmit,
        submitting,
        formMethods,
        noDocument,
        onNoDocumentChange
    } = useAssetAuthenticationForm(assetId, handleClose);

    return (
        <StandardModal
            sx={{ p: 5 }}
            title="assetAuthentication"
            open={open}
            handleClose={handleClose}
            maxw={'100%'}>
            <FormProvider {...formMethods}>
                <form
                    noValidate
                    autoComplete="off"
                    onSubmit={formMethods.handleSubmit(onSubmit)}>
                    <Grid
                        sx={{
                            mt: 1,
                            '@media (max-width: 340px)': {
                                width: '270px'
                            }
                        }}
                        container
                        spacing={1}>
                        {!noDocument && (
                            <Grid sx={{ pb: 1 }} item xs={12}>
                                <CertificateFileInput disabled={submitting} />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Checkbox
                                size="small"
                                textId="noDocument"
                                checked={noDocument}
                                onChange={onNoDocumentChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CustomTextField<AssetAuthenticationFormInput>
                                label="Address*"
                                name="address"
                                disabled={submitting}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <CustomTextField<AssetAuthenticationFormInput>
                                label="City*"
                                name="city"
                                disabled={submitting}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <CustomTextField<AssetAuthenticationFormInput>
                                label="State*"
                                name="state"
                                disabled={submitting}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <CustomTextField<AssetAuthenticationFormInput>
                                label="Zip Code*"
                                name="zipCode"
                                disabled={submitting}
                            />
                        </Grid>
                    </Grid>
                    <Row sx={{ mt: 3 }} justifyContent="space-between">
                        <DarkButton
                            textId="cancel"
                            onClick={onClose}
                            disabled={submitting}
                            style={{ marginRight: '10px' }}
                        />
                        <LightButton
                            type="submit"
                            textId="submit"
                            loading={submitting}
                        />
                    </Row>
                </form>
            </FormProvider>
        </StandardModal>
    );
};
