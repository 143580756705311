import { useAlert } from 'hooks/useAlert';
import { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, UseFormReturn, SubmitHandler } from 'react-hook-form';

import { logger } from 'libs/logger';
import { RootState } from 'app/store';
import { useAppSelector } from 'hooks/useAppSelector';
import { useAsyncAction } from 'hooks/useAsyncAction';
import { getMerchantAsset } from 'state/merchantAssets/selectors';
import { useUpdateAssetData } from 'hooks/useUpdateAssetData';
import { EditAssetDataFormInput } from 'config/types/asset';

import { editAssetDataSchema } from './editAssetDataSchema';

type StateLogic = {
    onClose: () => void;
    onSubmit: (data: EditAssetDataFormInput) => void;
    submitting: boolean;
    // eslint-disable-next-line @typescript-eslint/ban-types
    formMethods: UseFormReturn<EditAssetDataFormInput, object>;
};

type Options = {
    silent?: boolean;
};

const log = logger('useCreateAssetListingModal');

export const useEditAssetDataModal = (
    assetId: string,
    handleClose: () => void,
    { silent = false }: Options = {}
): StateLogic => {
    const { successAlert } = useAlert(silent);

    const [updateAssetData] = useUpdateAssetData(assetId, true);

    const assetData = useAppSelector((state: RootState) =>
        getMerchantAsset(state, assetId)
    );

    const formMethods = useForm<EditAssetDataFormInput>({
        resolver: yupResolver(editAssetDataSchema)
    });

    const onClose = (): void => {
        formMethods.reset();
        handleClose();
    };

    const action: SubmitHandler<EditAssetDataFormInput> = async (
        values
    ): Promise<void> => {
        log.info('Submitting new product', values);
        await updateAssetData({ status: 'pending', ...values });
        successAlert('editSuccess');
        handleClose();
    };

    const [onSubmit, submitting] = useAsyncAction<EditAssetDataFormInput, void>(
        action,
        { error: 'requestError', silent }
    );

    useEffect(() => {
        if (assetData) {
            formMethods.reset({
                color: assetData.color,
                price: assetData.price,
                name: assetData.name,
                material: assetData.material,
                description: assetData.description,
                dimensions: assetData.dimensions,
                category: assetData.category
            });
        }
    }, [assetData, formMethods]);

    return {
        onClose,
        onSubmit,
        submitting,
        formMethods
    };
};
