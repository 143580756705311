import { BigNumber, utils } from 'ethers';

import { allowedFileMimes, fileMaxAmount } from 'config';
import {
    AssetItem,
    AssetMediaMetadata,
    AssetMediaMetadataItem
} from 'config/types/asset';
import { BLOCKCHAIN_CURRENCY_TO_DOLLAR } from 'config/constants';

export const formatCryptoPriceForDisplay = (tokenPrice: BigNumber): number => {
    const priceAsStr: string = utils.formatUnits(
        tokenPrice.toString(),
        'ether'
    );
    return parseFloat(priceAsStr);
};

export const isValidDocumentFileType = (file: File): boolean =>
    !file || allowedFileMimes.document.includes(file.type);

// export const isValidPictureFileType = (file: File): boolean =>
//     !file || allowedFileMimes.picture.includes(file.type);

export const isValidPictureFileType = (files: File | File[]): boolean => {
    if (!files) {
        return false;
    }
    const filesToCheck = Array.isArray(files) ? files : [files];
    if (filesToCheck.length > fileMaxAmount.file) {
        return false;
    }

    for (const files1 of filesToCheck) {
        if (!allowedFileMimes.picture.includes(files1.type)) {
            return false;
        }
    }
    return true;
};
export const isValidInputNumber = (num: Number): boolean => {
    if (!num) {
        return false;
    }
    if (num < 0) {
        return false;
    }
    return true;
};

// The title of the file is the file name without the extension
// TODO: Better way to extract file title?
// export const getFileTitle = (file: File | File[]): string =>
//     file[0].name.split('.').shift() ?? file[0].name;
export const getFileTitle = (file: File | File[]): string => {
    const firstFile = Array.isArray(file) ? file[0] : file;
    return firstFile.name.split('.').shift() ?? firstFile.name;
};

export const getItemPics = (data: AssetItem): AssetMediaMetadataItem[] =>
    data.medias.filter((e) => e.type === 'picture');

export const getCertificateOfAuthenticity = (
    data: AssetItem
): string | undefined =>
    data?.medias.find(
        (e: AssetMediaMetadata) => e.type === 'document' && e.priority === 1
    )?.uri;

export const getItemPictures = (item: AssetItem): AssetMediaMetadata[] =>
    item.medias.filter((e: AssetMediaMetadata) => e.type === 'picture') || [];

export const getItemCoverPicture = (
    item: AssetItem
): AssetMediaMetadata | undefined =>
    item.medias.find(
        (e: AssetMediaMetadata) => e.type === 'picture' && e.priority === 1
    );

export const withEllipsis = (s: string, maxLen: number): string =>
    s.length > maxLen ? `${s.substring(0, maxLen)}...` : s;

export const setTimestampToDate = (date: string): string =>
    new Date(parseInt(date, 10)).toLocaleDateString();

export const convertFromBlockchainCurrencyUnit = (unit: BigNumber): number => {
    const valueInEth = utils.formatEther(unit);
    const amountInEth = parseFloat(valueInEth);
    const amountInFiat = amountInEth * BLOCKCHAIN_CURRENCY_TO_DOLLAR;
    return parseFloat(amountInFiat.toFixed(2));
};

export const convertToBlockchainCurrencyUnit = (amount: number): BigNumber => {
    const amountInBlockchainCurrency = amount / BLOCKCHAIN_CURRENCY_TO_DOLLAR;
    return utils.parseEther(amountInBlockchainCurrency.toFixed(17).toString());
};

export const dollarsToTokens = (amount: number): BigNumber =>
    BigNumber.from(Math.round(amount) * 100);

export const dollarsFromTokens = (amount: BigNumber): number =>
    amount.div(100).toNumber();

export const capitalize = (str: string): string =>
    str.charAt(0).toUpperCase() + str.slice(1);
