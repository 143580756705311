import MuiCheckbox from '@mui/material/Checkbox';
import { useTranslation } from 'react-i18next';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled, useTheme } from '@mui/material';

type Props = React.ComponentProps<typeof MuiCheckbox> & {
    textId: string;
};

export const Checkbox = ({ ...props }: Props): JSX.Element => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { textId, ...other } = props;
    const FormControlContainer = styled(FormControlLabel)({
        '& .MuiTypography-root': {
            color: theme.palette.primary.contrastText
        }
    });
    return (
        <FormControlContainer
            sx={{ color: 'black' }}
            control={<MuiCheckbox {...other} />}
            label={t(textId)}
        />
    );
};
