import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import { team } from 'config/team';
import { Typography } from 'components/Typography';
import { FloatingLogo } from 'components/FloatingLogo';
import { StandardPageTitle } from 'components/StandardPageTitle';

export const OurTeamPage = (): JSX.Element => (
    <Box sx={{ textAlign: 'center' }}>
        <StandardPageTitle textId="luxVestingTeam" />
        <Grid sx={{ p: 8 }} spacing={5} container>
            {team.map((p) => (
                <Grid item lg={3} md={4} sm={6} xs={12}>
                    <Stack alignItems="center">
                        <img
                            src={p.pic}
                            alt={p.name}
                            width="200px"
                            height="200px"
                        />
                        <Typography sx={{ mt: 1 }} bold uppercased variant="h6">
                            {p.name}
                        </Typography>
                        <Typography>{p.title}</Typography>
                    </Stack>
                </Grid>
            ))}
        </Grid>
        <FloatingLogo />
    </Box>
);
