import Box from '@mui/material/Box';
import { useState } from 'react';

import { BrokenImagePlaceholder } from 'components/BrokenImagePlaceholder';

type Props = React.ComponentProps<typeof Box> & {
    uri?: string;
    title: string;
};

export const Image = ({ uri, title, ...other }: Props): JSX.Element => {
    const [brokenImage, setBrokenImage] = useState<boolean>(false);

    const { sx, ...rest } = other;

    return brokenImage ? (
        <BrokenImagePlaceholder />
    ) : (
        <Box
            alt={title}
            src={uri}
            onError={() => setBrokenImage(true)}
            component="img"
            sx={{
                objectFit: 'cover',
                objectPosition: 'center',
                borderRadius: 1,
                ...sx
            }}
            {...rest}
        />
    );
};
