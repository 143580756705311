import { useContext } from 'react';
import { BlockChainContext } from 'blockchain/BlockchainContext';
import { assetsActions } from 'state/assets/assetsSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { merchantAssetsActions } from 'state/merchantAssets/merchantAssetsSlice';

import { useAsyncAction } from './useAsyncAction';

type StateLogic = [(params: ActionParams) => Promise<void>, boolean];

type ActionParams = {
    id: string;
    nftId: number;
};

export const useFetchItemBids = (): StateLogic => {
    const dispatch = useAppDispatch();
    const { bidding } = useContext(BlockChainContext);

    const action = async ({ nftId, id }: ActionParams): Promise<void> => {
        const bids = await bidding!.getItemBids(nftId);
        dispatch(assetsActions.updateData({ id, data: { bids } }));
        dispatch(merchantAssetsActions.updateAssetData({ id, data: { bids } }));
    };

    const [fetchBids, fetching] = useAsyncAction<ActionParams, void>(action, {
        error: 'errorRetrievingItemBids'
    });

    return [fetchBids, fetching];
};
