import { useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import {
    VictoryAxis,
    VictoryChart,
    VictoryLabel,
    VictoryTooltip,
    VictoryCandlestick,
    DomainTuple
} from 'victory';
import { getStakingMockData } from 'dummy/chartData';

type Props = {
    timeAgoFilter: string;
    domain: {
        x?: [number, number];
        y?: [number, number];
    };
    chartPadding: {
        left?: number;
        right?: number;
        top?: number;
        bottom?: number;
    };
    fontSize: string;
    offsetX: number;
};

export const StakingChart = (props: Props): JSX.Element => {
    const theme = useTheme();
    const [dates, setDates] = useState<[]>([]);
    const { timeAgoFilter, domain, chartPadding, fontSize, offsetX } = props;
    const [zoomDomain, setZoomDomain] = useState<
        { x?: DomainTuple; y?: DomainTuple } | undefined
    >(undefined);

    useEffect(() => {
        setDates(getStakingMockData(timeAgoFilter));
    }, [timeAgoFilter]);

    // const handleAxisClick = (domain: any, event: any): any => {
    //     const { x } = domain;
    //     const isTouchEvent = event.type.includes('touch');
    //     if (!isTouchEvent) {
    //         return;
    //     }
    //     const midPoint = (x[1] - x[0]) / 2 + x[0];
    //     const nextZoom = zoomDomain
    //         ? undefined
    //         : {
    //               x: [
    //                   midPoint - (midPoint - x[0]) * 1.1,
    //                   midPoint + (x[1] - midPoint) * 1
    //               ]
    //           };
    //     setZoomDomain({ x: nextZoom });
    // };

    return (
        <VictoryChart
            domain={domain}
            domainPadding={{ x: 35 }}
            padding={chartPadding}
            scale={{ x: 'time' }}
            width={1200}
            height={400}>
            {/* <VictoryAxis
                style={{
                    axis: { stroke: 'white' },
                    ticks: { stroke: 'white' },
                    tickLabels: { fill: theme.palette.common.white }
                }}
                tickFormat={(t) => `${t.getMonth()}/${t.getDate()}`}
            /> */}
            <VictoryAxis
                dependentAxis={true}
                tickFormat={(t) => `$${t.toFixed(2)}`}
                style={{
                    tickLabels: {
                        fill: theme.palette.primary.contrastText,
                        marginLeft: '50px',
                        paddingLeft: '50px',
                        fontSize: fontSize,
                        zIndex: '10',
                        marginRight: '20px',
                        paddingBottom: '200px'
                    },
                    axis: { stroke: 'none' }
                }}
                offsetX={offsetX}
                domainPadding={100}
                orientation="right"
            />
            <VictoryCandlestick
                style={{
                    data: {
                        stroke: 'none',
                        stopColor: '#fff',
                        marginLeft: '20px',
                        marginRight: '20px',
                        paddingLeft: '10px',
                        paddingRight: '200px',
                        marginBottom: '20px'
                    }
                }}
                wickStrokeWidth={1}
                candleRatio={0.8}
                candleColors={{
                    positive: theme.palette.primary.light,
                    negative: theme.palette.primary.dark
                }}
                candleWidth={12}
                data={dates}
                labels={({ datum: d }) => [
                    `open: ${d.open}`,
                    `close: ${d.close}`,
                    `high: ${d.high}`,
                    `low: ${d.low}`
                ]}
                labelComponent={
                    <VictoryTooltip
                        width={300}
                        flyoutStyle={{ fill: '#000' }}
                        flyoutWidth={80}
                        flyoutHeight={100}
                        orientation="top"
                        style={{
                            fill: '#EEE',
                            fontFamily: '"Jost",  sans-serif'
                        }}
                        labelComponent={<VictoryLabel lineHeight={1.6} />}
                    />
                }
            />
        </VictoryChart>
    );
};
