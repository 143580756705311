import { Link } from 'react-router-dom';
import { Typography } from './Typography';

type Props = {
    to: string;
};

export const ViewAllLink = ({ to }: Props): JSX.Element => (
    <Link to={to}>
        <Typography
            sx={{ textDecoration: 'underline' }}
            capitalized
            textId="view all"
        />
    </Link>
);
