import { Image } from 'components/Image';

type Props = {
    uri?: string;
    name: string;
};

export const ItemPicture = ({ uri, name }: Props): JSX.Element => (
    <Image
        sx={{
            borderRadius: 1,
            '@media (max-width: 290px)': {
                width: 100,
                height: 80,
                marginRight: '10px',
                marginLeft: '-10px'
            }
        }}
        uri={uri}
        title={name}
        width={120}
        height={100}
    />
);
