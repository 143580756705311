import * as yup from 'yup';
import Grid from '@mui/material/Grid';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';

import { logger } from 'libs/logger';
import { Row } from 'components/Row';
import { DarkButton } from 'components/buttons/DarkButton';
import { Typography } from 'components/Typography';
import { LightButton } from 'components/buttons/LightButton';
import { CustomTextField } from 'components/CustomTextField';
import { SellHoldingsInput } from 'config/types';
import { useTheme } from '@mui/material';

type Props = {
    children: React.ReactNode;
    onCancel: () => void;
    maxHoldings: number;
};

export const TradeHoldingsForm = ({
    children,
    onCancel,
    maxHoldings
}: Props): JSX.Element => {
    const formMethods = useForm<SellHoldingsInput>({
        defaultValues: {
            nbOfHoldings: '',
            pricePerHolding: '',
            percentageOfOwnership: ''
        },
        // The input validations are set by Yup
        resolver: yupResolver(
            yup
                .object({
                    minPrice: yup.number(),
                    nbOfHoldings: yup
                        .number()
                        .min(1)
                        .max(maxHoldings)
                        .required(),
                    pricePerHolding: yup.number().required(),
                    minNbOfHoldings: yup.number().min(1).max(maxHoldings),
                    percentageOfOwnership: yup.number().required()
                })
                .required()
        )
    });

    const onSubmit: SubmitHandler<SellHoldingsInput> = (data): void => {
        logger('Submitting Trade Holding Form').info(data);
    };
    const theme = useTheme();
    return (
        <FormProvider {...formMethods}>
            <form
                style={{ color: theme.palette.primary.contrastText }}
                noValidate
                autoComplete="off"
                onSubmit={formMethods.handleSubmit(onSubmit)}>
                <Grid container spacing={3} sx={{ mb: 3 }}>
                    <Grid item xs={12} md={4} sx={{ mr: -2 }}>
                        <Row spacing={1} alignItems="baseline">
                            <CustomTextField<SellHoldingsInput>
                                name="nbOfHoldings"
                                label="Number of Holdings *"
                                type="number"
                            />
                            <Typography variant="body1" textId="or" />
                        </Row>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <CustomTextField<SellHoldingsInput>
                            name="percentageOfOwnership"
                            label="Percentage of Ownership *"
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <CustomTextField<SellHoldingsInput>
                            name="pricePerHolding"
                            label="Price Per Holding *"
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <CustomTextField<SellHoldingsInput>
                            name="minPrice"
                            label="Minimum Price"
                            type="number"
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <CustomTextField<SellHoldingsInput>
                            name="minNbOfHoldings"
                            label="Minimum Number of Holdings"
                            type="number"
                        />
                    </Grid>
                </Grid>
                {children}
                <Row sx={{ mt: 3 }} justifyContent="space-between">
                    <DarkButton onClick={onCancel} textId="cancel" />
                    <LightButton onClick={onCancel} textId="proceed" />
                </Row>
            </form>
        </FormProvider>
    );
};
