import Box from '@mui/material/Box';

export const Space = ({
    sx,
    width,
    height
}: {
    sx?: Record<string, unknown>;
    width?: string | number;
    height?: string | number;
}): JSX.Element => <Box sx={sx ?? { width, height }} />;
