import { ChangeEvent, MouseEvent, MouseEventHandler, useState } from 'react';

import { AssetItem } from 'config/types/asset';
import { useBidOnItem } from 'hooks/useBidOnItem';
import { minBidValueAllowed } from 'config';
import { useAppSelector } from 'hooks/useAppSelector';
import { getUserBalance } from 'state/user/selectors';
import { useAddFundsDrawer } from 'hooks/useAddFundsDrawer';

type StateLogic = {
    onSubmit: (e: MouseEvent<HTMLButtonElement>) => void;
    processing: boolean;
    isButtonDisabled: boolean;
    handleBidChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

export const useBidInput = (
    item: AssetItem,
    onSuccess?: () => void
): StateLogic => {
    const [amount, setValue] = useState<string>('');
    const userBalance = useAppSelector(getUserBalance);
    const [open] = useAddFundsDrawer();

    const [bidOnItem, processing] = useBidOnItem(item);

    const value = parseInt(amount, 10);

    const isButtonDisabled = !value || value < minBidValueAllowed;

    const handleBidChange = (e: ChangeEvent<HTMLInputElement>): void => {
        setValue(e.target.value.trim());
    };

    const onSubmit: MouseEventHandler<HTMLButtonElement> = async () => {
        if (value > userBalance) {
            open('addFunds');
            return;
        }
        if (!value || value < minBidValueAllowed) return;
        await bidOnItem({ value });
        if (onSuccess) onSuccess();
    };

    return { onSubmit, isButtonDisabled, handleBidChange, processing };
};
