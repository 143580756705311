import {
    doc,
    query,
    where,
    setDoc,
    addDoc,
    getDoc,
    getDocs,
    collection,
    updateDoc,
    getFirestore,
    Firestore,
    SetOptions,
    arrayUnion
} from 'firebase/firestore';

import { User } from 'config/types/user';
import {
    AssetItem,
    NewAsset,
    UserAssetItem,
    AssetMediaMetadata,
    AssetMediaMetadataItem
} from 'config/types/asset';
import {
    setRecordWithId,
    setRecordWithDate,
    getDocSnapshotData,
    getQuerySnapshotData
} from 'libs/firestoreUtils';
import { Bid } from 'config/types';
import { logger } from 'libs/logger';
import { firebaseApp } from 'libs/firebaseApp';

const userCollection = 'users';
const assetCollection = 'assets';

const log = logger('Database');

const db: Firestore = getFirestore(firebaseApp);
export class DatabaseService {
    public static async getUserById(id: string): Promise<User> {
        log.debug('Attempt to get user with id:', id);
        const docRef = doc(db, userCollection, id);
        const docSnap = await getDoc(docRef);
        const data = getDocSnapshotData<User>(docSnap);
        log.debug('Successfully retrieved user:', data);
        return data;
    }

    public static async getAllAssets(): Promise<AssetItem[]> {
        log.debug('Get all assets');
        const q = query(collection(db, assetCollection));
        const querySnapshot = await getDocs(q);
        const data = getQuerySnapshotData<AssetItem>(querySnapshot);
        log.debug('Successfully retrieved all assets:', data);
        return data;
    }

    public static async getAllMarketItems(): Promise<AssetItem[]> {
        log.debug('Get all market items');
        const q = query(
            collection(db, assetCollection),
            where('status', 'in', ['unverified', 'listed'])
        );
        const querySnapshot = await getDocs(q);
        const data = getQuerySnapshotData<AssetItem>(querySnapshot);
        log.debug('Successfully retrieved all market items:', data);
        return data;
    }

    public static async getUserAssets(id: string): Promise<UserAssetItem[]> {
        log.debug('Get assets from user with id:', id);
        const col = collection(db, userCollection, id, assetCollection);
        const querySnap = await getDocs(query(col));
        const data = getQuerySnapshotData<UserAssetItem>(querySnap);
        log.debug('Successfully retrieved assets', data, 'from user:', id);
        return data;
    }

    public static async getMerchantAssets(id: string): Promise<AssetItem[]> {
        log.debug('Get assets from merchant id:', id);
        const col = collection(db, assetCollection);
        const snap = await getDocs(query(col, where('merchantId', '==', id)));
        const data = getQuerySnapshotData<AssetItem>(snap);
        log.debug('Successfully retrieved assets', data, 'from user:', id);
        return data;
    }

    public static async getAssetData(id: string): Promise<AssetItem> {
        log.debug('Get data for asset id:', id);
        const docRef = doc(db, assetCollection, id);
        const docSnap = await getDoc(docRef);
        const data = getDocSnapshotData<AssetItem>(docSnap);
        log.debug('Successfully retrieved', data, 'for asset:', id);
        return data;
    }

    public static async getAssetDataFromTokenURI(
        tokenURI: string
    ): Promise<AssetItem> {
        log.debug('Get data for asset with token uri:', tokenURI);
        const col = collection(db, assetCollection);
        const q = query(col, where('tokenURI', '==', tokenURI));
        const querySnapshot = await getDocs(q);
        const [data] = getQuerySnapshotData<AssetItem>(querySnapshot);
        // const docRef = doc(db, assetCollection, id);
        // const docSnap = await getDoc(docRef);
        // const data = getDocSnapshotData<AssetItem>(docSnap);
        log.debug('Successfully retrieved', data, 'with token uri:', tokenURI);
        return data;
    }

    public static async addUser(data: User): Promise<void> {
        log.debug('Add user data into collection:', data);
        // The user id is their ethereum account number
        const docRef = doc(db, userCollection, data.account);
        await setDoc(docRef, setRecordWithDate<User>(data));
        log.debug('Successfully added user with id', data.account);
    }

    public static async addNewAsset(data: NewAsset): Promise<string> {
        log.debug('Add asset:', data.name);
        const col = collection(db, assetCollection);
        const { id } = await addDoc(col, setRecordWithDate<NewAsset>(data));
        return id;
    }

    // public static async addMediaToAsset(
    //     assetId: string,
    //     media: AssetMediaMetadata
    // ): Promise<AssetMediaMetadataItem> {
    //     log.debug('Add media metadata:', media, 'to asset:', assetId);
    //     const assetRef = doc(db, assetCollection, assetId);
    //     const data = setRecordWithId<AssetMediaMetadata>(media);
    //     await updateDoc(assetRef, { medias: arrayUnion(data) });
    //     log.debug('Successfully added media', data, ' to asset ', assetId);
    //     return data;
    // }
    public static async addMediaToAsset(
        assetId: string,
        medias: AssetMediaMetadata[]
    ): Promise<AssetMediaMetadataItem[]> {
        log.debug('Add media metadata:', medias, 'to asset:', assetId);
        const assetRef = doc(db, assetCollection, assetId);
        const data = medias.map((media) =>
            setRecordWithId<AssetMediaMetadata>(media)
        );
        await updateDoc(assetRef, { medias: arrayUnion(...data) });
        log.debug('Successfully added media', data, ' to asset ', assetId);
        return data;
    }

    public static async addBidToItem(itemId: string, bid: Bid): Promise<void> {
        log.debug('Add bid:', bid, 'to item:', itemId);
        const assetRef = doc(db, assetCollection, itemId);
        // const data = setRecordWithDate<Bid>(bid);
        await updateDoc(assetRef, { bids: arrayUnion(bid) });
        log.debug('Successfully added bid', bid, ' to item ', itemId);
    }

    public static async updateUserData(
        userId: string,
        data: Partial<User>
    ): Promise<void> {
        log.debug('Set data :', data, ' for user: ', userId);
        const opts: SetOptions = { merge: true };
        await setDoc(doc(db, userCollection, userId), data, opts);
        log.debug('Successfully updated user data with:', data);
    }

    public static async updateAssetData(
        id: string,
        data: Partial<AssetItem>
    ): Promise<void> {
        log.debug('Update asset with data:', data);
        const docRef = doc(db, assetCollection, id);
        await updateDoc(docRef, data);
        log.debug('Successfully updated asset with data:', data);
    }

    public static async updateAssetMedias(
        assetId: string,
        medias: AssetMediaMetadataItem[] | AssetMediaMetadataItem
    ): Promise<void> {
        log.debug('Update media with:', medias, 'for asset:', assetId);
        const assetRef = doc(db, assetCollection, assetId);
        await updateDoc(assetRef, { medias });
        log.debug('Successfully update media for asset', assetId);
    }
}
