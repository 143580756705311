import capitalize from 'lodash/capitalize';
import Grid from '@mui/material/Grid';
import MuiAvatar from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import MuiCardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';
import { useTranslation } from 'react-i18next';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';

import { User } from 'config/types/user';
import { AddFundsButton } from 'features/addFunds/AddFundsButton';
import { getUserData } from 'state/user/selectors';
import { StandardCard } from 'components/StandardCard';
import { useAppSelector } from 'hooks/useAppSelector';
import { OpenWalletButton } from 'features/profileOverview/OpenWalletButton';
import { ToEditProfileButton } from 'features/profileOverview/ToEditProfileButton';
import { proprietaryCurrency } from 'config';

const Avatar = styled(MuiAvatar)`
    margin: auto;
    position: relative;
    margin-bottom: -70px;
    border-radius: 70px;
    width: 140px;
    height: 140px;
`;

const CardActions = styled(MuiCardActions)(({ theme }) => ({
    display: 'flex',
    margin: 'auto',
    justifyContent: 'space-between',
    marginTop: theme.spacing(3)
}));

const Name = styled(Grid)`
    font-size: 24px;
`;

const Item = styled(Grid)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: 'center',
    svg: {
        color: theme.palette.primary.light
    }
}));

const Data = styled('span')(({ theme }) => ({
    top: '-5px',
    position: 'relative',
    paddingLeft: theme.spacing(1)
}));

export const ProfileOverview = (): JSX.Element => {
    const { t } = useTranslation();

    const u: User = useAppSelector(getUserData);

    return (
        <>
            <Avatar src={u.avatar || u.profileImage} alt={u.name} />
            <StandardCard
                sx={{
                    margin: 'auto',
                    maxWidth: '91ch',
                    pt: 10
                }}>
                <CardContent>
                    <Grid container rowSpacing={3}>
                        {u.firstName && u.lastName && (
                            <Item item xs={12} md={12}>
                                <Name>
                                    {u.firstName} {u.lastName}
                                </Name>
                            </Item>
                        )}
                        <Item item xs={6} md={6}>
                            <BusinessIcon />
                            <Data>
                                {u.company || <i>{t('missingCompany')}</i>}
                            </Data>
                        </Item>
                        <Item item xs={6} md={6}>
                            <PersonIcon />
                            <Data>
                                {u.userType ? (
                                    capitalize(t(u.userType))
                                ) : (
                                    <i>{t('missingUserType')}</i>
                                )}
                            </Data>
                        </Item>
                        <Item item xs={6} md={6} lg={4}>
                            <MonetizationOnIcon />
                            <Data>
                                {u.balance ? (
                                    `${u.balance} (${proprietaryCurrency})`
                                ) : (
                                    <i>{t('noFundsYet')}</i>
                                )}
                            </Data>
                        </Item>
                        <Item item xs={6} md={6} lg={4}>
                            <AlternateEmailIcon />
                            <Data>{u.email || <i>{t('missingEmail')}</i>}</Data>
                        </Item>
                        <Item item xs={12} md={12} lg={4}>
                            <CreditCardIcon />
                            <Data>
                                {u.paymentAccount || (
                                    <i>{t('missingPaymentAccount')}</i>
                                )}
                            </Data>
                        </Item>
                        <Item item xs={12}>
                            {u.description || <i>{t('missingDescription')} </i>}
                        </Item>
                    </Grid>
                </CardContent>
                <CardActions>
                    <OpenWalletButton />
                    <AddFundsButton />
                    <ToEditProfileButton />
                </CardActions>
            </StandardCard>
        </>
    );
};
