import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import { Row } from 'components/Row';
import { AssetItem } from 'config/types/asset';
import { Typography } from 'components/Typography';
import { defaultCurrencySymbol } from 'config';

type Props = {
    data: AssetItem;
};

const Data = styled(Typography)(
    ({ theme }) => `
    color: ${theme.palette.primary.light};
    font-weight: 600;
`
);

export const AssetListingData = ({ data }: Props): JSX.Element => {
    return (
        <Box
            sx={{ display: 'flex', height: '100%' }}
            alignItems="center"
            justifyContent="center">
            <Row spacing={4} sx={{ height: '90%' }}>
                <Stack justifyContent="space-between">
                    <Row>
                        <Typography allCapitalized textId="merchant" />
                        <Typography>:</Typography>
                    </Row>
                    <Row>
                        <Typography allCapitalized textId="category" />
                        <Typography>:</Typography>
                    </Row>
                    <Row>
                        <Typography allCapitalized textId="price" />
                        <Typography>:</Typography>
                    </Row>
                    <Row>
                        <Typography allCapitalized textId="network" />
                        <Typography>:</Typography>
                    </Row>
                </Stack>
                <Stack justifyContent="space-between">
                    <Data noWrap sx={{ maxWidth: 200 }}>
                        {data.merchantName}
                    </Data>
                    <Data allCapitalized>{data.category}</Data>
                    <Data>
                        {defaultCurrencySymbol}
                        {data.price.toLocaleString()}
                    </Data>
                    <Data allCapitalized>{data.network}</Data>
                </Stack>
            </Row>
        </Box>
    );
};
