import { Grid } from '@mui/material';

import { FetchingScreen } from 'components/FetchingScreen';
import { ProductChartCard } from 'features/dataCards/productChartCard/ProductChartCard';
import { MarketItemForSale } from 'config/types/';
import { useFetchAllMarketItems } from 'hooks/useFetchAllMarketItems';

type Props = {
    onSelectitem: (item: MarketItemForSale) => void;
};

export const ProductChartList = ({ onSelectitem }: Props): JSX.Element => {
    const [list, fetching] = useFetchAllMarketItems();

    return (
        <FetchingScreen fetching={fetching} empty={!list || !list.length}>
            <Grid container sx={{ mt: 3 }} spacing={3}>
                {list.map((item: MarketItemForSale) => (
                    <Grid item xs={6} md={4} xl={4} key={item.id}>
                        <ProductChartCard<MarketItemForSale>
                            onClick={() => onSelectitem(item)}
                            data={item}
                        />
                    </Grid>
                ))}
            </Grid>
        </FetchingScreen>
    );
};
