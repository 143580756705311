import startCase from 'lodash/startCase';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { MenuItem, useTheme } from '@mui/material';
import { ErrorMessage } from '@hookform/error-message';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext, Path } from 'react-hook-form';

import { FormHelperText } from 'components/FormHelperText';
import { relative } from 'path';

type Props<T> = React.ComponentProps<typeof Select> & {
    name: Path<T>;
    label?: string;
    noLabel?: boolean;
    disabled?: boolean;
    required?: boolean;
    labelComponent?: React.ReactNode;
    options: string[];
};

export const CustomSelect = <T extends Record<string, unknown>>({
    label = undefined,
    name,
    noLabel = false,
    disabled = false,
    required = false,
    labelComponent,
    options,
    ...other
}: Props<T>): JSX.Element => {
    const { t } = useTranslation();
    const theme = useTheme();
    const {
        control,
        formState: { errors }
    } = useFormContext();
    const error = errors?.[name]?.message;
    const labelTxt = `${startCase(t(label ?? name))}${required ? '*' : ''}`;

    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => (
                <FormControl
                    sx={{ pb: error ? 0 : 3 }}
                    variant="standard"
                    fullWidth>
                    {!noLabel && (
                        <InputLabel
                            sx={{
                                color: theme.palette.primary.contrastText,
                                position: 'relative',
                                marginBottom: '-6px',
                                '&.Mui-focused': {
                                    color: theme.palette.primary.contrastText
                                }
                            }}
                            shrink
                            htmlFor={name}>
                            {labelTxt ?? label}
                        </InputLabel>
                    )}
                    <Select
                        id={labelTxt}
                        disabled={disabled}
                        {...field}
                        variant="outlined"
                        MenuProps={{
                            getContentAnchorEl: null,
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'center'
                            },
                            transformOrigin: {
                                vertical: 'bottom',
                                horizontal: 'center'
                            },
                            sx: { mb: '' }
                        }}
                        sx={{
                            color: 'black',
                            backgroundColor: '#fff',
                            borderRadius: '3px',
                            '& .MuiSelect-select': {
                                padding: '10px'
                            },
                            '& .MuiSelect-select:focus': {
                                padding: '10px'
                            },
                            '& .MuiSelect-icon': {
                                color: 'black'
                            },
                            '& .MuiSelect-root': {
                                '&:hover': {
                                    backgroundColor: 'white'
                                }
                            }
                        }}
                        {...other}>
                        {options.map((option) => (
                            <MenuItem
                                sx={{
                                    color: 'black',
                                    paddingLeft: '6px'
                                }}
                                key={option}
                                value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </Select>
                    {error && (
                        <ErrorMessage
                            errors={errors}
                            name={name}
                            render={({ message }) => (
                                <FormHelperText textId={message} />
                            )}
                        />
                    )}
                </FormControl>
            )}
        />
    );
};
