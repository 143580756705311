import Box from '@mui/material/Box';
import { GrDropbox } from 'react-icons/gr';
import { styled, useTheme } from '@mui/material';

import { Row } from 'components/Row';
import { Centered } from 'components/Centered';
import { Typography } from 'components/Typography';

const Icon = styled(GrDropbox)`
    font-size: 30px;
`;

type Props = React.ComponentProps<typeof Box> & {
    message?: string;
    fullscreen?: boolean;
};

export const EmptyResponse = ({
    message,
    fullscreen,
    ...other
}: Props): JSX.Element => {
    const theme = useTheme();

    return (
        <Centered fullscreen={fullscreen} {...other}>
            <Row spacing={2}>
                <Icon color={theme.palette.primary.light} />
                <Typography
                    capitalized
                    variant="h5"
                    textId={message ?? 'nothingFound'}
                    color="primary.light"
                />
            </Row>
        </Centered>
    );
};
