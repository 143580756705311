import Box from '@mui/material/Box';

import { Row } from 'components/Row';
import { ReactComponent as CertificateIcon } from 'assets/certificate.svg';

import { SoldBanner } from './SoldBanner';

type Props = {
    isSold: boolean;
};

export const ItemViewTopBar = ({ isSold }: Props): JSX.Element => (
    <Row
        sx={{
            justifyContent: isSold ? 'space-between' : 'flex-end'
        }}>
        {isSold && <SoldBanner />}
        <Box sx={{ justifyContent: 'flex-end' }}>
            <CertificateIcon height={40} width={40} />
        </Box>
    </Row>
);
