import { DataRow } from 'features/itemGallery/DataRow';
import { DataPoint } from 'components/DataPoint';
import { defaultCurrencySymbol } from 'config';

type Props = {
    data?: number;
};

export const SalesPrice = ({ data }: Props): JSX.Element => (
    <DataRow textId="salesPrice">
        <DataPoint bold data={data} prefix={defaultCurrencySymbol} />
    </DataRow>
);
