/* eslint-disable max-len, prettier/prettier */

import { Redirect, Route, Switch } from 'react-router-dom';

import { LandingPage } from 'pages/LandingPage';
import { RoadmapPage } from 'pages/RoadmapPage';
import { WhoWeArePage } from 'pages/WhoWeArePage';
import { PortfolioPage } from 'pages/PortfolioPage';
import { InvestingPage } from 'pages/InvestingPage';
import { ItemGalleryPage } from 'pages/ItemGalleryPage';
import { NotFoundPage } from 'pages/NotFoundPage';
import { EditProfilePage } from 'pages/EditProfilePage';
import { ProtectedRoute } from 'components/ProtectedRoute';
import { MarketplacePage } from 'pages/MarketplacePage';
import { ProfileOverviewPage } from 'pages/ProfileOverviewPage';
import { UnderConstructionPage } from 'pages/UnderConstructionPage';
import { SomethingWentWrongPage } from 'pages/SomethingWentWrongPage';
import { ProductChartListPage } from 'pages/ProductChartListPage';
import { PortfolioTablesPage } from 'pages/PortfolioTablesPage';
import { MerchantAssetsPage } from 'pages/MerchantAssetsPage';
import { AdminAssetListPage } from 'pages/AdminAssetListPage';
import { ContactUsPage } from 'pages/ContactUsPage';
import { OurTeamPage } from 'pages/OurTeamPage';
import { LoyaltyTiersPage } from 'pages/LoyaltyTiersPage';
import { PrivacyPolicyPage } from 'pages/PrivacyPolicyPage';
import { FaqPage } from 'pages/FAQPage';
import { TestPage } from 'pages/TestPage';
import { Wallet } from 'pages/Wallet';
import { Settings } from 'pages/Settings';
import { Staking } from 'pages/Staking';
import { SupportPage } from 'pages/SupportPage';
import { Invest } from 'pages/Invest';
import { ItemInvest } from 'pages/ItemInvest';
import { InvestChartPage } from 'pages/InvestChartPage';
export const Routes = (): JSX.Element => (
    <Switch>
        <Route path="/auth" component={LandingPage} />
        <Route path="/who-we-are" component={WhoWeArePage} />
        <Route path="/our-team" component={OurTeamPage} />
        <Route path="/roadmap" component={RoadmapPage} />
        <Route path="/loyalty-tiers" component={LoyaltyTiersPage} />
        <Route path="/contact-us" component={ContactUsPage} />
        <Route path="/privacy-policy" component={PrivacyPolicyPage} />
        <Route path="/faq" component={FaqPage} />
        <Route path="/not-found" component={NotFoundPage} />
        <Route
            path="/something-went-wrong"
            component={SomethingWentWrongPage}
        />
        <ProtectedRoute path="/profile" component={ProfileOverviewPage} />
        <ProtectedRoute path="/edit-profile" component={EditProfilePage} />
        <ProtectedRoute path="/marketplace" component={MarketplacePage} />
        <ProtectedRoute path="/investing" component={InvestingPage} />
        <ProtectedRoute path="/invest" component={Invest} />
        <ProtectedRoute
            path="/product-chart-list"
            component={ProductChartListPage}
        />
        <ProtectedRoute path="/portfolio" component={PortfolioPage} />
        <ProtectedRoute
            path="/portfolio-tables/:table?"
            component={PortfolioTablesPage}
        />
        <ProtectedRoute path="/item-gallery" component={ItemGalleryPage} />
        <ProtectedRoute
            path="/market-item/:itemId"
            component={UnderConstructionPage}
        />
        <ProtectedRoute path="/staking" component={InvestChartPage} />
        <ProtectedRoute path="/settings" component={Settings} />
        <ProtectedRoute path="/support" component={SupportPage} />
        <ProtectedRoute path="/invest-chart/:nftId" component={ItemInvest} />
        <ProtectedRoute
            path="/merchant-assets"
            component={MerchantAssetsPage}
        />
        <ProtectedRoute path="/my-wallet/:userId" component={Wallet} />
        <ProtectedRoute
            path="/admin/asset-list"
            component={AdminAssetListPage}
        />
        <ProtectedRoute path="/test" component={TestPage} />
        <Redirect exact from="/" to="auth" />
        <Redirect from="/" to="/not-found" />
    </Switch>
);
