import { useState, MouseEvent } from 'react';

import { Notification } from 'config/types/user';

import { getUserId } from 'state/user/selectors';
import { useAppSelector } from 'hooks/useAppSelector';
import { useUserNotifications } from 'hooks/useUserNotifications';
import { nftTokenService } from 'services/NFTTokenService';
import { nftMarketplaceService } from 'services/NFTMarketplaceService';

type StateLogic = {
    open: boolean;
    anchorEl: HTMLButtonElement | null;
    toggleOpen: (e: MouseEvent<HTMLButtonElement>) => void;
    notifications: Notification[];
    deleteNotification: ({ id }: { id: string }) => void;
};

export const useNotifications = (): StateLogic => {
    const userId = useAppSelector(getUserId);

    const [open, setOpen] = useState(false);

    const { notifications, deleteNotification } = useUserNotifications(userId);

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const toggleOpen = (e: MouseEvent<HTMLButtonElement>): void => {
        setOpen(!open);
        setAnchorEl(e.currentTarget);
        // nftTokenService.debugContract();
        // nftMarketplaceService.debugContract();
    };

    return { open, anchorEl, toggleOpen, notifications, deleteNotification };
};
