import Stack from '@mui/material/Stack';

import { DataPoint } from 'components/DataPoint';
import { Typography } from 'components/Typography';
import { AssetListingStatus } from 'config/types/asset';
import { setTimestampToDate } from 'libs/utils';

type Props = {
    date: string;
    status: AssetListingStatus;
};

export const ItemCreationDate = ({ date, status }: Props): JSX.Element => (
    <Stack alignItems="flex-end">
        <Typography
            sx={{
                '@media (max-width: 400px)': {
                    paddingRight: '10px',
                    fontSize: '12px'
                }
            }}
            uppercased
            variant="body1"
            textId={status === 'sold' ? 'date purchased' : 'date created'}
        />
        <DataPoint
            sx={{
                '@media (max-width: 400px)': {
                    paddingRight: '10px',
                    fontSize: '12px'
                }
            }}
            bold
            variant="body1"
            data={date}>
            {setTimestampToDate(date)}
        </DataPoint>
    </Stack>
);
