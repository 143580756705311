import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import MuiCardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';

import {
    EditProfileFormInput,
    EditProfileFormInputWithAvatar
} from 'config/types/user';
import { userTypes } from 'config';
import { StandardCard } from 'components/StandardCard';
import { useAppSelector } from 'hooks/useAppSelector';
import { getUserProfileData } from 'state/user/selectors';

import { InputField } from './InputField';
import { SelectValue } from './SelectValue';
import { AvatarUpload } from './AvatarUpload';
import { CancelButton } from './CancelButton';
import { SubmitButton } from './SubmitButton';
import { useEditProfile } from './useEditProfile';
import { InputTextField } from './InputTextField';

import { editProfileSchema } from './editProfileSchema';

const CardActions = styled(MuiCardActions)(({ theme }) => ({
    display: 'flex',
    margin: 'auto',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
}));

const Form = styled('form')`
    text-align: center;
    margin-top: 80px;
`;

export const EditProfile = (): JSX.Element => {
    const [onSubmit, submitting] = useEditProfile();

    const {
        avatar,
        company,
        lastName,
        firstName,
        userType,
        description,
        profileImage,
        paymentAccount
    } = useAppSelector(getUserProfileData);

    const formMethods = useForm<EditProfileFormInputWithAvatar>({
        // The input validations are defined by Yup
        resolver: yupResolver(editProfileSchema),
        // The currently stored user data are the default form values
        defaultValues: {
            company: company || '',
            userType: userType || 'customer',
            lastName: lastName || '',
            firstName: firstName || '',
            description: description || '',
            paymentAccount: paymentAccount || ''
        }
    });

    return (
        <FormProvider {...formMethods}>
            <AvatarUpload name={firstName} filepath={avatar || profileImage} />
            <StandardCard
                sx={{
                    margin: 'auto',
                    maxWidth: '91ch',
                    py: 0
                }}>
                <Form
                    noValidate
                    autoComplete="off"
                    onSubmit={formMethods.handleSubmit(onSubmit)}>
                    <CardContent>
                        <Grid container spacing={4}>
                            <InputTextField<EditProfileFormInput>
                                name="firstName"
                                disabled={submitting}
                            />
                            <InputTextField<EditProfileFormInput>
                                name="lastName"
                                disabled={submitting}
                            />
                            <InputTextField<EditProfileFormInput>
                                name="company"
                                disabled={submitting}
                                size={4}
                            />
                            <InputField size={3}>
                                <SelectValue<EditProfileFormInput>
                                    name="userType"
                                    values={userTypes}
                                    disabled={submitting}
                                />
                            </InputField>
                            <InputTextField<EditProfileFormInput>
                                name="paymentAccount"
                                disabled={submitting}
                                size={5}
                            />
                            <InputTextField<EditProfileFormInput>
                                fullWidth
                                multiline
                                name="description"
                                disabled={submitting}
                            />
                        </Grid>
                    </CardContent>
                    <CardActions>
                        <CancelButton disabled={submitting} />
                        <SubmitButton disabled={submitting} />
                    </CardActions>
                </Form>
            </StandardCard>
        </FormProvider>
    );
};
