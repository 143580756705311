import { grey } from '@mui/material/colors';
import startCase from 'lodash/startCase';
import { ErrorMessage } from '@hookform/error-message';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { FormControl, FormLabel, TextField, useTheme } from '@mui/material';

import { FormHelperText } from 'components/FormHelperText';

type Props = React.ComponentProps<typeof TextField> & {
    label: string;
    name: string;
    required?: boolean;
    placeholder?: string;
};

export const InputBox = ({
    name,
    label,
    required,
    placeholder,
    ...props
}: Props): JSX.Element => {
    const { t } = useTranslation();
    const theme = useTheme();
    const {
        register,
        formState: { errors }
    } = useFormContext();

    const error = errors?.[name]?.message;

    const { sx, ...other } = props;

    const labelText = `${startCase(t(label))}${required ? '*' : ''}`;

    const placeholderText =
        placeholder && `${startCase(t(placeholder))}${required ? '*' : ''}`;

    return (
        <FormControl>
            <FormLabel
                sx={{
                    color: theme.palette.primary.contrastText,
                    fontSize: '.72rem',
                    lineHeight: '1rem',
                    paddingTop: '16px'
                }}>
                {labelText}
            </FormLabel>
            <TextField
                sx={{
                    '& label': {
                        color: 'black'
                    },
                    backgroundColor: 'white',
                    borderRadius: '3px',
                    borderColor: 'blue',
                    '& .MuiInput-input': {
                        color: 'black',
                        paddingLeft: '.25rem'
                    },
                    ...sx
                }}
                InputProps={{ disableUnderline: true }}
                placeholder={placeholderText}
                variant="standard"
                {...register(name)}
                {...other}
            />
            {error && (
                <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }: { message: string }) => (
                        <FormHelperText textId={message} />
                    )}
                />
            )}
        </FormControl>
    );
};
