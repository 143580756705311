import { RedButton } from 'components/buttons/RedButton';
import { Typography } from 'components/Typography';
import { LightButton } from 'components/buttons/LightButton';
import { PurpleButton } from 'components/buttons/PurpleButton';
import { AssetListingStatus } from 'config/types/asset';
import { useUpdateAssetData } from 'hooks/useUpdateAssetData';
import { Spinner } from 'components/Spinner';

type Props = {
    id: string;
    status: AssetListingStatus;
    onCreate: () => void;
};

export const ListingStatusButton = ({
    id,
    status,
    onCreate
}: Props): JSX.Element => {
    const [updateAssetData, processing] = useUpdateAssetData(id);

    const onCancelListing = async (): Promise<void> =>
        updateAssetData({ status: 'notListed' });

    const statusToButton: Record<AssetListingStatus, JSX.Element> = {
        sold: (
            <PurpleButton
                textId="listItem"
                onClick={onCreate}
                loading={processing}
            />
        ),
        listed: <LightButton loading={processing} textId="delistItem" />,
        denied: (
            <Typography bold sx={{ color: 'error.main' }} textId="denied" />
        ),
        pending: (
            <RedButton
                textId="cancelProcess"
                onClick={onCancelListing}
                loading={processing}
            />
        ),
        notListed: (
            <PurpleButton
                textId="listItem"
                onClick={onCreate}
                loading={processing}
            />
        ),
        unverified: (
            <LightButton
                textId="verify"
                onClick={onCreate}
                loading={processing}
            />
        ),
        processing: <Spinner />
    };

    return statusToButton[status];
};
