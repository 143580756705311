import { useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';

import { Typography } from 'components/Typography';

export const InvestInHoldingsInstructions = (): JSX.Element => {
    const theme = useTheme();

    return (
        <Stack spacing={4}>
            <Typography
                variant="body2"
                textId="Be aware that completing more than 3 Sell and Invest Holdings
                transactions in a 24 hour period will result in a fee higher
                than 2%."
            />
            <Typography variant="body2" textId="Do you wish to proceed?" />
        </Stack>
    );
};
