import Grid from '@mui/material/Grid';

import { UserFundsCard } from 'features/dataCards/userFundsCard/UserFundsCard';
import { UserItemsSnapshot } from 'features/listSnapshots/userItemsSnapshot/UserItemsSnapshot';
import { UserLimitOrdersSnapshot } from 'features/listSnapshots/UserLimitOrdersSnapshot';
import { UserHistoricalActivitySnapshot } from 'features/listSnapshots/userHistoricalActivitySnapshot/UserHistoricalActivitySnapshot';

export const PortfolioPage = (): JSX.Element => (
    <Grid
        container
        spacing={2}
        sx={{
            width: '100vw',
            position: 'relative',
            margin: 'auto',
            '@media (min-width: 900px)': {
                width: '90vw',
                margin: '0 auto'
            },
            '@media (max-width: 540px)': {
                paddingLeft: '0px',
                paddingRight: '0px',
                margin: '0 auto',
                marginTop: '1rem'
            },
            '@media (max-width: 290px)': {
                paddingLeft: '0px',
                paddingRight: '0px',
                margin: '0 auto'
            }
        }}>
        <Grid
            item
            xs={12}
            md={5}
            lg={4}
            sx={{
                '@media (max-width: 600px)': {
                    maxWidth: '95%',
                    position: 'relative'
                }
            }}>
            <UserFundsCard />
        </Grid>
        <Grid
            item
            xs={12}
            md={7}
            lg={8}
            sx={{
                '@media (max-width: 600px)': {
                    maxWidth: '95%',
                    position: 'relative'
                }
            }}>
            <UserItemsSnapshot />
        </Grid>
        <Grid
            item
            xs={12}
            md={5}
            lg={4}
            sx={{
                '@media (max-width: 600px)': {
                    maxWidth: '95%',
                    position: 'relative'
                }
            }}>
            <UserHistoricalActivitySnapshot />
        </Grid>
        <Grid
            item
            xs={12}
            md={7}
            lg={8}
            sx={{
                marginBottom: '10rem',
                '@media (max-width: 600px)': {
                    maxWidth: '95%',
                    position: 'relative'
                }
            }}>
            <UserLimitOrdersSnapshot />
        </Grid>
    </Grid>
);
