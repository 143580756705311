import { styled } from '@mui/material/styles';

import { Main } from 'components/Main';
import { Sidebar } from 'features/sidebar/Sidebar';

const Container = styled('div')`
    height: 100%;
    display: flex;
`;

export const Layout = (): JSX.Element => (
    <Container>
        <Sidebar />
        <Main />
    </Container>
);
