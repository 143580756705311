import { Typography } from 'components/Typography';

type Props = {
    sx: Record<string, unknown>;
    data: string;
};

export const ItemNameMobile = ({ sx, data }: Props): JSX.Element => (
    <Typography
        sx={{
            width: '100%',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            textAlign: 'center',
            ...sx
        }}
        uppercased
        variant="h5"
        title={data}>
        {data}
    </Typography>
);
