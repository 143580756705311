import { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler, UseFormReturn } from 'react-hook-form';

import { useAddNewAsset } from 'hooks/useAddNewAsset';
import { AddNewAssetFormInputMultiple } from 'config/types/asset';

import { MerchantInvestSchema } from './MerchantInvestSchema';

type StateLogic = {
    onClose: () => void;
    onSubmit: (data: AddNewAssetFormInputMultiple) => void;
    submitting: boolean;
    fileDropped: boolean;
    // eslint-disable-next-line @typescript-eslint/ban-types
    formMethods: UseFormReturn<AddNewAssetFormInputMultiple, object>;
    onFileDropped: () => void;
    onFileRemoved: () => void;
};

export const useMerchantInvestModal = (handleClose: () => void): StateLogic => {
    const [fileDropped, setFileDropped] = useState<boolean>(false);
    const onFileDropped = (): void => {
        setFileDropped(true);
    };

    const onFileRemoved = (): void => {
        setFileDropped(false);
    };

    const [addNewAsset, submitting] = useAddNewAsset();

    const formMethods = useForm<AddNewAssetFormInputMultiple>({
        resolver: yupResolver(MerchantInvestSchema)
    });

    const onSubmit: SubmitHandler<AddNewAssetFormInputMultiple> = async (
        values
    ) => {
        console.log('creating new asset');
        await addNewAsset(values);
        formMethods.reset();
        handleClose();
    };
    const onClose = (): void => {
        formMethods.reset();
        setFileDropped(false);
        handleClose();
    };

    return {
        onClose,
        onSubmit,
        submitting,
        fileDropped,
        formMethods,
        onFileDropped,
        onFileRemoved
    };
};
