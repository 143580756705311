import * as yup from 'yup';

import { isValidPictureFileType } from 'libs/utils';
import {
    minAssetPrice,
    maxAssetPrice,
    minAssetDescription,
    maxAssetDescription
} from 'config/formValues';

export const createListingSchema = yup
    .object({
        price: yup.number().required().min(minAssetPrice).max(maxAssetPrice),
        color: yup.string().required().min(2),
        material: yup.string().required().min(2),
        description: yup
            .string()
            .required()
            .min(minAssetDescription)
            .max(maxAssetDescription),
        dimensions: yup.string().required()
    })
    .required();
