import Box from '@mui/material/Box';
import MuiButton from '@mui/material/Button';

import { AssetListingStatus } from 'config/types/asset';

import { Spinner } from './Spinner';
import { RedButton } from './buttons/RedButton';
import { DarkButton } from './buttons/DarkButton';
import { LightButton } from './buttons/LightButton';
import { BlackButton } from './buttons/BlackButton';
import { YellowButton } from './buttons/YellowButton';

type Props = React.ComponentProps<typeof MuiButton> & {
    status: AssetListingStatus;
    fullWidth?: boolean;
};

const statusToButton = (
    fullWidth?: boolean
): Record<AssetListingStatus, JSX.Element> => ({
    listed: <DarkButton fullWidth={fullWidth} textId="listed" />,
    denied: <RedButton fullWidth={fullWidth} textId="denied" />,
    pending: <YellowButton fullWidth={fullWidth} textId="pending" />,
    notListed: <LightButton fullWidth={fullWidth} textId="notListed" />,
    unverified: <BlackButton fullWidth={fullWidth} textId="unverified" />,
    sold: <LightButton fullWidth={fullWidth} textId="sold" />,
    processing: <Spinner />
});

export const AssetStatusLabel = ({ status, fullWidth }: Props): JSX.Element =>
    statusToButton(fullWidth)[status] ?? <Box />;
