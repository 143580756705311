import { Grid } from '@mui/material';

import { ItemCard } from 'features/itemCard/ItemCard';
import { AssetItem } from 'config/types/asset';

import { FetchingScreen } from './FetchingScreen';

type Props<T> = {
    list: T[];
    loading: boolean;
    children: (item: T) => React.ReactNode;
    onSelectitem: (item: T) => void;
};

export const ItemList = <T extends AssetItem>({
    list,
    loading,
    children,
    onSelectitem
}: Props<T>): JSX.Element => (
    <FetchingScreen<T[]>
        fullscreen
        data={list}
        fetching={loading}
        empty={!list || !list.length}>
        {(data: T[]) => (
            <Grid sx={{ mt: '30px' }} container spacing={3}>
                {data.map((item: T) => (
                    <Grid key={item.id} item xs={12} sm={6} md={4} xl={3}>
                        <ItemCard<T>
                            data={item}
                            onSelect={() => onSelectitem(item)}>
                            {children(item)}
                        </ItemCard>
                    </Grid>
                ))}
            </Grid>
        )}
    </FetchingScreen>
);
