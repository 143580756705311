import Box from '@mui/material/Box';
import { autoPlay } from 'react-swipeable-views-utils';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import SwipeableViews from 'react-swipeable-views';

import { Row } from 'components/Row';
import { ItemView } from 'features/itemGallery/ItemView';
import { AssetItem } from 'config/types/asset';
import { FetchingScreen } from 'components/FetchingScreen';

import { useItemGallery } from './useItemGallery';
import { ItemViewMobile } from './ItemViewMobile';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export const ItemGallery = (): JSX.Element => {
    const {
        list,
        fetching,
        activeStep,
        menuCollapsed,
        handleNext,
        handleBack,
        handleStepChange
    } = useItemGallery();

    const arrowStyle = {
        color: 'primary.dark',
        cursor: list && list.length > 1 ? 'pointer' : 'inherit',
        opacity: list && list.length > 1 ? 1 : 0.5,
        fontSize: '80px',
        '&:hover': {
            color: list && list.length > 1 ? 'primary.light' : 'primary.dark'
        }
    };

    return (
        <FetchingScreen<AssetItem[]>
            fullscreen
            data={list}
            fetching={fetching}
            empty={!list || !list.length}>
            {(data: AssetItem[]) => (
                <Row
                    alignItems="center"
                    justifyContent="center"
                    style={{
                        paddingTop: '3rem',
                        margin: '10px',
                        placeContent: 'center',
                        width: '90vw'
                    }}>
                    <ChevronLeft
                        sx={arrowStyle}
                        onClick={handleBack}
                        id="chevron-left-button"
                    />
                    <AutoPlaySwipeableViews
                        index={activeStep}
                        onChangeIndex={handleStepChange}
                        enableMouseEvents
                        id="auto-play-swipeable-views">
                        {data.map((item: AssetItem, index) => (
                            <Box key={item.id}>
                                {Math.abs(activeStep - index) <= 2 ? (
                                    <>
                                        <ItemView
                                            sx={{
                                                display: {
                                                    xs: 'none',
                                                    md: menuCollapsed
                                                        ? 'flex'
                                                        : 'none',
                                                    lg: 'flex'
                                                }
                                            }}
                                            data={item}
                                        />
                                        <ItemViewMobile
                                            sx={{
                                                margin: '0 auto',
                                                display: {
                                                    md: !menuCollapsed
                                                        ? 'block'
                                                        : 'none',
                                                    lg: 'none'
                                                }
                                            }}
                                            data={item}
                                        />
                                    </>
                                ) : null}
                            </Box>
                        ))}
                    </AutoPlaySwipeableViews>
                    <ChevronRight
                        sx={arrowStyle}
                        onClick={handleNext}
                        id="chevron-right-button"
                    />
                </Row>
            )}
        </FetchingScreen>
    );
};
