import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Typography } from 'components/Typography';
import { StakingChart } from 'features/stakingChart/StakingChart';

const CustomDiv = styled('div')(() => ({}));
const CardDiv = styled('div')(({ theme }) => ({
    width: '100%',
    height: '299px',
    maxWidth: '400px',
    padding: '20px',
    backgroundImage: theme.palette.error.dark,
    boxShadow: '0px 0px 6px 0px #424040',
    border: '2px solid #000000',
    borderRadius: '5px'
}));
const Price = styled('p')(({ theme }) => ({
    lineHeight: '38px',
    textAlign: 'right',
    margin: '0px',
    fontWeight: 'bold',
    color: theme.palette.primary.contrastText,
    '@media (min-width: 500px)': {
        fontSize: '26px',
        fontWeight: 600
    }
}));

const Flex = styled('div')(({ theme }) => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
}));

type Props = {
    asset: string;
    price: number;
    color: string;
    dimensions: string | undefined;
    description: string;
    material: string;
    image: string;
    nftId: number;
};

export const AssetCard = ({
    asset,
    price,
    color,
    dimensions,
    description,
    material,
    image,
    nftId
}: Props): JSX.Element => {
    const [isTall, setIsTall] = useState(false);
    const assetRef = useRef<HTMLParagraphElement | null>(null);
    useEffect(() => {
        if (!assetRef.current) return;
        const elementHeight = assetRef.current.innerText.length;
        if (elementHeight < 17) {
            setIsTall(true);
        }
    }, []);

    const Asset = styled('p')(({ theme }) => ({
        fontFamily: 'Jost',
        fontWeight: 400,
        fontSize: '15px',
        lineHeight: '29px',
        paddingLeft: '15px',
        color: theme.palette.primary.contrastText,
        textTransform: 'uppercase',
        marginTop: isTall ? '27px' : '0px',
        '@media (min-width: 400px)': {
            fontSize: '20px'
        }
    }));

    return (
        <Link
            to={{
                pathname: `/invest-chart/${nftId}`,
                state: {
                    asset,
                    price,
                    color,
                    dimensions,
                    description,
                    material,
                    image
                }
            }}>
            <CardDiv>
                <Flex
                    sx={{
                        justifyContent: 'normal'
                    }}>
                    <CustomDiv
                        sx={{
                            width: '115px',
                            height: '85px'
                        }}>
                        <img
                            src={image}
                            alt="asset"
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                objectPosition: 'center',
                                borderRadius: '10px'
                            }}
                        />
                    </CustomDiv>
                    <CustomDiv
                        className="text-container"
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between'
                        }}>
                        <Asset
                            ref={assetRef}
                            sx={{
                                fontFamily: 'Jost',
                                fontWeight: 400,
                                fontSize: '15px',
                                lineHeight: '29px',
                                paddingLeft: '15px',
                                textTransform: 'uppercase',
                                marginTop: isTall ? '27px' : '0px',
                                '@media (min-width: 400px)': {
                                    fontSize: '20px'
                                }
                            }}>
                            {asset}
                        </Asset>
                        <Price>${price}</Price>
                    </CustomDiv>
                </Flex>
                <CustomDiv>
                    <CustomDiv
                        sx={{
                            width: '100%',
                            height: '1px',
                            backgroundColor: '#a5cee4',
                            margin: '20px 0px'
                        }}></CustomDiv>
                    <CustomDiv className="staking">
                        <StakingChart
                            timeAgoFilter="3 Months"
                            domain={{}}
                            chartPadding={{ left: 0, right: 150, bottom: 50 }}
                            fontSize="50px"
                            offsetX={140}
                        />
                    </CustomDiv>
                </CustomDiv>
            </CardDiv>
        </Link>
    );
};
