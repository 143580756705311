import { Row } from 'components/Row';
import { MainButton } from 'components/buttons/MainButton';
import { DarkButton } from 'components/buttons/DarkButton';
import { LightButton } from 'components/buttons/LightButton';

type Props = {
    onSell: () => void;
    onStaking: () => void;
    onShowInfo: () => void;
};

export const UnsoldItemActions = ({
    onSell,
    onStaking,
    onShowInfo
}: Props): JSX.Element => (
    <Row justifyContent="space-between">
        <LightButton textId="info" onClick={onShowInfo} />
        <DarkButton textId="stakingPortfolio" onClick={onStaking} />
        <MainButton textId="sell" onClick={onSell} />
    </Row>
);
