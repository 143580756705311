import sortBy from 'lodash/sortBy';
import maxBy from 'lodash/maxBy';
import { useEffect } from 'react';

import { Bid } from 'config/types/index';
import { RootState } from 'app/store';
import { getAssetBids } from 'state/assets/selectors';
import { useAppSelector } from 'hooks/useAppSelector';
import { getItemPictures } from 'libs/utils';
import { useFetchItemBids } from 'hooks/useFetchItemBids';
import { AssetItem, AssetMediaMetadata } from 'config/types/asset';

type StateLogic = {
    pictures: AssetMediaMetadata[];
    fetchingBids: boolean;
    highestBid?: Bid;
};

export const useItemCard = (item: AssetItem): StateLogic => {
    const bids = useAppSelector((state: RootState) =>
        getAssetBids(state, item.id)
    );

    const highestBid = maxBy(bids, 'value');

    const [fetchBids, fetchingBids] = useFetchItemBids();

    const pictures = sortBy(getItemPictures(item), 'priority');

    useEffect(() => {
        (async () => {
            const { id, nftId } = item;
            await fetchBids({ id, nftId });
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { pictures, highestBid, fetchingBids };
};
