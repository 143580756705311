import { Row } from 'components/Row';
import { TextField } from 'components/TextField';
import { AssetItem } from 'config/types/asset';
import { LightButton } from 'components/buttons/LightButton';
import { minBidValueAllowed } from 'config';

import { DarkButton } from 'components/buttons/DarkButton';
import { useBidInput } from './useBidInput';

type Props = {
    item: AssetItem;
    handleClose: () => void;
};

export const BidInput = ({ item, handleClose }: Props): JSX.Element => {
    const { onSubmit, processing, isButtonDisabled, handleBidChange } =
        useBidInput(item, handleClose);

    return (
        <Row spacing={1} alignItems="center" justifyContent="flex-end">
            <TextField
                sx={{ width: 120, mr: 2 }}
                type="number"
                name="bid"
                onChange={handleBidChange}
                placeholder="yourBid"
                inputProps={{
                    min: minBidValueAllowed,
                    sx: { fontSize: 22, textAlign: 'center' }
                }}
            />
            <LightButton
                textId="submit"
                onClick={onSubmit}
                loading={processing}
                disabled={isButtonDisabled}
            />
            <DarkButton
                textId="cancel"
                onClick={handleClose}
                disabled={processing}
            />
        </Row>
    );
};
