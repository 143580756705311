import { Box, Typography } from '@mui/material';

import { Grid } from 'components/Grid';
import { Space } from 'components/Space';
import { AssetData } from 'config/types/asset';
import { LightDivider } from 'components/LightDivider';
import { ReactComponent as WatchImage } from 'assets/watch-example.svg';

type Props = {
    data: AssetData;
};

export const ProductOverview = ({ data }: Props): JSX.Element => (
    <div style={{ color: 'white', paddingLeft: 80 }}>
        <Typography variant="h5" sx={{ fontWeight: 300 }}>
            {data.name.toUpperCase()}
        </Typography>
        <Box sx={{ pt: 3, textAlign: 'center' }}>
            <WatchImage />
        </Box>
        <Box sx={{ py: 3 }}>
            <Typography variant="body2">{data.description}</Typography>
        </Box>
        <LightDivider />
        <Space height={20} />
        <Grid container spacing={1}>
            <Grid item md={4} textId="color" uppercased />
            <Grid item md={4} textId="material" uppercased />
            <Grid item md={4} textId="dimensions" uppercased />
            <Grid md={4} item>
                {data.color}
            </Grid>
            <Grid md={4} item>
                {data.material}
            </Grid>
            <Grid md={4} item>
                {data.dimensions}
            </Grid>
        </Grid>
    </div>
);
