import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { RedButton } from 'components/buttons/RedButton';

import { Row } from 'components/Row';
import { DarkButton } from 'components/buttons/DarkButton';
import { BlackButton } from 'components/buttons/BlackButton';
import { LightButton } from 'components/buttons/LightButton';
import { PaymentStatus, PortfolioFundsData } from 'config/types';
import { portfolioFundsData } from 'dummy/portfolioFundsData';
import { YellowButton } from 'components/buttons/YellowButton';
import { TableCell } from 'components/table/TableCell';
import { TableRow } from 'components/table/TableRow';
import { TableHeader } from 'components/table/TableHeader';

// TO REMOVE
const list = portfolioFundsData.slice(0, 20);

const headers = [
    'transaction type',
    'funds from',
    'funds to',
    'payment method',
    'amount ($)',
    'amount (LVX)',
    'status',
    'actions'
];

const statusToButton: Record<PaymentStatus, JSX.Element> = {
    pending: <YellowButton fullWidth textId="pending" />,
    complete: <RedButton fullWidth textId="complete" />
};

const actionsToButton: Record<PaymentStatus, JSX.Element> = {
    complete: <LightButton fullWidth textId="message user" />,
    pending: (
        <Row spacing={1}>
            <DarkButton fullWidth textId="approve" />
            <BlackButton fullWidth textId="deny" />
        </Row>
    )
};

export const FundsTable = (): JSX.Element => (
    <Table stickyHeader size="small" aria-label="portfolio">
        <TableHeader headers={headers} />
        <TableBody>
            {list.map((row: PortfolioFundsData) => (
                <TableRow key={row.id}>
                    <TableCell>
                        <b>{row.transactionType}</b>
                    </TableCell>
                    <TableCell>{row.fundsFrom}</TableCell>
                    <TableCell>{row.fundsTo}</TableCell>
                    <TableCell>{row.paymentMethod}</TableCell>
                    <TableCell>{row['amount($)'].toLocaleString()}</TableCell>
                    <TableCell>{row['amount(LVX)']}</TableCell>
                    <TableCell>{statusToButton[row.status]}</TableCell>
                    <TableCell>{actionsToButton[row.status]}</TableCell>
                </TableRow>
            ))}
        </TableBody>
    </Table>
);
