import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import MuiCard from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import { Row } from 'components/Row';
import { Typography } from 'components/Typography';
import { LightDivider } from 'components/LightDivider';
import { LightButton } from 'components/buttons/LightButton';
import { BlackButton } from 'components/buttons/BlackButton';
import { ProductStatusLabel } from 'components/ProductStatusLabel';
import { ActiveItemDropData } from 'config/types';
import { defaultCurrencySymbol } from 'config';
import { ReactComponent as ShieldIcon } from 'assets/shield.svg';
import { ReactComponent as WatchImage } from 'assets/watch-example.svg';

import { useTranslation } from 'react-i18next';
import { OwnedShareBar } from './OwnedShareBar';
import { MerchantAssetModal } from 'config/enums/merchantAssetModal';
import { MerchantInvestModal } from 'features/investModal/MerchantInvestModal';
import { useState } from 'react';

type Props = {
    data: ActiveItemDropData;
};

const Card = styled(MuiCard)(({ theme }) => {
    return {
        background: theme.palette.error.dark,
        paddingBottom: '10px'
    };
});

export const ActiveItemDropCard = ({ data }: Props): JSX.Element => {
    const { t } = useTranslation();
    const [openModal, setOpenModal] = useState<boolean>(false);

    const handleCloseModal = (): void => {
        setOpenModal(false);
    };

    const handleOpenModal = (): void => {
        setOpenModal(true);
    };

    return (
        <Card
            sx={{
                '@media (max-width: 290px)': {
                    width: '220px',
                    scale: '90%',
                    position: 'relative',
                    left: '-20px'
                }
            }}>
            <Row justifyContent="space-between">
                <ProductStatusLabel status="newListing" />
                {data.shareOwned ? (
                    <Typography sx={{ mt: 1, mr: 2 }} variant="subtitle2">
                        {data.timeLeft} left
                    </Typography>
                ) : (
                    <Box sx={{ pr: 2, pt: 1 }}>
                        <ShieldIcon height={20} width={20} />
                    </Box>
                )}
            </Row>
            <Stack sx={{ mt: 1, mx: 3 }} spacing={2}>
                <Row alignItems="center" justifyContent="space-between">
                    <Typography uppercased variant="h6">
                        {data.product}
                    </Typography>
                    <WatchImage height={170} width={260} />
                </Row>
                {data.shareOwned ? (
                    <OwnedShareBar
                        nbOfHolders={data.nbOfHolders}
                        shareOwned={data.shareOwned}
                    />
                ) : (
                    <Row spacing={2}>
                        <Typography variant="subtitle2">
                            0 {t('bids')}
                        </Typography>
                        <FiberManualRecordIcon
                            sx={{
                                color: 'primary.light',
                                width: 8,
                                position: 'relative'
                            }}
                        />
                        <Typography variant="subtitle2">
                            {data.timeLeft} left
                        </Typography>
                    </Row>
                )}
                <Row sx={{ pt: 1 }} justifyContent="space-between">
                    <Stack>
                        <Typography bold variant="h4">
                            {defaultCurrencySymbol}
                            {data.price.toLocaleString()}
                        </Typography>
                    </Stack>
                    <Stack alignItems="flex-end">
                        <Typography
                            uppercased
                            variant="body2"
                            textId="date created"
                        />
                        <Typography variant="body2">
                            {data.dateCreated}
                        </Typography>
                    </Stack>
                </Row>
                <LightDivider />
                <Row justifyContent="space-between">
                    <LightButton
                        textId="invest now"
                        onClick={() => handleOpenModal()}
                    />
                    <MerchantInvestModal
                        open={openModal}
                        handleClose={handleCloseModal}
                    />
                    <BlackButton textId="details" />
                </Row>
            </Stack>
        </Card>
    );
};
