import { useState } from 'react';

import { useUserHistoricalActivity } from 'hooks/useUserHistoricalActivity';
import { UserHistoricalActivityItem } from 'config/types/user';
import { defaultCurrency, proprietaryCurrency } from 'config';

type StateLogic = {
    list?: UserHistoricalActivityItem[];
    fetching: boolean;
    currency: string;
    onChangeCurrency: () => void;
};

export const useUserHistoricalActivitySnapshot = (): StateLogic => {
    const [currency, setCurrency] = useState<string>(defaultCurrency);

    const { activities: list, fetching } = useUserHistoricalActivity();

    const onChangeCurrency = (): void => {
        setCurrency(
            currency === defaultCurrency ? proprietaryCurrency : defaultCurrency
        );
    };

    return { list, fetching, currency, onChangeCurrency };
};
