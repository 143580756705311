import { useState, useEffect } from 'react';

import { Typography } from 'components/Typography';
import { Checkbox } from 'components/Checkbox';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ReactComponent as Search } from '../assets/App Assets/Investing/Group.svg';
import { AssetCard } from 'components/AssetCard';
import { useAppSelector } from 'hooks/useAppSelector';
import { isUserAdmin } from 'state/user/selectors';

import { DatabaseService } from 'services/DatabaseService';
import { AssetItem } from 'config/types/asset';

import {
    CustomDiv,
    Flex,
    Title,
    AddButton,
    StyledAppSelect,
    CustomSelect,
    SelectOption,
    SortList,
    SortListItem,
    CustomInput
} from './Invest.styles';

export const Invest = (): JSX.Element => {
    const [category, setCategory] = useState<string[]>([]);
    const [sort, setSort] = useState('');
    const [isSortOptionsDisplayed, setIsSortOptionsDisplayed] = useState(false);
    const [isOptionsDisplayed, setIsOptionsDisplayed] = useState(false);
    const [search, setSearch] = useState('');
    const isAdmin = useAppSelector(isUserAdmin);

    const [listedAssets, setListedAssets] = useState<AssetItem[]>([]);
    const [filteredData, setFilteredData] = useState<AssetItem[]>([]);

    const [categories, setCategories] = useState([
        'Watches',
        'Vehicles',
        'Insurance'
    ]);
    const sorted = [
        'Active Item Drops',
        'Post Item Drop Trading',
        'Title (DESC)',
        'Title (ASC)',
        'Total Return (DESC)',
        'Total Return (ASC)',
        'Purchase Price (DESC)',
        'Purchase Price (ASC)',
        'Sale Price (DESC)',
        'Sale Price (ASC)'
    ];

    const handleSortItemClick = (item: string): void => {
        setSort(item);
        const _listedAssets = [...listedAssets];
        let sortedAssets;

        if (item === 'Title (DESC)') {
            sortedAssets = _listedAssets.sort((a, b) => {
                const nameA = a.name.toLowerCase();
                const nameB = b.name.toLowerCase();
                if (nameA < nameB) {
                    return 1;
                }
                if (nameA > nameB) {
                    return -1;
                }
                return 0;
            });
        } else if (item === 'Title (ASC)' || item === 'Active Item Drops') {
            sortedAssets = _listedAssets.sort((a, b) => {
                const nameA = a.name.toLowerCase();
                const nameB = b.name.toLowerCase();
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            });
        } else if (
            item === 'Sale Price (DESC)' ||
            item === 'Purchase Price (DESC)'
        ) {
            sortedAssets = _listedAssets.sort((a, b) => {
                return b.price - a.price;
            });
        } else if (
            item === 'Sale Price (ASC)' ||
            item === 'Purchase Price (ASC)'
        ) {
            sortedAssets = _listedAssets.sort((a, b) => {
                return a.price - b.price;
            });
        } else {
            sortedAssets = _listedAssets;
        }
        setFilteredData(sortedAssets);
    };

    useEffect(() => {
        const fetchAssets = async (): Promise<void> => {
            const allAssets = await DatabaseService.getAllAssets();
            const listed = allAssets.filter(
                (asset) => asset.status === 'listed'
            );
            setListedAssets(listed);
            const uniqueCategories = Array.from(
                new Set(listed.map((asset) => asset.category))
            );
            setCategories(uniqueCategories);
        };

        fetchAssets();
    }, []);

    return (
        <>
            <CustomDiv
                className="super"
                sx={{
                    width: '92%',
                    margin: '0 auto'
                }}>
                <Flex
                    sx={{
                        alignItems: 'flex-start',
                        flexDirection: 'column',
                        '@media (min-width: 1350px)': {
                            flexDirection: 'row'
                        }
                    }}>
                    <Flex
                        className="first flexed div"
                        sx={{
                            alignItems: 'center',
                            width: '100%',
                            '@media (min-width: 1000px)': {
                                justifyContent: 'space-around'
                            },
                            '@media (min-width: 1350px)': {
                                width: '60%',
                                justifyContent: 'space-between'
                            }
                        }}>
                        <Title></Title>
                        {isAdmin ? (
                            <AddButton
                                sx={{
                                    marginTop: '0px',
                                    '@media (min-width: 1350px)': {
                                        marginTop: '20px'
                                    }
                                }}>
                                ADD LISTING
                            </AddButton>
                        ) : null}
                    </Flex>
                    <Flex
                        className="second flexed div"
                        sx={{
                            height: '100%',
                            justifyContent: 'normal',
                            flexDirection: 'column-reverse',
                            gap: '20px',
                            position: 'relative',
                            zIndex: '10',
                            '@media (min-width: 700px)': {
                                flexDirection: 'row',
                                justifyContent: 'space-between'
                            },
                            '@media (min-width: 1000px)': {
                                justifyContent: 'space-around'
                            },
                            '@media (min-width: 1350px)': {
                                position: 'relative',
                                top: '28px'
                            }
                        }}>
                        <CustomSelect
                            sx={{
                                position: 'relative',
                                order: '1',
                                '@media (min-width: 700px)': {
                                    order: '0'
                                }
                            }}>
                            <Flex
                                sx={{
                                    padding: '4px 8px',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    minWidth: '200px',
                                    cursor: 'pointer',
                                    userSelect: 'none'
                                }}
                                onClick={() =>
                                    setIsOptionsDisplayed(!isOptionsDisplayed)
                                }>
                                <Typography
                                    paragraph={true}
                                    sx={{
                                        margin: '0px'
                                    }}>
                                    Category: {category.join(', ')}
                                </Typography>
                                <KeyboardArrowDownIcon
                                    sx={{
                                        margin: '0px',
                                        cursor: 'pointer',
                                        transform: isOptionsDisplayed
                                            ? 'rotate(0deg)'
                                            : 'rotate(90deg)'
                                    }}
                                />
                            </Flex>
                            {isOptionsDisplayed && (
                                <Flex
                                    sx={{
                                        flexDirection: 'column',
                                        border: '1px solid #a5cee4',
                                        paddingLeft: '8px'
                                    }}>
                                    {categories.map((item) => (
                                        <div
                                            onClick={() => {
                                                if (category.includes(item)) {
                                                    setCategory([]);
                                                } else {
                                                    setCategory([item]);
                                                }
                                            }}>
                                            <Checkbox
                                                size="small"
                                                sx={{
                                                    color: '#a5cee4',
                                                    '&.Mui-checked': {
                                                        color: '#a5cee4'
                                                    }
                                                }}
                                                textId={item}
                                                value={item}
                                                checked={category.includes(
                                                    item
                                                )}
                                            />
                                        </div>
                                    ))}
                                </Flex>
                            )}
                        </CustomSelect>
                        <CustomSelect
                            sx={{
                                positon: 'relative'
                            }}>
                            <Flex
                                className="firstDiv"
                                sx={{
                                    padding: '4px 8px',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    minWidth: '200px',
                                    cursor: 'pointer',
                                    userSelect: 'none'
                                }}
                                onClick={() =>
                                    setIsSortOptionsDisplayed(
                                        !isSortOptionsDisplayed
                                    )
                                }>
                                <Typography
                                    paragraph={true}
                                    sx={{
                                        margin: '0px'
                                    }}>
                                    Sort: {sort}
                                </Typography>
                                <KeyboardArrowDownIcon
                                    sx={{
                                        margin: '0px',
                                        cursor: 'pointer',
                                        transform: isSortOptionsDisplayed
                                            ? 'rotate(0deg)'
                                            : 'rotate(90deg)'
                                    }}
                                />
                            </Flex>

                            {isSortOptionsDisplayed && (
                                <SortList
                                    className="second div"
                                    sx={{
                                        border: '1px solid #a5cee4'
                                    }}>
                                    {sorted.map((item, index) => (
                                        <SortListItem
                                            data-id={item[index]}
                                            onClick={() =>
                                                handleSortItemClick(item)
                                            }>
                                            {item}
                                        </SortListItem>
                                    ))}
                                </SortList>
                            )}
                        </CustomSelect>
                        <CustomDiv
                            sx={{
                                position: 'relative',
                                '@media(min-width: 700px)': {
                                    top: '5px'
                                },
                                order: '1'
                            }}>
                            <CustomDiv>
                                <CustomInput
                                    type="text"
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                            </CustomDiv>
                            <CustomDiv
                                sx={{
                                    display: 'flex',
                                    position: 'absolute',
                                    top: '0px',
                                    gap: '5px'
                                }}>
                                <Search
                                    color="primary"
                                    style={{
                                        width: '24px',
                                        height: '18px'
                                    }}
                                />
                                {search === '' && (
                                    <Typography paragraph={true}>
                                        Search Assets
                                    </Typography>
                                )}
                            </CustomDiv>
                        </CustomDiv>
                    </Flex>
                </Flex>
                <CustomDiv
                    className="asset container"
                    sx={{
                        margin: '50px auto 0px auto',
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                        gap: '30px',
                        width: '77%',
                        maxWidth: '1300px',
                        position: 'absolute',
                        left: '20px',
                        right: '20px',
                        top: '280px',
                        zIndex: '5',
                        '@media (min-width: 300px)': {
                            width: '81%'
                        },
                        '@media (min-width: 700px)': {
                            top: '170px'
                        }
                    }}>
                    {(filteredData.length > 0 ? filteredData : listedAssets)
                        .filter(
                            (element) =>
                                (category.length === 0 ||
                                    category.includes(element.category)) &&
                                element.name
                                    .toLowerCase()
                                    .includes(search.toLowerCase())
                        )
                        .map((element) => {
                            return (
                                <AssetCard
                                    asset={element.name}
                                    price={element.price}
                                    color={element.color}
                                    dimensions={`${element.dimensions}`}
                                    description={element.description}
                                    material={element.material}
                                    image={element.medias[0].uri}
                                    nftId={element.nftId}
                                />
                            );
                        })}
                </CustomDiv>
            </CustomDiv>
        </>
    );
};
