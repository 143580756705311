import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import MuiCard from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import CardContent from '@mui/material/CardContent';

import { Row } from 'components/Row';
import { AssetItem } from 'config/types/asset';
import { LightDivider } from 'components/LightDivider';
import { ImageCarousel } from 'features/imageCarousel/ImageCarousel';

import { Spinner } from 'components/Spinner';
import { ItemCardBid } from './ItemCardBid';
import { useItemCard } from './useItemCard';
import { ItemCardTitle } from './ItemCardTitle';
import { ItemCardPrice } from './ItemCardPrice';

type Props<T> = {
    data: T;
    children: React.ReactNode;
    onSelect: () => void;
};

const Card = styled(MuiCard)(({ theme }) => {
    return {
        background: theme.palette.success.dark,
        width: '250px',
        cursor: 'pointer',
        margin: 'auto',
        borderRadius: '5px'
    };
});

export const ItemCard = <T extends AssetItem>({
    data,
    children,
    onSelect
}: Props<T>): JSX.Element => {
    const { pictures, highestBid, fetchingBids } = useItemCard(data);
    return (
        <Card>
            <ImageCarousel images={pictures} onSelect={onSelect} />
            <CardContent
                onClick={onSelect}
                sx={{
                    textAlign: 'center',
                    color: 'common.white'
                }}>
                <Stack spacing={2}>
                    <Stack spacing={1} alignItems="center">
                        <ItemCardTitle title={data.name} />
                        {fetchingBids ? (
                            <Spinner />
                        ) : (
                            <ItemCardBid data={highestBid} />
                        )}
                    </Stack>
                    <LightDivider />
                    <Row justifyContent="space-between">
                        <ItemCardPrice price={data.price} />
                        <Box>{children}</Box>
                    </Row>
                </Stack>
            </CardContent>
        </Card>
    );
};
