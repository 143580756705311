import { useTheme } from '@mui/material/styles';
import {
    VictoryAxis,
    VictoryChart,
    VictoryLabel,
    VictoryTooltip,
    VictoryCandlestick
} from 'victory';

type Props<T> = {
    dates: T[];
    width?: number;
};

export const ProductChart = <T extends Record<string, unknown>>({
    dates,
    width
}: Props<T>): JSX.Element => {
    const theme = useTheme();

    return (
        <VictoryChart
            width={width}
            padding={0}
            domain={{ y: [15, 55] }}
            domainPadding={{ x: [-20, 20] }}>
            <VictoryAxis
                offsetX={0}
                orientation="right"
                dependentAxis
                style={{
                    axis: { stroke: 'transparent' },
                    ticks: { stroke: 'transparent' },
                    tickLabels: { fill: theme.palette.common.white }
                }}
            />
            <VictoryCandlestick
                style={{ data: { stroke: 'none' } }}
                candleRatio={0.4}
                candleColors={{
                    positive: theme.palette.primary.light,
                    negative: theme.palette.primary.dark
                }}
                data={dates}
                labels={({ datum: d }) => [
                    `open: ${d.open}`,
                    `close: ${d.close}`,
                    `high: ${d.high}`,
                    `low: ${d.low}`
                ]}
                labelComponent={
                    <VictoryTooltip
                        width={300}
                        flyoutStyle={{ fill: '#000' }}
                        flyoutWidth={80}
                        flyoutHeight={100}
                        orientation="top"
                        style={{
                            fill: '#EEE',
                            fontFamily: '"Jost",  sans-serif'
                        }}
                        labelComponent={<VictoryLabel lineHeight={1.6} />}
                    />
                }
            />
        </VictoryChart>
    );
};
