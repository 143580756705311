import startCase from 'lodash/startCase';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { ErrorMessage } from '@hookform/error-message';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext, Path } from 'react-hook-form';

import { FormHelperText } from 'components/FormHelperText';
import { useTheme } from '@mui/material';

type Props<T> = React.ComponentProps<typeof TextField> & {
    name: Path<T>;
    size?: string;
    label?: string;
    noLabel?: boolean;
    disabled?: boolean;
    multiline?: boolean;
    required?: boolean;
    labelComponent?: React.ReactNode;
};

export const CustomTextField = <T extends Record<string, unknown>>({
    label = undefined,
    size,
    name,
    noLabel = false,
    disabled = false,
    multiline = false,
    required = false,
    labelComponent,
    ...other
}: Props<T>): JSX.Element => {
    const { t } = useTranslation();

    const {
        control,
        formState: { errors }
    } = useFormContext();
    const theme = useTheme();
    const error = errors?.[name]?.message;

    const labelTxt = `${startCase(t(label ?? name))}${required ? '*' : ''}`;

    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => (
                <FormControl
                    sx={{ pb: error ? 0 : 3 }}
                    variant="standard"
                    fullWidth>
                    {!noLabel && (
                        <InputLabel
                            shrink
                            sx={{ color: theme.palette.primary.contrastText }}
                            htmlFor={name}>
                            {labelTxt ?? labelComponent}
                        </InputLabel>
                    )}
                    <TextField
                        sx={{
                            mt: noLabel ? 0 : 2
                        }}
                        id={labelTxt}
                        size={size ?? 'small'}
                        error={!!error}
                        disabled={disabled}
                        multiline={multiline}
                        InputProps={{
                            style: { backgroundColor: '#fff' }
                        }}
                        {...field}
                        {...other}
                    />
                    {error && (
                        <ErrorMessage
                            errors={errors}
                            name={name}
                            render={({ message }) => (
                                <FormHelperText textId={message} />
                            )}
                        />
                    )}
                </FormControl>
            )}
        />
    );
};
