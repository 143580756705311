import { Typography } from 'components/Typography';

type Props = {
    data: number;
};

export const ItemPrice = ({ data }: Props): JSX.Element =>
    data ? (
        <Typography variant="h6">${data.toLocaleString()}</Typography>
    ) : (
        <Typography sx={{ fontStyle: 'italic' }} textId="missingPrice" />
    );
