import { Web3State } from 'config/types/blockchain';
import { createContext } from 'react';
import blockchainHandler from './BlockchainHandler';

export const BlockChainContext = createContext<Web3State>({} as Web3State);

type Props = {
    children: React.ReactNode;
};

export const BlockchainProvider = ({ children }: Props): JSX.Element => {
    const state = blockchainHandler();

    return (
        <BlockChainContext.Provider value={state}>
            {children}
        </BlockChainContext.Provider>
    );
};
