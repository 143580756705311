import List from '@mui/material/List';
import Box from '@mui/material/Box';
import sortBy from 'lodash/sortBy';
import ListItem from '@mui/material/ListItem';

import { Row } from 'components/Row';
import { Spinner } from 'components/Spinner';
import { DarkButton } from 'components/buttons/DarkButton';
import { Typography } from 'components/Typography';
import { defaultCurrencySymbol } from 'config';

import { useUserBidsPanel } from './useUserBidsPanel';

type Props = {
    handleOpenChat: (id: string) => void;
};

export const UserBidsPanel = ({ handleOpenChat }: Props): JSX.Element => {
    // const { bids, name } =
    //     useAppSelector((state: RootState) =>
    //         getAssetData(state, 'PUXWAJrPsu6GDwq6PO6O')
    //     ) || {};
    const [assetData, fetching] = useUserBidsPanel();

    const { bids, name } = assetData || {};

    if (fetching || !bids || !bids.length)
        return (
            <Box
                sx={{
                    width: 200,
                    height: '100vh',
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                {fetching ? (
                    <Spinner />
                ) : (
                    <Typography
                        capitalized
                        sx={{ m: 'auto' }}
                        color="primary.light"
                        textId="noBids"
                        fontSize={24}
                    />
                )}
            </Box>
        );

    return (
        <Box sx={{ pt: 3, minWidth: 420 }}>
            {sortBy(bids, (e) => e.amount)
                .reverse()
                .map((bid) => (
                    <List key={bid.userId}>
                        <ListItem
                            secondaryAction={
                                <Row spacing={2}>
                                    <DarkButton
                                        size="small"
                                        textId="message"
                                        onClick={() =>
                                            handleOpenChat(
                                                assetData?.merchantId
                                            )
                                        }
                                    />
                                </Row>
                            }>
                            <Row spacing={2}>
                                <Typography bold color="primary.light">
                                    {defaultCurrencySymbol}
                                    {bid.amount.toLocaleString()}
                                </Typography>
                                <Typography textId="on" />
                                <Typography
                                    sx={{ width: 210 }}
                                    noWrap
                                    color="primary.light">
                                    {name}
                                </Typography>
                            </Row>
                        </ListItem>
                    </List>
                ))}
        </Box>
    );
};
