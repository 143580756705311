/* eslint-disable react/no-array-index-key */
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { getUserBalance } from 'state/user/selectors';
import Button from '@mui/material/Button';
import { useAddFundsDrawer } from 'hooks/useAddFundsDrawer';

import { getUserData } from 'state/user/selectors';
import { User } from 'config/types/user';
import { useAppSelector } from 'hooks/useAppSelector';

const GradientCard = styled('div')(({ theme }) => {
    return {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        textAlign: 'center',
        width: '100%',
        background: theme.palette.success.dark,
        boxShadow: '1px 0px 10px rgba(0, 0, 0, 0.42)',
        borderRadius: '5px',
        padding: '60px',
        [theme.breakpoints.down('md')]: {
            maxHeight: '100%',
            padding: '20px'
        }
    };
});

const Item = styled(Grid)(({ theme }) => {
    return {
        maxWidth: '100%',
        padding: '20px 0px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            maxWidth: '100%'
        },
        color: theme.palette.primary.contrastText
    };
});

const Balance = styled('h1')(() => ({
    fontFamily: 'Jost',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '50px',
    lineHeight: '30px',
    letterSpacing: '0.02em',
    textTransform: 'uppercase',
    textAlign: 'center'
}));

const TransactionsTitle = styled('span')(() => ({
    fontFamily: 'Jost',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '30px',
    lineHeight: '30px',
    letterSpacing: '0.02em',
    textTransform: 'uppercase',
    color: '#red',
    display: 'flex'
}));

const Table = styled('table')(({ theme }) => ({
    width: '100% !important',
    borderCollapse: 'collapse',
    margin: '50px 0px',
    [theme.breakpoints.down('md')]: {
        tableLayout: 'fixed',
        width: '100%'
    }
}));

const TableBody = styled('tbody')`
    border-bottom: 1px solid #a5cee4;
`;

const Cell = styled('td')(({ theme }) => {
    return {
        textAlign: 'center',
        padding: '20px',
        fontFamily: 'Jost',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '19px',
        color: theme.palette.primary.contrastText,
        [theme.breakpoints.down('md')]: {
            padding: '5px',
            fontSize: '14px'
        }
    };
});

const Thread = styled('th')(({ theme }) => {
    return {
        fontSize: '22px',
        color: theme.palette.primary.contrastText
    };
});

const AddBankAccount = styled(Button)`
    color: white;
    height: 50px;
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 33px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    background-color: #274b73;
    /* width: 45%; */
    border-radius: 5px;
    padding: 15px;
    &:hover {
        cursor: pointer;
        background-color: #3f77b7;
    }
`;

const WithdrawButton = styled(Button)`
    color: white;
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 33px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    background-color: #3c4858;
    width: 45%;
    border-radius: 5px;
    padding: 15px;
    &:hover {
        cursor: pointer;
        background-color: #505c6c;
    }
`;

const DepositButton = styled(Button)`
    color: black;
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 33px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    background-color: #a5cee4;
    width: 45%;
    border-radius: 5px;
    padding: 15px;
    &:hover {
        cursor: pointer;
        background-color: #b8e4fb;
    }
`;

const headers: string[] = ['Type', 'Description', 'Amount', 'Date', 'Status'];

const tableInfo = [
    {
        type: 'Deposit',
        description: 'Chase bank account #12345667',
        amount: 500000,
        date: new Date(2022, 9, 10),
        status: 'Pending'
    },
    {
        type: 'Withdraw',
        description: 'Chase bank account #12345667',
        amount: 500000,
        date: new Date(2022, 9, 5),
        status: 'Completed'
    },
    {
        type: 'Deposit',
        description: 'Chase bank account #33123467',
        amount: 500000,
        date: new Date(2022, 9, 3),
        status: 'Completed'
    }
];

const renderTableHeader: () => JSX.Element = () => {
    return (
        <tr
            style={{
                height: '80px',
                borderBottom: '1px solid #A5CEE4'
            }}>
            {headers.map((key) => {
                return <Thread key={key}>{key} </Thread>;
            })}
        </tr>
    );
};

const renderTableData: () => void = () => {
    const formatDate = (date: Date): string => {
        let month: string | number = date.getMonth() + 1;
        let day: string | number = date.getDate();
        let year = date.getFullYear();

        // Add leading zeros if necessary
        if (month < 10) {
            month = '0' + month;
        }
        if (day < 10) {
            day = '0' + day;
        }

        return month + '/' + day + '/' + year;
    };

    return tableInfo.map((d, index) => {
        return (
            <tr key={index}>
                <Cell style={{ fontWeight: 600 }}>{d.type}</Cell>
                <Cell style={{ textAlign: 'center', padding: '20px' }}>
                    {d.description}
                </Cell>
                <Cell style={{ textAlign: 'center', padding: '20px' }}>
                    {d.amount}
                </Cell>
                <Cell style={{ textAlign: 'center', padding: '20px' }}>
                    {formatDate(d.date)}
                </Cell>
                <Cell style={{ textAlign: 'center', padding: '20px' }}>
                    {d.status}
                </Cell>
            </tr>
        );
    });
};

export const Wallet = (): JSX.Element => {
    const userBalance: number = useAppSelector(getUserBalance);
    const [toggle] = useAddFundsDrawer();

    return (
        <Grid
            container
            sx={{
                width: '90vw',
                margin: 'auto',
                paddingLeft: '20px',
                paddingRight: '20px',
                '@media (max-width: 600px)': {
                    width: '95vw',
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    paddingTop: '12px'
                },
                '@media (max-width: 280px)': {
                    paddingLeft: '10px',
                    paddingRight: '10px'
                },
                marginBottom: '30vh'
            }}>
            <Item item xs={12}>
                <GradientCard>
                    <Grid container>
                        <Item
                            style={{
                                display: 'flex',
                                justifyContent: 'end',
                                padding: '0px'
                            }}
                            item
                            xs={12}>
                            <AddBankAccount>Add Bank Account</AddBankAccount>
                        </Item>
                        <Item item xs={12}>
                            <Balance
                                sx={{
                                    '@media (max-width: 600px)': {
                                        fontSize: '36px'
                                    }
                                }}>
                                Balance: {userBalance} Lux
                            </Balance>
                        </Item>
                        <Grid item xs={12}>
                            <TransactionsTitle
                                sx={{
                                    textAlign: 'center',
                                    display: 'block',
                                    placeContent: 'center'
                                }}>
                                Transactions
                            </TransactionsTitle>
                        </Grid>
                        <div
                            style={{
                                width: '100%',
                                display: 'grid',
                                placeContent: 'center'
                            }}>
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    '@media (max-width: 600px)': {
                                        scale: '60%',
                                        minWidth: '550px',
                                        marginTop: '-100px'
                                    },
                                    '@media (max-width: 280px)': {
                                        scale: '40%',
                                        minWidth: '550px',
                                        marginTop: '-140px'
                                    }
                                }}>
                                <Table>
                                    <TableBody>
                                        {renderTableHeader()}
                                        {renderTableData()}
                                    </TableBody>
                                </Table>
                            </Grid>
                        </div>
                        <Item
                            sx={{
                                '@media (max-width: 600px)': {
                                    marginTop: '-200px'
                                }
                            }}
                            style={{
                                justifyContent: 'space-between',
                                paddingLeft: '25%',
                                paddingRight: '25%'
                            }}
                            item
                            xs={12}>
                            <WithdrawButton
                                sx={{
                                    '@media (max-width: 600px)': {
                                        fontSize: '12px',
                                        paddingY: '2px'
                                    }
                                }}>
                                Withdraw
                            </WithdrawButton>
                            <DepositButton
                                onClick={() => toggle()}
                                sx={{
                                    '@media (max-width: 600px)': {
                                        fontSize: '12px',
                                        paddingY: '2px'
                                    }
                                }}>
                                Deposit
                            </DepositButton>
                        </Item>
                        <Grid item xs={6} />
                    </Grid>
                </GradientCard>
            </Item>
        </Grid>
    );
};
