import { Row } from 'components/Row';
import { ViewAllLink } from 'components/ViewAllLink';
import { StandardCard } from 'components/StandardCard';
import { ActiveItemDropData } from 'config/types';
import { ActiveItemDropCard } from 'features/dataCards/activeItemDropCard/ActiveItemDropCard';

import { SnapshotTitle } from './SnapshotTitle';

const data: ActiveItemDropData[] = [
    {
        price: 350,
        product: 'hermes h08 graphene composite',
        timeLeft: '4d 20h',
        dateCreated: '06/21/2022',
        shareOwned: 56,
        nbOfHolders: 20
    },
    {
        price: 250,
        timeLeft: '6d 4h',
        product: 'hermes h08 graphene composite',
        dateCreated: '07/15/2022',
        nbOfHolders: 0
    }
];

export const ActiveItemDropsSnapshot = (): JSX.Element => (
    <StandardCard
        sx={{
            width: '680px',
            position: 'absolute',
            right: '0px',
            top: '0px',
            '@media (max-width: 1130px)': {
                width: '100%'
            }
        }}>
        <Row alignItems="baseline" justifyContent="space-between">
            <SnapshotTitle textId="active item drops" />
            <ViewAllLink to="" />
        </Row>
        <Row
            sx={{
                display: 'grid',
                gap: '10px',
                gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
                '@media (max-width: 800px)': {
                    gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
                    height: '1000px'
                }
            }}>
            {data.map((item) => (
                <ActiveItemDropCard data={item} />
            ))}
        </Row>
    </StandardCard>
);
