import { useState, useEffect } from 'react';

import { AssetItem } from 'config/types/asset';
import { AssetBidsDrawer } from 'features/assetBidsDrawer/AssetBidsDrawer';
import { useFetchItemBids } from 'hooks/useFetchItemBids';
import { AssetListingStatusControlModal } from 'features/assetListingStatusControlModal/AssetListingStatusControlModal';
import { AssetAuthenticationStatusControlModal } from 'features/assetAuthenticationStatusControlModal/AssetAuthenticationStatusControlModal';

import { AdminAssetTableRow } from './AdminAssetTableRow';

type Props = {
    data: AssetItem;
};

enum Content {
    none,
    assetListingData,
    assetAuthenticationData
}

export const AdminAssetRecord = ({ data }: Props): JSX.Element => {
    const [openModal, setOpenModal] = useState<Content>();

    const [openedDrawer, setOpenedDrawer] = useState<boolean>(false);

    const handleCloseModal = (): void => {
        setOpenModal(Content.none);
    };

    const handleOpenDrawer = (): void => {
        setOpenedDrawer(true);
    };

    const handleCloseDrawer = (): void => {
        setOpenedDrawer(false);
    };

    const openListingModal = (): void => {
        setOpenModal(Content.assetListingData);
    };

    const onOpenAuthenticationModal = (): void => {
        setOpenModal(Content.assetAuthenticationData);
    };

    const [fetchBids, fetchingBids] = useFetchItemBids();

    useEffect(() => {
        // If has not been listed yet then it does not have a nft id
        if (!data.nftId) return;
        fetchBids({ id: data.id, nftId: data.nftId });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <AdminAssetTableRow
                data={data}
                fetchingBids={fetchingBids}
                onShowBids={handleOpenDrawer}
                onViewListingData={openListingModal}
                onViewAuthenticationData={onOpenAuthenticationModal}
            />
            <AssetListingStatusControlModal
                data={data}
                open={openModal === Content.assetListingData}
                handleClose={handleCloseModal}
            />
            {data.medias?.length && (
                <AssetAuthenticationStatusControlModal
                    data={data}
                    open={openModal === Content.assetAuthenticationData}
                    handleClose={handleCloseModal}
                />
            )}
            <AssetBidsDrawer
                open={openedDrawer}
                assetId={data.id}
                handleClose={handleCloseDrawer}
            />
        </>
    );
};
