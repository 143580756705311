import { ElementType, useState } from 'react';

import { AssetItem } from 'config/types/asset';
import { MarketItemSpecPanel } from 'features/marketItemSpecPanel/MarketItemSpecPanel';
import { MarketItemImagesPanel } from 'features/marketItemImagesPanel/MarketItemImagesPanel';
import { ComponentWithSelectedDataInDrawer } from 'layouts/ComponentWithSelectedDataInDrawer';

type Props = {
    MainComponent: ElementType;
};

export const ComponentWithSelectedAssetInDrawer = ({
    MainComponent
}: Props): JSX.Element => {
    const [picSelected, setPicSelected] = useState(0);
    console.log('asset component', picSelected);

    return (
        <ComponentWithSelectedDataInDrawer<AssetItem>
            noLeftPanelPadding
            rightPanelWidth={350}
            MainComponent={MainComponent}
            leftPanelComponent={(data) => (
                <MarketItemImagesPanel
                    data={data}
                    selected={picSelected}
                    onSelectPic={(i) => setPicSelected(i)}
                />
            )}
            rightPanelComponent={(data) => (
                <MarketItemSpecPanel
                    data={data}
                    selected={picSelected}
                    onSelectPic={(pic) => setPicSelected(pic)}
                />
            )}
        />
    );
};
