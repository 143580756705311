import { getUserId } from 'state/user/selectors';
import { userActions } from 'state/user/userSlice';
import { DatabaseService } from 'services/DatabaseService';

import { useAsyncAction } from './useAsyncAction';
import { useAppSelector } from './useAppSelector';
import { useAppDispatch } from './useAppDispatch';

type StateLogic = [(args: Record<string, unknown>) => Promise<void>, boolean];

export const useUpdateUserData = (noCatch?: boolean): StateLogic => {
    const uid = useAppSelector(getUserId);

    const dispatch = useAppDispatch();

    const updateUserData = async (
        data: Record<string, unknown>
    ): Promise<void> => {
        await DatabaseService.updateUserData(uid, data);
        dispatch(userActions.updateData({ id: uid, data }));
    };

    const [updateUserDataWithTryCatch, processing] = useAsyncAction<
        Record<string, unknown>,
        void
    >(updateUserData, { error: 'errorUpdatingUserData' });

    return [noCatch ? updateUserData : updateUserDataWithTryCatch, processing];
};
