import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { Staking } from './Staking';

import { useState, useEffect } from 'react';

export const InvestChartPage = (): JSX.Element => {
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    const GridContainer = styled(Grid)(({ theme }) => ({
        scrollbarWidth: 'none',
        display: 'flex',
        zIndex: 0,
        flexWrap: 'wrap',
        width: '88vw',
        position: 'relative',
        top: 0,
        left: 0,
        margin: '0 auto',
        marginTop: '2rem',
        backgroundImage: theme.palette.success.dark,
        background: theme.palette.success.dark,
        '@media (max-width: 600px)': {
            width: '100vw'
        },
        '@media (max-width: 290px)': {
            width: '100vw'
        }
    }));

    const StakingGrid = styled(Grid)(({ theme }) => ({
        width: '100%',
        backgroundColor: '#36454f',
        height: '100%',
        position: 'absolute',
        right: '0',
        top: '0',
        bottom: '0',
        background: theme.palette.success.dark
    }));

    return (
        <GridContainer>
            {/* graph */}
            <StakingGrid item>
                <Staking />
            </StakingGrid>
        </GridContainer>
    );
};
