import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import { RedButton } from 'components/buttons/RedButton';

import { Row } from 'components/Row';
import { DarkButton } from 'components/buttons/DarkButton';
import { BlackButton } from 'components/buttons/BlackButton';
import { LightButton } from 'components/buttons/LightButton';
import { PortfolioOrdersData, ProductStatus } from 'config/types';
import { portfolioOrdersData } from 'dummy/portfolioOrdersData';
import { TableCell } from 'components/table/TableCell';
import { TableRow } from 'components/table/TableRow';
import { TableHeader } from 'components/table/TableHeader';
import { TableCellWithToolip } from 'components/table/TableCellWithToolip';
import { ProductStatusLabel } from 'components/ProductStatusLabel';
import { Typography } from '@mui/material';

// TO REMOVE
const list = portfolioOrdersData.slice(0, 20);

const headers = [
    'product',
    'customer',
    'shares',
    'cost per shares',
    'total cost',
    'est. NAV',
    'impliedGrossChange',
    'net IRR',
    'date',
    'status',
    'payment type',
    'actions'
];

const actionsToButton: Record<ProductStatus, JSX.Element> = {
    denied: <DarkButton fullWidth textId="approve" />,
    purchased: <LightButton fullWidth textId="purchased" />,
    newListing: <LightButton fullWidth textId="new listing" />,
    sold: (
        <Row spacing={1}>
            <LightButton fullWidth textId="item info" />
            <BlackButton fullWidth textId="delete" />
        </Row>
    ),
    listed: (
        <Row spacing={1}>
            <RedButton fullWidth textId="markSold" />
            <LightButton fullWidth textId="delist" />
        </Row>
    ),
    pending: (
        <Row spacing={1}>
            <DarkButton fullWidth textId="approve" />
            <BlackButton fullWidth textId="deny" />
        </Row>
    )
};

export const LimitOrdersTable = (): JSX.Element => (
    <Table stickyHeader size="small" aria-label="portfolio">
        <TableHeader headers={headers} />
        <TableBody sx={{}}>
            {list.map((row: PortfolioOrdersData) => (
                <TableRow key={row.product}>
                    <TableCellWithToolip maxWidth="400px" title={row.product}>
                        <b>{row.product}</b>
                    </TableCellWithToolip>
                    <TableCell>{row.customer}</TableCell>
                    <TableCell>{row.shares}</TableCell>
                    <TableCell>{row.costPerShare}</TableCell>
                    <TableCell>{row.totalCost}</TableCell>
                    <TableCell>{row.estNav}</TableCell>
                    <TableCell>{row.impliedGrossChange}</TableCell>
                    <TableCell>{row.netIRR}</TableCell>
                    <TableCell>{row.date}</TableCell>
                    <TableCell>
                        <ProductStatusLabel fullWidth status={row.status} />
                    </TableCell>
                    <TableCell>{row.paymentType}</TableCell>
                    <TableCell>{actionsToButton[row.status]}</TableCell>
                </TableRow>
            ))}
        </TableBody>
    </Table>
);
