import { LightButton } from 'components/buttons/LightButton';
import { TradeHoldingsModal } from 'features/tradeHoldingsModal/TradeHoldingsModal';

import { InvestInHoldingsInstructions } from './InvestInHoldingsInstructions';

type Props = {
    holdingsOwned: number;
};

export const InvestInHoldingsModal = ({
    holdingsOwned
}: Props): JSX.Element => (
    <TradeHoldingsModal
        title="invest in holdings"
        holdingsOwned={holdingsOwned}
        instructions={<InvestInHoldingsInstructions />}>
        {(openModal: () => void) => (
            <LightButton textId="invest now" onClick={openModal} />
        )}
    </TradeHoldingsModal>
);
