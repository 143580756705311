import { Button } from 'components/buttons/Button';
import { MainButton } from 'components/buttons/MainButton';

type Props = {
    textId: string;
    onClick: () => void;
    selected?: boolean;
};

export const SelectRangeButton = ({
    selected,
    textId,
    onClick
}: Props): JSX.Element =>
    selected ? (
        <MainButton textId={textId} />
    ) : (
        <Button textId={textId} onClick={() => onClick()} />
    );
