import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';

import { Space } from 'components/Space';
import { defaultCurrencySymbol } from 'config';
import { TableCell } from 'components/table/TableCell';
import { TableRow } from 'components/table/TableRow';

type FinancialData = {
    price?: number;
    totalShares?: number;
    totalAmount?: number;
};

type Data = {
    digital: FinancialData;
    physical: FinancialData;
};

const data: Record<string, Data> = {
    purchase: {
        physical: {
            price: 24.75,
            totalShares: 18,
            totalAmount: 445.5
        },
        digital: {}
    },
    sales: {
        digital: {},
        physical: {}
    }
};

const formatPrice = (amount?: number): string | undefined =>
    amount ? `${defaultCurrencySymbol}${amount.toLocaleString()}` : undefined;

const add = (
    amount1: number | undefined,
    amount2: number | undefined
): number | undefined => {
    const total = (amount1 ?? 0) + (amount2 ?? 0);
    return total || undefined;
};

const ppPrice = data.purchase.physical.price;
const ppShares = data.purchase.physical.totalShares;
const ppAmount = data.purchase.physical.totalAmount;
const paPrice = data.purchase.digital.price;
const paShares = data.purchase.digital.totalShares;
const paAmount = data.purchase.digital.totalAmount;
const spPrice = data.sales.physical.price;
const spShares = data.sales.physical.totalShares;
const spAmount = data.sales.physical.totalAmount;
const sdPrice = data.sales.digital.price;
const sdShares = data.sales.digital.totalShares;
const sdAmount = data.sales.digital.totalAmount;

const TableCellWithBorder = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
        borderBottom: `1px solid ${theme.palette.primary.light}`
    }
}));

export const HistoricalActivitySummary = (): JSX.Element => (
    <>
        <TableRow>
            <TableCell colSpan={2} textId="purchases in physical marketplace" />
            <TableCell>{ppShares}</TableCell>
            <TableCell>{formatPrice(ppPrice)}</TableCell>
            <TableCell>{formatPrice(ppAmount)}</TableCell>
        </TableRow>
        <TableRow>
            <TableCell colSpan={2} textId="purchases in digital" />
            <TableCellWithBorder>{paShares}</TableCellWithBorder>
            <TableCellWithBorder>{formatPrice(paPrice)}</TableCellWithBorder>
            <TableCellWithBorder>{formatPrice(paAmount)}</TableCellWithBorder>
        </TableRow>
        <Space height={10} />
        <TableRow sx={{ pb: 20 }}>
            <TableCell
                sx={{ fontWeight: 'fontWeightBold' }}
                textId="total purchases"
                colSpan={2}
            />
            <TableCell>{add(ppShares, paShares)}</TableCell>
            <TableCell>{formatPrice(add(ppPrice, paPrice))}</TableCell>
            <TableCell>{formatPrice(add(ppAmount, paAmount))}</TableCell>
        </TableRow>
        <Space height={30} />
        <TableRow>
            <TableCell colSpan={2} textId="sales in physical marketplace" />
            <TableCell>{spShares}</TableCell>
            <TableCell>{formatPrice(spPrice)}</TableCell>
            <TableCell>{formatPrice(spAmount)}</TableCell>
        </TableRow>
        <TableRow>
            <TableCell
                colSpan={2}
                textId="distributions received from NFT sales"
            />
            <TableCellWithBorder>{sdShares}</TableCellWithBorder>
            <TableCellWithBorder>{formatPrice(sdPrice)}</TableCellWithBorder>
            <TableCellWithBorder>{formatPrice(sdAmount)}</TableCellWithBorder>
        </TableRow>
        <Space height={10} />
        <TableRow>
            <TableCell
                sx={{ fontWeight: 'fontWeightBold' }}
                textId="total sales and distributions"
                colSpan={2}
            />
            <TableCell>{add(spShares, sdShares)}</TableCell>
            <TableCell>{formatPrice(add(spPrice, sdPrice))}</TableCell>
            <TableCell>{formatPrice(add(spAmount, sdAmount))}</TableCell>
        </TableRow>
    </>
);
