import Box from '@mui/material/Box';
import { EmptyResponse } from 'components/EmptyResponse';
import { CenteredLoader } from 'components/CenteredLoader';

type Props<T> = React.ComponentProps<typeof Box> & {
    data?: T;
    empty: boolean;
    children: (data: T) => React.ReactNode;
    fetching: boolean;
    message?: string;
    fullscreen?: boolean;
};

export const FetchingScreen = <T extends unknown>({
    data,
    empty,
    children,
    fetching,
    message,
    fullscreen,
    ...other
}: Props<T>): JSX.Element => {
    if (fetching) {
        return <CenteredLoader fullscreen={fullscreen} {...other} />;
    }

    if (!data || empty) {
        return (
            <EmptyResponse
                fullscreen={fullscreen}
                message={message}
                {...other}
            />
        );
    }

    return <>{children(data)}</>;
};
