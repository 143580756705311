import Box from '@mui/material/Box';
import StarRateIcon from '@mui/icons-material/StarRate';

import { Row } from 'components/Row';
import { Typography } from 'components/Typography';

type Props = Omit<React.ComponentProps<typeof Row>, 'children'> & {
    data: number;
};

export const NumberOfBids = ({ data, ...other }: Props): JSX.Element => (
    <Row justifyContent="center" spacing={1} {...other}>
        <Box>
            <StarRateIcon sx={{ color: 'red', fontSize: 12 }} />
            <StarRateIcon sx={{ color: 'red', fontSize: 12 }} />
        </Box>
        <Typography bold variant="body1">
            {data}
        </Typography>
        <Typography bold uppercased variant="body1" textId="pending offers" />
        <Box>
            <StarRateIcon sx={{ color: 'red', fontSize: 12 }} />
            <StarRateIcon sx={{ color: 'red', fontSize: 12 }} />
        </Box>
    </Row>
);
