import { useState } from 'react';

import { useUpdateAssetData } from 'hooks/useUpdateAssetData';

type StateLogic = {
    denying: boolean;
    approving: boolean;
    onDeny: () => void;
    onApprove: () => void;
};

export const useAssetAuthenticationStatusControlModal = (
    id: string,
    onSuccess: () => void
): StateLogic => {
    const [updateAssetStatus] = useUpdateAssetData(id);

    const [denying, setDenying] = useState<boolean>(false);

    const [approving, setApproving] = useState<boolean>(false);

    const onApprove = async (): Promise<void> => {
        setApproving(true);
        await updateAssetStatus({ authenticationStatus: 'authenticated' });
        setApproving(false);
        onSuccess();
    };

    const onDeny = async (): Promise<void> => {
        setDenying(true);
        await updateAssetStatus({ authenticationStatus: 'denied' });
        setDenying(false);
        onSuccess();
    };

    return { onApprove, onDeny, approving, denying };
};
