import Box from '@mui/material/Box';
import { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';

import { PortfolioTabHeader } from 'config/types';
import { ReactComponent as ItemsIcon } from 'assets/items.svg';
import { ReactComponent as FundsIcon } from 'assets/funds.svg';
import { ReactComponent as OrdersIcon } from 'assets/orders.svg';
import { ReactComponent as ActivityIcon } from 'assets/historical-activity.svg';

import { TabPanel } from './TabPanel';
import { ItemsTable } from './ItemsTable';
import { FundsTable } from './FundsTable';
import { PortfolioTabs } from './PortfolioTabs';
import { TabPanelAsTable } from './TabPanelAsTable';
import { OverviewBanner } from './OverviewBanner';
import { LimitOrdersTable } from './LimitOrdersTable';
import { HistoricalActivityPanel } from './HistoricalActivityPanel';
import { Grid } from '@mui/material';

const tabHeaders: PortfolioTabHeader[] = [
    { label: 'limit orders', Icon: OrdersIcon },
    { label: 'items', Icon: ItemsIcon },
    { label: 'funds', Icon: FundsIcon },
    { label: 'historical activity', Icon: ActivityIcon }
];

export const PortfolioTables = (): JSX.Element => {
    const theme = useTheme();

    const { table } = useParams<{ table: string | undefined }>();

    const initialTabNb =
        tabHeaders.findIndex((it) => it.label.replace(' ', '-') === table) ?? 0;

    const [value, setValue] = useState<number>(initialTabNb);

    const handleChange = (newValue: number): void => {
        setValue(newValue);
    };

    const handleChangeIndex = (index: number): void => {
        setValue(index);
    };
    const GridContainer = styled(Grid)(() => ({
        scrollbarWidth: 'none',
        display: 'flex',
        zIndex: 0,
        flexWrap: 'wrap',
        width: '88vw',
        position: 'relative',
        top: 0,
        left: 0,
        margin: '0 auto',
        marginTop: '2rem',
        backgroundImage: theme.palette.success.dark,
        background: theme.palette.success.dark,
        '@media (max-width: 600px)': {
            width: '100vw'
        }
    }));

    const ListingGrid = styled(Grid)(() => ({
        width: '100%',
        backgroundColor: '#36454f',
        height: '100%',
        position: 'absolute',
        right: '0',
        top: '0',
        bottom: '0',
        background: theme.palette.success.dark
    }));

    return (
        <GridContainer container>
            <ListingGrid>
                <Box>
                    <Box
                        sx={{
                            marginTop: '30px',
                            '@media (min-width: 430px)': {
                                marginTop: '0px'
                            }
                        }}>
                        <OverviewBanner />
                    </Box>
                    <PortfolioTabs
                        value={value}
                        headers={tabHeaders}
                        onChange={handleChange}
                    />
                    <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={value}
                        onChangeIndex={handleChangeIndex}>
                        <TabPanelAsTable
                            value={value}
                            index={0}
                            dir={theme.direction}>
                            <LimitOrdersTable />
                        </TabPanelAsTable>
                        <TabPanelAsTable
                            value={value}
                            index={1}
                            dir={theme.direction}>
                            <ItemsTable />
                        </TabPanelAsTable>
                        <TabPanelAsTable
                            value={value}
                            index={2}
                            dir={theme.direction}>
                            <FundsTable />
                        </TabPanelAsTable>
                        <TabPanel dir={theme.direction} index={3} value={value}>
                            <HistoricalActivityPanel />
                        </TabPanel>
                    </SwipeableViews>
                </Box>
            </ListingGrid>
        </GridContainer>
    );
};
