import { Grid, Stack, styled, useTheme } from '@mui/material';
import { FormProvider } from 'react-hook-form';

import { Row } from 'components/Row';
import { TextInput } from 'components/TextInput';
import { DarkButton } from 'components/buttons/DarkButton';
import { LightButton } from 'components/buttons/LightButton';
import { PictureInput } from 'components/PictureInput';
import { StandardModal } from 'components/StandardModal';
import { useMerchantInvestModal } from './useMerchantInvestModal';
import { InputBox } from 'components/InputBox';

type Props = {
    open: boolean;
    handleClose: () => void;
};

export const MerchantInvestModal = ({
    open,
    handleClose
}: Props): JSX.Element => {
    const {
        onClose,
        onSubmit,
        submitting,
        fileDropped,
        formMethods,
        onFileDropped,
        onFileRemoved
    } = useMerchantInvestModal(handleClose);

    // console.log('Add new asset modal');
    const theme1 = useTheme();
    const InputGrid = styled('div')(({ theme }) => ({
        grid: 'initial',
        gridTemplateColumns: 'repeat(12, minmax(0, 1fr))',
        width: '100%',
        gap: '3rem'
    }));

    const TextGrid = styled('div')(({ theme }) => ({
        width: '100%'
    }));

    const StyledInputBox = styled(InputBox)({
        position: 'relative',

        '&::before': {
            content: '"%"',
            position: 'absolute',
            top: '50%',
            right: '0.5rem',
            transform: 'translateY(-50%)'
        },

        '&::after': {
            content: '"%"',
            position: 'absolute',
            top: '50%',
            right: '0.5rem',
            transform: 'translateY(-50%)'
        }
    });

    const StyledInputBox2 = styled(InputBox)({
        position: 'relative',

        '&::before': {
            content: '"$"',
            position: 'absolute',
            top: '50%',
            left: '0.5rem',
            transform: 'translateY(-50%)'
        },

        '&::after': {
            content: '"$"',
            position: 'absolute',
            top: '50%',
            left: '0.5rem',
            transform: 'translateY(-50%)'
        }
    });

    // Inside the MerchantInvestModal component:

    return (
        <StandardModal
            sx={{
                width: '100vw',
                '@media(max-width: 300px)': {
                    width: '120vw',
                    overflow: 'auto',
                    marginLeft: '-1.7rem'
                }
            }}
            title="investInHoldings"
            open={open}
            handleClose={onClose}
            maxw={'100%'}>
            <FormProvider {...formMethods}>
                <form
                    style={{ width: '100%' }}
                    noValidate
                    autoComplete="off"
                    onSubmit={formMethods.handleSubmit(onSubmit)}>
                    <Grid container columnSpacing={2}>
                        <Grid
                            item
                            xs={12}
                            sx={{
                                marginTop: '10px',
                                color: theme1.palette.primary.contrastText,
                                fontSize: '12px'
                            }}>
                            HOLDINGS AVAILABLE:{' '}
                            <span style={{ fontWeight: 'bold' }}>
                                340 / 30%
                            </span>
                        </Grid>
                        <Grid item xs={4}>
                            <InputBox
                                label="numberOfHoldings*"
                                name="name"
                                disabled={submitting}
                                required
                                sx={{ marginRight: '10px' }}
                            />
                            <span
                                style={{
                                    position: 'absolute',
                                    marginTop: '2rem',
                                    color: theme1.palette.primary.contrastText,
                                    fontSize: '12px'
                                }}>
                                or
                            </span>
                        </Grid>
                        <Grid item xs={5} style={{ paddingLeft: '25px' }}>
                            <StyledInputBox
                                label="percentageOfOwnership*"
                                name="name"
                                disabled={submitting}
                                required
                                sx={{
                                    marginRight: '1.5rem',
                                    '@media(max-width: 300px)': {
                                        marginRight: '0'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <StyledInputBox2
                                label="pricePerHolding"
                                name="name"
                                disabled={submitting}
                                required
                                sx={{
                                    paddingLeft: '.75rem'
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sx={{
                                marginTop: '20px',
                                color: theme1.palette.primary.contrastText,
                                fontSize: '12px',
                                fontWeight: '300'
                            }}>
                            Be aware that completing more than 3 Sell and Invest
                            Holdings transactions in a 24 hour period will
                            result in a fee higher than 2%
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sx={{
                                marginTop: '20px',
                                color: theme1.palette.primary.contrastText,
                                fontSize: '12px',
                                fontWeight: '300'
                            }}>
                            Do you wish to proceed?
                        </Grid>
                        <Grid item xs={7} sm={8} style={{ marginTop: '20px' }}>
                            <DarkButton
                                textId="cancel"
                                sx={{ width: '6rem' }}
                                onClick={onClose}
                                disabled={submitting}
                                style={{ marginRight: '10px' }}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={1}
                            sm={1}
                            sx={{
                                display: {
                                    xs: 'none',
                                    sm: 'block',
                                    md: 'block'
                                }
                            }}
                        />
                        <Grid
                            item
                            xs={1}
                            style={{
                                marginTop: '20px'
                            }}>
                            <LightButton
                                type="submit"
                                sx={{ width: '6rem' }}
                                textId="Proceed"
                                loading={submitting}
                                disabled={!fileDropped}
                            />
                        </Grid>
                    </Grid>
                </form>
            </FormProvider>
        </StandardModal>
    );
};
