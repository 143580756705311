import { DataRow } from 'features/itemGallery/DataRow';
import { DataPoint } from 'components/DataPoint';
import { defaultCurrencySymbol } from 'config';

type Props = {
    data: number;
};

export const PurchasePrice = ({ data }: Props): JSX.Element => (
    <DataRow textId="purchasePrice">
        <DataPoint bold data={data} prefix={defaultCurrencySymbol} />
    </DataRow>
);
