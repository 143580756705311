import { useEffect, useState } from 'react';

import { AssetItem } from 'config/types/asset';
import { useFetchMerchantAssets } from 'hooks/useFetchMerchantAssets';
import { useAppSelector } from 'hooks/useAppSelector';
import { getMenuState } from 'state/menu/selectors';

type StateLogic = {
    list: AssetItem[];
    fetching: boolean;
    activeStep: number;
    menuCollapsed: boolean;
    handleNext: () => void;
    handleBack: () => void;
    handleStepChange: (step: number) => void;
};

export const useItemGallery = (): StateLogic => {
    const [list, setList] = useState<AssetItem[]>([]);

    const { collapsed } = useAppSelector(getMenuState);

    const [activeStep, setActiveStep] = useState<number>(0);

    const [fetchMerchantAssets, fetching] = useFetchMerchantAssets();

    const maxSteps = list.length;

    const handleNext = (): void => {
        setActiveStep((prevActiveStep: number) =>
            prevActiveStep < maxSteps - 1 ? prevActiveStep + 1 : 0
        );
    };

    const handleBack = (): void => {
        setActiveStep((prevActiveStep) =>
            prevActiveStep < 1 ? maxSteps - 1 : prevActiveStep - 1
        );
    };

    const handleStepChange = (step: number): void => {
        setActiveStep(step);
    };

    useEffect(() => {
        (async () => {
            setList(await fetchMerchantAssets());
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        list,
        fetching,
        activeStep,
        menuCollapsed: collapsed,
        handleNext,
        handleBack,
        handleStepChange
    };
};
