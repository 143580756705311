import { ProductDataPoint } from 'components/dataPoints/ProductDataPoint';

type Props = {
    data?: number;
};

export const ExpectedReturnDataPoint = ({ data }: Props): JSX.Element => (
    <ProductDataPoint textId="expectedReturn" showMissing={!data}>
        {data?.toLocaleString()}%
    </ProductDataPoint>
);
