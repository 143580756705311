import MuiButton from '@mui/material/Button';

import { ProductStatus } from 'config/types/asset';

import { RedButton } from './buttons/RedButton';
import { DarkButton } from './buttons/DarkButton';
import { LightButton } from './buttons/LightButton';
import { BlackButton } from './buttons/BlackButton';
import { YellowButton } from './buttons/YellowButton';

type Props = React.ComponentProps<typeof MuiButton> & {
    status: ProductStatus;
    fullWidth?: boolean;
};

const statusToButton = (
    fullWidth?: boolean
): Record<ProductStatus, JSX.Element> => ({
    sold: <RedButton fullWidth={fullWidth} textId="sold" />,
    listed: <DarkButton fullWidth={fullWidth} textId="listed" />,
    denied: <BlackButton fullWidth={fullWidth} textId="denied" />,
    pending: <YellowButton fullWidth={fullWidth} textId="pending" />,
    purchased: <LightButton fullWidth={fullWidth} textId="purchased" />,
    newListing: <LightButton fullWidth={fullWidth} textId="new listing" />,
    processing: <YellowButton fullWidth={fullWidth} textId="processing" />,
    notListed: <LightButton fullWidth={fullWidth} textId="not listed" />
});

export const ProductStatusLabel = ({
    status,
    fullWidth
}: Props): JSX.Element => {
    return statusToButton?.(fullWidth)?.[status] || <></>;
};
