import PerfectScrollbar from 'react-perfect-scrollbar';
import { useMediaQuery } from '@react-hook/media-query';
import { ProSidebar, SidebarHeader, SidebarContent } from 'react-pro-sidebar';
import 'react-perfect-scrollbar/dist/css/styles.css';

import { SidebarTitle } from 'features/sidebar/SidebarTitle';
import { menuActions } from 'state/menu/menuSlice';
import { getMenuState } from 'state/menu/selectors';
import { useAppSelector } from 'hooks/useAppSelector';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { SidebarUserOverview } from 'features/sidebar/SidebarUserOverview';
import { getUserData, isUserLoggedIn } from 'state/user/selectors';

import { PublicSidebar } from './PublicSidebar';
import { ProtectedSidebar } from './ProtectedSidebar';
import { User } from 'config/types/user';
import styled from 'styled-components';

export const Sidebar = (): JSX.Element => {
    const dispatch = useAppDispatch();

    const userData = useAppSelector(getUserData);

    const loggedIn = useAppSelector(isUserLoggedIn);

    const menuState = useAppSelector(getMenuState);

    const onMobile = useMediaQuery('only screen and (max-width: 840px)');

    const handleToggleSidebar = (): void => {
        dispatch(menuActions.toggleOpen());
    };

    const handleCollapse = (): void => {
        if (menuState.collapsed) return;
        dispatch(menuActions.toggleCollapse());
    };

    const closeWhenOnMobile = (): void => {
        if (onMobile) {
            dispatch(menuActions.close());
        }
    };

    const ScrollbarContainer = styled(PerfectScrollbar)(() => ({
        background:
            userData?.isAdmin === false
                ? 'linear-gradient(179.14deg, #FFFFFF 0.73%, #F5F5F5 121.08%)'
                : 'linear-gradient(180deg, #3C4858 0%, #151516 58.85%)'
    }));

    const ProSideBarContainer = styled(ProSidebar)(() => ({
        '& .popper-inner': {
            background:
                userData?.isAdmin === false
                    ? 'linear-gradient(179.14deg, #FFFFFF 0.73%, #F5F5F5 121.08%)'
                    : 'linear-gradient(180deg, rgba(60, 72, 88, 0.93) 0%, rgba(21, 21, 22, 0.93) 58.85%)'
        },
        '& .pro-inner-list-item': {
            background:
                userData?.isAdmin === false
                    ? 'linear-gradient(179.14deg, #FFFFFF 0.73%, #F5F5F5 121.08%)'
                    : 'linear-gradient(180deg, rgba(60, 72, 88, 0.93) 0%, rgba(21, 21, 22, 0.93) 58.85%)'
        }
    }));

    console.log(menuState, 'menustate');

    return (
        <ProSideBarContainer
            id="sidebar"
            toggled={menuState.open}
            collapsed={menuState.collapsed}
            breakPoint="md"
            style={{ height: '100vh' }}
            onToggle={handleToggleSidebar}>
            {/* nav bar header */}
            <SidebarHeader>
                <SidebarTitle
                    user={userData}
                    onClick={closeWhenOnMobile}
                    handleCollapse={handleCollapse}
                    collapsed={menuState.collapsed}
                />
            </SidebarHeader>
            {loggedIn && (
                <SidebarHeader>
                    <SidebarUserOverview
                        name={userData}
                        avatar={userData.avatar || userData.profileImage}
                    />
                </SidebarHeader>
            )}
            {/* different nav links within nav bar */}
            <ScrollbarContainer>
                <SidebarContent>
                    {loggedIn ? (
                        <ProtectedSidebar
                            account={userData}
                            handleCollapse={handleCollapse}
                        />
                    ) : (
                        <PublicSidebar onClick={closeWhenOnMobile} />
                    )}
                </SidebarContent>
            </ScrollbarContainer>
        </ProSideBarContainer>
    );
};
