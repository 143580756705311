import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';

import { Logo } from 'components/Logo';
import { SITE_TITLE } from 'config/constants';
import siteTitleGraphic from 'assets/site-title.svg';
import { User } from 'config/types/user';

type Props = {
    onClick: () => void;
    collapsed: boolean;
    handleCollapse: () => void;
    user: User;
};

const Link = styled(RouterLink)(({ theme }) => ({
    display: 'flex',
    padding: 10,
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
    background: theme.palette.success.dark
}));

export const SidebarTitle = ({
    onClick,
    collapsed,
    handleCollapse
}: Props): JSX.Element => (
    <Link to="/" onClick={onClick}>
        {collapsed ? (
            <Logo width="60px" />
        ) : (
            <Stack alignItems="center" spacing={2} onClick={handleCollapse}>
                <Logo width="143px" />
                <img src={siteTitleGraphic} alt={SITE_TITLE} />
            </Stack>
        )}
    </Link>
);
