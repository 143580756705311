import { Row } from 'components/Row';
import { ViewAllLink } from 'components/ViewAllLink';
import { StandardCard } from 'components/StandardCard';
import { PostItemDropItem } from 'config/types';
import { PostItemDropTradingCard } from 'features/dataCards/postItemDropTradingCard/PostItemDropTradingCard';

import { SnapshotTitle } from './SnapshotTitle';
import { useState } from 'react';
import { MerchantInvestModal } from 'features/investModal/MerchantInvestModal';

const data: PostItemDropItem[] = [
    {
        id: '1',
        price: 350,
        product: 'hermes h08 graphene composite',
        createdAt: '06/07/2020'
    },
    {
        id: '2',
        price: 250,
        product: 'hermes h08 graphene composite',
        boughtInDrops: 62,
        createdAt: '08/20/2022'
    }
];

export const PostItemDropTradingSnapshot = (): JSX.Element => {
    const [openModal, setOpenModal] = useState<boolean>(false);

    const handleCloseModal = (): void => {
        setOpenModal(false);
    };

    const handleOpenModal = (): void => {
        setOpenModal(true);
    };
    return (
        <>
            <StandardCard
                sx={{
                    width: '680px',
                    position: 'absolute',
                    right: '0px',
                    bottom: '0px',
                    '@media (max-width: 1130px)': {
                        width: '100%'
                    }
                }}>
                <Row alignItems="baseline" justifyContent="space-between">
                    <SnapshotTitle textId="post item drop trading" />
                    <ViewAllLink to="" />
                </Row>
                <Row
                    sx={{
                        display: 'grid',
                        gap: '10px',
                        gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
                        '@media (max-width: 800px)': {
                            gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
                            height: '1000px'
                        }
                    }}>
                    {data.map((item) => (
                        <PostItemDropTradingCard
                            key={item.id}
                            data={item}
                            onClick={handleOpenModal}
                        />
                    ))}
                </Row>
            </StandardCard>
            <MerchantInvestModal
                open={openModal}
                handleClose={handleCloseModal}
            />
        </>
    );
};
