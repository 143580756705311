import { Bid } from 'config/types';
import { RootState } from 'app/store';
import { AssetItem, ListedItem } from 'config/types/asset';

export const getAllAssets = (state: RootState): ListedItem[] => state.assets;

export const getAssetData = (state: RootState, id: string): AssetItem =>
    state.assets.find((e: AssetItem) => e.id === id);

export const getAssetBids = (state: RootState, id: string): Bid[] =>
    getAssetData(state, id).bids;

export const getAssetNftId = (state: RootState, id: string): number =>
    getAssetData(state, id).nftId;

export const getAssetName = (state: RootState, id: string): string =>
    getAssetData(state, id).name;
