import capitalize from 'lodash/capitalize';
import startCase from 'lodash/startCase';
import MuiTypography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import { getUserData } from 'state/user/selectors';
import { User } from 'config/types/user';
import { useAppSelector } from 'hooks/useAppSelector';
import { useTheme } from '@mui/material';

type Props = React.ComponentProps<typeof MuiTypography> & {
    title?: string;
    bold?: boolean;
    textId?: string | number;
    children?: React.ReactNode;
    transVars?: Record<string, unknown>;
    capitalized?: boolean;
    uppercased?: boolean;
    showMissing?: boolean;
    allCapitalized?: boolean;
};

export const Typography = ({
    sx,
    title,
    bold,
    textId,
    transVars,
    children,
    capitalized,
    uppercased,
    showMissing,
    allCapitalized,
    ...props
}: Props): JSX.Element => {
    const { t } = useTranslation();
    const theme = useTheme();
    const user: User = useAppSelector(getUserData);
    const customer = user.isAdmin === false;

    if (showMissing)
        return (
            <MuiTypography
                variant="body1"
                sx={{
                    fontWeight: 300,
                    fontStyle: 'italic',
                    color: theme.palette.primary.contrastText
                }}>
                {t('missingData')}
            </MuiTypography>
        );

    let text = textId
        ? t(textId.toString(), transVars || {})
        : (children as string);

    if (capitalized && text) {
        text = capitalize(text);
    }

    if (allCapitalized && text) {
        text = startCase(text);
    }

    if (uppercased && text && text.toUpperCase) {
        text = text.toUpperCase();
    }

    return (
        <MuiTypography
            sx={{
                ...(sx ?? {}),
                fontWeight: bold ? 'fontWeightBold' : 'fontWeightRegular',
                color: theme.palette.primary.contrastText
            }}
            color="black"
            title={title && capitalize(t(title))}
            {...props}>
            {text}
        </MuiTypography>
    );
};
