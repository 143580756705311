import {
    doc,
    query,
    addDoc,
    collection,
    Firestore,
    deleteDoc,
    getFirestore,
    DocumentData,
    WithFieldValue,
    SnapshotOptions,
    FirestoreDataConverter,
    QueryDocumentSnapshot
} from 'firebase/firestore';
import pick from 'lodash/pick';
import { useCollectionData } from 'react-firebase-hooks/firestore';

import { firebaseApp } from 'libs/firebaseApp';
import { useAsyncAction } from 'hooks/useAsyncAction';
import { setRecordWithIdAndDate } from 'libs/firestoreUtils';
import { NotificationData, Notification } from 'config/types/user';

import { useAlert } from 'hooks/useAlert';
import { HookOptions } from 'config/types';

type DeleteNotificationParams = {
    id: string;
};

type StateLogic = {
    sending: boolean;
    deleting: boolean;
    fetching: boolean;
    notifications: Notification[];
    sendNotification: (data: NotificationData) => void;
    deleteNotification: (params: DeleteNotificationParams) => void;
};

const notificationConverter: FirestoreDataConverter<Notification> = {
    toFirestore(message: WithFieldValue<NotificationData>): DocumentData {
        return message;
    },
    fromFirestore(
        snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions
    ): Notification {
        const data = pick(snapshot.data(options), [
            'link',
            'type',
            'value',
            'createdAt'
        ]);
        return { id: snapshot.id, ...data };
    }
};

export const useUserNotifications = (
    userId: string,
    { silent, throws }: HookOptions = {}
): StateLogic => {
    const db: Firestore = getFirestore(firebaseApp);

    const { errorAlert } = useAlert();

    const notificationRef = collection(
        db,
        'users',
        userId,
        'notifications'
    ).withConverter(notificationConverter);

    const [items, fetching, error] = useCollectionData(query(notificationRef));

    if (error && !silent) {
        errorAlert('errorRetrievingNotifications');
        if (throws) throw new Error(error.message);
    }

    const sendNotificationAction = async (
        data: NotificationData
    ): Promise<void> => {
        await addDoc(notificationRef, setRecordWithIdAndDate(data));
    };

    const deleteNotificationAction = async ({
        id
    }: DeleteNotificationParams): Promise<void> => {
        await deleteDoc(doc(db, 'users', userId, 'notifications', id));
    };

    const [sendNotification, sending] = useAsyncAction<NotificationData, void>(
        sendNotificationAction,
        {
            error: 'errorSendingNotification',
            silent
        }
    );

    const [deleteNotification, deleting] = useAsyncAction<
        DeleteNotificationParams,
        void
    >(deleteNotificationAction, {
        error: 'errorDeletingNotifications',
        silent
    });

    return {
        sending,
        deleting,
        fetching,
        notifications: items || [],
        sendNotification,
        deleteNotification
    };
};
