import {
    getFunctions,
    httpsCallable
    // connectFunctionsEmulator
} from 'firebase/functions';

import { logger } from 'libs/logger';
import { getApp } from 'firebase/app';
import { IPFSMedia } from 'config/types';

import { useAsyncAction } from './useAsyncAction';

type StateLogic = [(media: IPFSMedia[]) => Promise<string>, boolean];

const log = logger('useUploadAssetMediaToIPFS');

const functions = getFunctions(getApp());

// if (process.env.NODE_ENV === 'development') {
//     connectFunctionsEmulator(functions, 'localhost', 5001);
// }

type CloudFunctionError = {
    error: string;
};

const uploadMediasToIPFS = httpsCallable<
    IPFSMedia[],
    string | CloudFunctionError
>(functions, 'uploadMediasToIPFS');

export const useUploadAssetMediaToIPFS = (noCatch?: boolean): StateLogic => {
    const action = async (media: IPFSMedia[]): Promise<string> => {
        log.debug('Calling server function to upload media to IPFS: ', media);
        const { data } = await uploadMediasToIPFS(media);
        const { error } = data as CloudFunctionError;
        if (error) {
            throw new Error(JSON.stringify(error));
        }
        log.debug('Path for new JSON file uploaded to IPFS: ', data);
        return data as string;
    };

    const [actionWithTryCatch, processing] = useAsyncAction<any, string>(
        action,
        {
            info: 'startUploadingFileToIPFS',
            error: 'errorUploadingFileToIPFS'
        }
    );

    return [noCatch ? action : actionWithTryCatch, processing];
};
