import { Spinner } from 'components/Spinner';
import { RedButton } from 'components/buttons/RedButton';
import { Typography } from 'components/Typography';
import { LightButton } from 'components/buttons/LightButton';
import { YellowButton } from 'components/buttons/YellowButton';
import { AssetItem, AssetListingStatus } from 'config/types/asset';

type Props = {
    asset: AssetItem;
    onViewData: (asset: AssetItem) => void;
};

export const AdminListingStatusButton = ({
    asset,
    onViewData
}: Props): JSX.Element => {
    const onClick = (): void => onViewData(asset);

    const statusToButton: Record<AssetListingStatus, JSX.Element> = {
        sold: <Typography sx={{ color: 'success.main' }} textId="sold" />,
        unverified: (
            <Typography sx={{ color: 'primary.main' }} textId="unverified" />
        ),
        listed: <LightButton textId="delistItem" onClick={onClick} />,
        denied: <RedButton textId="denied" onClick={onClick} />,
        pending: <YellowButton textId="pending" onClick={onClick} />,
        notListed: (
            <Typography sx={{ color: 'primary.light' }} textId="notListed" />
        ),
        processing: <Spinner />
    };

    return statusToButton[asset.status] || null;
};
