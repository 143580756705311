import { grey } from '@mui/material/colors';
import Stack from '@mui/material/Stack';
import MuiCard from '@mui/material/Card';
import { styled } from '@mui/material/styles';

import { Row } from 'components/Row';
import { Typography } from 'components/Typography';
import { setTimestampToDate } from 'libs/utils';
import { UserHistoricalActivityItem } from 'config/types/user';
import { defaultCurrency, defaultCurrencySymbol } from 'config';

type Props = {
    data: UserHistoricalActivityItem;
    currency: string;
};

const Card = styled(MuiCard)`
    padding: 10px;
    background-color: rgba(255, 255, 255, 0.1);
`;

export const UserHistoricalActivityCard = ({
    data,
    currency
}: Props): JSX.Element => (
    <Card>
        <Row justifyContent="space-between">
            <Stack sx={{ height: 90 }} justifyContent="space-between">
                <Typography
                    bold
                    allCapitalized
                    variant="body2"
                    textId={data.title}
                />
                {data.item && (
                    <Typography
                        sx={{ fontStyle: 'italic', color: 'primary.light' }}
                        variant="body1"
                        uppercased>
                        {data.item}
                    </Typography>
                )}
            </Stack>
            <Stack alignItems="flex-end" justifyContent="space-between">
                <Typography bold variant="body2">
                    {setTimestampToDate(data.createdAt)}
                </Typography>
                {data.amount && (
                    <Row spacing={1} alignItems="baseline">
                        <Typography bold variant="body1">
                            {currency === defaultCurrency &&
                                defaultCurrencySymbol}
                            {(
                                data.amount *
                                (currency === defaultCurrency ? 1 : 100)
                            ).toLocaleString()}
                        </Typography>
                        <Typography
                            sx={{
                                width: 30,
                                textAlign: 'right',
                                color: grey[400]
                            }}
                            variant="subtitle2">
                            {currency}
                        </Typography>
                    </Row>
                )}
            </Stack>
        </Row>
    </Card>
);
