import { utils } from 'ethers';

import TokenVendor from 'artifacts/contracts/LuxVestingTokenVendor.sol/LuxVestingTokenVendor.json';
import { TOKEN_VENDOR_CONTRACT_ADDRESS } from 'config/constants';

import { SmartContractService } from './SmartContractService';
import { Web3Provider } from '@ethersproject/providers';
import { sign } from 'crypto';

export class TokenVendorContractService extends SmartContractService {
    async releaseTokens(to: string, value: number): Promise<void> {
        this.debug('Token transfer initiated: to', to, 'with value', value);
        const parsedValue = utils.parseUnits(value.toString());
        const transaction = await this.contract.releaseTokens(to, parsedValue);
        this.debug('Token transfer pending');
        await transaction.wait();
        this.debug('Token transfer completed:: to', to, 'with value', value);
    }

    async retrieveTokens(from: string, value: number): Promise<void> {
        this.debug('Transaction initiated: from', from, 'with value', value);
        const parsedValue = utils.parseUnits(value.toString());
        const tx = await this.contract.retrieveTokens(from, parsedValue);
        this.debug('Transaction pending');
        await tx.wait();
        this.debug('Transfer completed:: from', from, 'with value', value);
    }
}
