import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

export const CustomDiv = styled('div')(() => ({}));

export const Flex = styled('div')(({ theme }) => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
}));

export const Title = styled('h1')(() => ({
    fontFamily: 'Jost',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '29px',
    lineHeight: '42px',
    color: '#FFFFFF'
}));

export const AddButton = styled(Button)(() => ({
    background: '#264257',
    borderRadius: '5px',
    color: '#FFF',
    fontFamily: 'Jost',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '33px',
    height: '47px',
    '@media (min-width: 500px)': {
        fontSize: '23px',
        padding: '0px 25px'
    }
}));

export const StyledAppSelect = styled('select')(() => ({
    backgroundColor: 'black',
    color: 'white'
}));

export const CustomSelect = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.success.main,
    color: theme.palette.primary.contrastText,
    border: '1px solid #a5cee4',
    height: '100%'
}));

export const SelectOption = styled('p')(() => ({
    color: 'white'
}));

export const SortList = styled('ul')(() => ({
    padding: '0px',
    margin: '0px'
}));

export const SortListItem = styled('li')(() => ({
    listStyle: 'none',
    margin: '6px 8px',
    cursor: 'pointer'
}));

export const CustomInput = styled('input')(({ theme }) => ({
    outline: 'none',
    border: 'none',
    height: '100%',
    background: 'transparent',
    borderBottom: '1px solid #a5cee4',
    position: 'relative',
    zIndex: 5,
    color: theme.palette.primary.contrastText,
    paddingLeft: '25px',
    paddingBottom: '5px',
    width: '100%',
    minWidth: '200px'
}));
