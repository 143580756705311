import { useState, useContext } from 'react';
import { BlockChainContext } from 'blockchain/BlockchainContext';

import { logger } from 'libs/logger';
import { useAlert } from 'hooks/useAlert';
import { TorusService } from 'services/TorusService';
import { userActions } from 'state/user/userSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';

type StateLogic = [() => Promise<void>, boolean, boolean];

export const useTorusLogout = (): StateLogic => {
    const dispatch = useAppDispatch();
    const { torus } = useContext(BlockChainContext);

    const { errorAlert } = useAlert();

    const [loading, setLoading] = useState<boolean>(false);

    const [loggedOut, setLoggedOut] = useState<boolean>(false);

    const logout = async (): Promise<void> => {
        try {
            setLoading(true);
            // const torusService = await getTorusService();
            await TorusService.logout(torus!);
            dispatch(userActions.reset());
            setLoggedOut(true);
        } catch (e) {
            errorAlert('logoutError');
            logger().error(e);
        } finally {
            setLoading(false);
            setLoggedOut(false);
        }
    };

    return [logout, loading, loggedOut];
};
