import { useEffect, useState } from 'react';

import { DatabaseService } from 'services/DatabaseService';
import { AssetItem } from 'config/types/asset';

import { useAsyncAction } from './useAsyncAction';

type StateLogic = [items: AssetItem[], loading: boolean];

export const useFetchAllMarketItems = (): StateLogic => {
    const [items, setItems] = useState<AssetItem[]>([]);

    const request = async (): Promise<AssetItem[]> =>
        DatabaseService.getAllMarketItems();

    const [getAllMarketItems, processing] = useAsyncAction<void, AssetItem[]>(
        request,
        { error: 'getAllMarketItemsError' }
    );

    useEffect(() => {
        (async () => {
            setItems(await getAllMarketItems());
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return [items || [], processing];
};
