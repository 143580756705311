import { ethers, BigNumber } from 'ethers';
import { dollarsToTokens } from 'libs/utils';
import { SmartContractService } from './SmartContractService';

export class TokenContractService extends SmartContractService {
    async getBalance(account: string): Promise<string> {
        console.log('getting balance');
        const balance = await this.contract.balanceOf(account);
        console.log(balance);
        return balance.toString();
    }

    async approve(recipient: string, qty: number): Promise<void> {
        try {
            console.log(qty);
            const value = dollarsToTokens(qty);
            console.log(value);

            const tx = await this.contract.functions.approve(
                recipient,
                ethers.BigNumber.from(value).toHexString()
            );

            console.log(tx);

            await tx.wait();
        } catch (e) {
            console.error(e);
            throw new Error('Could not approve tokens');
        }
    }
}
