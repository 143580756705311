import { UserBidsPanel } from 'features/userBidsPanel/UserBidsPanel';
import { ChatRoomPanel } from 'features/chatRoomPanel/ChatRoomPanel';
import { StandardDrawer } from 'components/StandardDrawer';

import { useUserBidsDrawer } from './useUserBidsDrawer';

type Props = {
    open: boolean;
    handleClose: () => void;
};

export const UserBidsDrawer = ({ open, handleClose }: Props): JSX.Element => {
    const assetId = 'PUXWAJrPsu6GDwq6PO6O';
    const {
        userId,
        merchantId,
        onClose,
        openedChat,
        handleOpenChat,
        handleCloseChat
    } = useUserBidsDrawer(handleClose);

    return (
        <StandardDrawer
            open={open}
            onClose={openedChat ? handleCloseChat : onClose}>
            {openedChat && merchantId ? (
                <ChatRoomPanel
                    assetId={assetId}
                    clientId={userId}
                    merchantId={merchantId}
                />
            ) : (
                <UserBidsPanel handleOpenChat={handleOpenChat} />
            )}
        </StandardDrawer>
    );
};
