import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';

type Props = React.ComponentProps<typeof Popper> & {
    open: boolean;
    children: React.ReactNode;
    anchorEl: HTMLButtonElement | null;
};

export const Popover = ({
    open,
    anchorEl,
    children,
    ...other
}: Props): JSX.Element => (
    <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom"
        transition
        {...other}>
        {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
                <Paper>{children}</Paper>
            </Fade>
        )}
    </Popper>
);
