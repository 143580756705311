import { RootState } from 'app/store';
import { AssetItem, AssetMediaMetadata } from 'config/types/asset';

export const getMerchantAssets = (state: RootState): AssetItem[] =>
    state.merchantAssets;

export const getMerchantAsset = (state: RootState, id: string): AssetItem =>
    state.merchantAssets.find((e: AssetItem) => e.id === id);

export const getMerchantAssetName = (state: RootState, id: string): string => {
    const asset = state.merchantAssets.find((e: AssetItem) => e.id === id);
    return asset?.name;
};

export const getMerchantAssetPictures = (
    state: RootState,
    id: string
): AssetMediaMetadata[] => {
    const asset = state.merchantAssets.find((e: AssetItem) => e.id === id);
    return asset?.medias.filter(
        (e: AssetMediaMetadata) => e.type === 'picture'
    );
};

export const getMerchantAssetCoverPicture = (
    state: RootState,
    id: string
): AssetMediaMetadata | undefined => {
    const pics = getMerchantAssetPictures(state, id);
    const pic = pics?.find((e: AssetMediaMetadata) => e.priority === 1);
    return pic;
};
