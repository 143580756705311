/* eslint-disable @typescript-eslint/no-explicit-any */
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { sendSupportMessage } from 'libs/firebaseApp';

const Container = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        display: 'flex',
        flexDirection: 'column'
    }
}));

const Item = styled(Grid)``;

const Input = styled(TextField)`
    background-color: #ffffff;
    width: 100%;
    border-radius: 5px;
    padding-bottom: 0px;
    color: black;
`;

const SubmitButton = styled(Button)`
    background-color: #264257;
    width: 100%;
    padding: 20px;
    color: white;
    font-family: 'Jost';
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    line-height: 33px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    &:hover {
        cursor: pointer;
        background-color: #264257;
    }
`;

const InputTitle = styled('span')`
    color: white;
    display: flex;
`;

type Props = {
    firstName: string;
    lastName: string;
    email: string;
    message: string;
    setMessage: (value: string) => void;
    setAssociatedId: (value: string) => void;
    associatedId: string;
    setMessageSent: (value: boolean) => void;
};

export const SupportForm = ({
    firstName,
    lastName,
    email,
    message,
    setMessage,
    associatedId,
    setAssociatedId,
    setMessageSent
}: Props): JSX.Element => {
    const handleSupportSubmission = (): Promise<any> => {
        return sendSupportMessage({
            firstName,
            lastName,
            email,
            message,
            associatedId
        }).then(() => setMessageSent(true));
    };
    return (
        <Container spacing={5} container>
            <Item item xs={12} md={4}>
                <InputTitle>First Name*</InputTitle>
                <Input
                    id="firstName"
                    type="text"
                    autoFocus
                    value={firstName}
                    disabled
                    InputProps={{
                        style: {
                            paddingBottom: '0px !important'
                        }
                    }}
                />
            </Item>
            <Item item xs={12} md={4}>
                <InputTitle>Last Name*</InputTitle>
                <Input
                    id="lastName"
                    type="text"
                    autoFocus
                    value={lastName}
                    disabled
                    InputProps={{
                        style: {
                            paddingBottom: '0px !important'
                        }
                    }}
                />
            </Item>
            <Item item xs={12} md={4}>
                <InputTitle>Email*</InputTitle>
                <Input
                    id="email"
                    type="text"
                    autoFocus
                    value={email}
                    disabled
                    InputProps={{
                        style: {
                            paddingBottom: '0px !important'
                        }
                    }}
                />
            </Item>
            <Item item xs={12} md={4}>
                <InputTitle>Associated ID</InputTitle>
                <Input
                    id="email"
                    type="text"
                    autoFocus
                    value={associatedId}
                    InputProps={{
                        style: {
                            paddingBottom: '0px !important'
                        }
                    }}
                    onChange={(e) => setAssociatedId(e.target.value)}
                />
            </Item>
            <Item item xs={12}>
                <InputTitle>Message*</InputTitle>
                <Input
                    id="message"
                    placeholder="How Can We Help?"
                    type="text"
                    autoFocus
                    value={message}
                    InputProps={{
                        style: {
                            paddingBottom: '0px !important'
                        }
                    }}
                    multiline
                    rows={6}
                    maxRows={6}
                    onChange={(e) => setMessage(e.target.value)}
                />
            </Item>
            <Item item xs={12}>
                <SubmitButton onClick={handleSupportSubmission}>
                    Send Message
                </SubmitButton>
            </Item>
        </Container>
    );
};
