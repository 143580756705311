import { DatabaseService } from 'services/DatabaseService';
import { assetsActions } from 'state/assets/assetsSlice';
import { NFTMarketplaceService } from 'services/NFTMarketplaceService';

import { useAsyncAction } from './useAsyncAction';
import { useAppDispatch } from './useAppDispatch';

type StateLogic = [() => Promise<void>, boolean];

export const useFetchAllMarketplaceItems = (noCatch?: boolean): StateLogic => {
    const dispatch = useAppDispatch();

    const action = async (): Promise<void> => {
        // TODO: revert
        const items = await DatabaseService.getAllMarketItems();
        console.log('All items!', items);
        // const items = await new NFTMarketplaceService().getItemsForSale();
        dispatch(assetsActions.setList(items));
    };

    const [actionWithTryCatch, processing] = useAsyncAction<void, void>(
        action,
        { error: 'errorFetchingMarketplaceItems' }
    );

    return [noCatch ? action : actionWithTryCatch, processing];
};
