import { styled } from '@mui/material/styles';
import MuiTooltip, {
    TooltipProps,
    tooltipClasses
} from '@mui/material/Tooltip';

type Props = {
    title: string;
    children: React.ReactElement & React.ReactNode;
    maxWidth: string;
};

const Component = styled(({ className, ...props }: TooltipProps) => (
    <MuiTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 500,
        background: theme.palette.primary.dark
    }
}));

const Content = styled('div')<{ maxwidth: string }>`
    overflow: hidden;
    max-width: ${(props) => props.maxwidth};
    font-weight: 700;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

export const Tooltip = ({ title, children, maxWidth }: Props): JSX.Element => (
    <Component
        placement="top-start"
        title={<div style={{ fontSize: 14 }}>{title}</div>}>
        <Content maxwidth={maxWidth}>{children}</Content>
    </Component>
);
