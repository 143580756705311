import { ProductStats } from 'features/productStats/ProductStats';
import { ProductChartList } from 'features/productChartList/ProductChartList';
import { ProductOverview } from 'features/productOverview/ProductOverview';
// eslint-disable-next-line max-len
import { ListWithSelectedItemInDrawer } from 'layouts/ListWithSelectedItemInDrawer';

export const ProductChartListPage = (): JSX.Element => (
    <ListWithSelectedItemInDrawer
        leftPanelWidth={350}
        ListComponent={ProductChartList}
        LeftPanelComponent={ProductOverview}
        RightPanelComponent={ProductStats}
    />
);
