import { yupLocale as yup } from 'config/yup';
import { isValidInputNumber } from 'libs/utils';

export const MerchantInvestSchema = yup
    .object({
        name: yup.string().required().min(3).max(200).trim(),
        picture: yup
            .mixed()
            .required()
            .test('input_type_validation', 'Invalid Number', isValidInputNumber)
    })
    .required();
