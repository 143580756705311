import Box from '@mui/material/Box';

import { Image } from 'components/Image';
import { AssetMediaMetadata } from 'config/types/asset';

type Props = {
    data: AssetMediaMetadata;
    onSelect: () => void;
};

export const ImageCarouselItem = ({ data, onSelect }: Props): JSX.Element => (
    <Box sx={{ height: '220px' }} onClick={onSelect}>
        <Image
            sx={{
                width: '100%',
                display: 'block',
                objectFit: 'cover',
                objectPosition: 'center',
                height: 220
            }}
            uri={data.uri}
            title={data.title}
        />
    </Box>
);
