import { useTheme } from '@mui/material';
import { Grid } from 'components/Grid';
import { ListedItem } from 'config/types/asset';

type Props = {
    data: ListedItem;
};

export const ItemSpecs = ({ data }: Props): JSX.Element => {
    const theme = useTheme();

    return (
        <Grid container spacing={1} sx={{ minWidth: 350 }}>
            {['color', 'material', 'dimensions'].map((spec) => (
                <Grid
                    sx={{ color: theme.palette.primary.contrastText }}
                    key={spec}
                    item
                    xs={4}
                    textId={spec}
                    uppercased
                />
            ))}
            <Grid xs={4} item>
                {data.color}
            </Grid>
            <Grid xs={4} item>
                {data.material}
            </Grid>
            <Grid xs={4} item>
                {data.dimensions}
            </Grid>
        </Grid>
    );
};
