import { useContext } from 'react';
import { BlockChainContext } from 'blockchain/BlockchainContext';

import { useAsyncAction } from './useAsyncAction';

type ApproveParams = {
    recipient: string;
    value: number;
};

type StateLogic = {
    approveTokens: (params: ApproveParams) => Promise<void>;
    approvingTokens: boolean;
};

export const useTokenService = (): StateLogic => {
    const { staking } = useContext(BlockChainContext);

    const approve = async ({
        recipient,
        value
    }: ApproveParams): Promise<void> => {
        console.log(value);
        return staking?.approve(recipient, value);
    };

    const [approveTokens, approvingTokens] = useAsyncAction<
        ApproveParams,
        void
    >(approve, {
        info: 'Giving Marketplace access to tokens...',
        error: 'Could not grant allowance for marketplace listing',
        success: 'Granted allowance of tokens for listing'
    });

    return {
        approveTokens,
        approvingTokens
    };
};
