import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { CardMedia, Stack } from '@mui/material';
import { Typography } from 'components/Typography';
import { Staking } from './Staking';
import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';

export const ItemInvest = (): JSX.Element => {
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    const SubTitle = styled(Typography)(({ theme }) => ({
        fontSize: 20,
        marginBottom: 12,
        paddingLeft: 18,
        marginTop: '-5vh',
        [theme.breakpoints.down('sm')]: {
            zIndex: 100,
            padding: 15,
            fontSize: 23,
            marginTop: '2vh'
        }
    }));

    const Description = styled(Typography)(({ theme }) => ({
        fontSize: 16,
        marginBottom: 16,
        marginLeft: 12,
        marginRight: 12,
        marginTop: '4vh',
        zIndex: 100,
        color: theme.palette.primary.contrastText,
        [theme.breakpoints.down('sm')]: {
            zIndex: 100,
            padding: 15,
            fontSize: 23,
            marginTop: '4vh'
        }
    }));

    const GridContainer = styled(Grid)(({ theme }) => ({
        scrollbarWidth: 'none',
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        zIndex: 150,
        position: 'absolute',
        top: 0,
        left: 0,
        marginTop: '6rem',
        backgroundImage: theme.palette.error.light,
        background: theme.palette.error.light,
        '@media (max-width: 600px)': {
            display: 'flex',
            flexDirection: 'column'
        },
        '@media (max-width: 540px)': {
            display: 'flex',
            flexDirection: 'column',
            zIndex: 100,
            overflow: 'hidden'
        }
    }));

    const StakingGrid = styled(Grid)(({ theme }) => ({
        width: '70%',
        backgroundColor: '#36454f',
        height: '100%',
        position: 'absolute',
        right: '0',
        top: '0',
        bottom: '0',
        '@media (max-width: 600px)': {
            backgroundColor: '#36454f',
            height: 'max-content',
            position: 'relative',
            width: '100vw'
        }
    }));

    const LeftGrid = styled(Grid)(({ theme }) => ({
        height: '100%',
        width: '30%',
        '@media (max-width: 600px)': {
            width: '100%'
        }
    }));

    const DescriptionGrid = styled(Grid)(({ theme }) => ({
        width: '100%'
    }));

    const BlueBorder = styled('div')(({ theme }) => ({
        paddingTop: '10px',
        marginLeft: 12,
        marginRight: 12,
        borderBottom: `1px solid ${theme.palette.primary.light}`
    }));
    const MaterialsDiv = styled('div')(({ theme }) => ({
        display: 'flex',
        width: '90%',
        paddingLeft: '8px',
        color: theme.palette.primary.contrastText,
        justifyContent: 'space-between',
        gap: '20px',
        margin: '0 auto',
        alignSelf: 'flex-start',
        fontSize: '14px',
        height: '100%'
    }));

    const { state } = useLocation();

    console.log(state);

    useEffect(() => {
        const handleResize = (): void => {
            const width = Math.min(window.innerWidth * 0.9, 250);
            setDimensions({ width, height: width });
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <GridContainer>
            <LeftGrid>
                <Stack spacing={2} justifyContent="space-between">
                    <Grid container style={{ marginTop: '50px' }}>
                        <Grid item>
                            <CardMedia component="img" image="" alt="" />
                        </Grid>
                        {/* <DescriptionGrid id="left-panel">
                            <Description
                                sx={{
                                    textAlign: 'center',
                                    fontSize: '32px',
                                    fontWeight: 'bold',
                                    marginLeft: '2rem',
                                    marginTop: '1vh',
                                    marginBottom: '7rem',
                                    '@media (max-width: 600px)': {
                                        marginTop: '-2vh',
                                        marginBottom: '2vh'
                                    },
                                    '@media (max-width: 900px)': {
                                        marginTop: '2vh',
                                        marginBottom: '5vh'
                                    },
                                    '@media (max-width: 541px)': {
                                        display: 'none'
                                    }
                                }}>
                                Fractional Investing
                            </Description>
                        </DescriptionGrid> */}
                    </Grid>
                    <Grid container justifyContent="center">
                        <Grid
                            sm={12}
                            paddingBottom="16px"
                            style={{
                                width: '100%'
                            }}>
                            <SubTitle
                                textId={state.asset ? state.asset : 'NA'}
                                sx={{
                                    textAlign: 'center',
                                    background: 'none',
                                    '@media (max-width: 540px)': {
                                        marginTop: '-3rem'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid
                            sx={{
                                width: dimensions.width,
                                height: dimensions.height,
                                margin: 'auto'
                            }}>
                            <img
                                src={state.image}
                                alt="asset"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    objectPosition: 'center',
                                    borderRadius: '10px'
                                }}
                            />
                        </Grid>
                        <Grid sm={12}>
                            <Description
                                sx={{ background: 'none' }}
                                textId={
                                    state.description ? state.description : 'NA'
                                }
                            />
                        </Grid>
                        <Grid sm={12}>
                            <BlueBorder />
                        </Grid>
                    </Grid>
                    <MaterialsDiv>
                        <div>
                            <p style={{ fontWeight: '200' }}>COLOR</p>
                            <p style={{ marginTop: '-10px' }}>
                                {state.color ? state.color : 'NA'}
                            </p>
                        </div>
                        <div>
                            {' '}
                            <p style={{ fontWeight: '200' }}>MATERIAL</p>
                            <p style={{ marginTop: '-10px' }}>
                                {state.material ? state.material : 'NA'}
                            </p>
                        </div>
                        <div>
                            {' '}
                            <p style={{ fontWeight: '200' }}>DIMENSIONS</p>
                            <p style={{ marginTop: '-10px' }}>
                                {state.dimensions ? state.dimensions : 'NA'}
                            </p>
                        </div>
                    </MaterialsDiv>
                </Stack>
            </LeftGrid>
            {/* graph */}
            <StakingGrid item>
                <Staking />
            </StakingGrid>
        </GridContainer>
    );
};
