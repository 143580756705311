import Avatar from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';
import { useDropzone } from 'react-dropzone';
import { useFormContext } from 'react-hook-form';
import { useCallback, useState } from 'react';

import { useAlert } from 'hooks/useAlert';
import { FileTooLargeError } from 'libs/customErrors';
import { allowedFileMimes, fileMaxSize } from 'config';

type FileWithPreview = File & {
    preview: string;
};

type Props = {
    name?: string;
    filepath?: string;
};

const Dropzone = styled('div')<{
    isfocused: string;
    isdragreject: string;
    isdragaccept: string;
}>`
    margin: auto;
    cursor: pointer;
    position: relative;
    margin-bottom: -70px;
    width: 146px;
    height: 146px;
    border-width: 3px;
    border-style: solid;
    border-radius: 70px;
    border-color: ${(props) => {
        if (props.isdragreject === 'true') return '#ff1744';
        if (props.isdragaccept === 'true') return '#00e676';
        return 'transparent';
    }};
`;

export const AvatarUpload = ({
    filepath = '',
    name = 'avatar'
}: Props): JSX.Element => {
    const fieldName = 'avatarFile';

    const { errorAlert } = useAlert();

    const { register, setValue } = useFormContext();

    const [newFile, setNewFile] = useState<FileWithPreview>();

    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            try {
                // Exit if no valid files passed
                if (acceptedFiles.length < 1) return;
                const pickedFile = acceptedFiles[0];
                // Restricts the max file size
                if (pickedFile.size > fileMaxSize.picture) {
                    throw new FileTooLargeError();
                }
                // Set the value for this field in react-hook-form
                setValue(fieldName, pickedFile, { shouldValidate: true });
                // Set the new file picked by the user with its preview added
                setNewFile(
                    Object.assign(pickedFile, {
                        preview: URL.createObjectURL(pickedFile)
                    })
                );
            } catch (e) {
                errorAlert(
                    e instanceof FileTooLargeError
                        ? 'fileTooLargeError'
                        : 'unexpectedError'
                );
            }
        },
        [errorAlert, setValue]
    );

    const {
        isFocused,
        isDragReject,
        isDragAccept,
        getRootProps,
        getInputProps
    } = useDropzone({
        onDrop,
        accept: allowedFileMimes.picture,
        maxFiles: 1
    });

    return (
        <Dropzone
            {...getRootProps()}
            isfocused={isFocused.toString()}
            isdragreject={isDragReject.toString()}
            isdragaccept={isDragAccept.toString()}>
            <input {...register(fieldName)} {...getInputProps()} />
            <Avatar
                src={newFile?.preview ?? filepath}
                alt={name}
                sx={{ width: 140, height: 140 }}
            />
        </Dropzone>
    );
};
