import Box from '@mui/material/Box';

import { Popover } from 'components/Popover';

import { useNotifications } from './useNotifications';
import { NotificationItem } from './NotificationItem';
import { NotificationsButton } from './NotificationsButton';

export const Notifications = (): JSX.Element => {
    const { open, anchorEl, notifications, toggleOpen, deleteNotification } =
        useNotifications();
    return (
        <Box>
            <NotificationsButton
                onClick={toggleOpen}
                nbOfNotifications={notifications.length}
            />
            <Popover sx={{ zIndex: 100 }} open={open} anchorEl={anchorEl}>
                {notifications.map((notification) => (
                    <NotificationItem
                        key={notification.id}
                        data={notification}
                        onDelete={deleteNotification}
                    />
                ))}
            </Popover>
        </Box>
    );
};
