import { FirebaseApp, initializeApp } from 'firebase/app';
import { getFunctions, httpsCallableFromURL } from 'firebase/functions';

import { firebaseConfig } from 'config/firebaseConfig';

export const firebaseApp: FirebaseApp = initializeApp(firebaseConfig);

// const functions = firebase.functions();
const functions = getFunctions(firebaseApp);

export const sendSupportMessage = httpsCallableFromURL(
    functions,
    // the URL of the function
    // eslint-disable-next-line max-len
    'https://us-central1-lux-vesting-350421.cloudfunctions.net/sendSupportMessage'
);
