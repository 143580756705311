import maxBy from 'lodash/maxBy';
import { useEffect } from 'react';

import { Bid } from 'config/types';
import { RootState } from 'app/store';
import { useAppSelector } from 'hooks/useAppSelector';
import { getAssetBids } from 'state/assets/selectors';
import { useFetchItemBids } from 'hooks/useFetchItemBids';
import { ListedItem, AssetMediaMetadataItem } from 'config/types/asset';
import { getItemPics, getCertificateOfAuthenticity } from 'libs/utils';

type StateLogic = {
    coaURI?: string;
    pictures: AssetMediaMetadataItem[];
    highestBid?: Bid;
    fetchingBids: boolean;
};

export const useMarketItemSpecPanel = (data: ListedItem): StateLogic => {
    const coaURI = getCertificateOfAuthenticity(data);

    const pictures = getItemPics(data);

    const bids = useAppSelector((state: RootState) =>
        getAssetBids(state, data.id)
    );

    const highestBid = maxBy(bids, 'value');

    const [fetchHighestBid, fetchingBids] = useFetchItemBids();

    useEffect(() => {
        fetchHighestBid({ nftId: data.nftId, id: data.id });
    }, []);

    return {
        coaURI,
        pictures,
        highestBid,
        fetchingBids
    };
};
