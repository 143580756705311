import Torus, {
    TorusInpageProvider,
    UserInfo,
    WALLET_PATH
} from '@toruslabs/torus-embed';

import { logger } from 'libs/logger';
import { torusConfig } from 'config/torus';
import { User } from 'config/types/user';

const log = logger('Torus Service');

export class TorusService {
    public api!: Torus;

    async init(): Promise<void> {
        console.log('initializing');
        log.debug('Initialisation');
        const torus = new Torus();

        await torus.init(torusConfig);
        console.log('initialized', torus);
        log.debug('successfully initialised');

        this.api = torus;
    }

    getProvider(): TorusInpageProvider {
        return this.api.provider;
    }

    static async login(torus: Torus): Promise<User> {
        // const instance = this.api;
        // console.log(instance);
        const [account] = await torus.login();
        console.log(account);
        // TODO: why do we need to pass a empty string?
        const userInfo: UserInfo = await torus.getUserInfo('');
        console.log(userInfo);
        return { ...userInfo, account };
    }

    static async logout(torus: Torus): Promise<void> {
        await torus.cleanUp();
    }

    isReady(): boolean {
        return this.api.isInitialized;
    }

    static showWallet(torus: Torus, type: WALLET_PATH): void {
        torus.showWallet(type);
    }
}

// let torusService: TorusService;
// let loading = false;

// const wait = async (): Promise<TorusService> =>
//     new Promise((resolve) => {
//         setTimeout(async () => {
//             const service = await getTorusService();
//             resolve(service);
//         }, 100);
//     });

// async function getTorusService(): Promise<TorusService> {
//     if (loading) {
//         return wait();
//     } else if (torusService) {
//         return torusService;
//     } else {
//         console.log('creating torus service');
//         torusService = new TorusService();

//         loading = true;
//         await torusService.init();

//         loading = false;

//         Object.freeze(torusService);

//         return torusService;
//     }
// }

// export { getTorusService };
