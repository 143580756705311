import { Asset } from 'config/types/asset';
import { assetsActions } from 'state/assets/assetsSlice';
import { DatabaseService } from 'services/DatabaseService';
import { merchantAssetsActions } from 'state/merchantAssets/merchantAssetsSlice';

import { useAsyncAction } from './useAsyncAction';
import { useAppDispatch } from './useAppDispatch';

type StateLogic = [(values: Partial<Asset>) => Promise<void>, boolean];

export const useUpdateAssetData = (
    id: string,
    noCatch?: boolean
): StateLogic => {
    const dispatch = useAppDispatch();

    const updateAssetData = async (data: Partial<Asset>): Promise<void> => {
        await DatabaseService.updateAssetData(id as string, data);
        dispatch(merchantAssetsActions.updateAssetData({ id, data }));
        dispatch(assetsActions.updateData({ id, data }));
    };

    const [updateAssetDataWithTryCatch, processing] = useAsyncAction<
        Partial<Asset>,
        void
    >(updateAssetData, { error: 'errorUpdatingAssetData' });

    return [
        noCatch ? updateAssetData : updateAssetDataWithTryCatch,
        processing
    ];
};
