import { withEllipsis } from 'libs/utils';
import { Typography } from 'components/Typography';

type Props = {
    name: string;
};

export const ItemName = ({ name }: Props): JSX.Element => (
    <Typography
        sx={{
            height: 80,
            overflow: 'hidden',
            '@media (max-width: 290px)': {
                fontSize: '16px'
            }
        }}
        title={name}
        variant="h4"
        uppercased>
        {withEllipsis(name, 40)}
    </Typography>
);
