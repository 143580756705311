import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import MuiCard from '@mui/material/Card';
import MuiSlider from '@mui/material/Slider';
import { useTranslation } from 'react-i18next';

import { Row } from 'components/Row';
import { Typography } from 'components/Typography';
import { LightDivider } from 'components/LightDivider';
import { LightButton } from 'components/buttons/LightButton';
import { BlackButton } from 'components/buttons/BlackButton';
import { UserLimitOrder } from 'config/types/user';
import { defaultCurrencySymbol } from 'config';
import { ReactComponent as EditIcon } from 'assets/edit.svg';
import { ReactComponent as WatchImage } from 'assets/watch-example.svg';

type Props = {
    data: UserLimitOrder;
};

const Card = styled(MuiCard)(({ theme }) => {
    return {
        padding: '15px',
        background: theme.palette.error.dark
    };
});
const Slider = styled(MuiSlider)<{ invertedtrack?: string }>(
    ({ theme, invertedtrack }) => ({
        color:
            invertedtrack === 'true'
                ? theme.palette.primary.dark
                : theme.palette.primary.light,
        height: 4,
        '& .MuiSlider-rail': {
            height: 6,
            opacity: 1,
            color:
                invertedtrack === 'true'
                    ? theme.palette.primary.light
                    : theme.palette.primary.dark
        },
        '& .MuiSlider-markLabel': {
            color: theme.palette.primary.contrastText,
            fontSize: 16,
            fontWeight: theme.typography.fontWeightBold
        },
        '& .MuiSlider-mark': {
            color: theme.palette.primary.dark,
            backgroundColor: theme.palette.primary.dark
        }
    })
);

export const UserLimitOrdersCard = ({ data }: Props): JSX.Element => {
    const { t } = useTranslation();

    const v1 = data.tradePrice / 2;

    const l1 = `${defaultCurrencySymbol}${data.tradePrice}`;

    const v2 = data.min ? 1 : 99;

    const l2 = (
        <Row justifyContent="center" alignItems="baseline" spacing={1}>
            <span>
                {defaultCurrencySymbol}
                {data.min || data.max}
            </span>
            <EditIcon height={12} width={12} />
        </Row>
    );

    return (
        <Card
            sx={{
                minWidth: 320,
                '@media (max-width: 400px)': {
                    minWidth: 290,
                    padding: 5,
                    overflowY: 'hidden'
                },
                '@media (max-width: 365px)': {
                    minWidth: 250,
                    padding: 5,
                    overflowY: 'hidden'
                },
                '@media (max-width: 290px)': {
                    minWidth: 200,
                    padding: 3
                }
            }}
            key={data.id}>
            <Stack alignItems="center">
                <Typography uppercased variant="body1">
                    {data.product}
                </Typography>
                <WatchImage width={120} />
                <Slider
                    sx={{
                        width: '75%',
                        placeContent: 'center',
                        padding: '6px',
                        '@media (max-width: 290px)': {
                            padding: '0px'
                        }
                    }}
                    invertedtrack={(!!data.min).toString()}
                    marks={[
                        { value: v1, label: l1 },
                        { value: v2, label: l2 }
                    ]}
                    value={v1}
                    aria-label={t('trade price')}
                />
            </Stack>
            <LightDivider margin={4} />
            <Row spacing={2} justifyContent="space-between">
                <LightButton textId="sell now" />
                <BlackButton textId="view details" />
            </Row>
        </Card>
    );
};
