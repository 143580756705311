import { Typography } from 'components/Typography';

type Props = {
    title: string;
};

export const ItemCardTitle = ({ title }: Props): JSX.Element => (
    <Typography
        uppercased
        variant="h6"
        sx={{
            height: 65,
            overflow: 'hidden',
            fontWeight: 300
        }}>
        {title}
    </Typography>
);
