import List from '@mui/material/List';
import Box from '@mui/material/Box';
import sortBy from 'lodash/sortBy';
import ListItem from '@mui/material/ListItem';

import { Row } from 'components/Row';
import { RootState } from 'app/store';
import { DarkButton } from 'components/buttons/DarkButton';
import { Typography } from 'components/Typography';
import { PurpleButton } from 'components/buttons/PurpleButton';
import { useAppSelector } from 'hooks/useAppSelector';
import { getMerchantAsset } from 'state/merchantAssets/selectors';
import { useBuyMarketItem } from 'hooks/useBuyMarketItem';
import { defaultCurrencySymbol } from 'config';

type Props = {
    assetId: string;
    handleOpenChat: (id: string) => void;
};

export const AssetBidsPanel = ({
    assetId,
    handleOpenChat
}: Props): JSX.Element => {
    const [buyMarketItem] = useBuyMarketItem();

    const { bids, nftId } = useAppSelector((state: RootState) =>
        getMerchantAsset(state, assetId)
    );

    const buy = (): void => {
        buyMarketItem({ assetId, nftId });
    };

    return (
        <Box
            sx={{
                width: 500,
                '@media (max-width: 540px)': {
                    scale: '80%'
                },
                '@media (max-width: 470px)': {
                    scale: '70%',
                    position: 'relative',
                    right: '-40px'
                },
                '@media (max-width: 400px)': {
                    scale: '60%',
                    position: 'relative',
                    right: '-80px'
                },
                '@media (max-width: 340px)': {
                    scale: '60%',
                    position: 'relative',
                    right: '-120px'
                },
                '@media (max-width: 300px)': {
                    scale: '50%',
                    position: 'relative',
                    right: '-130px'
                }
            }}>
            {bids && bids.length ? (
                sortBy(bids, (e) => e.value)
                    .reverse()
                    .map((bid) => (
                        <List key={bid.bidder + bid.value}>
                            <ListItem
                                secondaryAction={
                                    <Row spacing={2}>
                                        <PurpleButton
                                            size="small"
                                            textId="accept"
                                            onClick={() => buy()}
                                        />
                                        <DarkButton
                                            size="small"
                                            textId="message"
                                            onClick={() =>
                                                handleOpenChat(bid.bidder)
                                            }
                                        />
                                    </Row>
                                }>
                                <Row spacing={2}>
                                    <Typography bold color="primary.light">
                                        {defaultCurrencySymbol}
                                        {bid.value.toLocaleString()}
                                    </Typography>
                                    <Typography textId="by" />
                                    <Typography
                                        sx={{ maxWidth: 210 }}
                                        noWrap
                                        color="primary.light">
                                        {bid.bidder}
                                    </Typography>
                                </Row>
                            </ListItem>
                        </List>
                    ))
            ) : (
                <Typography
                    capitalized
                    sx={{ m: 'auto' }}
                    color="primary.light"
                    textId="noBids"
                    fontSize={24}
                />
            )}
        </Box>
    );
};
