import { Tooltip } from '../Tooltip';
import { TableCell } from './TableCell';

type Props = {
    title: string;
    children: React.ReactElement & React.ReactNode;
    maxWidth: string;
};

export const TableCellWithToolip = ({
    title,
    children,
    maxWidth
}: Props): JSX.Element => (
    <TableCell>
        <Tooltip title={title} maxWidth={maxWidth}>
            {children}
        </Tooltip>
    </TableCell>
);
