import { useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { IconButton } from '@mui/material';

type Props = {
    onClick: () => void;
};

export const FloatingAddButton = ({ onClick }: Props): JSX.Element => {
    const [hover, setHover] = useState<boolean>(false);

    const handleMouseEnter = (): void => {
        setHover(true);
    };

    const handleMouseLeave = (): void => {
        setHover(false);
    };

    return (
        <IconButton
            sx={{
                right: 20,
                bottom: 20,
                width: 50,
                height: 50,
                opacity: 0.8,
                position: 'fixed',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'primary.light',
                borderRadius: 50,
                '&:hover': {
                    opacity: 1,
                    backgroundColor: '#363636'
                }
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={onClick}>
            <AddIcon
                fontSize="large"
                sx={{ color: hover ? 'primary.light' : 'common.black' }}
            />
        </IconButton>
    );
};
