import { useContext } from 'react';
import { BlockChainContext } from 'blockchain/BlockchainContext';
import { CreateListingParams } from 'services/NFTMarketplaceService';

import { useAsyncAction } from './useAsyncAction';

type StateLogic = [(params: CreateListingParams) => Promise<void>, boolean];

export const useCreateMarketplaceItem = (): StateLogic => {
    const { marketplace } = useContext(BlockChainContext);

    const action = (params: CreateListingParams): Promise<void> =>
        marketplace!.createListing(params);

    const [createMarketplaceItem, processing] = useAsyncAction<
        CreateListingParams,
        void
    >(action, {
        info: 'listingProcessLaunched',
        error: 'errorListingNewAsset',
        success: 'successListingNewAsset'
    });

    return [createMarketplaceItem, processing];
};
