import { styled } from '@mui/material';
import MuiCard from '@mui/material/Card';
import { ThemeConsumer } from 'styled-components';

type Props = React.ComponentProps<typeof MuiCard> & {
    width?: string;
    children: React.ReactNode;
};

const Card = styled(MuiCard)(({ theme }) => ({
    padding: theme.spacing(4),
    borderRadius: '5px',
    background: theme.palette.success.dark
}));

export const StandardCard = ({ sx, width, children }: Props): JSX.Element => {
    return <Card sx={{ ...{ mx: 'auto', width }, ...sx }}>{children}</Card>;
};
