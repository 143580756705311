import { RootState } from 'app/store';
import { useAppSelector } from 'hooks/useAppSelector';
import { getMerchantAssetCoverPicture } from 'state/merchantAssets/selectors';
import { Image } from 'components/Image';

type Props = {
    assetId: string;
    assetName: string;
};

export const AssetRecordPicture = ({
    assetId,
    assetName
}: Props): JSX.Element => {
    const { uri } =
        useAppSelector((state: RootState) =>
            getMerchantAssetCoverPicture(state, assetId)
        ) || {};
    console.log(uri, 'uri');
    return (
        <Image
            sx={{ borderRadius: 1 }}
            uri={uri}
            title={assetName}
            width={40}
            height={40}
        />
    );
};
