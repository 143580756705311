import { AssetBidsPanel } from 'features/assetBidsPanel/AssetBidsPanel';
import { ChatRoomPanel } from 'features/chatRoomPanel/ChatRoomPanel';
import { StandardDrawer } from 'components/StandardDrawer';

import { useAssetBidsDrawer } from './useAssetBidsDrawer';

type Props = {
    open: boolean;
    assetId: string;
    handleClose: () => void;
};

export const AssetBidsDrawer = ({
    open,
    assetId,
    handleClose
}: Props): JSX.Element => {
    const {
        userId,
        clientId,
        onClose,
        openedChat,
        handleOpenChat,
        handleCloseChat
    } = useAssetBidsDrawer(handleClose);

    return (
        <StandardDrawer
            open={open}
            onClose={openedChat ? handleCloseChat : onClose}>
            {openedChat && clientId ? (
                <ChatRoomPanel
                    assetId={assetId}
                    clientId={clientId}
                    merchantId={userId}
                />
            ) : (
                <AssetBidsPanel
                    assetId={assetId}
                    handleOpenChat={handleOpenChat}
                />
            )}
        </StandardDrawer>
    );
};
