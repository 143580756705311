import { styled } from '@mui/material/styles';
import { Typography } from 'components/Typography';

const Container = styled('div')`
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Text = styled(Typography)(({ theme }) => ({
    color: theme.palette.error.main
}));

export const SomethingWentWrongPage = (): JSX.Element => {
    return (
        <Container>
            <Text variant="h4" textId="somethingWentWrong" />
        </Container>
    );
};
