import { Box } from '@mui/material';
import { Typography } from 'components/Typography';

type Props = {
    data?: string;
};

export const AssetName = ({ data }: Props): JSX.Element => (
    <Box
        sx={{
            p: 1,
            my: 2,
            color: 'primary.light',
            textAlign: 'center',
            backgroundColor: 'common.black'
        }}>
        {data ?? (
            <Typography
                sx={{ color: 'primary.light', fontStyle: 'italic' }}
                textId="missingName"
                variant="body1"
            />
        )}
    </Box>
);
