import {
    move,
    swap,
    GridItem,
    GridDropZone,
    GridContextProvider
} from 'react-grid-dnd';
import { useState } from 'react';
import { Box, styled } from '@mui/material';
import { RootState } from 'app/store';

import { MediaItem } from 'components/MediaItem';
import { StandardModal } from 'components/StandardModal';
import { useAppSelector } from 'hooks/useAppSelector';
import { AssetItem, AssetMediaMetadata } from 'config/types/asset';

type Item = {
    id: number;
    name: string;
};

const StyledGridDropZone = styled(GridDropZone)`
    flex: 1;
    height: 400px;
    margin-right: 10px;
    border: 1px solid green;
`;

const data: Item[] = [
    { id: 7, name: 'george' },
    { id: 8, name: 'rupert' },
    { id: 9, name: 'alice' },
    { id: 10, name: 'katherine' },
    { id: 11, name: 'pam' },
    { id: 12, name: 'katie' }
];

export const TestPage = (): JSX.Element => {
    const medias: AssetMediaMetadata[] = useAppSelector((state: RootState) => {
        const asset: AssetItem = state.merchantAssets[0];
        return asset.medias.filter((e) => e.type === 'picture') || [];
    });

    const [deletedItems, setDeletedItems] = useState<AssetMediaMetadata[]>([]);

    const [items, setItems] = useState<AssetMediaMetadata[]>(medias);

    const onChange = (
        _: string,
        sourceIndex: number,
        targetIndex: number
    ): void => {
        const result = swap(items, sourceIndex, targetIndex);
        return setItems(result);
    };

    const deleteItem = (idx: number): void => {
        const copyOfState = items.slice();
        const deletedElement = copyOfState.splice(idx, 1);
        setDeletedItems([...deletedElement, ...deletedItems]);
        setItems(copyOfState);
    };

    return (
        <StandardModal open handleClose={() => null}>
            <GridContextProvider onChange={onChange}>
                <Box>
                    <StyledGridDropZone
                        id="left"
                        boxesPerRow={2}
                        rowHeight={180}>
                        {items.map((item, idx) => (
                            <GridItem key={item.name}>
                                <MediaItem
                                    draggable
                                    data={item}
                                    onRemove={() => deleteItem(idx)}
                                />
                            </GridItem>
                        ))}
                    </StyledGridDropZone>
                </Box>
            </GridContextProvider>
        </StandardModal>
    );
};
