import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import MuiCard from '@mui/material/Card';
import { styled } from '@mui/material/styles';

import { Row } from 'components/Row';
import { RootState } from 'app/store';
import { AssetItem } from 'config/types/asset';
import { LightDivider } from 'components/LightDivider';
import { useAppSelector } from 'hooks/useAppSelector';
import { AssetStatusLabel } from 'components/AssetStatusLabel';
import { ReactComponent as ShieldIcon } from 'assets/shield.svg';
import { getMerchantAssetCoverPicture } from 'state/merchantAssets/selectors';

import { ItemPrice } from './ItemPrice';
import { ItemName } from './ItemName';
import { ItemPicture } from './ItemPicture';
import { NumberOfBids } from './NumberOfBids';
import { ItemCreationDate } from './ItemCreationDate';

type Props = {
    data: AssetItem;
    children: React.ReactNode;
};

const Card = styled(MuiCard)(({ theme }) => {
    return {
        width: '200px',
        background: theme.palette.error.dark
    };
});

export const UserItemCard = ({ data, children }: Props): JSX.Element => {
    const coverPic = useAppSelector((state: RootState) =>
        getMerchantAssetCoverPicture(state, data.id)
    );

    return (
        <Card
            sx={{
                minWidth: 320,
                paddingBottom: '10px',
                '@media (max-width: 400px)': {
                    minWidth: 250
                },
                '@media (max-width: 290px)': {
                    minWidth: 215
                }
            }}>
            <Row justifyContent="space-between">
                <AssetStatusLabel status={data.status} />
                <Box
                    sx={{
                        pr: 2,
                        pt: 1,
                        '@media (max-width: 290px)': {
                            marginRight: '6px'
                        }
                    }}>
                    <ShieldIcon height={20} width={20} />
                </Box>
            </Row>
            {data.bids ? (
                <NumberOfBids sx={{ pt: 2 }} data={data.bids.length} />
            ) : (
                <Box sx={{ height: 40 }} />
            )}
            <Stack
                sx={{
                    mx: 3,
                    '@media (max-width: 290px)': {
                        spacing: 5
                    }
                }}
                spacing={3}>
                <Row
                    spacing={2}
                    alignItems="center"
                    justifyContent="space-between">
                    <ItemName name={data.name} />
                    <ItemPicture uri={coverPic?.uri} name={data.name} />
                </Row>
                <Row justifyContent="space-between">
                    <ItemPrice status={data.status} price={data.price} />
                    <ItemCreationDate
                        date={data.createdAt}
                        status={data.status}
                    />
                </Row>
                <LightDivider />
                {children}
            </Stack>
        </Card>
    );
};
