import { TabPanelProps } from 'config/types';

export const TabPanel = (props: TabPanelProps): JSX.Element => {
    const { children, value, index, ...other } = props;

    return (
        <div
            id={`portfolio-item-table-${index}`}
            role="tabpanel"
            hidden={value !== index}
            aria-labelledby={`portfolio-item-${index}`}
            {...other}>
            {children}
        </div>
    );
};
