import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { FormProvider } from 'react-hook-form';

import { Row } from 'components/Row';
import { DarkButton } from 'components/buttons/DarkButton';
import { LightButton } from 'components/buttons/LightButton';
import { StandardModal } from 'components/StandardModal';
import { CustomTextField } from 'components/CustomTextField';
import { AssetListingFormInput } from 'config/types/asset';

import { TextInput } from 'components/TextInput';
import { useEditAssetDataModal } from './useEditAssetDataModal';
import './editAssetDataModal.css';

import { CustomSelect } from 'components/CategorySelect';
import { categories } from 'config';
import { Grid } from '@mui/material';

type Props = {
    open: boolean;
    assetId: string;
    handleClose: () => void;
};

export const EditAssetDataModal = ({
    open,
    assetId,
    handleClose
}: Props): JSX.Element => {
    const { onClose, onSubmit, submitting, formMethods } =
        useEditAssetDataModal(assetId, handleClose);

    return (
        <StandardModal
            sx={{
                p: 5
            }}
            title="editAssetData"
            open={open}
            className="assetDataModal"
            handleClose={handleClose}>
            <FormProvider {...formMethods}>
                <form
                    noValidate
                    autoComplete="off"
                    onSubmit={formMethods.handleSubmit(onSubmit)}>
                    <Grid
                        container
                        sx={{
                            my: 3,
                            mb: 0
                        }}>
                        <Box
                            sx={{
                                mb: 6,
                                width: '100%',
                                textAlign: 'center',
                                bgcolor: 'common.black'
                            }}>
                            <TextInput
                                sx={{
                                    p: 1,
                                    '& .MuiInput-underline:before': {
                                        borderBottomWidth: 0
                                    },

                                    width: '100%'
                                }}
                                required
                                autoFocus
                                name="name"
                                disabled={submitting}
                                placeholder="productName"
                                inputProps={{
                                    sx: {
                                        textAlign: 'center',
                                        '@media (max-width: 575px)': {
                                            textAlign: 'left'
                                        }
                                    }
                                }}
                            />
                        </Box>
                        <Row
                            sx={{
                                display: 'grid',
                                gridTemplateColumns:
                                    'repeat(2, minmax(0, 1fr))',
                                gap: '10px',
                                '@media (max-width: 400px)': {
                                    gridTemplateColumns:
                                        'repeat(1, minmax(0, 1fr))'
                                }
                            }}>
                            {/* asset description */}
                            <div>
                                <Stack spacing={3} sx={{ flex: 3 }}>
                                    <CustomTextField<AssetListingFormInput>
                                        rows={5}
                                        multiline
                                        required
                                        label="assetDescription"
                                        name="description"
                                        disabled={submitting}
                                    />
                                    <CustomSelect<AssetListingFormInput>
                                        label="category"
                                        options={categories}
                                        disabled={submitting}
                                        required
                                        name={'category'}></CustomSelect>
                                </Stack>
                            </div>
                            {/* price, dimensions, color, material */}
                            <div>
                                <div
                                    style={{
                                        display: 'grid',
                                        gridTemplateColumns:
                                            'repeat(2, minmax(0, 1fr))',
                                        gap: '10px'
                                    }}>
                                    <CustomTextField<AssetListingFormInput>
                                        required
                                        type="number"
                                        label="price"
                                        name="price"
                                        disabled={submitting}
                                    />
                                    <CustomTextField<AssetListingFormInput>
                                        required
                                        type="text"
                                        label="dimensions"
                                        name="dimensions"
                                        disabled={submitting}
                                    />
                                </div>
                                <div
                                    style={{
                                        display: 'grid',
                                        gridTemplateColumns:
                                            'repeat(2, minmax(0, 1fr))',
                                        gap: '10px'
                                    }}>
                                    <CustomTextField<AssetListingFormInput>
                                        required
                                        label="color"
                                        name="color"
                                        disabled={submitting}
                                    />
                                    <CustomTextField<AssetListingFormInput>
                                        required
                                        label="material"
                                        name="material"
                                        disabled={submitting}
                                    />
                                </div>
                            </div>
                        </Row>
                    </Grid>
                    <Row sx={{ mt: 3 }} justifyContent="space-between">
                        <DarkButton
                            textId="cancel"
                            onClick={onClose}
                            disabled={submitting}
                        />
                        <LightButton
                            type="submit"
                            textId="submit"
                            loading={submitting}
                        />
                    </Row>
                </form>
            </FormProvider>
        </StandardModal>
    );
};
