import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';
import DialogContent from '@mui/material/DialogContent';

import { DialogTitle } from 'components/DialogTitle';
import { Typography } from './Typography';

type Props = React.ComponentProps<typeof Dialog> & {
    title?: string;
    open: boolean;
    children: React.ReactNode;
    minwidth?: string;
    handleClose: () => void;
    maxw?: string;
};

const RoundedDialog = styled(Dialog)<{ minwidth?: string }>(({ minwidth }) => ({
    '& .MuiDialog-paper': {
        minWidth: minwidth,
        borderRadius: '5px',
        background: 'transparent'
    }
}));

const DialogTitleContainer = styled(DialogTitle)(({ theme }) => ({
    textAlign: 'center',
    background: theme.palette.warning.dark,
    color: theme.palette.primary.contrastText
}));

const DialogContentContainer = styled(DialogContent)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    minHeight: 200,
    background: theme.palette.warning.contrastText,
    // ...(maxw ? { maxWidth: maxw } : {}),
    maxWidth: '100%',
    color: theme.palette.primary.contrastText
}));

export const StandardModal = ({
    title,
    open,
    children,
    minwidth,
    handleClose,
    maxw,
    ...other
}: Props): JSX.Element => (
    <div style={{ overflow: 'hidden' }}>
        <RoundedDialog
            open={open}
            onClose={handleClose}
            minwidth={minwidth}
            {...other}>
            {title && <DialogTitleContainer textId={title} allCapitalized />}
            <DialogContentContainer
                sx={{}}
                style={{
                    margin: 'auto',
                    maxWidth: ''
                }}
                id="all-cards-width">
                {children}
            </DialogContentContainer>
        </RoundedDialog>
    </div>
);
