import { styled } from '@mui/material/styles';
import MuiButton from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

const Button = styled(MuiButton)(({ theme }) => ({
    background: theme.palette.primary.dark,
    '&: hover: enabled': {
        background: theme.palette.secondary.main
    },
    '&: enabled': {
        background: theme.palette.secondary.main
    }
}));

const Link = styled(RouterLink)`
    color: #fff;
    text-decoration: none;
`;

type Props = {
    disabled: boolean;
};

export const CancelButton = ({ disabled }: Props): JSX.Element => {
    const { t } = useTranslation();

    return (
        <Button disabled={disabled}>
            {disabled ? t('cancel') : <Link to="/profile">{t('cancel')}</Link>}
        </Button>
    );
};
