import { Typography } from './Typography';

type Props = {
    textId?: string;
    children?: React.ReactNode;
};

export const StandardPageSubtitle = ({
    textId,
    children
}: Props): JSX.Element =>
    textId ? (
        <Typography variant="h6" textId={textId} />
    ) : (
        <Typography variant="h6">{children}</Typography>
    );
