import Stack from '@mui/material/Stack';

import { DataPoint } from 'components/DataPoint';
import { Typography } from 'components/Typography';
import { AssetListingStatus } from 'config/types/asset';
import { defaultCurrencySymbol } from 'config';

type Props = {
    price: number;
    status: AssetListingStatus;
};

export const ItemPrice = ({ price, status }: Props): JSX.Element => (
    <Stack>
        <Typography
            sx={{
                '@media (max-width: 400px)': {
                    fontSize: '12px'
                }
            }}
            uppercased
            variant="body1"
            textId={status === 'sold' ? 'sale price' : 'listed price'}
        />
        <DataPoint
            sx={{
                '@media (max-width: 400px)': {
                    fontSize: '12px'
                }
            }}
            bold
            data={price}
            prefix={defaultCurrencySymbol}
        />
    </Stack>
);
