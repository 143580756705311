import { useAppDispatch } from 'hooks/useAppDispatch';
import { getAddFundsDrawerState } from 'state/addFundsDrawer/selectors';
import { addFundsDrawerActions } from 'state/addFundsDrawer/addFundsDrawerSlice';

import { useAppSelector } from './useAppSelector';

type StateLogic = [(content?: string) => void, () => void, boolean];

export const useAddFundsDrawer = (): StateLogic => {
    const dispatch = useAppDispatch();

    const opened = useAppSelector(getAddFundsDrawerState);

    const open = (content?: string): void => {
        dispatch(addFundsDrawerActions.open(content));
    };

    const close = (): void => {
        dispatch(addFundsDrawerActions.close());
    };

    return [open, close, opened];
};
