import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { MouseEvent } from 'react';

import { IconButton } from 'components/IconButton';
import { ThemeOptions, styled } from '@mui/material';

type Props = {
    onClick: (e: MouseEvent<HTMLButtonElement>) => void;
    nbOfNotifications: number;
    theme: ThemeOptions;
};
const CustomIcon = styled(NotificationsIcon)`
    color: ${(props) => props.theme.palette.success.light};
`;
export const NotificationsButton = ({
    onClick,
    nbOfNotifications
}: Props): JSX.Element => {
    return (
        <IconButton onClick={onClick} style={{ zIndex: 200 }}>
            <Badge badgeContent={nbOfNotifications} color="error">
                <CustomIcon />
            </Badge>
        </IconButton>
    );
};
