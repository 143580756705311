import { useAlert } from 'hooks/useAlert';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, UseFormReturn, SubmitHandler } from 'react-hook-form';

import { logger } from 'libs/logger';
import { RootState } from 'app/store';
import { useAppSelector } from 'hooks/useAppSelector';
import { useAsyncAction } from 'hooks/useAsyncAction';
import { useUpdateAssetData } from 'hooks/useUpdateAssetData';
import { AssetItem, AssetListingFormFields } from 'config/types/asset';
import { getMerchantAssetName } from 'state/merchantAssets/selectors';

import { createListingSchema } from './createListingSchema';
import { createListingDefaultValues } from './createListingDefaultValues';
import { isUserAdmin } from 'state/user/selectors';
import { useAssetListingStatusUpdateButton } from 'features/assetListingStatusControlModal/useAssetListingStatusUpdateButton';

type StateLogic = {
    onClose: () => void;
    onSubmit: (data: AssetListingFormFields) => void;
    submitting: boolean;
    assetName: string;
    // eslint-disable-next-line @typescript-eslint/ban-types
    formMethods: UseFormReturn<AssetListingFormFields, object>;
};

type Options = {
    silent?: boolean;
};

const log = logger('useCreateAssetListingModal');

export const useCreateAssetListingModal = (
    assetId: string,
    asset: AssetItem,
    handleClose: () => void,
    { silent = false }: Options = {}
): StateLogic => {
    const { successAlert } = useAlert(silent);

    const { onApprove } = useAssetListingStatusUpdateButton(asset, handleClose);

    const [updateAssetData] = useUpdateAssetData(assetId, true);

    const userIsAdmin = useAppSelector(isUserAdmin);

    const assetName = useAppSelector((state: RootState) =>
        getMerchantAssetName(state, assetId)
    );

    const formMethods = useForm<AssetListingFormFields>({
        defaultValues: createListingDefaultValues,
        resolver: yupResolver(createListingSchema)
    });

    const onClose = (): void => {
        formMethods.reset();
        handleClose();
    };

    const action: SubmitHandler<AssetListingFormFields> = async (
        values
    ): Promise<void> => {
        log.info('Submitting new product', values);
        const { price: priceStr, ...data } = values;
        const price = parseInt(priceStr, 10);
        console.log('create asset listing modal!!', price, data);
        await updateAssetData({ status: 'pending', price, ...data });
        successAlert('requestSuccess');
        userIsAdmin ? await onApprove({ ...asset, price }) : handleClose();
    };

    const [onSubmit, submitting] = useAsyncAction<AssetListingFormFields, void>(
        action,
        { error: 'requestError', silent }
    );

    return {
        onClose,
        onSubmit,
        submitting,
        assetName,
        formMethods
    };
};
