import { useContext } from 'react';
import { BlockChainContext } from 'blockchain/BlockchainContext';
import { MainButton } from 'components/buttons/MainButton';
import { TorusService } from 'services/TorusService';

export const OpenWalletButton = (): JSX.Element => {
    const { torus } = useContext(BlockChainContext);
    return (
        <MainButton
            disableElevation
            textId="openWallet"
            variant="contained"
            onClick={async () => await TorusService.showWallet(torus!, 'home')}
        />
    );
};
