import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import MuiTab from '@mui/material/Tab';
import AppBar from '@mui/material/AppBar';
import { useTranslation } from 'react-i18next';

import { PortfolioTabHeader } from 'config/types';
import { useEffect, useState } from 'react';

type Props = {
    value: number;
    headers: PortfolioTabHeader[];
    onChange: (alue: number) => void;
};

const a11yProps = (index: number): { id: string; 'aria-controls': string } => ({
    id: `portfolio-item-${index}`,
    'aria-controls': `portfolio-item-table-${index}`
});

const Tab = styled(MuiTab)(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    minHeight: 52,
    fontWeight: theme.typography.fontWeightRegular,
    background: theme.palette.success.dark,
    svg: {
        width: 30,
        height: 30,
        position: 'relative',
        left: -10
    },
    '&.Mui-selected': {
        borderRadius: '5px',
        background: theme.palette.primary.dark,
        fontWeight: theme.typography.fontWeightRegular,
        color: 'white'
    }
}));

const AppBarContainer = styled(AppBar)(({ theme }) => ({
    borderRadius: '5px 5px 0 0',
    background: theme.palette.success.dark
}));

export const PortfolioTabs = ({
    value,
    headers,
    onChange
}: Props): JSX.Element => {
    const { t } = useTranslation();
    const [variant, setVariant] = useState('fullWidth');
    useEffect(() => {
        const handleResize = (): any => {
            if (window.innerWidth <= 290) {
                setVariant('scrollable');
            } else {
                setVariant('fullWidth');
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <AppBarContainer position="static">
            <Tabs
                sx={{
                    borderRadius: '5px 5px 0 0'
                }}
                value={value}
                aria-label="portofolio items"
                textColor="inherit"
                variant={variant}
                indicatorColor="primary"
                onChange={(_, val) => onChange(val)}>
                {headers.map(({ label, Icon }, i) => (
                    <Tab
                        key={label}
                        label={t(label)}
                        icon={<Icon />}
                        aria-label={t(label)}
                        iconPosition="start"
                        {...a11yProps(i)}
                    />
                ))}
            </Tabs>
        </AppBarContainer>
    );
};
