import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { Row } from 'components/Row';
import { CardTitle } from 'components/CardTitle';
import { AlignRight } from 'components/AlignRight';
import { ViewAllLink } from 'components/ViewAllLink';
import { StandardCard } from 'components/StandardCard';
import { FetchingScreen } from 'components/FetchingScreen';
import { UserHistoricalActivityItem } from 'config/types/user';
import { UserHistoricalActivityCard } from 'features/dataCards/userHistoricalActivityCard/UserHistoricalActivityCard';

import { SwitchCurrencyButton } from './SwitchCurrencyButton';
import { useUserHistoricalActivitySnapshot } from './useUserHistoricalActivitySnapshot';

export const UserHistoricalActivitySnapshot = (): JSX.Element => {
    const { list, fetching, currency, onChangeCurrency } =
        useUserHistoricalActivitySnapshot();

    return (
        <StandardCard sx={{ maxWidth: 400, height: 550 }}>
            <Row
                sx={{ mb: 1 }}
                spacing={8}
                alignItems="baseline"
                justifyContent="space-between">
                <CardTitle textId="historicalActivity" />
                {!!list?.length && (
                    <ViewAllLink to="/portfolio-tables/historical-activity" />
                )}
            </Row>
            <FetchingScreen<UserHistoricalActivityItem[]>
                fullscreen
                data={list}
                empty={!list?.length}
                fetching={fetching}>
                {(data: UserHistoricalActivityItem[]) => (
                    <>
                        <AlignRight>
                            <SwitchCurrencyButton onToggle={onChangeCurrency} />
                        </AlignRight>
                        <Box sx={{ mt: 2, height: 380 }}>
                            <PerfectScrollbar>
                                <Stack spacing={4}>
                                    {data.map((item) => (
                                        <UserHistoricalActivityCard
                                            key={item.id}
                                            data={item}
                                            currency={currency}
                                        />
                                    ))}
                                </Stack>
                            </PerfectScrollbar>
                        </Box>
                    </>
                )}
            </FetchingScreen>
        </StandardCard>
    );
};
