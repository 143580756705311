import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import MuiCard from '@mui/material/Card';
import { styled } from '@mui/material/styles';

import { Row } from 'components/Row';
import { Typography } from 'components/Typography';
import { LightDivider } from 'components/LightDivider';
import { LightButton } from 'components/buttons/LightButton';
import { BlackButton } from 'components/buttons/BlackButton';
import { PostItemDrop } from 'config/types';
import { defaultCurrencySymbol } from 'config';
import { ReactComponent as ShieldIcon } from 'assets/shield.svg';
import { ReactComponent as WatchImage } from 'assets/watch-example.svg';
import { BoughtInDropsBar } from './BoughtInDropsBar';

type Props = {
    data: PostItemDrop;
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const Card = styled(MuiCard)(({ theme }) => {
    return {
        background: theme.palette.error.dark,
        paddingBottom: '10px'
    };
});

export const PostItemDropTradingCard = ({
    data,
    onClick
}: Props): JSX.Element => (
    <Card
        sx={{
            '@media (max-width: 290px)': {
                width: '220px',
                scale: '90%',
                position: 'relative',
                left: '-20px'
            }
        }}>
        <Row sx={{ pr: 2, pt: 1 }} justifyContent="flex-end">
            <ShieldIcon height={20} width={20} />
        </Row>
        <Stack sx={{ mt: 1, mx: 3 }} spacing={2}>
            <Row alignItems="center" justifyContent="space-between">
                <Typography uppercased variant="h6">
                    {data.product}
                </Typography>
                <WatchImage height={170} width={260} />
            </Row>
            <Box sx={{ height: 30 }}>
                {data.boughtInDrops && (
                    <BoughtInDropsBar percentage={data.boughtInDrops} />
                )}
            </Box>
            <Stack>
                <Typography bold variant="h4">
                    {defaultCurrencySymbol}
                    {data.price.toLocaleString()}
                </Typography>
            </Stack>
            <LightDivider />
            <Row justifyContent="space-between">
                <LightButton textId="invest now" onClick={onClick} />
                <BlackButton textId="details" />
            </Row>
        </Stack>
    </Card>
);
